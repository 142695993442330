import { List, Skeleton } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPoint } from 'api/types/v1.0/points';
import './PointsList.scss';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';

export interface IProps {
    listPoints: {
        data: IPoint[] | null,
        totalCount: number,
    };
    refresh: (isFirstFetch: boolean) => void;
    onPointClick: (point: IPoint) => void;
}

export const PointsList: React.FC<IProps> = (props) => {
    const { data, totalCount } = props.listPoints;

    const handleClickPoint = (point: IPoint) => {
        props.onPointClick(point);

        browserHistory.push(`${Pages.PointInfo.url}/${point.id}`);
        setTimeout(() => {
            window.scrollTo(0, -3000);
        }, 100);
    };

    return (
        <div>
            <div>
                <InfiniteScroll
                    dataLength={data ? data.length : 0}
                    next={() => props.refresh(false)}
                    hasMore={data ? data.length < totalCount : false}
                    loader={<Skeleton paragraph={{ rows: 1 }} style={{ padding: 20 }} active />}
                >
                    <List
                        bordered
                        dataSource={data ?? undefined}
                        renderItem={(item) => (
                            <List.Item className={'object-list-item'} onClick={() => {
                                handleClickPoint(item);
                            }}>
                                <div className={'object-title'}>
                                    {item.title}
                                </div>
                                <div className={'object-region'}>
                                    {item.address}
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
};
