import { INewsItem } from 'api/types/v1.0/news';
import { SET_LOADING, SET_REGIONAL_NEWS, SET_FEDERAL_NEWS } from './actionTypes';

export const setRegionalNews = (regionalNews: INewsItem[] | null) =>
    ({ type: SET_REGIONAL_NEWS, payload: regionalNews } as const);

export const setFederalNews = (federalNew: INewsItem[] | null) =>
    ({ type: SET_FEDERAL_NEWS, payload: federalNew } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);
