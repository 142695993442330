import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import ConfirmModal from '../StatesmanPage/components/ActivitiesList/ActivityCard/components/ConfirmModal/ConfirmModal';
import CitizenGroupsList from './components/CitizenGroupsList/CitizenGroupsList';
import CitizenGroupsSearch from './components/CitizenGroupsSearch/CitizenGroupsSearch';
import useCitizenGroupsPage from './hooks';

const CitizenGroupsPage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);

    const {
        groups,
        totalCount,
        refresh,
        setGroupsByInput,
        deleteGroup,
        setTotalCount,
        isConfirmModalOpen,
        setConfirmModalOpen,
        leaveGroup,
        isLoading,
    } = useCitizenGroupsPage(Number(user?.sub));

    return <Page
        content={
            <Page.Content sidebar={'Main'}>
                <div className={'favorite-page-wrapper'}>
                    <div className={'favorite-page-header'}>Группы</div>
                    <CitizenGroupsSearch setGroupsByInput={setGroupsByInput} />
                    <CitizenGroupsList
                        groups={groups}
                        deleteGroup={deleteGroup}
                        setTotalCount={setTotalCount}
                        totalCount={totalCount}
                        refresh={refresh}
                        user={user}
                        setConfirmModalOpen={setConfirmModalOpen}
                    />
                    <ConfirmModal
                        yesActionLoading={isLoading}
                        onYesAction={leaveGroup}
                        title={'Подтвердите что хотите выйти из группы'}
                        isOpen={isConfirmModalOpen}
                        setIsOpen={setConfirmModalOpen}
                    />
                </div>
            </Page.Content>
        }
    />;
};

export default CitizenGroupsPage;
