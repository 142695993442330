import { Reducer } from 'redux';
import { ActionsType, IState } from './types';
import * as actionTypes from './actionTypes';

export const defaultState: IState = {
    isLoading: false,
    isJoinGroupModalOpen: false,
    isTakePartModalOpen: false,
    joinGroupYesButtonLoading: false,
    needToHideButton: false,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_JOIN_GROUP_YES_BUTTON_LOADING:
            return {
                ...state,
                joinGroupYesButtonLoading: action.payload,
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_IS_NEED_TO_HIDE_BUTTON:
            return {
                ...state,
                needToHideButton: action.payload,
            };

        case actionTypes.SET_IS_JOIN_GROUP_MODAL_OPEN:
            return {
                ...state,
                isJoinGroupModalOpen: action.payload,
            };

        case actionTypes.SET_IS_TAKE_PART_MODAL_OPEN:
            return {
                ...state,
                isTakePartModalOpen: action.payload,
            };

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
