import React from 'react';
import { Button } from 'antd';
import { Pages } from 'constants/links';
import { redirect } from 'utils/browser';
import styles from './LayoutFooter.module.scss';

const LayoutFooter: React.FC = () => {
    return (
        <>
            <div className={styles['layout-footer']}>
                <div className={styles['menu']}>
                    <div className={styles['logo']}></div>
                    <div className={styles['footer-menu']}>
                        <div className={styles['element']}>
                            <Button className={'icon-button'} type="link" onClick={() => redirect(Pages.Hints.url)}>
                                <span className={styles['text']}>Подсказки</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles['menu']}>
                    <div className={styles['divider']} />
                </div>
                <div className={styles['menu']}>
                    <div className={styles['copyright']}>
                        <span className={styles['text']}>© 2005-{new Date().getFullYear()}, Партия &quot;Единая Россия&quot;. Все права защищены.</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LayoutFooter;
