import { SelectOptions } from '../constants/SelectOptions';

export const getReasonOfRemoval = (reason: string) => {
    switch (reason) {
        case SelectOptions[0].value:
            return 0;
        case SelectOptions[2].value:
            return 2;
        default:
            return 1;
    }
};
