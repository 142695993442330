import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import { IFederalCoordinatorProfileResponse } from '../types/v1.0/federalCoordinatorProfile';
import { ICoordinatorStatisticsResponse } from '../types/v1.0/coordinatorStatistics';
import { IStatisticsByObjectsRequest } from './regionalCoordinatorProfile';

export const getFederalCoordinatorProfile = async (deputyId: number) => {
    const request = await axiosPostRequest< { Id: number } ,
        IFederalCoordinatorProfileResponse>(ApiPaths.FederalCoordinator, { Id: deputyId });
    return request.data;
};

export const getFederalCoordinatorStatistics = async (req: IStatisticsByObjectsRequest) => {
    const request = await axiosPostRequest< IStatisticsByObjectsRequest ,
        ICoordinatorStatisticsResponse>(ApiPaths.FederalCoordinatorStatistics, req);
    return request.data;
};
