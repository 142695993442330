import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useField } from 'formik';
import TextArea from 'antd/es/input/TextArea';
import { validationMessages } from 'constants/validationMessages';
import { MAX_AREA_LENGTH } from 'constants/numbers';
import styles from './DescriptionTextAreaField.module.scss';

export const DescriptionTextAreaField: React.FC = () => {
    const name = 'description';
    const placeholder = 'Описание';

    const [field, meta, { setValue, setTouched }] = useField<string>(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            name='description'
            initialValue={field.value}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: true, message: validationMessages.description }]}
            className={styles.descriptionTextAreaFieldContainer}
        >
            <div className={styles.descriptionTextAreaFieldPlaceholderAsterisk}>
                <TextArea
                    maxLength={MAX_AREA_LENGTH}
                    className={styles.descriptionTextAreaField}
                    itemType={'text'}
                    placeholder={placeholder}
                    value={field.value as string | number | undefined}
                    onChange={onChange}
                />
            </div>
        </Form.Item>
    );
};
