import './OrangeButtonsModal.scss';
import React from 'react';
import { Divider, Modal } from 'antd';

export interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    subtitle?: string;
    children: React.ReactNode;
}

const OrangeButtonsModal: React.FC<IProps> = (props: IProps) => {
    return (
        <Modal
            className={'orange-buttons-modal'}
            closable={false}
            footer={null}
            open={props.open}
            onOk={props.onClose}
            onCancel={props.onClose}
        >
            <div className={'title'}>{props.title}</div>
            {!!props.subtitle && <div className={'subtitle'}>{props.subtitle}</div>}
            <Divider style={{ margin: 0 }} />
            <div className={'content-wrapper'}>
                {props.children}
            </div>
        </Modal>
    );
};

export default OrangeButtonsModal;
