import React, { Fragment } from 'react';
import { Button } from 'antd';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import useTakePartButton from './hooks';

interface IProps {
    activity: IDeputyActivity;
    setShowYouParticipantButton: (showYouParticipantButton: boolean) => void;
    isUserBanned: boolean;
}

const TakePartButton: React.FC<IProps> = ({ activity, setShowYouParticipantButton, isUserBanned }) => {
    const {
        takePart,
        checkGroupAndTakePartIfPossible,
        joinGroup,
        isTakePartModalOpen,
        isJoinGroupModalOpen,
        joinGroupYesButtonLoading,
        isLoading,
        needToHideButton,
        setIsJoinGroupModalOpen,
        setIsTakePartModalOpen,
    } = useTakePartButton(activity, setShowYouParticipantButton);

    const onTakePartButtonClick = async () => {
        isUserBanned
            ? alert('Вы были исключены из группы объекта')
            : await checkGroupAndTakePartIfPossible();
    };

    const onJoinGroupAction = async () => {
        const isJoinedSuccessfully = await joinGroup();

        if (isJoinedSuccessfully) {
            setIsJoinGroupModalOpen(false);
            setIsTakePartModalOpen(true);
        }
    };

    const onTakePartModalAction = async () => {
        await takePart();
        setIsTakePartModalOpen(false);
    };

    return <Fragment>
        <ConfirmModal
            isOpen={isJoinGroupModalOpen}
            setIsOpen={setIsJoinGroupModalOpen}
            onYesAction={onJoinGroupAction}
            title={'Чтобы принять участие нужно вступить в группу объекта. Вы уверены что хотите вступить в группу?'}
            yesActionLoading={joinGroupYesButtonLoading}
        />

        <ConfirmModal
            isOpen={isTakePartModalOpen}
            setIsOpen={setIsTakePartModalOpen}
            onYesAction={onTakePartModalAction}
            title={'Вы уверены что хотите принять участие в мероприятии?'}
            yesActionLoading={isLoading}
        />

        <Button
            hidden={needToHideButton}
            loading={isLoading}
            className={'activity-card-action-button'}
            onClick={onTakePartButtonClick}>
            Принять участие
        </Button>
    </Fragment>;
};

export default TakePartButton;
