import './index.scss';
import './App/init/initAxios';
import './App/init/initDateTime';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
// eslint-disable-next-line
// @ts-ignore
import DocumentMeta from 'react-document-meta';
import App from 'App';
import browserHistory from 'App/root/browserHistory';
import ErrorBoundary from 'components/ErrorBoundary';
import { initApp } from 'ducks/app/actionCreators';
import store from 'App/root/store';
import MobileDetector from 'components/MobileDetector/MobileDetector';

document.body.classList.add('theme__default');

const meta = {
    title: 'Народная программа',
    meta: {
        property: {
            'og:url': window.location.origin,
            'og:type': 'website',
            'og:title': 'Народная программа',
            'og:description': 'Спутник народной программы',
            'og:image': `${window.location.origin}/favicon.png`,
            'og:image:secure_url': `${window.location.origin}/favicon.png`,
            'og:image:type': 'image/png',
        },
    },
};

ReactDOM.render(
    <ErrorBoundary>
        <MobileDetector>
            <Provider store={store}>
                <Router history={browserHistory}>
                    <DocumentMeta {...meta}>
                        <App />
                    </DocumentMeta>
                </Router>
            </Provider>
        </MobileDetector>
    </ErrorBoundary>,
    document.getElementById('root')
);

store.dispatch(initApp());
