import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Spin } from 'antd';
import ChatConversationCard from 'components/Layout/components/LayoutHeader/components/Chat/components/ChatConversationCard/ChatConversationCard';
import GroupListMembers from 'components/Layout/components/LayoutHeader/components/Chat/components/GroupMembersListCard/GroupListMembers';
import useChatModal from 'components/Layout/components/LayoutHeader/components/Chat/hooks/useChatModal';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import { Role } from 'types/account';
import { hasRole } from 'utils/user';
import styles from './ChatsPage.module.scss';

interface IChatProps {
    isGroupMembersListHidden?: boolean;
}
export const Chat: React.FC<IChatProps> = () => {
    const router = useParams<{ selectedChatId: string }>();
    const { isGroupMembersOpen } =
        useSelector((state: RootState) => state.chat);
    const selectedChatId = Number(router.selectedChatId);
    const [isPreviewHidden] = useState(false);
    const [isGroupMembersListHidden, setIsGroupMembersListHidden] = useState(!isGroupMembersOpen ?? true);
    const { user } = useSelector((state: RootState) => state.account);
    const {
        groupMessages,
        messagesTotalCount,
        isChatMessagesLoading,
        isSendMessageLoading,
        groupMembersCount,
        getGroupMessagesList,
        getInitialGroupMessageList,
        sendMessageSeen,
        replyMessage,
        sendMessage,
        removeMessage,
        getGroupMembersCount,
        changeMessagesSort,
        sortType,
        sortDirection,
    } = useChatModal(user?.sub);
    const isResponse = useSelector((state: RootState) => state.chat.isResponse);
    const isEditDisabled = hasRole([Role.Municipal], user?.role as Role) && !isResponse;

    useEffect(() => {
        getInitialGroupMessageList(selectedChatId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType, sortDirection]);

    return (
        <Page content={
            <Page.Content sidebar={'MainWithoutNews'}>
                <div className={styles.chatConversationPageWrapper}>
                    <Spin spinning={isChatMessagesLoading || isSendMessageLoading}>
                        <Card style={{ width: '100%' }}>
                            {isGroupMembersListHidden &&
                                (selectedChatId ?
                                    <div style={{ display: 'flex', flexDirection:'row' }}>
                                        <ChatConversationCard
                                            isChatMessagesLoading={isChatMessagesLoading}
                                            isSendMessageLoading={isSendMessageLoading}
                                            sendMessage={sendMessage}
                                            group_id={selectedChatId}
                                            groupMessages={groupMessages}
                                            groupMembersCount={groupMembersCount}
                                            getGroupMembersCount={getGroupMembersCount}
                                            replyMessage={replyMessage}
                                            messagesTotalCount={messagesTotalCount}
                                            isEditDisabled={isEditDisabled}
                                            getGroupMessagesList={getGroupMessagesList}
                                            getInitialGroupMessageList={getInitialGroupMessageList}
                                            sendMessageSeen={sendMessageSeen}
                                            setIsGroupMembersListHidden={setIsGroupMembersListHidden}
                                            removeMessage={removeMessage}
                                            changeMessagesSort={changeMessagesSort}
                                        />
                                    </div>
                                    : !selectedChatId &&
                                    <div className={styles.missingChatTextWrapper}>
                                        <span className={styles.missingChatText}>
                                            Выберите чат в который хотите написать или
                                            перейдите на страницу объектов и войдите в новый
                                        </span>
                                    </div>
                                )}
                            {!isGroupMembersListHidden && <div className={isPreviewHidden
                                ? styles.groupMembersListCardWrapperFull
                                : styles.groupMembersListCardWrapper
                            }>
                                <GroupListMembers
                                    setIsGroupMembersListHidden={setIsGroupMembersListHidden}
                                    isEditDisabled={isEditDisabled}
                                    selectedGroup_Id={selectedChatId}
                                />
                            </div>}
                        </Card>
                    </Spin>
                </div>
            </Page.Content>
        } />
    );
};
