import React from 'react';
import Icon from 'ui-kit/Icon';
import './FolkCheckbox.scss';

interface IProps {
    onChange?: () => void,
    checked?: boolean,
}

const FolkCheckbox: React.FC<IProps> = ({ onChange, checked, ...props }) => {
    const defaultChecked = checked ?? false;

    const onChangeHandler = () => {
        if (onChange) {
            onChange();
        }
    }

    return (
        <label className="checkBox">
            <input
                type={'checkbox'}
                checked={defaultChecked}
                onChange={onChangeHandler}
                {...props}
            />
            <Icon  type={'Check'} className='checkmark'/>
        </label>
    );
};

export default FolkCheckbox;
