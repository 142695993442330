import React, { useState } from 'react';
import { Button, Carousel, Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fileTypes } from 'constants/fileTypesDictionary';
import { MediaCamera } from 'ui-kit/MediaCamera/MediaCamera';
import { MediaImage } from 'ui-kit/MediaImage/MediaImage';
import Icon from 'ui-kit/Icon';
import usePointInfoMedia from './hooks/usePointInfoMedia';
import defaultImage from './images/defaultPointImage.jpg';
import './PointMedia.scss';

const settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    swipeToSlide: true,
    draggable: true,
};

const PointMedia: React.FC<{ pointId: number }> = ({ pointId }) => {
    const { isLoading, pointMedia } = usePointInfoMedia({ id: pointId });

    const [mainMediaLoading, setMainMediaLoading] = useState(true);

    const [currentMedia, setCurrentMedia] = useState(0);
    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            {pointMedia &&
                <>
                    <div className={'main-carousel-media'}>
                        <div>
                            {
                                pointMedia[currentMedia].type === fileTypes.Video
                                    ? (<MediaCamera
                                        customLoading={mainMediaLoading}
                                        setCustomLoading={setMainMediaLoading}
                                        videoContentUrl={pointMedia[currentMedia].media_url}
                                        isMainVideo={true}
                                        preview />)
                                    : (<MediaImage
                                        customLoading={mainMediaLoading}
                                        setCustomLoading={setMainMediaLoading}
                                        url={pointMedia[currentMedia].media_url} />)
                            }
                        </div>
                    </div>
                    {pointMedia.length > 4 &&
                        <Carousel
                            arrows
                            prevArrow={<Button shape={'circle'} icon={<Icon type={'ArrowLeft'} />} />}
                            nextArrow={<Button shape={'circle'} icon={<Icon type={'ArrowRight'} />} />}
                            className={'carousel-wrapper'} {...settings}
                            beforeChange={(currentSlide, to) => {
                                if (to === currentSlide) {
                                    return;
                                }

                                setMainMediaLoading(true);
                                setCurrentMedia(to);
                            }}>
                            {pointMedia.map((item) =>
                                <MediaImage
                                    showVideoIcon={item.type === fileTypes.Video}
                                    key={item.Id}
                                    preview={false}
                                    url={item.preview_url} />)
                            }
                        </Carousel>
                    }
                    {(pointMedia.length > 0 && pointMedia.length <= 4) &&
                        <div className={'media-list'}>
                            {
                                pointMedia.map((item, index) =>
                                    <MediaImage
                                        onClick={() => {
                                            if (currentMedia !== index) {
                                                setMainMediaLoading(true);
                                                setCurrentMedia(index);
                                            }
                                        }}
                                        showVideoIcon={item.type === fileTypes.Video}
                                        key={item.Id}
                                        preview={false}
                                        url={item.preview_url} />)
                            }
                        </div>
                    }
                </>
            }
            {!pointMedia && !isLoading &&
                <div className={'main-carousel-media'}>
                    <div>
                        <Image src={defaultImage} />
                    </div>
                </div>
            }
        </Spin>
    );
};

export default PointMedia;
