import { IAccount, Role } from '../types/account';

export const hasRole = (account: IAccount, role: Role) => {
    return role === account.role;
};

export const hasDeputyRole = (user: IAccount | null) => {
    return [Role.Municipal, Role.Regional, Role.Federal].some((r) => user && hasRole(user, r));
};

export const hasCitizenRole = (user: IAccount | null) => {
    return !!user && hasRole(user, Role.Citizen);
};

export const hasMunicipalRole = (user: IAccount | null) => {
    return !!user && hasRole(user, Role.Municipal);
};

export const hasCoordinatorRole = (user: IAccount | null) => {
    return [Role.Regional, Role.Federal].some((r) => user && hasRole(user, r));
};
