import { isDesktop } from 'react-device-detect';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import { ReactComponent as ChatIcon } from 'components/Layout/components/LayoutHeader/assets/chat-icon.svg';
import FolkButton from 'ui-kit/FolkButtons';
import ConfirmModal from '../ActivitiesList/ActivityCard/components/ConfirmModal/ConfirmModal';
import useChatButton from './hooks/useChatButton';
import './index.scss';

export interface IProps {
    pointId: number;
    responsibleDepId: number;
    isUserSubscribedToGroup: boolean;
    isUserDeletedFromGroup: boolean;
}

const ChatButton: React.FC<IProps> = (props: IProps) => {
    const {
        user,
        joinGroupYesButtonLoading,
        handleChatButtonClick,
        isJoinGroupModalOpen,
        handleJoinGroupAction,
        setIsJoinGroupModalOpen,
    } = useChatButton(props.pointId, props.responsibleDepId, props.isUserSubscribedToGroup);

    return (
        <Fragment>
            <ConfirmModal
                isOpen={isJoinGroupModalOpen}
                setIsOpen={setIsJoinGroupModalOpen}
                onYesAction={handleJoinGroupAction}
                yesActionLoading={joinGroupYesButtonLoading}
                title={'Для получения доступа к чату нужно вступить в группу объекта. Вы уверены что хотите вступить в группу?'}
            />

            {
                !props.isUserDeletedFromGroup &&
                    <FolkButton
                        className={classnames('chat-button', !user ? 'disabled-button' : '')}
                        onClick={handleChatButtonClick}
                    >
                        <div className={'chat-button-content'}>
                            {isDesktop ? 'Перейти в чат' : undefined}
                            <ChatIcon className={'chat-icon'}></ChatIcon>
                        </div>
                    </FolkButton>
            }
        </Fragment>
    );
};

export default ChatButton;
