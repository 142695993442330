import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState, PointSortType } from './types';

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

export const defaultState: IState = {
    points: null,
    isLoading: false,
    sortType: PointSortType.rating,
    descending: true,
    totalCount: 0,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_FEDERAL_COORDINATORS_POINTS:
            return {
                ...state,
                points: [...state.points ?? [], ...action.payload ?? [] ],
            };
        case actionTypes.SET_TOTALCOUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.CLEAR_COORDINATOR_LIST:
            return {
                ...state,
                totalCount: 0,
                points: null,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_SORT_BY_ALPHABET:
            return {
                ...state,
                sortType: PointSortType.title,
            };
        case actionTypes.SET_SORT_BY_RATING:
            return {
                ...state,
                sortType: PointSortType.rating,
            };

        default:
            return state;
    }
};

export default reducer;
