import axios, { AxiosRequestConfig } from 'axios';
import config from 'configuration.json';
import { ApiPaths } from 'constants/ApiPaths';
import { login } from 'ducks/account/actionCreators';
import store from 'App/root/store';
import { IAccountResponse } from '../api/types/v1.0/account';
import { getAccount } from '../api/v1.0/account';

export type SuccessAuthResponse = {
  token: string;
};

export type TokenPayload =
{
    sub: string;
    oi_prst: string;
    client_id: string;
    scope: string;
    exp: string;
    iss: string;
    iat: string;
}

export enum TAuthStateEnum {
  UNKNOWN = 'UNKNOWN',
  LOADING = 'LOADING',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum TAuthTokenField {
  AUTH_STATE = 'AUTH_STATE',
  USER_ID = 'USER_ID',
  TOKEN_ACCSES = 'TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  TOKEN_EXPIRATION_OFFSET = 'TOKEN_EXPIRATION_OFFSET',
}

export const AuthHelper = {
    getAuthorizeCodeUrl() : string
    {
        const { redirectUrlLocal, redirectUrl } = config.authorization;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return redirectUrlLocal;
        }

        return redirectUrl;
    },

    async setAccessToken(code: string): Promise<boolean>
    {
        const configAxios: AxiosRequestConfig =
        {
            method: 'POST',
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
            },

            withCredentials: false,
        };

        let request;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            request = new URLSearchParams ({
                'code': code,
            });
        } else {
            request = new URLSearchParams ({
                'code': code,
                'redirect_uri': 'https://barsup-bi.bars.group/ernp-dev/oauth/callbackWeb',
            });
        }

        const response = await axios.post(ApiPaths.Token, request, configAxios);

        if (response.status === 200)
        {
            const data = response.data as SuccessAuthResponse;
            store.dispatch(login(data.token));
            return true;
        }

        return false;
    },

    async Authorize(code: string): Promise<IAccountResponse> {
        await this.setAccessToken(code);
        const { data } = await getAccount();
        if (data.name) {
            return data;
        } else {
            return {} as IAccountResponse;
        }
    },
};
