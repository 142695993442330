import React from 'react';
import { useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import { RootState } from 'App/root/rootReducer';
import { hasDeputyRole, hasRole } from 'helpers/account';
import { Role } from 'types/account';
import CitizenMenu from '../CitizenMenu';
import DeputyMenu from '../DeputyMenu/DeputyMenu';
import NewsSidebar from '../News/NewsSidebar';
import styles from './MainSidebar.module.scss';

interface IProps {
    hideNews?: boolean;
}

const MainSidebar: React.FC<IProps> = (props) => {
    const user = useSelector((state: RootState) => state.account.user);

    const hasCitizenRole = !!user && hasRole(user, Role.Citizen);
    const hasDeputyRoleVar = hasDeputyRole(user);

    return (
        <>
            {hasCitizenRole && isDesktop && <CitizenMenu />}
            {hasDeputyRoleVar && isDesktop && <DeputyMenu />}
            {!props.hideNews && (
                <div className={styles['mainContainer']}>
                    <NewsSidebar />
                </div>
            )}
        </>
    );
};

export default MainSidebar;
