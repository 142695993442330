import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

export const defaultState: IState = {
    favorites: null,
    isLoading: false,
    totalCount: 0,
    isExpanded: false,
};

const citizenMenuFavoritesReducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CITIZEN_MENU_FAVORITES:
            return {
                ...state,
                favorites: action.payload,
            };

        case actionTypes.SET_CITIZEN_MENU_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_CITIZEN_MENU_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.DELETE_CITIZEN_MENU_FAVORITES:
            return {
                ...state,
                favorites: [],
            };

        case actionTypes.DELETE_CITIZEN_MENU_FAVORITE:
            return {
                ...state,
                favorites: state.favorites
                    ? state.favorites.filter((value) => value.Id !== action.payload)
                    : null,
            };

        case actionTypes.SET_CITIZEN_MENU_FAVORITES_IS_EXPANDED:
            return {
                ...state,
                isExpanded: action.payload,
            };

        case actionTypes.CLEAR_CITIZEN_MENU_FAVORITES:
            return defaultState;

        default:
            return state;
    }
};

export default citizenMenuFavoritesReducer;
