import React, { MouseEventHandler } from 'react';
import { Col, Row } from 'antd';
import { isDesktop } from 'react-device-detect';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import FolkButton from 'ui-kit/FolkButtons';
import styles from './ToolBar.module.scss';

interface IProps {
    onProjectChange: (id: number | null) => void,
    onObjectChange: (id: number | null) => void,
    projectId: number | null,
    isUserSelected: boolean,
    isObjectSelected: boolean,
    pointId: number | null;
    onPost: MouseEventHandler,
    userId: string,
}

const ToolBar: React.FC<IProps> = (
    {
        onProjectChange,
        onObjectChange,
        isUserSelected,
        isObjectSelected,
        projectId,
        pointId,
        onPost,
        userId,
    }
) => {
    return (
        <Row wrap={false} gutter={[24, 24]} className={styles['toolBar']}>
            <Col flex={1}>
                <Row gutter={24} style={{ height: '100%' }}>
                    <Col span={12}>
                        <div className={styles['selector']}>
                            <ProjectFilter
                                userId={userId}
                                managedValue={projectId}
                                onChange={onProjectChange}
                                alwaysShowSuffix={true}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles['selector']}>
                            <PointFilter
                                userId={userId}
                                managedValue={pointId}
                                defaultPointId={pointId ?? undefined}
                                onChange={onObjectChange}
                                projectId={projectId ?? undefined}
                                alwaysShowSuffix={true}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
            {isDesktop &&
                <Col flex={'0 0 168px'}>
                    <FolkButton
                        className={styles['button']}
                        disabled={!(isUserSelected && isObjectSelected)}
                        onClick={onPost}>
                        Пригласить
                    </FolkButton>
                </Col>}
        </Row>
    );
};

export default ToolBar;
