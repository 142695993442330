import React, { useEffect, useState } from 'react';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { handleApiError } from 'api/v1.0/common';
import { PointSortType } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';
import DeputyPoint from '../DeputyPoint';
import DeputySortPoints from '../DeputySortPoints';
import useDeputyPoints from './hooks/useDeputyPoints';
import './DeputyPoints.scss';

interface IDeputyPointsProps{
    deputyId: number,
    changePoint: (point: number) => void;
    clearPoints: () => void;
    compact?: boolean;
}

const DeputyPoints: React.FC<IDeputyPointsProps> = (props) => {
    const { state, changeSort, refresh } = useDeputyPoints({
        deputyId: props.deputyId,
        sortType: PointSortType.rating,
        page: { start:0, end:10 } });
    const [isClearSelected, setClearSelected] = useState(false);
    const [pointSortType, setPointSortType] = useState<PointSortType>(PointSortType.rating);
    const loadMoreData = () => {
        refresh((state.points?.data.length ?? 0) + 10);
    };

    useEffect(() => {
        props.clearPoints();
        setClearSelected(!isClearSelected);
        //eslint-disable-next-line
    },[pointSortType]);

    return (
        <>
            <div className={'deputy-points-sort'}>
                <DeputySortPoints
                    onKeyChange={(val) => {
                        changeSort(val).then( (_) => {
                            setPointSortType(val === PointSortType.rating ? PointSortType.rating : PointSortType.title);
                        }).catch((e)=> handleApiError(e));
                    }}
                />
            </div>
            <div className='deputy-points-wrapper'>
                <InfiniteScroll
                    dataLength={ state.points?.data ? state.points?.data.length : 0}
                    next={loadMoreData}
                    hasMore={ state.points?.data ? state.points?.data?.length < state.points?.totalCount : false}
                    loader={<Skeleton paragraph={{ rows: 2 }} active />}
                    scrollableTarget="scrollableDiv"
                ><List
                        itemLayout="horizontal"
                        dataSource={ state.points?.data ?? undefined}
                        renderItem={(item) => (
                            <List.Item className={'favorite-list-item'}>
                                <div className='deputy-list-item'>
                                    <DeputyPoint
                                        key={item.Id}
                                        data={item}
                                        onSelectedChange={(val) => {props.changePoint(val);}}
                                        clearSelected={isClearSelected}
                                        compact={props.compact}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </>);
};

export default DeputyPoints;
