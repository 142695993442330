import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { postRegionsList } from 'api/v1.0/regionList';
import { IRegionList } from 'api/types/v1.0/regionList';

export const useRegionListFilter = () => {
    const [state, setState] = useState<IRegionList>();
    const [loading, setLoading] = useState(false);

    const fetch = () => {
        setLoading(true);

        postRegionsList({ })
            .then((response) => {
                setState(response);
            })
            .catch((e) => handleApiError(e))
            .finally(() => setLoading(false));
    };

    const refresh = useCallback(
        () => fetch(),
        []
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        ...state,
        refresh,
        loading,
    };
};
