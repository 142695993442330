import { Col, Row } from 'antd';
import { IFilterStyleType, ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { RegionFilter } from 'ui-kit/filters/RegionFilter';
import ObjectViewSwitch from '../../ObjectViewSwitch';
import { IProps } from '../interface';

const MobileContainerFilter: React.FC<IProps> = ({
    selectedProjects,
    changeSelectedProject,
    selectedRegionId,
    changeSelectedRegion,
    changePointsVisualisation,
    pointsVisualisation,
}) => {
    return (
        <>
            <Col flex={1}>
                <Row gutter={[24, 16]} style={{ height: '100%' }}>
                    <Col span={24}>
                        <ProjectFilter
                            defaultProjectId={selectedProjects[0]?.Id}
                            onChange={changeSelectedProject}
                            alwaysShowSuffix
                        />
                    </Col>
                    <Col span={24}>
                        <RegionFilter
                            defaultRegionId={selectedRegionId ?? undefined}
                            managedValue={selectedRegionId}
                            type={IFilterStyleType.outlined}
                            onChange={changeSelectedRegion}
                            alwaysShowSuffix
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <ObjectViewSwitch changeView={changePointsVisualisation} pointsView={pointsVisualisation} />
            </Col>
        </>
    );
};

export default MobileContainerFilter;
