import React from 'react';
import { Button } from 'antd';
import { Pages } from 'constants/links';
import browserHistory from 'App/root/browserHistory';
import styles from './NewsDetailsButton.module.scss';

interface IProps {
    newsId: number;
}

const NewsDetailsButton: React.FC<IProps> = (props) => {
    const handleClick = (newsId: number) => {
        browserHistory.push(`${Pages.NewsDetails.url}/${newsId}`);
    };

    return (
        <Button
            className={styles.newsDetailsButton}
            onClick={() => handleClick(props.newsId)}
        >
            Подробнее
        </Button>
    );
};

export default NewsDetailsButton;
