/* eslint-disable */
/**
 * see for details: https://stackoverflow.com/a/2140644
 * (warning: function may not work with Unicode special characters)
 */
export const areEqualIgnoreCase = (first: string, second: string) =>
    first.toUpperCase() === second.toUpperCase();

export const capitalizeFirstLetter = (string: string) =>
    `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

export const isNullOrEmpty = (value: string) =>
    value === undefined || value === null || value.trim() === '';

export const converterTextToHtml = (text: string) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(text, 'text/html');
    return html.body.outerHTML.includes('null') ? 'Описания ещё нет' : html.body.outerHTML;
}