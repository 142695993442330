import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, notification, Row } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import Icon from 'ui-kit/Icon';
import {
    increaseAmountOfLikesInMenu,
    decreaseAmountOfLikesInMenu,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/LikesCitizenMenuList/reducer';
import { RootState } from 'App/root/rootReducer';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import styles from './LikeDislike.module.scss';

interface ILikeProps {
    likesCount: number;
    dislikesCount: number;
    isLiked: boolean;
    isDisliked: boolean;
    onLikeClick: () => Promise<void>;
    onDislikeClick: () => Promise<void>;
    isNotClickable?: boolean;
    isDisabled?: boolean;
}

const LikeDislike: React.FC<ILikeProps> = ({ likesCount, dislikesCount,
    onLikeClick, onDislikeClick, isDisliked, isLiked, isNotClickable, isDisabled }) => {
    const [stateLikesCount, setStateLikesCount] = useState<number>(0);
    const [stateDislikesCount, setStateDislikesCount] = useState<number>(0);

    const [stateIsLiked, setStateIsLiked] = useState<boolean>(false);
    const [stateIsDisliked, setStateIsDisliked] = useState<boolean>(false);

    const [api, contextHolder] = notification.useNotification();
    const account = useSelector((state: RootState) => state.account);
    const isLoggedIn = !!account.user;
    const [isLoading, setIsLoading] = useState(false);

    const globalDispatch = useDispatch();

    useEffect(() => {
        setStateIsLiked(isLiked);
        setStateIsDisliked(isDisliked);
        setStateLikesCount(likesCount);
        setStateDislikesCount(dislikesCount);
    }, [isLiked, isDisliked, likesCount, dislikesCount]);

    const openNotification = (placement: NotificationPlacement) => {
        api.info({
            message: 'Необходимо войти в группу, чтобы иметь возможность оценить',
            placement,
        });
    };

    const openErrorNotification = () => {
        setIsLoading(false);
        api.error({
            message: 'Произошла ошибка при проставлении/отмене лайка/дизлайка',
            placement: 'bottom',
        });
    };

    const checkAvailability = () => {
        if (!isLoggedIn)
        {
            redirect(AuthHelper.getAuthorizeCodeUrl(), false);
            return false;
        }

        if (isNotClickable)
        {
            openNotification('bottom');
            return false;
        }

        return true;
    };

    const onLikeIconClick = () => {
        const availableToLike = checkAvailability();

        if (!availableToLike) {
            return;
        }

        setIsLoading(true);

        if (stateIsDisliked)
        {
            //Убираем стиль дизлайка
            setStateIsDisliked(false);
            //Ставим стиль лайка
            setStateIsLiked(true);
            //Запрос на удаление дизлайка
            onDislikeClick()
                .then(() => onLikeClick().then(() => setIsLoading(false)).catch(openErrorNotification))
                .catch(openErrorNotification); //Запрос на добавление лайка
            setStateLikesCount(stateLikesCount + 1);
            setStateDislikesCount(stateDislikesCount - 1);
        }
        else if (!stateIsLiked)
        {
            //Ставим стиль лайка
            setStateIsLiked(true);
            //Запрос на добавление лайка
            onLikeClick().then(() => setIsLoading(false)).catch(openErrorNotification);
            setStateLikesCount(stateLikesCount + 1);
            globalDispatch(increaseAmountOfLikesInMenu());
        }
        else { // Хотим убрать поставленный лайк
            setStateIsLiked(false);
            onLikeClick().then(() => setIsLoading(false)).catch(openErrorNotification);
            setStateLikesCount(stateLikesCount - 1);
            globalDispatch(decreaseAmountOfLikesInMenu());
        }
    };

    const onDislikeIconClick = () => {
        const availableToDislike = checkAvailability();

        if (!availableToDislike) {
            return;
        }

        setIsLoading(true);

        if (stateIsLiked)
        {
            //Убираем стиль лайка
            setStateIsLiked(false);
            //Ставим стиль дизлайка
            setStateIsDisliked(true);
            //Запрос на удаление лайка
            onLikeClick()
                .then(() => onDislikeClick().then(() => setIsLoading(false)).catch(openErrorNotification))
                .catch(openErrorNotification); //Запрос на добавление дизлайка
            setStateDislikesCount(stateDislikesCount + 1);
            setStateLikesCount(stateLikesCount - 1);
        }
        else if (!stateIsDisliked)
        {
            //Ставим стиль дизлайка
            setStateIsDisliked(true);
            //Запрос на добавление дизлайка
            onDislikeClick().then(() => setIsLoading(false)).catch(openErrorNotification);
            setStateDislikesCount(stateDislikesCount + 1);
            globalDispatch(increaseAmountOfLikesInMenu());
        }
        else { // Хотим убрать поставленный дизлайк
            setStateIsDisliked(false);
            onDislikeClick().then(() => setIsLoading(false)).catch(openErrorNotification);
            setStateDislikesCount(stateDislikesCount - 1);
            globalDispatch(decreaseAmountOfLikesInMenu());
        }
    };

    return (
        <>
            {contextHolder}
            <Row gutter={28}>
                <Col>
                    <Button disabled={isLoggedIn && (isLoading || isDisabled)} className={styles['button']} onClick={onLikeIconClick}>
                        <Icon type={stateIsLiked ? 'LikeActive' : 'Like'} className={styles['like']} />
                        <div className={styles['numbers-container']}>
                            <span className={styles['numbers']}>
                                {stateLikesCount ?? 0}
                            </span>
                        </div>
                    </Button>
                </Col>
                <Col>
                    <Button disabled={isLoggedIn && (isLoading || isDisabled)} className={styles['button']} onClick={onDislikeIconClick}>
                        <Icon type={stateIsDisliked ? 'DisLikeActive' : 'DisLike'} className={styles['like']} />
                        <span className={styles['numbers']}>
                            {stateDislikesCount ?? 0}
                        </span>
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default LikeDislike;
