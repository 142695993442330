import { useCallback, useEffect, useReducer } from 'react';
import { getProtectedSourceUrl } from 'utils/protectedSourceAccess';
import { getRegionalCoordinatorProfile } from 'api/v1.0/regionalCoordinatorProfile';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

export interface IUseDeputyProfilePageProps {
    deputyId: number;
}

export const useRegionalCoordinatorProfilePage = ({ deputyId }: IUseDeputyProfilePageProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const fetchProfile = async () => {
        dispatch(actionCreators.deputyProfileFetch());
        try
        {
            const response = await getRegionalCoordinatorProfile({ id: deputyId });
            response.data.avatar = await getProtectedSourceUrl(response.data.avatar);
            dispatch(actionCreators.deputyProfileSet(response.data));
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.deputyProfileError(error));
        }
        finally {
            dispatch(actionCreators.deputyProfileLoad());
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = useCallback(() => void fetchProfile(), []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        state,
    };
};
