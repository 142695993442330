import React from 'react';
import './templates.scss';

const DisLikeActive: React.FC = () => {
    return (
        <svg className={'dislike-template-active'} viewBox="0 0 29 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6671 1.66663V16.3333M27.3337 12.0666V5.93329C27.3337 4.43982 27.3337 3.69308 27.0431 3.12265C26.7874 2.62089 26.3795 2.21294 25.8777 1.95728C25.3073 1.66663 24.5605 1.66663 23.0671 1.66663H8.82441C6.87577 1.66663 5.90145 1.66663 5.1145 2.0232C4.42093 2.33748 3.83146 2.84319 3.41538 3.48091C2.94329 4.20448 2.79514 5.16748 2.49883 7.09346L1.8014 11.6268C1.41059 14.167 1.21518 15.4372 1.59213 16.4255C1.92299 17.2929 2.54526 18.0182 3.35229 18.4771C4.27177 19 5.55684 19 8.12698 19H9.2004C9.94713 19 10.3205 19 10.6057 19.1453C10.8566 19.2731 11.0606 19.4771 11.1884 19.728C11.3337 20.0132 11.3337 20.3866 11.3337 21.1333V25.0455C11.3337 26.8613 12.8057 28.3333 14.6215 28.3333C15.0546 28.3333 15.4471 28.0782 15.623 27.6825L20.104 17.6002C20.3078 17.1416 20.4097 16.9123 20.5708 16.7442C20.7132 16.5956 20.888 16.482 21.0817 16.4122C21.3007 16.3333 21.5516 16.3333 22.0535 16.3333H23.0671C24.5605 16.3333 25.3073 16.3333 25.8777 16.0426C26.3795 15.787 26.7874 15.379 27.0431 14.8773C27.3337 14.3068 27.3337 13.5601 27.3337 12.0666Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default DisLikeActive;
