import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getBarsPointInfoFilter } from 'api/bars-request-objects';
import { getPointMediaFiles } from 'api/v1.0/pointMedia';
import { IPointMediaFile } from 'api/types/v1.0/pointMedia';

export interface IProps {
    id: number;
}

const usePointInfoMedia = ({ id }: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [pointMedia, setPointMedia] = useState<IPointMediaFile[] | null>(null);

    const refresh = useCallback(() => {
        setIsLoading(true);
        const pointFilter = getBarsPointInfoFilter([id]);

        getPointMediaFiles({ dataFilter: pointFilter })
            .then((response) =>
                response.data.length > 0 && setPointMedia(response.data))
            .catch((e) => handleApiError(e))
            .finally(() => setIsLoading(false));
    }, [id]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        isLoading,
        pointMedia,
        refresh,
    };
};

export default usePointInfoMedia;
