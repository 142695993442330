import { Button, Form, Tooltip } from 'antd';
import React from 'react';
import styles from './PollSubmitButton.module.scss';

export interface IPollSubmitButtonProps {
    disabled?: boolean;
    buttonText: string;
    customValidationMessage?: string;
}

export const PollSubmitButton: React.FC<IPollSubmitButtonProps> = (props) => {
    const getValidationMessage = () => {
        if (!props.disabled) {
            return '';
        }

        if (!props.customValidationMessage) {
            return 'Заполнены не все обязательные поля';
        }

        return props.customValidationMessage;
    };

    return (
        <Form.Item wrapperCol={{}}>
            <Tooltip title={getValidationMessage()}>
                <Button
                    className={props.disabled ? styles['disabledSubmitButton'] : styles['submitButton']}
                    htmlType={'submit'}
                    disabled={props.disabled}
                    block
                >
                    {props.buttonText}
                </Button>
            </Tooltip>
        </Form.Item>
    );
};
