import React, { useEffect } from 'react';
import { Select, Spin } from 'antd';
import useProjectList from 'hooks/useProjectList';
import useCitizenProjectList from 'hooks/useCitizenProjectList';
import { usePointInfo } from 'hooks/usePointInfo';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import { ProjectType } from 'api/types/v1.0/activityInfo';
import Icon from 'ui-kit/Icon';
import styles from '../SelectorFilter.module.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { compareFilterSearchOptions } from 'utils/comparers';

const { Option } = Select;

export enum IFilterStyleType {
    primary,
    outlined,
}

export interface IProjectFilterProps {
    defaultProjectId?: number;
    defaultProjectLabel?: string;
    pointId?: number;
    onChange?: (projectId: number | null) => void;
    disabled?: boolean;
    userId?: string;
    type?: IFilterStyleType;
    updateProjectType?: (type_project: ProjectType) => void;
    onClear?: () => void;
    useCitizenProjectList?: boolean;
    placeholder?: string;
    disableClearButton?: boolean;
    alwaysShowSuffix?: boolean;
    managedValue?: number | null;
    needClearProjectId?: boolean;
}

export const ProjectFilter: React.FC<IProjectFilterProps> = (props) => {
    const { defaultProjectId, onChange, userId, updateProjectType } = props;
    const { projectId, setProjectId } = usePointInfo(props.pointId);
    const { data, loading } = props.useCitizenProjectList
        ? useCitizenProjectList(userId as string)
        : useProjectList(userId);
    const value = !!props.managedValue ? props.managedValue : (props.needClearProjectId ? undefined : projectId);

    useEffect(() => {
        if (defaultProjectId && data && updateProjectType) {
            updateProjectType(
                data.filter((project: IProjectListItem) => project.Id === defaultProjectId)[0]?.type_project
            );
        }
        if (defaultProjectId) {
            setProjectId(defaultProjectId);
        }
    }, [data, defaultProjectId]);

    const getSuffixIcon = () => {
        return loading
            ? <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <Icon className={styles['icon']} type={'DropDownArrow'} />;
    }

    return (
        <div
            className={
                props.type === IFilterStyleType.outlined
                    ? styles['selector-wrapper-secondary']
                    : styles['selector-wrapper']
            }
        >
            <Select
                className={props.disabled ? styles['selector-disabled'] : styles['selector']}
                suffixIcon={(!projectId || props.alwaysShowSuffix) && getSuffixIcon()}
                value={loading ? undefined : value}
                showSearch
                getPopupContainer={triggerNode => triggerNode.parentElement}
                placeholder={props.placeholder || "Выберите проект"}
                optionFilterProp="children"
                allowClear={!props.disableClearButton}
                disabled={props.disabled}
                onChange={(newValue: number | undefined) => {
                    setProjectId(newValue);
                    if (onChange) {
                        onChange(newValue ?? null);
                    }
                    if (updateProjectType && data) {
                        updateProjectType(
                            data.filter((project: IProjectListItem) => project.Id === newValue)[0]?.type_project
                        );
                    }
                }}
                onClear={() => {
                    setProjectId(undefined);
                    if (onChange) {
                        onChange(null);
                    }
                    props.onClear?.();
                }}
                filterOption={compareFilterSearchOptions}
            >
                {data &&
                    data.map((project) => (
                        <Option key={project.Id} value={project.Id}>
                            {project.project_name}
                        </Option>
                    ))}
            </Select>
        </div>
    );
};
