import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import * as jwt from 'App/root/jwt';
import axiosCacheClient from './axiosCacheClient';

export function axiosGet<TResponse>(url: string) : AxiosPromise<TResponse>
{
    return axios.get<TResponse>(url, getAxiosConfigAuthToken());
}

export function axiosCacheGet<TResponse>(url: string) : AxiosPromise<TResponse>
{
    return axiosCacheClient.get<TResponse>(url, getAxiosConfigAuthToken());
}

export function axiosPost<TResponse>(url: string) : AxiosPromise<TResponse>
{
    return axios.post<TResponse>(url, null, getAxiosConfigAuthToken());
}

export function axiosCachePostRequest<TRequest, TResponse>(url: string, request: TRequest) : AxiosPromise<TResponse>
{
    return axiosCacheClient.post<TResponse>(url, request, getAxiosConfigAuthToken());
}

export function axiosPostRequest<TRequest, TResponse>(url: string, request: TRequest) : AxiosPromise<TResponse>
{
    return axios.post<TResponse>(url, request, getAxiosConfigAuthToken());
}

export function axiosBearerPostRequest<TRequest, TResponse>(url: string, request: TRequest) : AxiosPromise<TResponse>
{
    return axios.post<TResponse>(url, request, getAxiosConfigAuthToken());
}

export function axiosPostNoResponse<TRequest>(url: string, request: TRequest)
{
    return axios.post(url, request, getAxiosConfigAuthToken());
}

export function axiosPut<TResponse>(url: string) : AxiosPromise<TResponse>
{
    return axios.put<TResponse>(url, null, getAxiosConfigAuthToken());
}

export function axiosPutRequest<TRequest, TResponse>(url: string, request: TRequest) : AxiosPromise<TResponse>
{
    return axios.put<TResponse>(url, request, getAxiosConfigAuthToken());
}

export function axiosLoadFilesRequest<TResponse>(url: string, data: FormData) : AxiosPromise<TResponse>
{
    return axios.post<TResponse>(url, data, getAxiosConfigAuthForFiles());
}

export function axiosDeleteNoResponse<TRequest>(url: string, request: TRequest)
{
    const auth = getAxiosConfigAuthToken();
    const headers = auth.headers as { 'Authorization' : string };

    return axios.delete(url, { data: request, headers: headers } as AxiosRequestConfig);
}

export function hasJwt(): boolean {
    return jwt.get() !== null;
}

export function getAxiosConfigAuthToken() : AxiosRequestConfig
{
    const token = jwt.get() as string;

    return {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    };
}

export function getAxiosConfigAuthForFiles() : AxiosRequestConfig
{
    const token = jwt.get() as string;

    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        },
    };
}
