import React, { ReactNode } from 'react';
import { Tabs, TabsProps } from 'antd';
import './FolkTabs.scss';

// @ts-ignore
interface IProps extends TabsProps {
    actionsList?: { (): void; } [];
    tabBarExtraContent?: {left?: ReactNode, right?: ReactNode};
    onClick?: (key: string) => void;
}

export const FolkTabs: React.FC<IProps> = (props) => {
    const handleClick = (key: string) => {
        props?.onClick && props?.onClick(key)
    }

    return props.actionsList ? (
        <div className={'tabs-wrapper'}>
            <Tabs onChange={(itemKey) => {
                // @ts-ignore
                props.actionsList[Number(itemKey)]();
            }} items={props.items}
                tabBarExtraContent={props.tabBarExtraContent}
            />
        </div>
    ) : (
        <div className={'tabs-wrapper'}>
            <Tabs items={props.items}
                onTabClick={handleClick} tabBarExtraContent={props.tabBarExtraContent}
            />
        </div>
    );
};
