import * as yup from 'yup';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';

export const activityCardPollValidationSchema = yup.object().shape(
    {
        question: yup.string().required(`${alerts.REQUIRED}`),
        answers: yup.array().min(2, 'Нужно как минимум два варианта ответа').required()
            .of(yup.object().shape(
                {
                    answer: yup.string().required(`${alerts.REQUIRED}`),
                }
            )),
    }
);
