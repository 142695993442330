import { Col, Row } from 'antd';
import { IFilterStyleType, ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { RegionFilter } from 'ui-kit/filters/RegionFilter';
import ObjectViewSwitch from '../../ObjectViewSwitch';
import { IProps } from '../interface';

const DesktopContainerFilter: React.FC<IProps> = ({
    selectedProjects,
    changeSelectedProject,
    selectedRegionId,
    changeSelectedRegion,
    changePointsVisualisation,
    pointsVisualisation,
    handleClearSearchCallback,
}) => {
    return (
        <>
            <Col flex={1}>
                <Row gutter={24} style={{ height: '100%' }}>
                    <Col span={12}>
                        <ProjectFilter
                            defaultProjectId={selectedProjects[0]?.Id}
                            onChange={changeSelectedProject}
                            alwaysShowSuffix
                        />
                    </Col>
                    <Col span={12}>
                        <RegionFilter
                            defaultRegionId={selectedRegionId ?? undefined}
                            managedValue={selectedRegionId}
                            type={IFilterStyleType.outlined}
                            onChange={changeSelectedRegion}
                            handleClearSearchCallback={handleClearSearchCallback}
                            alwaysShowSuffix
                        />
                    </Col>
                </Row>
            </Col>
            <Col flex={'0 0 168px'}>
                <ObjectViewSwitch changeView={changePointsVisualisation} pointsView={pointsVisualisation} />
            </Col>
        </>
    );
};

export default DesktopContainerFilter;
