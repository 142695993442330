import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import React from 'react';
import FolkCheckbox from 'ui-kit/FolkCheckbox/FolkCheckbox';
import style from '../CommunityFormationPage.module.scss';

interface IUserProps {
    isSelected: boolean,
    userId: number,
    name: string,
    region: string,
    avatar: string,
    onSelect: (id: number) => void,
}

const UserInviteCard: React.FC<IUserProps> = ({ isSelected, userId, name, region, avatar, onSelect }) => {
    const onChange = () => {
        onSelect(userId);
    };

    return (
        <Row wrap={false} justify={'space-between'} align={'middle'}>
            <Col>
                <Row wrap={false} align={'middle'}>
                    <Col>
                        <Avatar src={avatar} size={64} icon={<UserOutlined />} />
                    </Col>
                    <Col style={{ paddingLeft: '1em' }}>
                        <div className={style.name}>{name}</div>
                        <div className={style.region}>{region}</div>
                    </Col>
                </Row>
            </Col>
            <Col>
                <FolkCheckbox
                    onChange={onChange}
                    checked={isSelected}
                />
            </Col>
        </Row>
    );
};

export default UserInviteCard;
