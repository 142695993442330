import React from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserView, isDesktop, MobileView } from 'react-device-detect';
import withInnerComponents from 'ui-kit/decorators/withInnerComponents';
import { isOnPageWithoutTabs, isOnPersonalPages } from 'utils/location';
import LayoutHeightAdjuster from './components/LayoutHeightAdjuster';
import LayoutHeader from './components/LayoutHeader';
import LayoutMobileHeader from './components/LayoutMobileHeader';
import LayoutFooter from './components/LayoutFooter';

import './Layout.scss';

interface IProps {
    postHeader?: JSX.Element;
}

const Layout: React.FC<IProps> = ({ children, postHeader }) => {
    const nav = useHistory();
    const layoutPersonalPages = isDesktop ? 'layout-content layout-content-personal-pages' : 'layout-mobile layout-content-personal-pages';
    const layout = isDesktop ? 'layout-content' : 'layout-mobile';
    const layoutHeightAdjusterClassName =
        isOnPersonalPages(nav.location.pathname) || isOnPageWithoutTabs(nav.location.pathname)
            ? layoutPersonalPages
            : layout;

    return (
        <>
            <BrowserView>
                <LayoutHeader postHeader={postHeader} />
                <LayoutHeightAdjuster className={layoutHeightAdjusterClassName}>
                    {children}
                </LayoutHeightAdjuster>
                <LayoutFooter />
            </BrowserView>
            <MobileView>
                <LayoutMobileHeader />
                <LayoutHeightAdjuster className={layoutHeightAdjusterClassName}>
                    {children}
                </LayoutHeightAdjuster>
                <LayoutFooter />
            </MobileView>
        </>
    );
};

const addInners = withInnerComponents({ HeightAdjuster: LayoutHeightAdjuster });

export default addInners(Layout);
