import { Reducer } from 'redux';
import moment from 'moment';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    activities: null,
    isLoading: false,
    selectedProjectId: null,
    selectedPointId: null,
    selectedDate: null,
    totalCount: 0,
    selectedMonth: null,
    selectedYear: null,
    monthActivities: null,
    currentPage: 1,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_DEPUTY_ACTIVITIES:
            if (action.payload !== null) {
                action.payload.forEach((x) => {
                    x.date_time = moment.utc(x.date_time).toDate();
                });
            }

            return {
                ...state,
                activities: action.payload,
            };
        case actionTypes.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProjectId: action.payload,
            };
        case actionTypes.SET_SELECTED_POINT:
            return {
                ...state,
                selectedPointId: action.payload,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.DELETE_DEPUTY_ACTIVITIES:
            return {
                ...state,
                activities: [],
            };
        case actionTypes.SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload,
            };
        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.SET_SELECTED_MONTH:
            return {
                ...state,
                selectedMonth: action.payload,
                monthActivities: null,
            };
        case actionTypes.SET_SELECTED_YEAR:
            return {
                ...state,
                selectedYear: action.payload,
                monthActivities: null,
            };
        case actionTypes.SET_MONTH_ACTIVITIES:
            return {
                ...state,
                monthActivities: action.payload,
            };
        case actionTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
