import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IGroupsMessageItem, MessagesSortType } from 'api/types/v1.0/groupsList';
import { RootState } from 'App/root/rootReducer';
import { hasRole } from 'utils/user';
import { Role } from 'types/account';
import { FolkTabs } from 'ui-kit/FolkTabs/FolkTabs';
import { IChatConversationProps, UploadedImage } from './types';
import './ChatConversationCard.scss';
import ChatMessagesList from './components/ChatMessagesList';
import ChatInput from './components/ChatInput';
import ChatConversationHeader from './components/ChatConversationHeader';

const ChatConversationCard: React.FC<IChatConversationProps> = ({
    group_id,
    messagesTotalCount,
    groupMessages,
    isEditDisabled,
    groupMembersCount,
    getGroupMessagesList,
    sendMessageSeen,
    getInitialGroupMessageList,
    replyMessage,
    setIsGroupMembersListHidden,
    sendMessage,
    getGroupMembersCount,
    removeMessage,
    changeMessagesSort,
}) => {
    const { user } = useSelector((state: RootState) => state.account);
    const [inputValue, setInputValue] = useState('');
    const [imageFiles, setImageFiles] = useState<File[] | null>(null);
    const [uploadedImages, setUploadedImages] = useState<UploadedImage[] | null>(null);
    const [answeringMessage, setAnsweringMessage] = useState<IGroupsMessageItem | null>(null);
    const isCoordinator = hasRole([Role.Federal, Role.Regional], user?.role as Role);

    const resetImages = () => {
        setImageFiles(null);
        setUploadedImages(null);
    };

    const resetInputs = () => {
        setInputValue('');
        setAnsweringMessage(null);
        resetImages();
    };

    const changeSort = (sortType: string) => {
        changeMessagesSort(sortType as MessagesSortType);
    };

    return (
        <div className={'chat-conversation-card-wrapper'}>
            <ChatConversationHeader
                groupMembersCount={groupMembersCount}
                onGroupMembersClick={() => {setIsGroupMembersListHidden(false);}}
            />
            <div className={'sort-tabs-wrapper'}>
                <FolkTabs
                    onClick={changeSort}
                    items={[
                        {
                            label: 'По дате',
                            key: 'index_sort_date',
                        },
                        {
                            label: 'По оценкам',
                            key: 'index_sort_lks',
                        },
                    ]}
                />
            </div>
            <div className={'chat-conversation-card-input-messages'}>
                <ChatMessagesList
                    group_id={group_id}
                    groupMessages={groupMessages}
                    messagesTotalCount={messagesTotalCount}
                    getGroupMessagesList={getGroupMessagesList}
                    replyMessage={replyMessage}
                    removeMessage={removeMessage}
                    uploadedImages={uploadedImages}
                    sendMessageSeen={sendMessageSeen}
                    answeringMessage={answeringMessage}
                    setAnsweringMessage={setAnsweringMessage}
                    getGroupMembersCount={getGroupMembersCount}
                    getInitialGroupMessageList={getInitialGroupMessageList}
                    resetInputs={resetInputs}
                />
                {!(isCoordinator || isEditDisabled) && <ChatInput
                    group_id={group_id}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    uploadedImages={uploadedImages}
                    imageFiles={imageFiles}
                    sendMessage={sendMessage}
                    resetInputs={resetInputs}
                    setImageFiles={setImageFiles}
                    setUploadedImages={setUploadedImages}
                    answeringMessage={answeringMessage}
                />}
            </div>
        </div>
    );
};

export default ChatConversationCard;
