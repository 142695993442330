import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';
import Page from 'components/Page';
import DeputyMenu from 'components/Layout/components/Sidebar/DeputyMenu';
import { Role } from 'types/account';
import DeputyPoints from './components/DeputyPoints';
import { useFederalCoordinatorProfilePage } from './hooks/useFederalCoordinatorProfilePage';
import CoordinatorStatistics from './components/CoordinatorStatistics';
import { useCoordinatorSelectedPoints } from './hooks/useCoordinatorSelectedPoints';
import styles from './FederalCoordinator.module.scss';

interface IFederalCoordinatorProps{
    deputyId: number;
}

export const FederalCoordinatorProfile: React.FC<IFederalCoordinatorProps> = ({ deputyId }) => {
    const role = Role.Federal;
    const { state } = useFederalCoordinatorProfilePage(deputyId);
    const { statisticsState,
        changeSelectedPoint,
        clearPoints } = useCoordinatorSelectedPoints(deputyId,role);
    return (
        <Page
            content={
                <Page.Content>
                    <Spin spinning={state.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                        {
                            isMobile && <div className='deputy-statistics-container'>
                                <span className='deputy-statistics-header'>Общий рейтинг&nbsp;
                                    <span className='statistics-value'>
                                        {state.profile?.rating}%
                                    </span>
                                </span>
                            </div>
                        }
                        <div className={isDesktop ? styles.body : styles.bodyMobile}>
                            <div className={styles.mainContainer}>
                                <div className={styles.pointsContainer}>
                                    <div className={styles.textTitle}>Объекты</div>
                                    <DeputyPoints
                                        deputyId={deputyId}
                                        changePoint={changeSelectedPoint}
                                        clearPoints={clearPoints}
                                        statisticsState={statisticsState}
                                        compact={!isDesktop} />
                                </div>
                            </div>
                            <div>
                                {isDesktop && <DeputyMenu />}
                                <CoordinatorStatistics
                                    statistics={statisticsState.statistics}
                                    deputyId={deputyId}
                                    role={role}
                                    rating={state.profile?.rating}
                                />
                            </div>
                        </div>
                    </Spin>
                </Page.Content>
            }
        />
    );
};
