import { IPointControlPointsResponse, IPointControlPointsRequest } from 'api/types/v1.0/pointControlPoints';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';

export const getPointControlPointsAsync = async (
    request: IPointControlPointsRequest
): Promise<IPointControlPointsResponse> => {
    const req = {
        page: request.page ? request.page : 1,
        limit: request.limit ? request.limit : 50,
        start: request.start ? request.start : 0,
        dataFilter:
        {
            Group: 2,
            Filters:
            [
                {
                    Operand: 0,
                    Value: request.id,
                    DataIndex: 'Object_Id',
                },
                {
                    Operand: 0,
                    Value: 1,
                    DataIndex: 'displayed',
                },
            ],
        },
    };

    const result = await axiosPostRequest<typeof req, IPointControlPointsResponse>(ApiPaths.PointControlPoints, req);
    return result.data;
};
