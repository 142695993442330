import React, { useCallback } from 'react';
import { Form, Select } from 'antd';
import { useField } from 'formik';
import Icon from 'ui-kit/Icon';
import { PickUserProject } from 'pages/UserSettings/components/UserSettingsForm/UserSettingsForm';

import styles from './SelectProjectsField.module.scss';

interface ISelectFieldsProps {
    name: string;
    options?: ISelectOption[];
    placeholder: string;
    disabled?: boolean;
    changedProjects?: (bool: boolean) => void;
}

interface ISelectOption {
    value: number;
    label: string;
}

export const SelectProjectsField: React.FC<ISelectFieldsProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField<number[]>(name);

    const onSelect = useCallback(
        (_: number, option: ISelectOption) => {
            if (option) {
                const fieldValue = option.value;
                if (field.value) {
                    field.value.push(fieldValue);
                    setValue(field.value);
                } else {
                    setValue([fieldValue]);
                }

                setTouched(true, false);
                if (props.changedProjects) {
                    props.changedProjects(true);
                }
            }
        },
        [field.value, setTouched, props, setValue]
    );

    const onDeselect = useCallback(
        (_: string | number, option: ISelectOption) => {
            if (option) {
                const fieldValue = option.value;
                setValue(
                    field.value.filter((value: PickUserProject | number) =>
                        typeof value === 'number' ? value !== fieldValue : value.value !== fieldValue
                    )
                );
                setTouched(true, false);
                if (props.changedProjects) {
                    props.changedProjects(true);
                }
            }
        },
        [setValue, field.value, setTouched, props]
    );

    return (
        <Form.Item
            initialValue={field.value}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <Select
                mode={'multiple'}
                className={styles.selectProjectField}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={field.value}
                onSelect={onSelect}
                onDeselect={onDeselect}
                filterOption={(inputValue, option) => {
                    return (option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1) >= 0;
                }}
                options={props.options}
                showSearch
                showArrow={true}
                suffixIcon={<Icon className={styles['icon']} type={'DropDownArrow'} />}
            />
        </Form.Item>
    );
};
