import {
    IPointsResponse,
} from 'api/types/v1.0/points';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IHasFilters } from '../bars-request-objects';

export const deputyActivitiesPoints = async (request: IHasFilters): Promise<IPointsResponse> => {
    try {
        const result = await axiosCachePostRequest<IHasFilters,IPointsResponse>(
            ApiPaths.DeputyObjectsPlanner, request);
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
