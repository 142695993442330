import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'antd';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { DatePickerField } from 'ui-kit/Form/FormFields/DatePickerField';

import styles from './IndicatorDatePickers.module.scss';

interface IDatePicker {
    start: string;
    end: string;
}

interface IProps {
    setSelectedPeriod: React.Dispatch<React.SetStateAction<{ start?: string; end?: string }>>;
}

const IndicatorDatePickers: React.FC<IProps> = ({ setSelectedPeriod }) => {
    const { values } = useFormikContext<IDatePicker>();

    useEffect(() => {
        setSelectedPeriod(values);
    }, [setSelectedPeriod, values]);

    const checkDisabledForPeriodStart = (date: moment.Moment) => {
        const endDate = moment(values.end, DEFAULT_DATE_FORMAT);
        const dateFormatted = moment(date, DEFAULT_DATE_FORMAT);

        return dateFormatted.isSameOrAfter(endDate, 'day');
    };

    const checkDisabledForPeriodEnd = (date: moment.Moment) => {
        const startDate = moment(values.start, DEFAULT_DATE_FORMAT);
        const dateFormatted = moment(date, DEFAULT_DATE_FORMAT);

        return dateFormatted.isSameOrBefore(startDate, 'day');
    };

    return (
        <Form className={styles['container']}>
            <DatePickerField
                format={DEFAULT_DATE_FORMAT}
                placeholder={'Начало'}
                name={'start'}
                required={false}
                disabledDate={values.end ? checkDisabledForPeriodStart : undefined}
            />
            <DatePickerField
                format={DEFAULT_DATE_FORMAT}
                placeholder={'Конец'}
                name={'end'}
                required={false}
                disabledDate={values.start ? checkDisabledForPeriodEnd : undefined}
            />
        </Form>
    );
};

export default IndicatorDatePickers;
