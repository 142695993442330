import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { IStatesman } from 'api/types/v1.0/statesman';
import {
    ADD_ACTIVITIES,
    SAVE_STATESMAN,
    SET_ACTIVITIES,
    SET_LOADING,
    SET_TOTAL_COUNT,
} from './actionTypes';

export const saveStatesman = (statesman: IStatesman) => ({ type: SAVE_STATESMAN, payload: statesman } as const);

export const setActivities = (activities: IDeputyActivity[]) =>
    ({ type: SET_ACTIVITIES, payload: activities } as const);

export const addActivities = (activities: IDeputyActivity[]) =>
    ({ type: ADD_ACTIVITIES, payload: activities } as const);

export const setIsLoading = (isLoading: boolean) => ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: number) => ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);
