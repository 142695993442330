import React from 'react';
import { OKShareButton, TelegramShareButton, VKShareButton } from 'react-share';
import styles from './ShareBar.module.scss';
import Icon from '../Icon';
import DocumentMeta from 'react-document-meta';
// eslint-disable-next-line
// @ts-ignore

interface IShareProps {
    title?: string;
    image?: string;
    description?: string;
}

const ShareBar: React.FC<IShareProps> = (props) => {
    const meta = {
        title: props?.title,
        description: props?.description,
        meta: {
            property: {
                'og:url': window.location.href,
                'og:type': 'website',
                'og:title': props?.title,
                'og:description': props?.description,
                'og:image': props?.image,
                'twitter:title': props?.title,
                'twitter:description': props?.description,
                'twitter:image': props?.image
            }
        }
    };
    return (

        <div className={styles['share-bar']}>
            <DocumentMeta {...meta}>
                <span>Поделитесь новостью:</span>
                <div className={styles['share-bar-content']}>
                    <VKShareButton className={styles['share-bar-icon']} title={`${props?.title}\n${props?.description}`}
                                   url={window.location.href}
                                   image={props?.image}>
                        <Icon type={"Vk"} />
                    </VKShareButton>
                    <OKShareButton className={styles['share-bar-icon']} title={`${props?.title}\n${props?.description}`}
                                   url={window.location.href}
                                   image={props?.image}>
                        <Icon type={"Ok"} />
                    </OKShareButton>
                    <TelegramShareButton className={styles['share-bar-icon']}
                                         title={`${props?.title}\n${props?.description}`} url={window.location.href}>
                        <Icon type={"Tg"} />
                    </TelegramShareButton>
                </div>
            </DocumentMeta>
        </div>
    );
};

export default ShareBar;
