import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Button, Modal } from 'antd';
import './QrCode.scss';

export interface IProps {
    url: string
    pointName: string
}

const QrCode: React.FC<IProps> = (props: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const downloadQRCode = () => {
        const canvas = document.getElementById('qr-object') as HTMLCanvasElement;
        if (canvas === null) {
            throw new Error('Could not get context');
        }

        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${props.pointName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <Button className='qr-button' type='primary' onClick={showModal}>
                Скачать QR-код объекта
            </Button>
            <Modal title={props.pointName} open={isModalOpen} onCancel={handleCancel} footer={null} width={400}>
                <div className={'modal-body'}>
                    <QRCode
                        size={256}
                        value={props.url}
                        viewBox={'0 0 256 256'}
                        className={'qrcode-main'}
                        id={'qr-object'}
                    />
                    <div className={'download-button'}>
                        <Button type="link" onClick={downloadQRCode}>
                            Скачать
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default QrCode;
