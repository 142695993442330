function withInnerComponents<TInnerComponents>(innerComponents: TInnerComponents) {
    const inners = Object.entries(innerComponents);

    return function<TRootComponent>(RootComponent: TRootComponent) {
        const result = inners.reduce((acc, [key, InnerComponent]) => {
            acc[key] = InnerComponent;
            return acc;
        }, RootComponent as any);

        return result as TRootComponent & TInnerComponents;
    };
}

export default withInnerComponents;
