import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'antd';
import moment from 'moment';
import { useRegionList } from 'hooks/useRegionList';
import { TextInputField } from 'ui-kit/Form/FormFields/TextInputField';
import { DatePickerField } from 'ui-kit/Form/FormFields/DatePickerField';
import { SelectField } from 'ui-kit/Form/FormFields/SelectField';
import { MaskedTextInputField } from 'ui-kit/Form/FormFields/MaskedTextInputField';
import { SubmitButton } from 'ui-kit/Form/SubmitButton/SubmitButton';
import { SwitchField } from 'ui-kit/Form/FormFields/SwitchField';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';
import { SelectProjectsField } from 'pages/Auth/components/UserRegistrationForm/UserRegistrationFormFields/SelectProjectsField/SelectProjectsField';
import { getMinDate } from 'utils/dateTime';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { IUserSettingsFormValues, PickUserProject } from './UserSettingsForm';

import './UserSettingsForm.scss';

export interface IUserSettingsFormFieldsProps {
    lazyValues: IUserSettingsFormValues;
    onSubmit: () => void;
}

export const UserSettingsFormFields: React.FC<IUserSettingsFormFieldsProps> = (props) => {
    const { isValid, setValues } = useFormikContext<IUserSettingsFormValues>();
    const [isTouched, setTouched] = useState<boolean>(false);
    const regionsList = useRegionList();

    const getSelectedProject = () => {
        return props.lazyValues.projects?.reduce(
            (acc: PickUserProject[], project: PickUserProject) => {
                if (!project.ProjectChoice) {
                    acc.push({ ...project, value: project.ProjectLink_Ref_Id });
                }

                return acc;
            },
            []
        );
    };

    useEffect(() => {
        setValues((prevState) => {
            return { ...props.lazyValues, projects: getSelectedProject() } ?? prevState;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.lazyValues, setValues]);

    const changeTouch = (bool: boolean) => {
        setTouched(bool);
    };

    return (
        <Form
            className={'user-settings-form-fields-wrapper'}
            onFinish={() => {
                setTouched(false);
                props.onSubmit();
            }}
            onChange={() => {
                setTouched(true);
            }}
        >
            <TextInputField disabled placeholder={'Имя'} name={'name'} />
            <TextInputField disabled placeholder={'Отчество'} name={'phantomic'} />
            <TextInputField disabled placeholder={'Фамилия'} name={'surname'} />
            <DatePickerField
                placeholder={'Дата рождения'}
                name={'birthDate'}
                disabledDate={(date) => date.isBefore(getMinDate()) || date.isAfter(moment())}
                format={DEFAULT_DATE_FORMAT}
                disabled
                needToSetNameUndefined={true}
            />
            <SelectField
                placeholder={'Регион'}
                options={regionsList.data?.map((region) => ({ label: region.region, value: region.Id }))}
                name={'regionId'}
                validationMessage={alerts.REQUIRED}
                needToSetNameAsUndefined={true}
                setTouched={setTouched}
                disabled
            />
            <MaskedTextInputField disabled type={'phone'} placeholder={'Телефон'} name={'phone'} />
            <TextInputField name={'address'} placeholder={'Адрес'} disabled />
            <TextInputField placeholder={'Электронная почта'} name={'email'} />
            <div className={'directions'}>Направления:</div>
            <div className={'directions'}>
                <SelectProjectsField
                    placeholder={'Выберите проекты'}
                    options={props.lazyValues.projects?.map((project) => ({
                        label: project.ProjectLink_project_name,
                        value: project.ProjectLink_Ref_Id,
                    }))}
                    name={'projects'}
                    changedProjects={changeTouch}
                />
            </div>
            <SwitchField
                title={'Получать уведомления о новых событиях'}
                name={'isReceiveEventMessages'}
                setTouched={setTouched}
            />
            <SwitchField
                title={'Получать уведомления о новых приглашениях'}
                name={'isReceiveGroupMessages'}
                setTouched={setTouched}
            />
            <div className={'submit-user-button'}>
                <SubmitButton disabled={!isValid} isTouched={isTouched} buttonText={'Сохранить'} />
            </div>
        </Form>
    );
};
