import { handleApiError } from 'api/v1.0/common';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IOfferCoordinator } from 'api/types/v1.0/offerCoordinator';

export const sendOfferCoordinator = async (request : IOfferCoordinator) => {
    try {
        return axiosPostRequest(ApiPaths.SendOfferCoordinator, request);
    } catch (exception) {
        handleApiError(exception);
    }
};
