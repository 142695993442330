import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    notifications: null,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    if (action.type === actionTypes.SET_NOTIFICATIONS) {
        return {
            ...state,
            notifications: action.payload,
        };
    }

    return state;
};

export default reducer;
