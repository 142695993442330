import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import { IRegionalCoordinatorProfileResponse } from '../types/v1.0/regionalCoordinatorProfile';
import { ICoordinatorStatisticsResponse } from '../types/v1.0/coordinatorStatistics';
import { IDeputyProfileRequest } from '../types/v1.0/deputyProfile';

export interface IStatisticsByObjectsRequest{
    deputy_id: number;
    object_id: number[];
}

export const getRegionalCoordinatorProfile = async (req: IDeputyProfileRequest) => {
    const request = await axiosPostRequest< IDeputyProfileRequest ,
        IRegionalCoordinatorProfileResponse>(ApiPaths.RegionalCoordinator, req);
    return request.data;
};

export const getRegionalCoordinatorStatistics = async (req: IStatisticsByObjectsRequest) => {
    const request = await axiosPostRequest< IStatisticsByObjectsRequest ,
        ICoordinatorStatisticsResponse>(ApiPaths.RegionalCoordinatorStatistics, req);
    return request.data;
};
