import React, { useEffect } from 'react';
import { Button, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getMonthAndYear } from 'utils/dateTime';
import { getExtension } from 'utils/file';
import { documentTypes } from 'constants/documentTypes';
import { ReactComponent as DocumentIcon } from './assets/DocumentIcon.svg';
import { ReactComponent as RemoveIcon } from './assets/RemoveIcon.svg';
import { ReactComponent as UploadIcon } from './assets/UploadIcon.svg';
import useEditPointDocuments from './hooks/useEditPointDocuments';
import './EditDocuments.scss';

export interface IEditDocumentsProps
{
    isDesktop: boolean;
    pointId: number | null;
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const EditDocuments: React.FC<IEditDocumentsProps> = ({ isDesktop, pointId, setIsLoaded }:IEditDocumentsProps ) => {
    const { state, uploadDocument, removeDocument } = useEditPointDocuments({ pointId });
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setIsLoaded(!state.isLoading);
    }, [state.isLoading, setIsLoaded]);

    const handlerUploadDocWrapper = () =>
    {
        if (hiddenFileInput.current)
        {
            hiddenFileInput.current.click();
        }
    };

    const openIncorrectTypesNotification = () => {
        api.info({
            message: 'Разрешены файлы форматов txt, pdf, doc, docx, xls, xlsx',
            placement: 'bottom',
        });
    };

    const handlerUploadDoc =(event: React.FormEvent<HTMLInputElement>) =>
    {
        const file = event.currentTarget.files?.item(0);
        const fileExtension = getExtension(file ? file.name : '').toLowerCase();
        const isCorrectDocumentType = documentTypes.includes(fileExtension);

        if (!isCorrectDocumentType)
        {
            openIncorrectTypesNotification();
        }
        else if (file)
        {
            const form = document.getElementById('formDocument') as HTMLFormElement;
            if (form)
            {
                const data = new FormData();
                data.append('modelData', `{'ContentType': ${1}, 'Object': {'Id': ${pointId ? pointId : 0}}}`);
                const dataBlob = file as Blob;
                data.append('FileOriginal', dataBlob);
                uploadDocument(data);
            }
        }
    };

    const handlerRemoveDoc =(id: number) =>
    {
        removeDocument(id);
    };

    return (
        <Spin
            spinning={state.isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
            <div className='edit-documents-header'>Документы:</div>
            {contextHolder}
            {
                state.pointDocuments.map((value)=> {
                    return (
                        <div
                            key={value.Id}
                            className={'edit-documents-body'}
                        >
                            <div className='edit-documents-item'>
                                <DocumentIcon className='edit-documents-icon' />
                                <div className={isDesktop ? 'edit-documents-item-doc' : 'mobile-edit-documents-item-doc'}>
                                    <span className='edit-documents-item-doc-date'>
                                        {getMonthAndYear(value.FIleOriginal_ObjectCreateDate)}
                                    </span> {value.FullName}
                                </div>
                            </div>
                            <RemoveIcon
                                className='remove-icon'
                                onClick={() => handlerRemoveDoc(value.Id)}
                            />
                        </div>
                    );
                })
            }
            <div>
                <form id='formDocument' method='POST' encType='multipart/form-data' >
                    <Button
                        icon={<UploadIcon className={'upload-icon'} />}
                        className={'submit-upload'}
                        onClick={handlerUploadDocWrapper}
                    >
                        Прикрепить документ
                    </Button>
                    <input ref={hiddenFileInput}
                        name={'FIleOriginal'}
                        type="file"
                        onChange={handlerUploadDoc}
                        style={{ display: 'none' }}
                    />
                </form>
            </div>
        </Spin>
    );
};

export default EditDocuments;
