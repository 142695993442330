import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import browserHistory from 'App/root/browserHistory';
import { RootState } from 'App/root/rootReducer';
import { Pages } from 'constants/links';
import { createNews } from 'api/v1.0/createNews';
import { uploadNewsImage } from 'api/v1.0/uploadNewsImage';
import { IHasId } from 'api/types/v1.0/deputyActivities';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import NewsGeneratorFormFields, { INewsGeneratorValues } from './NewsGeneratorFormFields/NewsGeneratorFormFields';
import { validateNewsGeneratorValues } from './NewsGeneratorFormFields';

export interface INewsGeneratorFormProps {
    projectId?: number;
    pointId?: number;
    activityId?: number;
}

const NewsGeneratorForm: React.FC<INewsGeneratorFormProps> = (props) => {
    const { user } = useSelector((state: RootState) => state.account);
    const [loading, setLoading] = useState(false);

    const redirectToNewsPage = (newsId: number) => browserHistory.push(`${Pages.NewsDetails.url}/${newsId}`);

    const finishRequest = (newsId: number) => {
        setLoading(false);
        redirectToNewsPage(newsId);
    };

    const onSubmitNewsForm = (data: INewsGeneratorValues) => {
        setLoading(true);
        data['deputy_id'] = { Id: Number(user?.sub) } as IHasId;
        data['deputyRole'] = user?.role;
        void createNews(data).then((firstResponse) => {
            if (data.file) {
                void uploadNewsImage(data.file, firstResponse.data.Id).then(() => finishRequest(firstResponse.data.Id));
            } else {
                finishRequest(firstResponse.data.Id);
            }
        });
    };

    return (
        <Formik<INewsGeneratorValues>
            initialValues={{ projectId: props.projectId, activityId: props.activityId, pointId: props.pointId }}
            validate={(values) => validateNewsGeneratorValues(values, user?.role)}
            validateOnChange
            validateOnBlur
            validateOnMount
            onSubmit={onSubmitNewsForm}
        >
            {
                (childFormProps) => {
                    return (
                        <OnLoadingSpinner isLoading={loading} content={(
                            <Form onSubmit={async (e) => {
                                e.preventDefault();
                                await childFormProps.submitForm();
                            }}>
                                <NewsGeneratorFormFields />
                            </Form>
                        )} />
                    );
                }
            }
        </Formik>
    );
};

export default NewsGeneratorForm;
