import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { IQuestionsAndSuggestsRequest } from 'api/types/v1.0/questionsAndSuggests';
import { getQuestionsAndSuggests } from 'api/v1.0/questionsAndSuggests';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useQuestionsAndSuggestsPage = (economyId?: number | null) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = (start?: number) => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: start ?? state.messages?.length,
            limit: 10,
            branchEconomyId: economyId,
            sort: [{ property: 'dateTime', direction: 'desc' }],
        } as IQuestionsAndSuggestsRequest;

        void getQuestionsAndSuggests(request)
            .then((response) => {
                dispatch(actionCreators.setMessages(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            }).catch((exception) =>handleApiError(exception))
            .finally(() =>actionCreators.setLoading(false));
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    useEffect(() => {
        dispatch(actionCreators.deleteMessages());
        setTotalCount(0);
        refresh(0);
        //eslint-disable-next-line
    }, [economyId]);

    return {
        ...state,
        refresh,
        setTotalCount,
    };
};

export default useQuestionsAndSuggestsPage;
