import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IPointMediaRequest, IObjectMediaResponse } from 'api/types/v1.0/pointMedia';
import { fileTypesDictionary } from 'constants/fileTypesDictionary';

export const getPointMediaFiles = async (request: IPointMediaRequest): Promise<IObjectMediaResponse> => {
    try {
        const response = await axiosPostRequest<IPointMediaRequest,
            IObjectMediaResponse>(ApiPaths.PointMedia, request);

        const responseWithCorrectUrls = (response.data.data.map( (file) => {
            file.preview_url = file.preview_link;
            file.media_url = file.object_media;

            const lowerCasedFileFormat = file.Format_file.toLowerCase();

            return ({
                ...file,
                type: fileTypesDictionary[lowerCasedFileFormat],
            });
        }));

        response.data.data = responseWithCorrectUrls;
        return response.data;
    } catch (error) {
        throw Error(error);
    }
};
