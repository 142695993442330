import { IState } from './types';
import {
    SET_LOADING,
    SET_SELECTED_PROJECT,
    SET_TOTAL_COUNT,
    SET_NEWS,
    DELETE_NEWS,
    SET_SELECTED_DEPUTY,
    SET_SELECTED_END_DATE,
    SET_SELECTED_OBJECT,
    SET_SELECTED_REGION,
    SET_SELECTED_START_DATE,
} from './actionTypes';

export const setNews = (news: IState['news']) =>
    ({ type: SET_NEWS, payload: news } as const);

export const setSelectedRegion = (selectedRegionId: IState['selectedRegionId']) =>
    ({ type: SET_SELECTED_REGION, payload: selectedRegionId } as const);

export const setSelectedProject = (selectedProjectId: IState['selectedProjectId']) =>
    ({ type: SET_SELECTED_PROJECT, payload: selectedProjectId } as const);

export const setSelectedObject = (selectedPointId: IState['selectedObjectId']) =>
    ({ type: SET_SELECTED_OBJECT, payload: selectedPointId } as const);

export const setSelectedDeputy = (selectedPointId: IState['selectedDeputyId']) =>
    ({ type: SET_SELECTED_DEPUTY, payload: selectedPointId } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const deleteNews = () =>
    ({ type: DELETE_NEWS } as const);

export const setSelectedStartDate = (selectedDate: IState['selectedStartDate']) =>
    ({ type: SET_SELECTED_START_DATE, payload: selectedDate } as const);

export const setSelectedEndDate = (selectedDate: IState['selectedEndDate']) =>
    ({ type: SET_SELECTED_END_DATE, payload: selectedDate } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);
