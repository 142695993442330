import React from 'react';
import { INewsItem } from 'api/types/v1.0/news';
import { formatNewsTime } from 'utils/dateTime';
import NewsDetailsButton from '../NewsDetailsButton/NewsDetailsButton';
import './MainNews.scss';

interface IMainNewsProps {
    mainNews?: INewsItem;
}

const MainNews: React.FC<IMainNewsProps> = ({ mainNews }) => {
    return (
        <div>
            {mainNews ?
                (<div className={'main-news'}>
                    <div className={'main-news-image-container'}>
                        <img
                            src={mainNews?.image}
                            className={'main-news-image'}
                            alt={'Новость'}
                        />
                    </div>
                    <div className={'main-news-description-container'}>
                        {mainNews?.title}
                    </div>
                    <div className='main-news-date'>
                        {formatNewsTime(mainNews.date_time)}
                    </div>
                    <NewsDetailsButton newsId={mainNews?.Id} />
                </div>)
                : null}
        </div>
    );
};

export default MainNews;
