import { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App/root/rootReducer';
import {
    ICitizenGroupCreateRequest,
    ICitizenGroupRequest,
} from 'api/types/v1.0/citizenGroup';
import { addCitizenGroup, getCitizenGroup } from 'api/v1.0/citizenGroup';
import { ICitizenEvent, ICitizenEventCreateRequest } from 'api/types/v1.0/citizenEvent';
import { addCitizenEvent } from 'api/v1.0/citizenEvents';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import {
    addCitizenEvents,
    setTotalCount as setEventsTotalCount,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/reducer';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import { updateCitizenMenuGroups } from 'ducks/utils/updateCitizenMenuGroups';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

const useTakePartButton = (activity: IDeputyActivity,
    setShowYouParticipantButton: (showYouParticipantButton: boolean) => void) => {
    const [takePartButtonState, dispatch] = useReducer(reducer, defaultState);

    const user = useSelector((state: RootState) => state.account.user);
    const userId = Number(user?.sub);

    const globalState = useSelector((state: RootState) => state);
    const citizenMenuEventsState = globalState.citizenMenuCitizenEventsReducer;
    const citizenMenuGroupsState = globalState.citizenMenuCitizenGroupsReducer;

    const globalDispatch = useDispatch();

    const updateCitizenMenuEvents = (eventId: number) => {
        if (citizenMenuEventsState.totalCount < COUNT_OF_ELEMENTS_TO_SHOW) {
            const citizenEvent = {
                project_name: globalState.selectedPoint.selectedPoint?.projectName,
                object_name: globalState.selectedPoint.selectedPoint?.title,
                event_date_time: activity.date_time,
                event_name: activity.event_name,
                Id: eventId,
            } as ICitizenEvent;

            globalDispatch(addCitizenEvents([citizenEvent]));
        }

        globalDispatch(setEventsTotalCount(citizenMenuEventsState.totalCount + 1));
    };

    const checkGroupAndTakePartIfPossible = async () => {
        if (!userId) {
            redirect(AuthHelper.getAuthorizeCodeUrl(), false);
            return;
        }

        if (!activity.object_id) {
            await takePart();
            return;
        }

        dispatch(actionCreators.setLoading(true));

        const request = { CitizenId: userId, ObjectId: activity.object_id } as ICitizenGroupRequest;
        const response = await getCitizenGroup(request);
        if (response.data.length !== 0) {
            await takePart();
        }
        else {
            dispatch(actionCreators.setIsJoinGroupModalOpen(true));
        }

        dispatch(actionCreators.setLoading(false));
    };

    const joinGroup: () => Promise<boolean> = async () => {
        dispatch(actionCreators.setJoinGroupYesButtonLoading(true));

        const createRequest = {
            modelData: {
                citizen_id: { Id: userId },
                object_id: { Id: activity.object_id },
            },
        } as ICitizenGroupCreateRequest;

        const response = await addCitizenGroup(createRequest);
        if (!response.data.Id) {
            return false;
        }

        dispatch(actionCreators.setJoinGroupYesButtonLoading(false));

        updateCitizenMenuGroups(citizenMenuGroupsState, globalDispatch, response);

        return true;
    };

    const takePart = async () => {
        dispatch(actionCreators.setLoading(true));

        const createRequest = {
            modelData: {
                Citizen: { Id: userId },
                ObjectEvent: { Id: activity.object_event_id },
            },
        } as ICitizenEventCreateRequest;

        const response = await addCitizenEvent(createRequest);
        dispatch(actionCreators.setLoading(false));
        dispatch(actionCreators.setNeedToHideButton(true));
        setShowYouParticipantButton(true);

        updateCitizenMenuEvents(response.data.Id);
    };

    const setIsJoinGroupModalOpen = (isJoinGroupModalOpen: boolean) => {
        dispatch(actionCreators.setIsJoinGroupModalOpen(isJoinGroupModalOpen));
    };

    const setIsTakePartModalOpen = (isTakePartModalOpen: boolean) => {
        dispatch(actionCreators.setIsTakePartModalOpen(isTakePartModalOpen));
    };

    return {
        ...takePartButtonState,
        checkGroupAndTakePartIfPossible,
        joinGroup,
        takePart,
        setIsJoinGroupModalOpen,
        setIsTakePartModalOpen,
    };
};

export default useTakePartButton;
