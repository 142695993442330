import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FolkButton from 'ui-kit/FolkButtons';
import { ICitizenGroupCreateRequest, ICitizenGroupItem } from 'api/types/v1.0/citizenGroup';
import { addCitizenGroup } from 'api/v1.0/citizenGroup';
import { RootState } from 'App/root/rootReducer';
import {
    setGroups,
    setTotalCount,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/reducer';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import styles from './PointJoinGroup.module.scss';

interface IProps {
    objectId: number | null;
    userId: number | null;
    hasCitizenRole: boolean;
    isUserInSubscribed?: boolean;
    isUserDeleted?: boolean;
}

export const PointJoinGroup: React.FC<IProps> = (props) => {
    const { hasCitizenRole, objectId, userId, isUserDeleted, isUserInSubscribed } = props;

    const [isJoined, setIsJoined] = useState<boolean>(isUserInSubscribed ?? false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const globalState = useSelector((state: RootState) => state.citizenMenuCitizenGroupsReducer);
    const globalDispatch = useDispatch();

    const onAuthorizedClick = async () => {
        setIsLoading(true);
        if (userId && objectId && hasCitizenRole)
        {
            const createRequest = {
                modelData: {
                    citizen_id: { Id: userId },
                    object_id: { Id: objectId },
                },
            } as ICitizenGroupCreateRequest;
            const response = await addCitizenGroup(createRequest);
            setIsJoined(true);

            const addedObjectInfo = {
                Id: response.data.Id,
                object_id: response.data.object_id.Id,
                address: response.data.object_id.address_o,
                object_name: response.data.object_id.object_short_name,
            } as ICitizenGroupItem;

            if (globalState.totalCount < COUNT_OF_ELEMENTS_TO_SHOW) {
                globalDispatch(setGroups([addedObjectInfo]));
            }

            globalDispatch(setTotalCount(globalState.totalCount + 1));
        }

        setIsLoading(false);
    };

    const onUnauthorizedClick = () => {
        redirect(AuthHelper.getAuthorizeCodeUrl(), false);
    };

    if (isJoined) {
        return isUserDeleted
            ? (<FolkButton disabled type='primary'>Вступить в группу</FolkButton>)
            : (<FolkButton disabled type='primary'>Вы уже вступили</FolkButton>);
    }

    return !userId
        ? <FolkButton type='primary' onClick={onUnauthorizedClick} className={styles.disabledButton}>Вступить в группу</FolkButton>
        : (<FolkButton type='primary' onClick={onAuthorizedClick} loading={isLoading}>Вступить в группу</FolkButton>);
};
