import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useField } from 'formik';
import './TextInputAreaField.scss';
import TextArea from 'antd/es/input/TextArea';
import { validationMessages } from 'constants/validationMessages';

export interface ITextInputAreaFieldProps {
    disabled?: boolean;
    name: string;
    placeholder: string;
    maxLength?: number;
}

export const TextInputAreaField: React.FC<ITextInputAreaFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLTextAreaElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            name={props.name ? field.name : undefined}
            initialValue={field.value}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: true, message: validationMessages.description }]}
        >
            <TextArea
                showCount
                maxLength={props.maxLength}
                disabled={props.disabled}
                className={'text-input-area-field'}
                itemType={'text'}
                placeholder={props.placeholder}
                value={field.value as string | number | undefined}
                onChange={onChange} />
        </Form.Item>
    )
};
