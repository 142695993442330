//TODO: set ids when ready.
export const MobileAppStoreIds = {
    AndroidAppStore: {
        id: 'com.devsisters.ck', //mock data
    },

    IosAppStore: {
        id: '',
    },
};
