import React, { useCallback } from 'react';
import { useField } from 'formik';
import { Form, Input } from 'antd';
import styles from './HeaderTextInputField.module.scss';

export const ControlPointNameField: React.FC = () => {
    const name = 'title';
    const placeholder = 'Заголовок';

    const [field, meta, { setValue, setTouched }] = useField(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            className={styles.headerTextFieldContainer}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <div className={styles.headerTextFieldPlaceholderAsterisk}>
                <Input
                    className={styles.headerTextField}
                    type={'text'}
                    placeholder={placeholder}
                    value={field.value as string}
                    onChange={onChange}
                />
            </div>
        </Form.Item>
    );
};
