import React from 'react';
import './templates.scss';

const Like: React.FC = () => {
    return (
        <svg className={'like-template'} viewBox="0 0 29 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33366 28.3333V13.6666M1.66699 16.3333V25.6666C1.66699 27.1394 2.8609 28.3333 4.33366 28.3333H22.2353C24.2096 28.3333 25.8886 26.8928 26.1888 24.9415L27.6247 15.6082C27.9975 13.1852 26.1228 11 23.6712 11H19.0003C18.2639 11 17.667 10.403 17.667 9.66663V4.95441C17.667 3.13862 16.195 1.66663 14.3792 1.66663C13.9461 1.66663 13.5536 1.92169 13.3777 2.31746L8.68558 12.8748C8.47158 13.3563 7.99408 13.6666 7.46716 13.6666H4.33366C2.8609 13.6666 1.66699 14.8605 1.66699 16.3333Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Like;
