import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getDeputyActivitiesFilter } from 'api/bars-request-objects';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { getDeputyActivities } from 'api/v1.0/deputyActivities';
import { MAX_INT } from 'constants/numbers';
import { eventStatuses } from 'constants/eventStatuses';

export interface IUseActivityListProps {
    userId: string;
    pointId: number | undefined;
    projectId: number | undefined;
}

export type ReferenceBoolean = {
    value: boolean;
}

export const useActivityList = (props : IUseActivityListProps) => {
    const [loading, setLoading] = useState(false);
    const { userId, pointId, projectId } = props;
    const [activities, setActivities] = useState<IDeputyActivity[]>();

    const refresh = useCallback(async (didCancelUpdate: ReferenceBoolean) => {
        try {
            setLoading(true);
            const response = await getDeputyActivities({
                start:0,
                limit: MAX_INT,
                dataFilter: getDeputyActivitiesFilter(projectId ?? null,
                    pointId ?? null, null, userId, eventStatuses.completed),
            });

            if (!didCancelUpdate.value) {
                setActivities(response.data);
                setLoading(false);
            }
        } catch (exception) {
            handleApiError(exception);
            setLoading(false);
        }
    }, [projectId, pointId, userId]);

    useEffect(() => {
        const didCancelUpdate: ReferenceBoolean = { value: false };

        refresh(didCancelUpdate).catch((e) => handleApiError(e));

        return () => {
            didCancelUpdate.value = true;
        };
    }, [projectId, pointId, userId, refresh]);

    return {
        activities,
        loading,
    };
};
