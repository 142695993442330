import React, { useEffect, useMemo, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Spin, Tooltip } from 'antd';
import { IPointControlPoint } from 'api/types/v1.0/pointControlPoints';
import { getDayMonthYearString } from 'utils/dateTime';
import { ControlPointTypeEnum } from 'constants/controlPointTypeEnum';
import ControlPointModal, { IControlPointModalValues } from '../ControlPointModal/ControlPointModal';
import { ReactComponent as EditIcon } from './assets/EditIcon.svg';
import { ReactComponent as AddIcon } from './assets/AddIcon.svg';
import useControlPointsForEditing from './hooks/useControlPointsForEditing';
import './EditControlPoints.scss';

export interface IEditControlPointsProps {
    pointId: number;
    deputyId: number;
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditControlPoints: React.FC<IEditControlPointsProps> = ({ pointId, deputyId, setIsLoaded }) => {
    const { controlPoints, isLoading, refresh: updateControlPoints } = useControlPointsForEditing(pointId);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentPoint, setCurrentPoint] = useState<IControlPointModalValues | null>(null);
    const [currentPointType, setCurrentPointType] = useState<number>(0);

    useEffect(() => {
        setIsLoaded(!isLoading);
    }, [isLoading, setIsLoaded]);

    const getControlPointPlanDates = useMemo(() => {
        if (!controlPoints || controlPoints.length === 0) {
            return [];
        }

        return controlPoints
            .map((x: IPointControlPoint) =>
                x.date_plan ? new Date(x.date_plan) : undefined
            ).filter((d) => d !== undefined) as Date[];
    }, [controlPoints]);

    const getDate = (value: IPointControlPoint) => {
        if (!value.finished && value.date_plan) {
            return getDayMonthYearString(new Date(value.date_plan));
        }

        if (value.finished) {
            if (value.date_fact) {
                return getDayMonthYearString(new Date(value.date_fact));
            }

            if (value.date_plan) {
                return getDayMonthYearString(new Date(value.date_plan));
            }
        }
    };

    const getActualNamePoint = (point: IPointControlPoint): string => {
        if (point.newCheckpointName) {
            return (point.newCheckpointName);
        } else {
            return (point.checkpoint_name);
        }
    };

    const onModalClose = () => {
        setModalOpen(false);
        setCurrentPoint(null);
    };

    const fillLastAvailableDatesIfPossible = (mappedData: IControlPointModalValues, index: number) => {
        const previousControlPoints = controlPoints.filter((_: IPointControlPoint, i: number) => i < index);
        const biggestPlanDateFromPreviousControlPoints = previousControlPoints
            .filter((x: IPointControlPoint) => x.date_plan)
            .map((x: IPointControlPoint) => x.date_plan)
            .pop();

        if (previousControlPoints && biggestPlanDateFromPreviousControlPoints) {
            mappedData.previousLastAvailableDate = new Date(biggestPlanDateFromPreviousControlPoints);
        }

        const nextControlPoint = controlPoints[index + 1];
        if (nextControlPoint && nextControlPoint.date_plan) {
            mappedData.nextLastAvailableDate = new Date(nextControlPoint.date_plan);
        }
    };

    const openModalForEditing = (selectedPoint: IPointControlPoint, index: number) => {
        const mappedData = {
            name: selectedPoint.checkpoint_name,
            id: selectedPoint.Id,
            isCompleted: !!selectedPoint.finished,
            isDisplayed: !!selectedPoint.displayed,
            required: !!selectedPoint.required,
            factDate: selectedPoint.date_fact ? new Date(selectedPoint.date_fact) : null,
            planDate: selectedPoint.date_plan ? new Date(selectedPoint.date_plan) : null,
        } as IControlPointModalValues;

        fillLastAvailableDatesIfPossible(mappedData, index);

        setCurrentPoint(mappedData);
        setCurrentPointType(selectedPoint.checkpoint_type);
        setModalOpen(true);
    };

    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <div className='edit-control-point-header'>
                Этапы работ:
            </div>
            <div className='edit-control-point-body'>
                {
                    controlPoints.length > 0 && controlPoints.map((value, index) =>
                        <div className='edit-control-point-items-wrapper' key={index}>
                            <div className='edit-control-point-items'>
                                <div className='edit-control-point-info'>
                                    <Checkbox
                                        checked={!!value.finished}
                                        className='edit-control-point-cursor'
                                    />
                                    <div className='edit-control-point-text'>
                                        <span className={'control-point-date'}>{getDate(value)}     </span>
                                        <Tooltip title={getActualNamePoint(value)}>
                                            <span className={'control-point-name'}>
                                                {getActualNamePoint(value)}
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <EditIcon
                                    onClick={() => openModalForEditing(value, index)}
                                    className='edit-icon'
                                />
                            </div>
                            {value !== controlPoints[controlPoints.length - 1]
                                && <Divider className={'edit-control-divider'} type='vertical' />
                            }
                        </div>
                    )
                }
                <ControlPointModal
                    isOpen={isModalOpen}
                    onClose={onModalClose}
                    onUpdate={updateControlPoints}
                    formValue={currentPoint}
                    deputyId={deputyId}
                    objectId={pointId}
                    currentPointType={currentPoint?.id ? currentPointType : ControlPointTypeEnum.notTypical}
                    controlPointDates={getControlPointPlanDates}
                />
            </div>
            <Button
                className='edit-control-point-add'
                onClick={() => {
                    setModalOpen(true);
                }}
                icon={<AddIcon className='add-icon' />}
            >
                Добавить этап
            </Button>
        </Spin>
    );
};

export default EditControlPoints;
