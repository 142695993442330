import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as jwt from 'App/root/jwt';
import { initAccount } from 'ducks/account';

const useRefreshTokenSimulator = () => {
    const dispatch = useDispatch();
    const token = jwt.get();

    useEffect(() => {
        if (token) {
            dispatch(initAccount(true));
        }
        //eslint-disable-next-line
    }, []);
};

export default useRefreshTokenSimulator;
