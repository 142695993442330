import { IProjectListItem } from 'api/types/v1.0/projectList';
import { HANDLE_SELECT_PROJECT, HANDLE_SETTINGS_PROJECT, RESET_SELECT_PROJECT } from './actionTypes';

export const handleSelectProject = (project: IProjectListItem | undefined) =>
    ({ type: HANDLE_SELECT_PROJECT, payload: project } as const);

export const handleSettingsProject = (project: IProjectListItem[] | undefined) =>
    ({ type: HANDLE_SETTINGS_PROJECT, payload: project } as const);

export const resetSelectProject = () =>
    ({ type: RESET_SELECT_PROJECT } as const);
