import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useField } from 'formik';
import InputMask from 'react-input-mask';
import { phoneMask } from 'constants/phone';
import './MaskedTextInputField.scss';

export interface ITextInputFieldProps {
    disabled?: boolean;
    type: 'phone';
    name: string;
    placeholder: string;
}

export const MaskedTextInputField: React.FC<ITextInputFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField<string | undefined>(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    let mask = '';
    if (props.type === 'phone') {
        mask = phoneMask;
    }

    return (
        <Form.Item
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <InputMask
                value={field.value}
                disabled={props.disabled}
                className={'masked-text-input-field ant-input'}
                placeholder={props.placeholder}
                mask={mask}
                onChange={onChange}
            />
        </Form.Item>
    );
};
