import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Modal } from 'antd';
import { SelectField } from 'ui-kit/Form/FormFields/SelectField';
import { SubmitButton } from 'ui-kit/Form/SubmitButton/SubmitButton';
import { TextInputAreaField } from 'ui-kit/Form/FormFields/TextInputAreaField';
import { MultipleSelect } from 'ui-kit/Form/FormFields/MultipleSelect';
import { getOfferNPHashtagList } from 'api/v1.0/getOfferHashtagList';
import { IHashtagItem, IHashtagList } from 'api/types/v1.0/offerNPHachtagList';
import { validationMessages } from 'constants/validationMessages';
import { getBranchEconomyList } from 'api/v1.0/getBranchEconomyList';
import { EconomyItem, EconomyList } from 'api/types/v1.0/branchEconomyList';
import { handleApiError } from 'api/v1.0/common';
import { modalValidationSchema } from './ModalValidationSchema';
import { sendOfferCoordinator } from './sendOfferCoordinator';

import styles from './ModalOffer.module.scss';

interface IModalProps {
    stateIsOpen: [boolean, (isOpen: boolean) => void];
    deputyId: string;
}

export interface IModalFormValues {
    industry?: string;
    offer?: string;
    hashtags?: string[];
}

const ModalOffer: React.FC<IModalProps> = ({ stateIsOpen, deputyId }) => {
    const [open, setOpen] = stateIsOpen;
    const [loading, setLoading] = useState(false);

    const [hashtagList, setHashtagList] = useState<IHashtagItem[] | []>([]);
    const [economyList, setEconomyList] = useState<EconomyItem[] | []>([]);

    const initialValues = { industry: undefined, offer: undefined, hashtags: [] };

    const handleCancel = () => {
        setOpen(false);
    };

    const getHashtagList = async () => {
        const response: IHashtagList = (await getOfferNPHashtagList()) as IHashtagList;

        setHashtagList(response.data);
    };

    const getEconomyList = async () => {
        const response = (await getBranchEconomyList()) as EconomyList;
        setEconomyList(response.data);
    };

    const handleOnSubmit = (values: IModalFormValues) => {
        setLoading(true);
        const { industry, offer, hashtags } = values;
        void sendOfferCoordinator({ deputyId, branchEconomyId:industry, description:offer, hashtag:hashtags })
            .then(() => {
                setLoading(false);
                setOpen(false);
            })
            .catch((err) => {
                setLoading(false);
                handleApiError(err);
            });
    };

    useEffect(() => {
        if (open) {
            void getHashtagList();
            void getEconomyList();
        }
    }, [open]);

    return (
        <Formik<IModalFormValues>
            initialValues={initialValues}
            validateOnChange
            validationSchema={modalValidationSchema}
            validateOnBlur
            isInitialValid={false}
            onSubmit={handleOnSubmit}
        >
            {(props) => (
                <Modal
                    className={styles['modal-offer']}
                    title="Отправить предложение"
                    open={open}
                    onCancel={handleCancel}
                    footer={null}
                    destroyOnClose
                    afterClose={() => props.resetForm({ values: initialValues, isValidating: false })}
                >
                    <Form onSubmit={props.handleSubmit}>
                        <SelectField
                            placeholder="Тематика сообщений *"
                            name="industry"
                            validationMessage={validationMessages.industry}
                            options={economyList.map((economyItem: EconomyItem) => ({
                                value: economyItem.Id,
                                label: economyItem.Name,
                            }))}
                        />
                        <TextInputAreaField placeholder="Ваше предложение *" name="offer" />
                        <MultipleSelect
                            placeholder="Добавить хештег"
                            name="hashtags"
                            options={hashtagList.map((hashtagItem: IHashtagItem) => ({
                                value: hashtagItem.hashtag || '',
                                label: hashtagItem.hashtag || '',
                            }))}
                        />
                        <footer className={styles['footer']}>
                            <Button key="back" onClick={handleCancel}>
                                Отмена
                            </Button>
                            <SubmitButton loading={loading} disabled={!props.isValid} key="submit" buttonText="Отправить" />
                        </footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

export default ModalOffer;
