import { axiosDeleteNoResponse, axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { getCitizenFavoritesSort, getFavoritePointFilter, getFavoritePoints, IHasFilters } from '../bars-request-objects';
import {
    IFavoritePointCreateRequest,
    IFavoritePointCreateResponse,
    IFavoritePointDeleteRequest,
    IFavoritePointRequest,
    IFavoritePointsListResponse,
} from '../types/v1.0/favoritePoint';

export const getFavoritePoint = async (request: IFavoritePointRequest): Promise<IFavoritePointsListResponse> => {
    try {
        const filterRequest = {
            start: request.start,
            limit: request.limit,
            dataFilter: getFavoritePointFilter(request),
            sort: getCitizenFavoritesSort(),
        };
        const result = await axiosPostRequest<IHasFilters, IFavoritePointsListResponse>(
            ApiPaths.FavoritePointsList,
            filterRequest
        );

        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};

export const getFavoritesPoints = async (request: IFavoritePointRequest): Promise<IFavoritePointsListResponse> => {
    try {
        const filterRequest = {
            dataFilter: getFavoritePoints(request),
            sort: getCitizenFavoritesSort(),
        };
        const result = await axiosPostRequest<IHasFilters, IFavoritePointsListResponse>(
            ApiPaths.FavoritePointsList,
            filterRequest
        );

        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};

// Возвращает Id сущности favoritePoint
export const addFavoritePoint = async (request: IFavoritePointCreateRequest): Promise<IFavoritePointCreateResponse> => {
    try {
        const response = await axiosPostRequest<IFavoritePointCreateRequest, IFavoritePointCreateResponse>(
            ApiPaths.FavoritePointCreate,
            request
        );
        return response.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};

export const deleteFavoritePoint = async (request: IFavoritePointDeleteRequest) => {
    try {
        await axiosDeleteNoResponse<IFavoritePointDeleteRequest>(ApiPaths.FavoritePointDelete, request);
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
