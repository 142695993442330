import { useCallback, useEffect, useState } from 'react';
import { IObjectPoint, IPointsResponse } from 'api/types/v1.0/points';
import { getPointsList } from 'api/v1.0/points';
import { handleApiError } from 'api/v1.0/common';
import { MAX_INT } from 'constants/numbers';
import { getBarsProjectDeputyActivityFilter, getBarsProjectFilter } from 'api/bars-request-objects';
import { deputyActivitiesPoints } from '../api/v1.0/deputyActivitiesPoints';

export interface IUsePointListProps {
    projectId?: number;
    userId?: string;
}

export const usePointList = ({ projectId, userId }: IUsePointListProps) => {
    const [points, setPoints] = useState<IObjectPoint[]>();
    const [loading, setLoading] = useState(false);

    const fetch = () => {
        setLoading(true);

        let pointsRequest: Promise<IPointsResponse>;

        if (userId)
        {
            pointsRequest = deputyActivitiesPoints({
                dataFilter: getBarsProjectDeputyActivityFilter(userId, projectId ? [projectId] : null),
            });
        }
        else
        {
            pointsRequest = getPointsList({
                start: 0,
                limit: MAX_INT,
                page: 1,
                dataFilter: getBarsProjectFilter(projectId ? [projectId] : null),
            });
        }

        pointsRequest
            .then((response) => setPoints(response.data))
            .catch((e) => handleApiError(e))
            .finally(() => setLoading(false));
    };

    const refresh = useCallback(
        () => fetch(),
        // Игнорируем deps, которые используются в useEffect ниже.
        // Если не было изменений projectId, то не должно быть нового fetch запроса.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [projectId]
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        points,
        refresh,
        loading,
    };
};
