import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { SortType } from 'api/bars-request-objects';
import { IRegionalCoordinatorPointsRequest } from 'api/types/v1.0/regionalCoordinatorPoints';
import { getRegionalCoordinatorPoints } from 'api/v1.0/regionalCoordinatorPoints';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';
import { setSortByRating, setSortByAlphabet } from './actionCreators';

const useRegionalCoordinatorPoints = (data: IRegionalCoordinatorPointsRequest) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refreshPoints = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getRegionalCoordinatorPoints(data, state.descending)
            .then((response) => {
                dispatch(actionCreators.setRegionalCoordinatorPoints(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refreshPoints();
    }, [refreshPoints]);

    const changeSort = async (sortType: SortType) => {
        data.start = 0;
        dispatch(actionCreators.clearRegionalCoordinatorPoints());
        switch (sortType) {
            case 'title': {
                data.sortType = 'title';
                state.descending = !state.descending;
                const pointsResponse = await getRegionalCoordinatorPoints(data, state.descending);
                dispatch(actionCreators.setRegionalCoordinatorPoints(pointsResponse.data));
                dispatch(actionCreators.setTotalCount(pointsResponse.totalCount));
                dispatch(setSortByAlphabet());
                break;
            }

            case 'rating': {
                data.sortType = 'rating';
                state.descending = !state.descending;
                const respRat = await getRegionalCoordinatorPoints(data, state.descending);
                dispatch(actionCreators.setRegionalCoordinatorPoints(respRat.data));
                dispatch(actionCreators.setTotalCount(respRat.totalCount));
                dispatch(setSortByRating());
                break;
            }
        }
    };

    const refresh = async (start: number) => {
        dispatch(actionCreators.setIsLoading(true));
        try {
            data.start = start;
            const response = await getRegionalCoordinatorPoints(data, state.descending);
            dispatch(actionCreators.setRegionalCoordinatorPoints(response.data));
            dispatch(actionCreators.setTotalCount(response.totalCount));
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setIsLoading(false));
        }
    };

    return {
        state,
        refreshPoints,
        changeSort,
        refresh,
    };
};

export default useRegionalCoordinatorPoints;
