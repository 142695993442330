import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { ICitizenVotePollCreateRequest, ICitizenVotePollCreateResponse } from '../types/v1.0/citizenVotePoll';

export const addCitizenVoteForInterview = async (request: ICitizenVotePollCreateRequest) => {
    request.functionName = 'VotePoll';
    const stringedRequest = new URLSearchParams(Object.entries(request)).toString();

    const responseData = await axiosPostRequest<string,
        ICitizenVotePollCreateResponse>(ApiPaths.DataUsingSqlFunction, stringedRequest);

    return responseData.data;
};
