import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'App/root/rootReducer';
import ExpandableListHeaderForMenu from 'ui-kit/ExpandableListForMenu';
import ExpandableListShowAllButton from 'ui-kit/ExpandableListForMenu/components/ExpandableListShowAllButton';
import { Pages } from 'constants/links';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import {
    CitizenEventItem,
} from 'pages/CitizenEvents/components/CitizenEventsList/components/CitizenEventItem';
import browserHistory from 'App/root/browserHistory';
import styles from './CitizenEventsCitizenMenulist.module.scss';
import useCitizenEventsMenuList from './hooks/useCitizenEventsMenuList';

const CitizenEventsCitizenMenuList: React.FC = () => {
    const location = useLocation();
    const citizenEventsState = useSelector((state: RootState) => state.citizenMenuCitizenEventsReducer);
    const { user } = useSelector((state: RootState) => state.account);
    const {
        citizenEvents,
        totalCount,
        isExpanded,
        changeIsExpanded,
        isLoading,
    } = useCitizenEventsMenuList(user?.sub as string);

    const listName = 'Мероприятия';
    const redirectUrl = Pages.CitizenEvents.url;

    const handleListItemClick = () => {
        browserHistory.push(redirectUrl);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    return (
        <>
            <ExpandableListHeaderForMenu
                listName={listName}
                count={totalCount}
                isExpanded={isExpanded}
                setIsExpanded={changeIsExpanded}
                markAsSelected={location.pathname === redirectUrl}
                isLoading={isLoading}
            />
            <div className={styles.expandableListExpandedElementsBlockWrapper}>
                {
                    citizenEventsState.isExpanded && citizenEvents && citizenEvents.map((data, index) => {
                        if (index < COUNT_OF_ELEMENTS_TO_SHOW) {
                            return <div
                                onClick={handleListItemClick}
                                key={data.Id}
                                className={styles.expandedElementItem}
                            >
                                <CitizenEventItem citizenEvent={data} key={data.Id} needToHideActionsBlock={true} />
                            </div>;
                        }

                        return <Fragment key={index} />;
                    })
                }
                { citizenEventsState.isExpanded &&
                    <ExpandableListShowAllButton
                        redirectUrl={redirectUrl}
                        listName={listName}
                    />
                }
            </div>
        </>
    );
};

export default CitizenEventsCitizenMenuList;
