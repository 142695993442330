import React from 'react';
import { Divider } from 'antd';
import { formatNewsTime } from 'utils/dateTime';
import { INewsItem } from 'api/types/v1.0/news';
import NewsDetailsButton from '../NewsDetailsButton/NewsDetailsButton';
import styles from './NewsCard.module.scss';

interface INewsCardProps {
    newsInfo: INewsItem;
}

const NewsCard: React.FC<INewsCardProps> = ({ newsInfo }) => {
    return (
        <div>
            <Divider />
            <div className={styles.newsCard}>
                <div className={styles.newsCardImageContainer}>
                    <img src={newsInfo?.image} className={styles.newsCardImage} alt={'Новость'} />
                </div>
                <div className={styles.newsCardDescriptionContainer}>{newsInfo?.title}</div>
                <div className={styles.newsDate}>{formatNewsTime(newsInfo.date_time)}</div>
                <NewsDetailsButton newsId={newsInfo?.Id} />
            </div>
        </div>
    );
};

export default NewsCard;
