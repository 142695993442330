export interface IEventLikeRequest {
    modelData: {
        Likeit: number;
        ObjectEvent_id: number;
    }
}

export interface IPointLikeRequest {
    id: number;
    mark_type: number;
}

export interface INewsLikeRequest {
    news_id: number;
    mark_type: number;
}

export interface IMessageLikeRequest {
    message_id: number;
    mark_type: number;
}

export enum LikeType {
    activityLike,
    pointLike,
    newsLike,
    messageLike,
}
