import { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { IGroupMessageResponseData, IGroupResponseData, IGroupsMessageItem, MessagesSortType } from 'api/types/v1.0/groupsList';
import {
    getGroupMessages,
    getGroupsList,
    getReplyMessage,
    getSingleReplyMessage,
    removeMessageFromChat,
    sendMessageRequest,
} from 'api/v1.0/getChatInfo';
import { IAccount } from 'types/account';
import { getGroupMembersList } from 'api/v1.0/GroupMembersList';
import { handleApiError } from 'api/v1.0/common';
import { RootState } from 'App/root/rootReducer';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';
import { sendMessagesSeenHelper } from './helpers';

export interface ISendMessage {
    user_id: number;
    date: Date;
    object_id: number;
    text: string;
    timezone: number;
    TypeQAC: number;
    AnsweringMessageAuthorName?: string;
    QuestionAnswerCommentId?: number;
}

const useChatModal = (userId?: string) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { user } = useSelector((state: RootState) => state.account);

    const changeMessagesSort = (sortType: MessagesSortType) => {
        if (sortType !== state.sortType) {
            dispatch(actionCreators.setMessagesSortType(sortType));
        }

        switch (state.sortDirection) {
            case 'ASC': dispatch(actionCreators.setMessagesSortDirection('DESC'));
                break;
            case 'DESC': dispatch(actionCreators.setMessagesSortDirection('ASC'));
                break;
            default:
                dispatch(actionCreators.setMessagesSortDirection('ASC'));
        }
    };

    const getGroupMessagesList = (object_id: number, start?: number, limit?: number) => {
        dispatch(actionCreators.setIsChatMessagesLoading(true));
        void getGroupMessages({
            object_Id: object_id,
            start,
            limit,
            sortType: state.sortType,
            sortDirection: state.sortDirection,
        }).then((groupMessage: IGroupMessageResponseData) => {
            dispatch(actionCreators.setGroupMessages([...state.groupMessages ?? [], ...groupMessage.data]));
            dispatch(actionCreators.setIsChatMessagesLoading(false));
        });
    };

    const getInitialGroupMessageList = (object_id: number) => {
        dispatch(actionCreators.setIsChatMessagesLoading(true));
        void getGroupMessages({
            object_Id: object_id,
            start: 0,
            limit: 5,
            sortType: state.sortType,
            sortDirection: state.sortDirection,
        }).then((groupMessage) => {
            dispatch(actionCreators.setGroupMessages(groupMessage.data));
            dispatch(actionCreators.setMessagesTotalCount(groupMessage.totalCount));
            dispatch(actionCreators.setIsChatMessagesLoading(false));
        });
    };

    const setEmptyGroupMessageList = () => {
        dispatch(actionCreators.setGroupMessages([]));
    };

    const refresh = (projectId: number | null, start?: number) => {
        dispatch(actionCreators.setIsPreviewLoading(true));
        if (userId) {
            void getGroupsList({
                user_Id: +userId,
                user_role: user?.role,
                project_id: projectId,
                start,
            }).then((groupsList: IGroupResponseData) => {
                if (start) {
                    dispatch(actionCreators.setGroupsList(groupsList.data));
                } else {
                    dispatch(actionCreators.setFilteredGroupsList(groupsList.data));
                }

                dispatch(actionCreators.setGroupsTotalCount(groupsList.totalCount));
                dispatch(actionCreators.setIsPreviewLoading(false));
            });
        }
    };

    const sendMessage = async (
        data: ISendMessage,
        user: IAccount,
        isMundep: boolean,
        imgs?: File[]
    ) => {
        dispatch(actionCreators.setIsSendMessageLoading(true));

        const msgReq = {
            modelData: {
                citizen_id: isMundep ? null : { Id: data.user_id },
                deputy_id: isMundep ? { Id: data.user_id } : null,
                DateTime: data.date,
                object_id: { Id: data.object_id },
                QuestionStatus: 0,
                Text: data.text,
                TypeQAC: data.TypeQAC,
                Viewed: 0,
                QuestionAnswerCommentId: { Id: data.QuestionAnswerCommentId },
                replied_message_id: data.QuestionAnswerCommentId,
                replied_message_author_name: data.AnsweringMessageAuthorName,
            },
            clientTimezoneParams: { ClientTimeZoneOffset: data.timezone },
        };

        const imgReq = imgs && imgs.map((img) => ({
            MediaAuthorCitizen: { Id: +user.sub },
            Object: { Id: data.object_id },
            FIleOriginal: img,
        }));

        const res = await sendMessageRequest(msgReq, imgReq);

        const message = res &&
            await getSingleReplyMessage({ object_id: data.object_id, message_id: res.msgRes.data.Id });

        message && dispatch(actionCreators.sendMessage(message));
        dispatch(actionCreators.setIsSendMessageLoading(false));
    };

    const removeMessage = async (message_id: number) => {
        const res = await removeMessageFromChat(message_id);

        if (res?.success) {
            dispatch(actionCreators.deleteMessage(message_id));
        }
    };

    const getGroupMembersCount = async (object_id: number) => {
        try {
            const request = {
                object_id: object_id,
                noLimit: true,
            };

            const response = await getGroupMembersList(request);
            const groupMembers = response?.data.data.filter((member) => !member.remove_from_group);
            if (response) {
                dispatch(actionCreators.setGroupMembersCount(groupMembers?.length as number));
            }
        } catch (exception) {
            handleApiError(exception);
        }
    };

    const sendMessageSeen = (messages: IGroupsMessageItem[]) => {
        void sendMessagesSeenHelper(messages).then(() => {
            dispatch(actionCreators.setMessagesSeen(messages));
        }).catch((exception) => handleApiError(exception));
    };

    const replyMessage = async (req: {object_id: number, message_id: number, start: number}) => {
        try {
            dispatch(actionCreators.setIsChatMessagesLoading(true));
            const response = await getReplyMessage(req);

            dispatch(actionCreators.setMessagesTotalCount(response?.data.totalCount as number));
            dispatch(actionCreators.setGroupMessages(response?.data.data as IGroupsMessageItem[]));
            dispatch(actionCreators.setIsChatMessagesLoading(false));

            return response?.data.data as IGroupsMessageItem[];
        } catch (exception) {
            handleApiError(exception);
        }
    };

    return {
        ...state,
        getInitialGroupMessageList,
        getGroupMessagesList,
        setEmptyGroupMessageList,
        replyMessage,
        getGroupMembersCount,
        sendMessageSeen,
        refresh,
        removeMessage,
        sendMessage,
        changeMessagesSort: changeMessagesSort,
    };
};

export default useChatModal;
