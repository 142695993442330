import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiError } from 'api/v1.0/common';
import { RootState } from 'App/root/rootReducer';
import { COUNT_OF_ELEMENTS_TO_SHOW_OBJECTS } from 'constants/citizenMenu';
import { IFavoritePointRequest } from 'api/types/v1.0/favoritePoint';
import { getFavoritePoint } from 'api/v1.0/favoritePoint';
import * as actionCreators from '../reducer';
import { setIsExpanded } from '../reducer';

const useFavoritesCitizenMenuList = (userId: number) => {
    const citizenFavoritesState = useSelector((state: RootState) => state.citizenMenuFavoritesReducer);
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: 0,
            limit: COUNT_OF_ELEMENTS_TO_SHOW_OBJECTS,
            CitizenId: userId,
        } as IFavoritePointRequest;

        void getFavoritePoint(request)
            .then((response) => {
                dispatch(actionCreators.deleteFavorites());
                dispatch(actionCreators.setFavorites(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
                dispatch(actionCreators.setLoading(false));
            }).catch((exception) => {
                handleApiError(exception);
                dispatch(actionCreators.setLoading(false));
            });
    };

    const deleteFavorite = (favoriteId: number) => {
        dispatch(actionCreators.deleteFavorite(favoriteId));
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    const changeIsExpanded = (isExpanded: boolean) => {
        dispatch(setIsExpanded(isExpanded));
        refresh();
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...citizenFavoritesState,
        refresh,
        deleteFavorite,
        setTotalCount,
        changeIsExpanded,
    };
};

export default useFavoritesCitizenMenuList;
