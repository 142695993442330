import React from 'react';
import { converterTextToHtml } from 'utils/string';
import styles from './ProjectInfoText.module.scss';

interface IProjectInfoTextProps {
    desc: string;
}

const ProjectInfoText: React.FC<IProjectInfoTextProps> = ({ desc }: IProjectInfoTextProps) => {
    const descriptionWithIndentations = desc.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const descriptionHtml = converterTextToHtml(descriptionWithIndentations);

    return (
        <div className={styles['project-info-text']}>
            <div dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div>
        </div>
    );
};

export default ProjectInfoText;
