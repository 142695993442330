import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
    ICitizenGroupCreateRequest,
} from 'api/types/v1.0/citizenGroup';
import { addCitizenGroup } from 'api/v1.0/citizenGroup';
import { updateCitizenMenuGroups } from 'ducks/utils/updateCitizenMenuGroups';
import { RootState } from 'App/root/rootReducer';
import { hasCitizenRole, hasMunicipalRole } from 'helpers/account';
import * as actionCreators from 'ducks/chat/actionCreators';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';

const useChatButton = (pointId: number, responsibleDepId: number, isUserSubscribedToGroup: boolean) => {
    const { user } = useSelector((state: RootState) => state.account);
    const userId = Number(user?.sub);
    const citizenMenuGroupsState = useSelector((state: RootState) => state.citizenMenuCitizenGroupsReducer);
    const globalDispatch = useDispatch();
    const [joinGroupYesButtonLoading, setJoinGroupYesButtonLoading] = useState<boolean>(false);
    const [isJoinGroupModalOpen, setIsJoinGroupModalOpen] = useState<boolean>(false);

    const userHasCitizenRole = hasCitizenRole(user);
    const userHasMunicipalDeputyRole = hasMunicipalRole(user);

    const openChat = () => {
        globalDispatch(actionCreators.setSelectedChatId(pointId));
        const isUserResponsibleForPoint = userHasMunicipalDeputyRole && Number(user?.sub) === responsibleDepId;
        globalDispatch(actionCreators.setIsResponse(isUserResponsibleForPoint));

        isMobile
            ? browserHistory.push(`${Pages.Chat.url}/${pointId}`)
            : globalDispatch(actionCreators.setChatModalState(true));
    };

    const handleJoinGroupAction = async () => {
        const isJoinedSuccessfully = await joinGroup();

        if (isJoinedSuccessfully) {
            setIsJoinGroupModalOpen(false);
            openChat();
        }
    };

    const handleChatButtonClick = () => {
        if (!user) {
            redirect(AuthHelper.getAuthorizeCodeUrl(), false);
            return;
        }

        if (userHasCitizenRole && !isUserSubscribedToGroup) {
            setIsJoinGroupModalOpen(true);
            return;
        }

        openChat();
    };

    const joinGroup: () => Promise<boolean> = async () => {
        setJoinGroupYesButtonLoading(true);

        const createRequest = {
            modelData: {
                citizen_id: { Id: userId },
                object_id: { Id: pointId },
            },
        } as ICitizenGroupCreateRequest;

        const response = await addCitizenGroup(createRequest);

        setJoinGroupYesButtonLoading(false);

        if (!response.data.Id) {
            return false;
        }

        setJoinGroupYesButtonLoading(false);

        updateCitizenMenuGroups(citizenMenuGroupsState, globalDispatch, response);

        return true;
    };

    return {
        user,
        joinGroupYesButtonLoading,
        isJoinGroupModalOpen,
        handleJoinGroupAction,
        handleChatButtonClick,
        setIsJoinGroupModalOpen,
    };
};

export default useChatButton;
