import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import { EditPointForm } from './components/EditPointForm';
import styles from './PointConstructorPage.module.scss';

export interface IPointConstructorState {
    projectId: number;
    pointId: number;
    scrollToId?: string;
}

export const PointConstructorPage: React.FC = () => {
    const router = useParams<{ id: string }>();
    const id = router.id ? Number(router.id) : undefined;
    const { user } = useSelector((state: RootState) => state.account);
    const userId = user?.sub;
    const history = useHistory<IPointConstructorState | undefined>();
    const pageState = history.location.state;
    const [updates, setUpdates] = useState<number>(0);

    if (pageState) {
        window.history.replaceState({}, document.title);
    }

    useEffect(() => {
        if (pageState?.scrollToId) {
            document.getElementById(pageState.scrollToId)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [updates, pageState?.scrollToId]);

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={styles.pointConstructorPageContainerWrapper}>
                        <div className={styles.pointConstructorTitleBlock}>
                            <div className={styles.pointConstructorTitle}>
                                Редактирование объекта
                            </div>
                        </div>
                        <EditPointForm
                            userId={userId}
                            pointId={id}
                            projectId={pageState?.projectId}
                            setUpdate={setUpdates} />
                    </div>
                </Page.Content>
            }
        />
    );
};
