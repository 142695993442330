import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { FolkInput } from 'ui-kit/FolkInput/FolkInput/FolkInput';
import './FavoriteSearch.scss';

interface IProps {
    setFavoritesByInput: (input: string) => void;
}
const FavoriteSearch: React.FC<IProps> = ({ setFavoritesByInput }) => {
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFavoritesByInput(e.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 500);
        //eslint-disable-next-line
    }, []);

    return (
        <div className={'favorite-search'}>
            <FolkInput onChange={debouncedResults} height={'48px'} textAlign={'left'} placeholder={'Поиск по объектам'} />
        </div>);
};

export default FavoriteSearch;
