import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

export const defaultState: IState = {
    groups: null,
    isLoading: false,
    totalCount: 0,
    isExpanded: false,
};

const citizenMenuCitizenGroupsReducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CITIZEN_MENU_CITIZEN_GROUPS:
            return {
                ...state,
                groups: [...state.groups ?? [], ...action.payload ?? []],
            };

        case actionTypes.SET_CITIZEN_MENU_CITIZEN_GROUPS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_CITIZEN_MENU_CITIZEN_GROUPS_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.DELETE_CITIZEN_MENU_CITIZEN_GROUPS:
            return {
                ...state,
                groups: [],
            };

        case actionTypes.DELETE_CITIZEN_MENU_CITIZEN_GROUP:
            return {
                ...state,
                groups: state.groups
                    ? state.groups.filter((value) => value.Id !== action.payload)
                    : null,
            };

        case actionTypes.SET_CITIZEN_MENU_CITIZEN_GROUPS_IS_EXPANDED:
            return {
                ...state,
                isExpanded: action.payload,
            };

        case actionTypes.CLEAR_CITIZEN_MENU_CITIZEN_GROUPS:
            return defaultState;

        case actionTypes.DELETE_CITIZEN_GROUP_FROM_GROUPS_LIST:
            return {
                ...state,
                totalCount: --state.totalCount,
            };
        default:
            return state;
    }
};

export default citizenMenuCitizenGroupsReducer;
