import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import './ActivityCardPoll.scss';
import { IAnswer, IInterview } from 'api/types/v1.0/deputyActivities';
import { TextInputField } from 'ui-kit/Form/FormFields/TextInputField';
import { IActivityCardPollFormValues } from './ActivityCardPollForm';
import { QuestionTextInput } from './components/QuestionTextInput/QuestionTextInput';
import { ReactComponent as CloseIconSmall } from './assets/IconCloseSmall.svg';
import { PollSubmitButton } from './components/PollSubmitButton/PollSubmitButton';

export interface IActivityCardPollFormFieldsProps {
    interview: IInterview;
    disabled?: boolean;
    setModalIsOpen: (open: boolean) => void;
    onFinish: (values: IInterview) => void;
    open: boolean;
    onCancel: () => void;
}

export const ActivityCardPollFormFields: React.FC<IActivityCardPollFormFieldsProps> = (props) => {
    const { isValid, setValues, values } = useFormikContext<IActivityCardPollFormValues>();

    const initialInterviewWhenEmpty = () => {
        if (values.question === undefined) {
            setValues({ question: '', answers: ['', ''] as unknown as IAnswer[] } as IInterview);
        }
    };

    initialInterviewWhenEmpty();

    const checkSubmit = () => {
        if (isValid) {
            props.setModalIsOpen(!props.open);
            props.onFinish(values);
        }
    };

    useEffect(() => {
        setValues((prevState) => {
            return props.interview ?? prevState;
        });
    }, [props.interview, setValues]);

    return (
        <>
            <div>
                <div className={'activity-card-poll-field-title'}>Вопрос:</div>
                <QuestionTextInput name={'question'} placeholder={'Задайте вопрос'} />
            </div>
            <div>
                <div className={'activity-card-poll-field-title'}>Варианты ответа:</div>
                <FieldArray
                    name="answers"
                    render={(arrayHelpers) => (
                        <div>
                            <div>
                                {values.answers &&
                                    values.answers.map((_answer, index) =>
                                        index === 0 || index === 1 ? (
                                            <div className={'activity-card-answer'} key={index}>
                                                <div>
                                                    <Field as={TextInputField} name={`answers.[${index}].answer`} />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={'activity-card-answer'} key={index}>
                                                <div>
                                                    <Field as={TextInputField} name={`answers.[${index}].answer`} />
                                                    <CloseIconSmall
                                                        className="activity-card-delete-button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                            </div>
                            <div className={'activity-card-poll-new'} onClick={() => arrayHelpers.push('')}>
                                <div className={'activity-card-poll-new-plus'}>
                                    <PlusOutlined />
                                </div>
                                <div className={'activity-card-poll-add'}>Добавить вариант</div>
                            </div>
                        </div>
                    )}
                />
                <div className={'activity-card-create-poll'}>
                    <div onClick={() => checkSubmit()} className={'submit-button-poll'}>
                        <PollSubmitButton disabled={!isValid} buttonText={'Сохранить опрос'}></PollSubmitButton>
                    </div>
                </div>
            </div>
        </>
    );
};
