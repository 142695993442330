export const SET_NEWS = 'newsPage/SET_NEWS' as const;
export const SET_SELECTED_REGION = 'newsPage/SET_SELECTED_REGION';
export const SET_SELECTED_PROJECT = 'newsPage/SET_SELECTED_PROJECT';
export const SET_SELECTED_OBJECT = 'newsPage/SET_SELECTED_OBJECT';
export const SET_SELECTED_DEPUTY = 'newsPage/SET_SELECTED_DEPUTY';
export const SET_SELECTED_START_DATE = 'newsPage/SET_SELECTED_START_DATE' as const;
export const SET_SELECTED_END_DATE = 'newsPage/SET_SELECTED_END_DATE' as const;
export const SET_LOADING = 'newsPage/SET_LOADING' as const;
export const SET_TOTAL_COUNT = 'newsPage/SET_TOTAL_COUNT' as const;
export const DELETE_NEWS = 'newsPage/DELETE_NEWS' as const;
