import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as UpIcon } from './assets/UpIcon.svg'
import { ReactComponent as DownIcon } from './assets/DownIcon.svg'
import { Spin } from 'antd';
import styles from './ExpandableListHeaderForMenu.module.scss';

interface IProps {
    count: number;
    listName: string;
    disabled?: boolean;
    isExpanded: boolean;
    markAsSelected: boolean;
    isLoading: boolean;
    setIsExpanded?: (newState: boolean) => void;
    hideExpandButton?: boolean;
}

const ExpandableListHeaderForMenu: React.FC<IProps> = (props) => {
    const showExpandButton = props.count > 0 && !props.markAsSelected && !props.isLoading && !props.hideExpandButton;
    const styleSelected = props.markAsSelected ? styles.underlined : '';
    const layoutLoading = (
        props.isLoading
            ? <Spin spinning={props.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <span className={styles.count}>{props.count}</span>
    );
    const layoutExpanded = (
        props.isExpanded
            ? <UpIcon className={styles.icon} onClick={() => props.setIsExpanded?.(false)} />
            : <DownIcon className={styles.icon} onClick={() => props.setIsExpanded?.(true)} />
    );

    return (
        <>
            <div className={styles.expandableListForMenuWrapper}>
                <div className={props.markAsSelected
                    ? [styles.titleContentContainer, styles.selected].join(' ')
                    : styles.titleContentContainer }>
                    {
                        props.disabled
                            ?
                            <div className={styles.expandableListTitleBlock}>
                                <span className={styles.disabledListName}>{props.listName}</span>
                            </div>
                            :
                            <div className={styles.expandableListTitleBlock}>
                                <span className={styleSelected}>{props.listName}</span>
                                {
                                    layoutLoading
                                }
                                {
                                    showExpandButton && <span className={styles.expandIcons}>
                                    {
                                        layoutExpanded
                                    }
                                </span>
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ExpandableListHeaderForMenu;
