import { IProjectIndicators, IProjectIndicatorsRequest } from 'api/types/v1.0/projectIndicators';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';

export const getProjectIndicators = async (request: IProjectIndicatorsRequest): Promise<IProjectIndicators> => {
    const result = await axiosPostRequest<IProjectIndicatorsRequest, IProjectIndicators>(
        ApiPaths.ProjectIndicator,
        request
    );
    return result.data;
};
