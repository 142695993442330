import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

export const defaultState: IState = {
    profile: null,
    isLoading: false,
    error: null,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.DEPUTY_PROFILE_FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.DEPUTY_PROFILE_LOAD:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.DEPUTY_PROFILE_SET:
            return {
                ...state,
                profile: action.payload,
            };
        case actionTypes.DEPUTY_PROFILE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
