import React, {useState} from 'react';
import { Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IMessageItem } from 'api/types/v1.0/questionsAndSuggests';
import dateTime from 'utils/dateTime';
import { DAY_MONTH_FORMAT, ONLY_TIME_FORMAT } from 'constants/dateTime';
import './MessageCard.scss';

interface IProps {
    messageInfo: IMessageItem;
    readMessage: (id: number) => void;
    className?: string;
 }

const MessageCard: React.FC<IProps> = ({ messageInfo, readMessage, className }) => {
    const date = dateTime.formatDate(messageInfo.dateTime, DAY_MONTH_FORMAT);
    const time = dateTime.formatDate(messageInfo.dateTime, ONLY_TIME_FORMAT);
    const [viewedMsg, setViewedMsg] = useState(messageInfo.viewed);
    const readThisMessage = () => {
        readMessage(messageInfo.QFid);
        setViewedMsg(true);
    }
    return (
        <div className={`message-card-wrapper ${className}`}>
            <div className={messageInfo.viewed ? 'message-card message-card-viewed' : 'message-card'}
                 onClick={readThisMessage}>
                <div className={'message-user-icon-block'}>
                    <Avatar
                        className={'message-user-icon'}
                        icon={<UserOutlined />}
                        src={messageInfo.citizenPhoto}
                    />
                </div>
                <div className={'message-card-info'}>
                    <div className={'message-card-header'}>
                        <span className={'message-card-title'}>{messageInfo.citizenName}</span>
                        {!viewedMsg && <Badge status="warning" />}
                    </div>
                    <div className={'message-card-text'}>
                        {messageInfo.name}
                    </div>
                    <div className={'message-card-time'}>
                        {date} в {time}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageCard;
