import { useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import {
    getDeputyActivitiesFilter,
    getDeputyActivitiesSort,
} from 'api/bars-request-objects';
import { handleApiError } from 'api/v1.0/common';
import { getDeputyActivities } from 'api/v1.0/deputyActivities';
import { QUERY_FORMAT } from 'constants/dateTime';
import { MAX_INT } from 'constants/numbers';
import { initialValues } from '../components/ActivitiesList/constants';
import { defaultPageSize } from '../../MainPage/components/const/pagination';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useActivitiesSchedulerPage = (userId: string | undefined, projectId: number | null = null,
    pointId: number | null = null) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingActivities, setLoadingActivities] = useState(false);
    const [pageByDate, setPageByDate] = useState(1);
    const [useDefaultProjectAndPointIds, setUseDefaultProjectAndPointIds] =
        useState(projectId !== null && pointId !== null);

    const [readyToFetchNewData, setReadyToFetchNewData] = useState(false);

    const refresh = (take = 10, skip = 0) => {
        try {
            setLoadingActivities(true);
            const request = {
                start: skip,
                limit: take,
                sort: getDeputyActivitiesSort(),
                dataFilter: getDeputyActivitiesFilter(state.selectedProjectId,
                    state.selectedPointId, state.selectedDate, userId ?? null),
            };
            void getDeputyActivities(request)
                .then((response) => {
                    dispatch(actionCreators.setDeputyActivities(response.data));
                    dispatch(actionCreators.setTotalCount(response.totalCount));
                    setLoadingActivities(false);
                });
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setReadyToFetchNewData(true);
        }
    };

    const getActivitiesByDate = () => {
        try {
            setLoadingActivities(true);
            const request = {
                start: initialValues.initialSkip,
                limit: defaultPageSize,
                sort: getDeputyActivitiesSort(),
                dataFilter: getDeputyActivitiesFilter(state.selectedProjectId,
                    state.selectedPointId, state.selectedDate, userId ?? null),
            };
            void getDeputyActivities(request)
                .then((response) => {
                    refresh(initialValues.initialTake, (response.currentPageByDate - 1) * defaultPageSize);
                    setPageByDate(response.currentPageByDate);
                    setLoadingActivities(false);
                });
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setReadyToFetchNewData(true);
        }
    };

    const fetchMonthActivities = () => {
        setLoading(true);
        try {
            const request = {
                start: 0,
                limit: MAX_INT,
                dataFilter: getDeputyActivitiesFilter(state.selectedProjectId,
                    state.selectedPointId, state.selectedDate, userId ?? null, null, null, true),
            };
            void getDeputyActivities(request)
                .then((response) => {
                    dispatch(actionCreators.setMonthActivities(response.data));
                    setLoading(false);
                });
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setLoading(false));
        }
    };

    useEffect(() => {
        getActivitiesByDate();
        //eslint-disable-next-line
    }, [state.selectedProjectId, state.selectedPointId, state.selectedDate]);

    useEffect(() => {
        if (state.selectedMonth !== null) {
            fetchMonthActivities();
        }
        //eslint-disable-next-line
    }, [state.selectedProjectId, state.selectedPointId, state.selectedMonth, state.selectedYear]);

    const changeSelectedProject = (id: number | null) => {
        deleteDeputyActivities();
        dispatch(actionCreators.setSelectedProject(id));
        dispatch(actionCreators.setSelectedPoint(null));
    };

    const changeSelectedPoint = (id: number | null) => {
        deleteDeputyActivities();
        dispatch(actionCreators.setSelectedPoint(id));
    };

    const changeSelectedDate = (date: string | null) => {
        deleteDeputyActivities();
        dispatch(actionCreators.setSelectedDate(date));
    };

    const deleteDeputyActivities = () => {
        dispatch(actionCreators.deleteDeputyActivities());
    };

    const clearFiltersForCalendar = () => {
        deleteDeputyActivities();
        dispatch(actionCreators.setSelectedDate(moment().format(QUERY_FORMAT)));
    };

    const clearFiltersForList = () => {
        deleteDeputyActivities();
        dispatch(actionCreators.setSelectedDate(null));
    };

    const changeSelectedMonth = (month: number) => {
        dispatch(actionCreators.setSelectedMonth(month));
    };

    const changeSelectedYear = (year: number) => {
        dispatch(actionCreators.setSelectedYear(year));
    };

    const setCurrentPage = (pageNumber: number) => {
        dispatch(actionCreators.setCurrentPage(pageNumber));
    };

    if (useDefaultProjectAndPointIds) {
        changeSelectedProject(projectId);
        changeSelectedPoint(pointId);
        setUseDefaultProjectAndPointIds(false);
    }

    return {
        ...state,
        isLoading,
        isLoadingActivities,
        refresh,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedDate,
        deleteDeputyActivities,
        clearFiltersForCalendar,
        clearFiltersForList,
        changeSelectedMonth,
        changeSelectedYear,
        setCurrentPage,
        readyToFetchNewData,
        pageByDate,
    };
};

export default useActivitiesSchedulerPage;
