import React, { useEffect, useState } from 'react';
import { Badge, Button, message } from 'antd';
import { INotification } from 'api/types/v1.0/notifications';
import { getNotificationTitle } from 'utils/getNotificationTitle';
import { formatDateTimeFriendly } from 'utils/dateTime';
import { getMessageFromStatus } from 'utils/getMessageFromStatus';
import { InvitationResponses, messageInfo } from 'pages/NotificationCenter/constants';
import { notificationSeen } from 'api/v1.0/notifications';
import { IAccount } from 'types/account';
import styles from './NotificationCard.module.scss';
import InvitationButtons from './components/InvitationButtons';

interface IProps {
    notification: INotification;
    respondToInvitation: (invitation_id: number, invitationResponse: number) => void;
    user?: IAccount | null;
    updateIndicator: () => void;
}

const NotificationCard: React.FC<IProps> = ({ notification, respondToInvitation, updateIndicator }) => {
    const notificationTitle = getNotificationTitle(notification?.type);
    const [notificationViewed, setNotificationViewed] = useState<boolean>(!notification?.viewed);
    const [notificationStatus, setNotificationStatus] = useState<number | undefined>(
        notification?.invitation_responce_status
    );
    let timerId: number | undefined;

    useEffect(() => {
        if (!notification.viewed) {
            setTimeout(() => {
                void notificationSeen(notification.Id, 1).then(() => updateIndicator());
                setNotificationViewed((viewed) => !viewed);
            },5000);
        }
    }, [notification]); //eslint-disable-line

    const handleResponse = (invitation_id: number, invitation_response_status: number) => {
        info(getMessageFromStatus(invitation_response_status));
        timerId = window.setTimeout(() => {
            respondToInvitation(invitation_id, invitation_response_status);
        }, 5000);
    };

    const MessageContent = ({ text }: {text: string}) => {
        return <div className={styles.messageContent}>
            <span className={styles.messageContentText}>{text}</span>
            <Button
                className={styles.messageContentButton}
                onClick={() => {
                    window.clearTimeout(timerId);
                    message.destroy(notification?.Id);
                    setNotificationStatus(InvitationResponses.notResponded);
                }}
            >
                Отменить
            </Button>
        </div>;
    };

    const info = (text: string) => {
        // .open() возвращает PromiseLike, у которого нет .catch() метода, поэтому не можем обрабоботать выполнение.
        /* eslint-disable-next-line */
        message.open({
            content: <MessageContent text={text} />,
            duration: messageInfo.duration,
            getPopupContainer: () => document.getElementById('root') as HTMLElement,
            key: notification?.Id,
        });
    };

    return (
        <div className={styles.notificationCardWrapper}>
            <div className={styles.notificationCardHeader}>
                <span className={styles.notificationCardTitle}>{notificationTitle}</span>
                {notificationViewed && <Badge status="warning" />}
            </div>
            <div className={styles.notificationCardDescription}>{notification?.text}</div>
            <div className={styles.notificationCardDate}>
                {notification && formatDateTimeFriendly(notification.datetime)}
            </div>
            <InvitationButtons
                notificationStatus={notificationStatus}
                onAcceptClick={() => {
                    notification && handleResponse(notification?.invitation_id, InvitationResponses.accepted);
                    setNotificationStatus(InvitationResponses.accepted);
                }}
                onRejectClick={() => {
                    notification && handleResponse(notification?.invitation_id, 2);
                    setNotificationStatus(InvitationResponses.rejected);
                }}
            />
        </div>
    );
};

export default NotificationCard;
