import React, { useState } from 'react';
import { Card, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Page from 'components/Page';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import FolkDivider from 'ui-kit/FolkDivider';
import styles from './CommonQuestions.module.scss';
import useCommonQuestionsPage from './hooks';

const CommonQuestionsPage: React.FC = () => {
    const [projectId, setProjectId] = useState<number | null>();
    const {
        questions,
        totalCount,
        refresh } = useCommonQuestionsPage(Number(projectId));

    return (
        <Page content={
            <Page.Content sidebar={'Main'}>
                <div className={styles['commonQuestionBody']}>
                    <Card>
                        <div className={styles.questionsHeader}>
                            Часто задаваемые вопросы
                        </div>
                        <FolkDivider />
                        <div style={{ height: 48 }}>
                            <ProjectFilter onChange={setProjectId} alwaysShowSuffix={true} />
                        </div>
                        <FolkDivider />
                        <InfiniteScroll
                            dataLength={questions ? questions.length : 0}
                            next={refresh}
                            hasMore={questions ? questions?.length < totalCount : false}
                            loader={<Skeleton paragraph={{ rows: 2 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <List dataSource={questions ?? undefined} renderItem={(item) => (
                                <div key={item.title}>
                                    <span className={styles['questionTitle']}>{item.title}</span>
                                    {
                                        item.answers.map((answer) => (
                                            <Card className={styles['answerCard']} key={answer}>{answer}</Card>)
                                        )
                                    }
                                </div>
                            )} />
                        </InfiniteScroll>
                    </Card>
                </div>
            </Page.Content>
        }
        />);
};

export default CommonQuestionsPage;
