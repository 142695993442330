import { IState } from './types';
import {
    DECREASE_AMOUNT,
    INCREASE_AMOUNT,
    SET_CITIZEN_MENU_LIKES_COUNT,
    SET_CITIZEN_MENU_LIKES_LOADING,
} from './actionTypes';

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_CITIZEN_MENU_LIKES_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_CITIZEN_MENU_LIKES_COUNT, payload: totalCount } as const);

export const increaseAmountOfLikesInMenu = () =>
    ({ type: INCREASE_AMOUNT } as const);

export const decreaseAmountOfLikesInMenu = () =>
    ({ type: DECREASE_AMOUNT } as const);
