import React, { HTMLAttributes } from 'react';
import IconContainer from './IconContainer';

export type IconType =
    | 'Check'
    | 'DropDownArrow'
    | 'DoubleCheck'
    | 'CheckBlack'
    | 'Document'
    | 'Like'
    | 'DisLike'
    | 'LikeActive'
    | 'Remove'
    | 'DisLikeActive'
    | 'Vk'
    | 'Tg'
    | 'Ok'
    | 'Close'
    | 'ArrowLeft'
    | 'ArrowRight';

export interface IIconProps extends HTMLAttributes<HTMLElement> {
    type: IconType;
    className?: string;
    children?: React.ReactNode;
}

const getTemplate = (type: IconType): React.FC =>
    require(`./templates/${type}`).default;

const Icon: React.FC<IIconProps> = ({ type, className, ...tail }) => {
    const Template = getTemplate(type);

    return (
        <IconContainer className={className} {...tail}>
            <Template />
        </IconContainer>
    );
};

export default Icon;
