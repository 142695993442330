import {
    IPointsRequest,
    IPointsResponse,
} from 'api/types/v1.0/points';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export const getPointsList = async (request: IPointsRequest): Promise<IPointsResponse> => {
    try {
        const result = await axiosCachePostRequest<IPointsRequest, IPointsResponse>(ApiPaths.Points, request);
        return result.data;
    } catch (error) {
        throw Error(error);
    }
};
