import { citizenProjectSave } from 'api/v1.0/citizenProjectSave';
import { updateSettings } from 'api/v1.0/userSettings';
import { IUserProject } from 'pages/Auth/components/UserRegistrationForm/UserRegistrationFormFields/UserProjects';
import { IHasId } from 'api/types/v1.0/deputyActivities';
import { IUserSettingsFormValues } from './UserSettingsForm';

export interface ISettingsRequest {
    BirthDate?: string;
    avatar?: string;
    preview?: string;
    Id?: string;
    email?: string;
    fact_address?: string;
    FirstName?: string;
    Patronymic?: string;
    phone?: string;
    RegionId?: IHasId;
    Surname?: string;
    isReceiveEventMessages?: boolean;
    isReceiveGroupMessages?: boolean;
    building?: string;
    street?: string;
    city?: string;
}

export const setSettingsData = async (
    data: IUserSettingsFormValues,
    projects?: Pick<IUserProject, 'ProjectChoice' | 'ProjectLink_project_name' | 'ProjectLink_Ref_Id'>[]
) => {
    const request = {
        Id: data.sub,
        avatar: data.avatar,
        preview: data.preview,
        BirthDate: data.birthDate,
        email: data.email,
        address: data.address,
        FirstName: data.name,
        Patronymic: data.phantomic,
        phone: data.phone,
        Surname: data.surname,
        RegionId: { Id: data.regionId },
        isReceiveEventMessages: data.isReceiveEventMessages,
        isReceiveGroupMessages: data.isReceiveGroupMessages,
        building: data.building,
        street: data.street,
        city: data.city,
    } as ISettingsRequest;

    const allIdProject = projects?.reduce((acc: [] | number[], project) => [...acc, project.ProjectLink_Ref_Id], []);
    const idSelectedProject = data.projects
        ? data.projects?.reduce((acc: [] | number[], project) => [...acc, project.ProjectLink_Ref_Id ?? project], [])
        : [];

    const disabledProjects = [];

    if (allIdProject) {
        for (const idProject of allIdProject) {
            if (idSelectedProject?.indexOf(idProject) === -1 && idProject !== undefined) {
                disabledProjects.push(idProject);
            }
        }
    }

    const formData = new FormData();
    formData.append('functionName', 'upd_citizen_proj');
    formData.append('functionArguments[0]', `'${data.sub!}'`);
    formData.append('functionArguments[1]', `'${idSelectedProject.join(',')}'`);
    formData.append('functionArguments[2]', `'${data.sub!}'`);
    formData.append('functionArguments[3]', `'${disabledProjects.join(',')}'`);

    await citizenProjectSave(formData);

    await updateSettings(request);
};
