import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React from 'react';
import Icon from 'ui-kit/Icon';
import { getMonthAndYear } from 'utils/dateTime';
import usePointDocuments from './hooks';
import styles from './PointDocument.module.scss';

export interface IProps {
    pointId: number;
}

const PointDocuments: React.FC<IProps> = (props) => {
    const { pointDocuments, isLoading } = usePointDocuments({ pointId: props.pointId });
    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>Документы</div>
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                {
                    pointDocuments?.data.map((doc) => {
                        return (
                            <div key={doc.Id} className={styles['document']}>
                                <Row wrap={false}>
                                    <Col>
                                        <a href={doc.document_link} download={doc.FullName}>
                                            <Icon className={styles['document-icon']} type={'Document'} />
                                        </a>
                                    </Col>
                                    <Col flex={1} className={styles.documentTextContainer}>
                                        <div>
                                            <span className={styles.documentDate}>
                                                {getMonthAndYear(doc.FIleOriginal_ObjectCreateDate)}
                                            </span>
                                            {doc.FullName}
                                        </div>
                                    </Col>
                                </Row>
                            </div>);
                    })
                }
                {!pointDocuments && (<div className={styles.noDocumentMessage}>Список документов пуст</div>)}
            </Spin>
        </div>
    );
};

export default PointDocuments;
