import React from 'react';
import useSearchParams from 'hooks/useSearchParams';
import { UserRegistrationForm } from './components/UserRegistrationForm';
import './RegistrationPage.scss';

const RegistrationPage: React.FC = () => {
    const params = useSearchParams();
    const error = params.get('error');
    const code = params.get('code');

    if (code) {
        return (<UserRegistrationForm />);
    }

    return <div className={'authorization-error-message'}>Во время авторизации произошла ошибка: {error}</div>;
};

export default RegistrationPage;
