import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Notification from 'ui-kit/Notification';
import useSearchParams from 'hooks/useSearchParams';

const BadLink: React.FC = () => {
    const location = useSearchParams();

    useEffect(() => {
        const errorMessage = location.get('error') || 'Некорректная ссылка';
        Notification.error({ message: errorMessage });
    }, [location]);

    return <Redirect to="/" />;
};

export default BadLink;
