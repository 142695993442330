import {
    IStatesmanRequest,
    IStatesmanResponse,
} from 'api/types/v1.0/statesman';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export const getStatesman = async (request: IStatesmanRequest): Promise<IStatesmanResponse> => {
    try
    {
        const result = await axiosPostRequest<IStatesmanRequest, IStatesmanResponse>(ApiPaths.StatesmanInfo, request);
        return result.data;
    } catch (error)
    {
        throw new Error(error);
    }
};
