import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { getBranchEconomyList } from 'api/v1.0/getBranchEconomyList';
import { EconomyItem, EconomyList } from 'api/types/v1.0/branchEconomyList';
const { Option } = Select;
import Icon from '../../Icon';
import styles from '../SelectorFilter.module.scss';

export interface IIndustryFilterProps {
    onChange?: (economyId: number | null) => void;
}

export const IndustryFilter: React.FC<IIndustryFilterProps> = ({ onChange }) => {
    const [economyList, setEconomyList] = useState<EconomyItem[] | []>([]);
    const [selectedEconomyItem, setSelectedEconomyItem] = useState<number | null>();

    const getEconomyList = async () => {
        const response = (await getBranchEconomyList()) as EconomyList;
        setEconomyList(response.data);
    };

    useEffect(() => {
        void getEconomyList();
    }, [])

    return (
        <div className={styles['selector-wrapper']}>
            <Select
                className={styles['selector']}
                suffixIcon={!selectedEconomyItem && <Icon className={styles['icon']} type={'DropDownArrow'} />}
                value={selectedEconomyItem}
                showSearch
                placeholder={'Выберите отрасль'}
                optionFilterProp={'label'}
                filterOption
                allowClear
                onChange={
                    (value: number | undefined) => {
                        setSelectedEconomyItem(value);
                        onChange?.(value ?? null);
                    }
                }
            >
                {
                    economyList && economyList.map((economyItem: EconomyItem) =>
                        <Option
                            key={economyItem.Name}
                            value={economyItem.Id}>
                            {economyItem.Name}
                        </Option>
                    )
                }
            </Select>
        </div>
    );
};
