import React from 'react';
import './StatiscticsBlock.scss';

export interface IStatisticsBlockProps
{
    title: string;
    value: number | string;
}

const StatiscticsBlock: React.FC<IStatisticsBlockProps> = ({ title, value }: IStatisticsBlockProps) => {
    return (
        <div className={'statistics-block'}>
            <div className={'statistics-block-value'}>{value}</div>
            <div className={'statistics-block-title'}>{title}</div>
        </div>);
};

export default StatiscticsBlock;
