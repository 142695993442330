import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'antd';
import { DatePickerField } from 'ui-kit/Form/FormFields/DatePickerField';
import { SelectField } from 'ui-kit/Form/FormFields/SelectField';
import { TextInputField } from 'ui-kit/Form/FormFields/TextInputField';
import { MaskedTextInputField } from 'ui-kit/Form/FormFields/MaskedTextInputField';
import { SubmitButton } from 'ui-kit/Form/SubmitButton/SubmitButton';
import Icon from 'ui-kit/Icon';
import { useRegionList } from 'hooks/useRegionList';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import { SelectProjectsField } from './SelectProjectsField/SelectProjectsField';
import styles from './UserRegistrationFormFields.module.scss';

export interface IRegistrationValues {
    name?: string;
    surname?: string;
    patronymic?: string;
    birthdate?: string;
    region?: number;
    phone?: string;
    address?: string;
    email?: string;
    projects?: number[];
    sub?: string,
    avatar?: string,
    isReceiveEventMessages?: boolean;
    building?: string;
    street?: string;
    city?: string;
}

export interface IUserRegistrationFormFieldsProps {
    lazyValues?: IRegistrationValues;
    onSubmit: () => void;
    projectList: IProjectListItem[] | undefined;
}

export const UserRegistrationFormFields: React.FC<IUserRegistrationFormFieldsProps> = (props) => {
    const { isValid, setValues, errors } =
        useFormikContext<IRegistrationValues>();

    const regionsList = useRegionList();

    const getValidationMessage = () => {
        if (!errors) {
            return '';
        }

        if (errors.email) {
            return errors.email;
        }

        return 'Заполнены не все обязательные поля';
    };

    useEffect(() => {
        setValues((prevState) => {
            return props.lazyValues ?? prevState;
        });
    }, [props.lazyValues, setValues]);

    return (
        <Form
            className={styles.formFieldsWrapper}
            onFinish={props.onSubmit}
        >
            <div className={styles.fieldsFormContainer}>
                <div className={styles.subTitle}>
                    <span>Персональные данные:</span>
                </div>
                <TextInputField
                    disabled
                    placeholder={'Имя *'}
                    name={'name'}
                />
                <TextInputField
                    disabled
                    placeholder={'Фамилия *'}
                    name={'surname'}
                />
                <TextInputField
                    disabled
                    placeholder={'Отчество *'}
                    name={'patronymic'}
                />
                <DatePickerField
                    disabled
                    placeholder={'Дата рождения *'}
                    name={'birthdate'}
                    format={DEFAULT_DATE_FORMAT}
                    needToSetNameUndefined={true}
                />
                <div className={styles.regionField}>
                    <SelectField
                        options={regionsList.data?.map((region) => ({ label: region.region, value: region.Id }))}
                        name={'region'}
                        customSuffixIcon={<Icon className={styles['icon']} type={'DropDownArrow'} />}
                        validationMessage={'Необходимо выбрать регион'}
                        needToSetNameAsUndefined={true}
                        disabled
                    />
                </div>
                <div className={styles.phoneField}>
                    <MaskedTextInputField
                        disabled
                        type={'phone'}
                        placeholder={'Номер телефона *'}
                        name={'phone'}
                    />
                </div>
                <TextInputField placeholder={'Адрес'} name={'address'} disabled />
                <TextInputField
                    placeholder={'Эл. почта'}
                    name={'email'}
                />
                <div className={styles.subTitle}>
                    <span>Направления:</span>
                </div>
                <SelectProjectsField
                    placeholder={'Выберите проекты'}
                    options={props.projectList?.map((project) => ({ label: project.project_name, value: project.Id }))}
                    name={'projects'}
                />
            </div>
            <div className={styles.agreementBlockContainer}>
                <div className={styles.agreementBlock}>
                    <span>
                    Нажимая на кнопку «Создать аккаунт», я даю согласие на обработку моих персональных данных в
                    соответствии ФЗ №152-ФЗ «О персональных данных».
                    </span>
                </div>
            </div>
            <div className={styles.submitButtonContainer}>
                <SubmitButton
                    customValidationMessage={getValidationMessage()}
                    disabled={!isValid}
                    buttonText={'Создать аккаунт'}
                />
            </div>
        </Form>
    );
};
