import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getFederalCoordinatorPoints } from 'api/v1.0/federalCoordinatorPoints';
import { IFederalCoordinatorsPointsRequest } from 'api/types/v1.0/federalCoordinatorPoints';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';
import { setSortByAlphabet, setSortByRating } from './actionCreators';
import { PointSortType } from './types';

const useFederalCoordinatorPoints = (data: IFederalCoordinatorsPointsRequest) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refreshPoints = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getFederalCoordinatorPoints(data, state.descending)
            .then((response) =>
            {
                dispatch(actionCreators.setFederalCoordinatorPoints(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
        // eslint-disable-next-line
    }, []);

    const refresh = async (start: number) => {
        dispatch(actionCreators.setIsLoading(true));
        try {
            data.start = start;
            const response = await getFederalCoordinatorPoints(data, state.descending);
            dispatch(actionCreators.setFederalCoordinatorPoints(response.data));
            dispatch(actionCreators.setTotalCount(response.totalCount));
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setIsLoading(false));
        }
    };

    useEffect(() => {
        refreshPoints();
    }, [refreshPoints]);

    const changeSort = async (sortType: PointSortType) => {
        dispatch(actionCreators.clearCoordinatorList());
        switch (sortType) {
            case PointSortType.title: {
                data.sortType = PointSortType.title;
                state.descending = !state.descending;
                const pointsResponse = await getFederalCoordinatorPoints(data, state.descending);
                dispatch(actionCreators.setFederalCoordinatorPoints(pointsResponse.data));
                dispatch(actionCreators.setTotalCount(pointsResponse.totalCount));
                dispatch(setSortByAlphabet());
                break;
            }

            case PointSortType.rating: {
                data.sortType = PointSortType.rating;
                state.descending = !state.descending;
                const respRat = await getFederalCoordinatorPoints(data, state.descending);
                dispatch(actionCreators.setFederalCoordinatorPoints(respRat.data));
                dispatch(actionCreators.setTotalCount(respRat.totalCount));
                dispatch(setSortByRating());
                break;
            }
        }
    };

    return {
        state,
        refreshPoints,
        changeSort,
        refresh,
    };
};

export default useFederalCoordinatorPoints;
