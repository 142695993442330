import { Image, ImageProps } from 'antd';
import React, { useState } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';
import { OnLoadingSpinner } from '../OnLoadingSpinner';
import styles from '../MediaCamera/MediaCamera.module.scss';

interface IProps extends ImageProps {
    url: string;
    imageWidth?: number;
    key?: number;
    customLoading?: boolean;
    setCustomLoading?: (customLoading: boolean) => void;
    showVideoIcon?: boolean;
}

export const MediaImage: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(props.customLoading === undefined ? true : props.customLoading);

    const onLoad = () => {
        props.setCustomLoading === undefined ? setLoading(false) : props.setCustomLoading(false);
    }

    return (
        <OnLoadingSpinner isLoading={props.customLoading === undefined ? loading : props.customLoading} content={
            <div key={props.url} className={'image-container-class'}>
                <Image
                    onClick={props.onClick}
                    key={props.key}
                    onLoad={onLoad}
                    width={props.imageWidth}
                    src={props.url}
                    preview={props.preview}
                />
                {
                    props.showVideoIcon &&
                    <VideoCameraOutlined className={styles['video-icon']} />
                }
            </div>
        }></OnLoadingSpinner>
    );
}
