import { IGroupsMessageItem, MessagesSortType } from 'api/types/v1.0/groupsList';
import { SortDirection } from 'api/bars-request-objects';
import {
    DELETE_MESSAGE,
    SEND_MESSAGE,
    SET_FILTERED_GROUPS_LIST,
    SET_GROUPS_LIST,
    SET_GROUPS_TOTALCOUNT,
    SET_GROUP_MEMBERS_COUNT,
    SET_GROUP_MESSAGES,
    SET_IS_CHAT_MESSAGES_LOADING,
    SET_IS_PREVIEW_LOADING,
    SET_IS_SEND_MESSAGE_LOADING,
    SET_MESSAGES_TOTALCOUNT,
    SET_MESSAGES_SEEN,
    SET_MESSAGES_SORT_TYPE, SET_MESSAGES_SORT_DIRECTION,
} from './actionTypes';
import { IState } from './types';

export const setGroupsList = (groupsList: IState['groupsList']) =>
    ({ type: SET_GROUPS_LIST, payload: groupsList } as const);

export const setFilteredGroupsList = (groupsList: IState['groupsList']) =>
    ({ type: SET_FILTERED_GROUPS_LIST, payload: groupsList } as const);

export const setGroupMessages = (groupMessages: IState['groupMessages']) =>
    ({ type: SET_GROUP_MESSAGES, payload: groupMessages } as const);

export const setGroupsTotalCount = (groupsTotalCount: IState['groupsTotalCount']) =>
    ({ type: SET_GROUPS_TOTALCOUNT, payload: groupsTotalCount } as const);

export const setMessagesTotalCount = (messagesTotalCount: IState['messagesTotalCount']) =>
    ({ type: SET_MESSAGES_TOTALCOUNT, payload: messagesTotalCount } as const);

export const setGroupMembersCount = (groupMembersCount: IState['groupMembersCount']) =>
    ({ type: SET_GROUP_MEMBERS_COUNT, payload: groupMembersCount } as const);

export const sendMessage = (message: IGroupsMessageItem) =>
    ({ type: SEND_MESSAGE, payload: message } as const);

export const deleteMessage = (id: number) =>
    ({ type: DELETE_MESSAGE, payload: id } as const);

export const setIsChatMessagesLoading = (isChatMessagesLoading: boolean) =>
    ({ type: SET_IS_CHAT_MESSAGES_LOADING, payload: isChatMessagesLoading } as const);

export const setIsPreviewLoading = (isPreviewLoading: boolean) =>
    ({ type: SET_IS_PREVIEW_LOADING, payload: isPreviewLoading } as const);

export const setIsSendMessageLoading = (isSendMessageLoading: boolean) =>
    ({ type: SET_IS_SEND_MESSAGE_LOADING, payload: isSendMessageLoading } as const);

export const setMessagesSeen = (messages: IGroupsMessageItem[]) =>
    ({ type: SET_MESSAGES_SEEN, payload: messages } as const);

export const setMessagesSortType = (sortType: MessagesSortType) =>
    ({ type: SET_MESSAGES_SORT_TYPE, payload: sortType } as const);

export const setMessagesSortDirection = (sortDirection: SortDirection) =>
    ({ type: SET_MESSAGES_SORT_DIRECTION, payload: sortDirection } as const);
