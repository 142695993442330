import React from 'react';
import { Input } from 'antd';
import './FolkInput.scss';
import { DebouncedFunc } from 'lodash';

export interface ITextInputFieldProps {
    disabled?: boolean;
    placeholder: string;
    maxLength?: number;
    height?: string;
    textAlign?: string;
    onChange?:  DebouncedFunc<(e?: (React.ChangeEvent<HTMLInputElement> | undefined)) => void>;
    value?: string;
}

export const FolkInput: React.FC<ITextInputFieldProps> = (props) => {
    return (
            <Input
                style={{height: props.height}}
                maxLength={props.maxLength}
                disabled={props.disabled}
                className={props.textAlign === 'left'
                    ? 'folk-input-left'
                    : 'folk-input'}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
            />
    );
};
