import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';
import Page from 'components/Page';
import DeputyMenu from 'components/Layout/components/Sidebar/DeputyMenu';
import { Role } from 'types/account';
import { useCoordinatorSelectedPoints } from '../FederalCoordinatorProfile/hooks/useCoordinatorSelectedPoints';
import styles from '../FederalCoordinatorProfile/FederalCoordinator.module.scss';
import DeputyPoints from './components/DeputyPoints';
import { useRegionalCoordinatorProfilePage } from './hooks/useRegionalCoordinatorProfilePage';
import CoordinatorStatistics from './CoordinatorStatistics';

interface IRegionalCoordinatorProps {
    deputyId: number;
}

export const RegionCoordinatorProfile: React.FC<IRegionalCoordinatorProps> = ({ deputyId }) => {
    const role = Role.Regional;
    const { state } = useRegionalCoordinatorProfilePage({ deputyId });
    const { statisticsState, changeSelectedPoint, clearPoints } = useCoordinatorSelectedPoints(deputyId, role);
    return (
        <Page
            content={
                <Page.Content>
                    <Spin spinning={state.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                        {
                            isMobile && <div className='deputy-statistics-container'>
                                <span className='deputy-statistics-header'>Общий рейтинг&nbsp;
                                    <span className='statistics-value'>
                                        {state.profile?.rating}%
                                    </span>
                                </span>
                            </div>
                        }
                        <div className={isDesktop ? styles.body : styles.bodyMobile}>
                            <div className={styles.mainContainer}>
                                <div className={styles.pointsContainer}>
                                    <div className={styles.textTitle}>Объекты</div>
                                    <DeputyPoints
                                        statisticsState={statisticsState}
                                        deputyId={deputyId}
                                        changePoint={changeSelectedPoint}
                                        clearPoints={clearPoints}
                                        compact={!isDesktop} />
                                </div>
                            </div>
                            <div className={styles.statisticsContainer}>
                                {isDesktop && <DeputyMenu />}
                                <CoordinatorStatistics
                                    statistics={statisticsState.statistics}
                                    deputyId={deputyId}
                                    role={role}
                                    rating={state.profile?.rating}
                                    region_name={state.profile?.region_name}
                                />
                            </div>
                        </div>
                    </Spin>
                </Page.Content>
            }
        />
    );
};
