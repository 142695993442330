import { axiosBearerPostRequest } from "utils/axiosClient";
import { ApiPaths } from "constants/ApiPaths";

const ReadQuestion = (request: ReadQuestionRequest) => {

   return axiosBearerPostRequest<ReadQuestionRequest, ReadQuestionRequest>(ApiPaths.ReadMessage, request)
}

interface ReadQuestionRequest {
    questionId: number;
}

export default ReadQuestion;