import React from 'react';
import { Skeleton } from 'antd';
import { IProjectIndicators } from 'api/types/v1.0/projectIndicators';
import { getFormatNumbers } from './ulits';

import styles from './IndicatorStatisticAll.module.scss';

const IndicatorStatisticAll: React.FC<IProjectIndicators> = ({ data }) => {
    return (
        <div className={styles['indicator-statistic-container']}>
            <div className={styles['indicator-statistic-title']}>Количество объектов</div>
            <div className={styles['indicator-statistic-content']}>
                <div className={styles['indicator-statistic-plan']}>
                    {data
                        ? getFormatNumbers(data?.plannedCount)
                        : <Skeleton.Input active />
                    }
                    <label className={styles['indicator-statistic-label']}>План</label>
                </div>
                <div className={styles['indicator-statistic-fact']}>
                    {data
                        ? getFormatNumbers(data?.totalCount)
                        : <Skeleton.Input active />
                    }
                    <label className={styles['indicator-statistic-label']}>Факт</label>
                </div>
            </div>
        </div>
    );
};

export default IndicatorStatisticAll;
