import { handleApiError } from 'api/v1.0/common';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { INewsDetails, INewsDetailsResponse } from '../types/v1.0/newsDetails';

export const getNewsDetails = async (id: string) => {
    try {
        const response = await axiosPostRequest<INewsDetailsResponse, INewsDetails>(
            ApiPaths.NewsData,
            { Id: id }
        );

        return response?.data;
    } catch (exception) {
        handleApiError(exception);
    }
};
