import React from 'react';
import { Avatar, List, Skeleton } from 'antd';
import { isDesktop } from 'react-device-detect';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FRIENDLY_DATE_FORMAT_WITH_YEAR, ONLY_TIME_FORMAT } from 'constants/dateTime';
import { Pages } from 'constants/links';
import browserHistory from 'App/root/browserHistory';
import { formatDateLocalTime } from 'utils/dateTime';
import { IProps } from '../hooks/types';
import styles from './NewsItems.module.scss';

const NewsItems: React.FC<IProps> = (props: IProps) => {
    const sizeAvatar = isDesktop ? 72 : 128;

    return (
        <div>
            <List
                dataSource={props.news}
                loading={props.isLoading}
                itemLayout="horizontal"
                split={false}
                renderItem={(item) => (
                    <List.Item
                        className={styles['itemStyle']}
                        onClick={() => {browserHistory.push(Pages.NewsDetails.url.concat(`/${item.Id}`));
                            setTimeout(()=>{window.scrollTo(0, -3000);},100);}}
                    >
                        <Skeleton avatar title={false} loading={false}>
                            <List.Item.Meta
                                avatar={ <Avatar className={styles['avatarStyle']} shape="square" size={sizeAvatar} icon={<UserOutlined />} src={item.image ?? ''} /> }
                                title={ <div className={styles['titleStyle']}>{ item.title ?? ''}</div>}
                                description={ `${moment(item.date_time).format(FRIENDLY_DATE_FORMAT_WITH_YEAR) } в ${ formatDateLocalTime(item.date_time, ONLY_TIME_FORMAT)}` }
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />
        </div>);
};

export default NewsItems;
