import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isDesktop } from 'react-device-detect';
import { IPointInfo } from 'api/types/v1.0/pointInfo';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { ExternalRedirectLinks } from 'constants/externalRedirectUrls';
import { PointPowerForm } from '../PointPowerForm';
import QrCode from '../ObjectQrCode';
import EditDocuments from './components/EditDocuments';
import EditControlPoints from './components/EditControlPoints';
import EditPointMedia from './components/EditPointMedia';
import { useEditPointForm } from './hooks';
import styles from './EditPointForm.module.scss';

export interface IEditPointFormProps
{
    userId?: string;
    projectId?: number;
    pointId?: number;
    setUpdate: React.Dispatch<React.SetStateAction<number>>;
}

export const EditPointForm: React.FC<IEditPointFormProps> = (
    { userId, pointId, projectId, setUpdate }: IEditPointFormProps) => {
    const [currentPointId, setCurrentPointId] = useState<number | undefined>(pointId);
    const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(projectId);
    const [documentsLoaded, setDocumentsLoaded] = useState<boolean>(false);
    const [controlPointsLoaded, setControlPointsLoaded] = useState<boolean>(false);
    const [pointMediaLoaded, setPointMediaLoaded] = useState<boolean>(false);

    const {
        state,
        refreshPointInfo,
    } = useEditPointForm(currentPointId, currentProjectId);

    const onPointChange = (newPointId: number | null) => {
        setCurrentPointId(newPointId ?? undefined);
        browserHistory.push(Pages.PointConstructor.url.concat(`/${newPointId ?? ''}`));
    };

    const onProjectChange = (newProjectId: number | null) => {
        setCurrentProjectId(newProjectId ?? undefined);
        setCurrentPointId(undefined);
    };

    useEffect(() => {
        setUpdate((prev) => prev + 1);
    }, [setUpdate, documentsLoaded, controlPointsLoaded, pointMediaLoaded]);

    useEffect(() => {
        setCurrentProjectId(projectId);
    }, [projectId, pointId]);

    return (
        <Spin spinning={state.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <div className={styles.editPointFormContentContainer}>
                <div className={styles.editPointFormContent}>
                    <div className={styles.textInfo}>
                        Принадлежность:
                    </div>
                    <div className={isDesktop ? styles.filtersWrapper : `${styles.mobileFilters} ${styles.filtersWrapper}`}>
                        <div className={isDesktop ? styles.filter : `${styles.mobileFilter} ${styles.filter}`}>
                            <ProjectFilter
                                userId={userId}
                                pointId={currentPointId}
                                onChange={onProjectChange}
                                defaultProjectId={currentProjectId}
                                disableClearButton={true}
                                alwaysShowSuffix={true}
                            />
                        </div>
                        <div className={isDesktop ? styles.filter : `${styles.mobileFilter} ${styles.filter}`}>
                            <PointFilter
                                userId={userId}
                                onChange={onPointChange}
                                projectId={currentProjectId}
                                defaultPointId={currentPointId}
                                disableClearButton={true}
                                alwaysShowSuffix={true}
                            />
                        </div>
                    </div>
                    <PointPowerForm
                        pointId={currentPointId}
                        value={state.info ?? {} as IPointInfo}
                        onSubmit={() => refreshPointInfo()}
                    />

                    <EditDocuments
                        isDesktop={isDesktop}
                        pointId={currentPointId ?? null}
                        setIsLoaded={setDocumentsLoaded} />

                    <EditControlPoints
                        pointId={Number(currentPointId)}
                        deputyId={Number(userId)}
                        setIsLoaded={setControlPointsLoaded} />

                    <EditPointMedia pointId={currentPointId} setIsLoaded={setPointMediaLoaded} />
                </div>

                <div className={styles.editPointFormQrCodeBlock}>
                    <div className={styles.qrCodeContent}>
                        <QrCode
                            pointName={state.info?.object_name ?? ''}
                            url={`${window.location.origin.toString()}/?${ExternalRedirectLinks.FolkApp.redirectPath.toString()}=${ExternalRedirectLinks.FolkApp.selectedObject.toString()}?${ExternalRedirectLinks.FolkApp.objectIdKey.toString()}=${ currentPointId ? currentPointId.toString() : '0'}`} />
                    </div>
                </div>
            </div>
        </Spin>
    );
};
