import './NotRespondedQuestion.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import MessageCard from 'ui-kit/MessageCard/ChatMessageCard/MessageCard';
import { IDeputyQuestion } from 'api/types/v1.0/deputyQuestions';
import ReadQuestion from "./helper/ReadQuestion";
import { RootState } from '../../App/root/rootReducer';
import { Role } from '../../types/account';

export interface IProps {
    question: IDeputyQuestion;
    onClick: () => void;
}

const NotRespondedQuestion: React.FC<IProps> = (props: IProps) => {
    const account = useSelector((state: RootState) => state.account);

    return (
        <MessageCard
            messageInfo={{
                name: props.question.text,
                QFid: props.question.Id,
                viewed: !!props.question.viewed,
                citizenName: props.question.citizen_name,
                citizenPhoto: props.question.citizen_avatar,
                dateTime: props.question.date_time,
            }}
            readMessage={async () => {
                props.onClick();
                if(!(account.user?.role == Role.Regional || account.user?.role == Role.Federal))
                {
                    await ReadQuestion({questionId: props.question.Id});
                }
            }}
        />
    );
};

export default NotRespondedQuestion;
