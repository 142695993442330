import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { ICommonQuestionsRequest, ICommonQuestionsResponse } from '../types/v1.0/commonQuestions';

export const getCommonQuestions = async (request: ICommonQuestionsRequest): Promise<ICommonQuestionsResponse> => {
    try {
        const result = await axiosCachePostRequest<ICommonQuestionsRequest, ICommonQuestionsResponse>(
            ApiPaths.CommonQuestions, request);
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
