import React from 'react';
import Icon from 'ui-kit/Icon';
import styles from './FolkRadio.module.scss';

interface IProps {
    onClick: () => void;
}

const FolkRadio: React.FC<IProps> = ({ onClick }) => {
    return (
        <label className={styles['custom-radio-button-container']}>
            <input type="radio" name="radio" onClick={onClick} />
            <span className={styles['custom-radio-button']}>
              <Icon className={styles['icon']} type={'Check'} />
            </span>
        </label>
    );
};

export default FolkRadio;
