import { useEffect, useReducer } from 'react';
import { getCommonQuestions } from 'api/v1.0/commonQuestions';
import { handleApiError } from 'api/v1.0/common';
import { ICommonQuestionsRequest } from 'api/types/v1.0/commonQuestions';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useCommonQuestionsPage = (projectId?: number) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = () => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: state.questions ? state.questions.length : 0,
            limit: 10,
            project_id: projectId,
        } as ICommonQuestionsRequest;

        void getCommonQuestions(request)
            .then((response) => {
                dispatch(actionCreators.setQuestions(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            }).catch((exception) => handleApiError(exception))
            .finally(() =>actionCreators.setLoading(false));
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    useEffect(() => {
        dispatch(actionCreators.deleteQuestions());
        setTotalCount(0);
        refresh();
        //eslint-disable-next-line
    }, [projectId]);

    return {
        ...state,
        refresh,
        setTotalCount,
    };
};

export default useCommonQuestionsPage;
