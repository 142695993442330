import { ApiPaths } from 'constants/ApiPaths';
import {
    axiosBearerPostRequest,
    axiosPostRequest,
} from 'utils/axiosClient';
import { getTimeZone } from 'utils/dateTime';
import {
    IActivityCreateRequest,
    IActivityEditRequest,
    IDeputyActivitiesRequest,
    IDeputyActivitiesResponse,
    IDeputyActivitiesTimeZoneRequest,
    IEventPollCreateRequest,
} from '../types/v1.0/deputyActivities';

export const getDeputyActivities = async (request: IDeputyActivitiesRequest) => {
    const requestTimeZone = {
        dataFilter: request.dataFilter,
        sort: request.sort,
        limit: request.limit,
        start: request.start,
        clientTimezoneParams: { clientTimeZoneOffset: getTimeZone() },
    };

    const activities = await axiosPostRequest<IDeputyActivitiesTimeZoneRequest,
        IDeputyActivitiesResponse>(ApiPaths.ActivitiesList, requestTimeZone);

    return {
        data: activities.data.data,
        success: true,
        summaryData: '',
        totalCount: activities.data.totalCount,
        currentPageByDate: activities.data.currentPageByDate + 1,
    } as IDeputyActivitiesResponse;
};

export const getDeputyActivitiesMain = async (request: IDeputyActivitiesRequest) => {
    const requestTimeZone = {
        dataFilter: request.dataFilter,
        sort: request.sort,
        limit: request.limit,
        start: request.start,
        clientTimezoneParams: { clientTimeZoneOffset: getTimeZone() },
    };
    const activities = await axiosPostRequest<IDeputyActivitiesTimeZoneRequest,
        IDeputyActivitiesResponse>(ApiPaths.DeputyActivitiesMain, requestTimeZone);

    return {
        data: activities.data.data,
        success: true,
        summaryData: '',
        totalCount: activities.data.totalCount,
    } as IDeputyActivitiesResponse;
};

export const editActivity = (formData: IActivityEditRequest) => {
    return axiosPostRequest(ApiPaths.ActivityEdit, { modelData:formData, clientTimezoneParams: { 'ClientTimeZoneOffset': getTimeZone() } });
};

export const createEventPoll = (formData: IEventPollCreateRequest) => {
    return axiosPostRequest(ApiPaths.ActivityPoll, { ObjectEvent_id: formData.ObjectEvent_id, poll: formData.poll });
};

export const createActivity = (formData: IActivityCreateRequest) => {
    return axiosBearerPostRequest(ApiPaths.ActivityCreate, { modelData: formData, clientTimezoneParams: { 'ClientTimeZoneOffset': getTimeZone() } });
};
