import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import Page from 'components/Page';
import { UploadAvatar } from 'ui-kit/Form/FormFields/UploadAvatar';
import { SetAvatar } from 'ui-kit/Form/FormFields/UploadAvatar/SetAvatar';
import useRegistrationPage from 'pages/Auth/hooks/useRegistrationPage';
import { AuthHelper } from 'utils/AuthHelper';
import { redirect } from 'utils/browser';
import { fetchAccount } from 'ducks/account';
import { formatDateRequest } from 'utils/dateTime';
import BrowserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import useProjectList from 'hooks/useProjectList';
import { setRegistrationData } from './UserRegistrationFormFields/SetRegistrationData';
import {
    IRegistrationValues,
    UserRegistrationFormFields,
    ValidateRegistrationValues,
} from './UserRegistrationFormFields';
import styles from './UserRegistrationForm.module.scss';

export const UserRegistrationForm: React.FC = () => {
    const dispatch = useDispatch();
    const [imageFile, setImageFile] = useState<File | null>(null);
    const { isLoading, registrationInfo, isNeedRedirect } = useRegistrationPage();
    const { data: projectList } = useProjectList();

    if (isNeedRedirect) {
        redirect(AuthHelper.getAuthorizeCodeUrl(), false);
    }

    const setAvatar = () => {
        const fromData = new FormData();
        fromData.append('FileOriginal', imageFile as Blob);
        const modelData = `{"Citizen":{"Id":'${registrationInfo?.sub as string}'},"ContentType":2}`;
        fromData.append('modelData', modelData.toString());
        void SetAvatar(fromData).then(() => {
            dispatch(fetchAccount());
        });
    };

    const lazyLoadedValues = useMemo(() => {
        return {
            name: registrationInfo?.name,
            surname: registrationInfo?.surname,
            patronymic: registrationInfo?.phantomic,
            birthdate: registrationInfo?.birthDate,
            phone: registrationInfo?.phone,
            region: registrationInfo?.regionId,
            sub: registrationInfo?.sub,
            address: registrationInfo?.address,
            email: registrationInfo?.email,
            avatar: registrationInfo?.avatar,
        } as IRegistrationValues;
        // eslint-disable-next-line
    }, [registrationInfo]);

    return (
        <Page
            content={
                <Page.Content>
                    <div hidden={isLoading} className={styles.formContainer}>
                        <div className={styles.formWrapper}>
                            <div className={styles.titleBlock}>
                                <div className={styles.formTitle}>Создание аккаунта</div>
                            </div>
                            <div className={styles.avatarContainer}>
                                <div className={styles.avatarBlock}>
                                    <UploadAvatar
                                        disableHints={true}
                                        sub={lazyLoadedValues.sub}
                                        imageUrl={lazyLoadedValues?.avatar}
                                        setImageFile={setImageFile}
                                    />
                                </div>
                            </div>
                            <Formik<IRegistrationValues>
                                initialValues={lazyLoadedValues}
                                validate={ValidateRegistrationValues}
                                validateOnChange
                                validateOnBlur
                                validateOnMount
                                enableReinitialize
                                onSubmit={async (data) => {
                                    data.birthdate = formatDateRequest(data.birthdate);
                                    await setRegistrationData(data, projectList).then(() => {
                                        setAvatar();
                                        BrowserHistory.push(Pages.Main.url);
                                    });
                                }}
                            >
                                {(childFormProps) => {
                                    return (
                                        <UserRegistrationFormFields
                                            lazyValues={lazyLoadedValues}
                                            onSubmit={childFormProps.submitForm}
                                            projectList={projectList}
                                        />
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </Page.Content>
            }
        />
    );
};
