import axios, { AxiosInstance } from 'axios';
import { ApiPaths } from 'constants/ApiPaths';
import { getAxiosConfigAuthToken } from 'utils/axiosClient';

import {
    IAccountResponse,
} from '../types/v1.0/account';

const baseUrl = '/api/v1.0/account';

export const logout = (axiosInstance: AxiosInstance = axios) =>
    axiosInstance.get(ApiPaths.Logout, getAxiosConfigAuthToken());

export const refreshToken = (axiosInstance: AxiosInstance = axios) =>
    axiosInstance.post<string>(`${baseUrl}/token/refresh`);

export const getAccount = (axiosInstance: AxiosInstance = axios) =>
    axiosInstance.get<IAccountResponse>(ApiPaths.UserInfo, getAxiosConfigAuthToken());
