import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import StatiscticsBlock from 'ui-kit/StatiscticsBlock/StatiscticsBlock';
import 'pages/DeputyProfile/components/DeputyProfile/components/DeputyStatistics/DeputyStatistics.scss';
import { ICoordinatorStatistics } from 'api/types/v1.0/coordinatorStatistics';
import { getFederalCoordinatorStatistics } from 'api/v1.0/federalCoordinatorProfile';
import { handleApiError } from 'api/v1.0/common';
import { Role } from 'types/account';
import { getRegionalCoordinatorStatistics } from 'api/v1.0/regionalCoordinatorProfile';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';

export interface ICoordinatorStatisticsProps
{
    deputyId: number;
    statistics: ICoordinatorStatistics | null;
    role: Role;
    rating: number | undefined;
}

const CoordinatorStatistics: React.FC<ICoordinatorStatisticsProps> = (props: ICoordinatorStatisticsProps) => {
    const [events,setEvents] = useState<ICoordinatorStatistics>();

    useEffect(() =>{
        if (props.role === Role.Federal) {
            getFederalCoordinatorStatistics({ deputy_id: props.deputyId ,object_id:[] })
                .then((response) => {
                    setEvents(response.data);
                })
                .catch((err) => handleApiError(err));
        } else {
            getRegionalCoordinatorStatistics({ deputy_id: props.deputyId ,object_id:[] })
                .then((response) => {setEvents(response.data);})
                .catch((err) => handleApiError(err));
        }
    },[props.deputyId, props.role]);

    return (
        <>
            { isDesktop && <div className='deputy-statistics-container'>
                <span className='deputy-statistics-header'>Общий рейтинг&nbsp;
                    <span className='statistics-value'>
                        {props.rating}%
                    </span>
                </span>
            </div>
            }
            <div className='deputy-statistics-container'>
                <div className='deputy-statistics-header'>Статистика по мероприятиям</div>
                <div className='deputy-statistics-body'>
                    <div className='deputy-statistics-item1'>
                        <StatiscticsBlock key={1} value={props.statistics?.planed_events ?? 0} title={'Запланировано'} />
                    </div>
                    <div className='deputy-statistics-item2'>
                        <StatiscticsBlock key={2} value={props.statistics?.passed_events ?? 0} title={'Прошло'} />
                    </div>
                    <div className='deputy-statistics-item3'>
                        <StatiscticsBlock key={3} value={props.statistics?.participants_events ?? 0} title={'Участника'} />
                    </div>
                    <div className='deputy-statistics-item4'>
                        <StatiscticsBlock key={4} value={props.statistics?.not_provided_reports ?? 0} title={'Без отчета'} />
                    </div>
                    <div className='deputy-statistics-item5'>
                        <StatiscticsBlock key={5} value={props.statistics?.satisfaction_events.toString().concat('%') ?? '0%'} title={'Одобрение граждан'} />
                    </div>
                </div>
            </div>
            <br />
            <div className='deputy-statistics-container'>
                <div className='deputy-statistics-header'>Поступившие вопросы и предложения</div>
                <div className='coordinator-questions-body'>
                    <div className='coordinator-statistics-item1' onClick={() => browserHistory.push(Pages.QuestionsAndSuggestions.url)}>
                        <StatiscticsBlock key={6} value={events?.all_questions ?? 0} title={'Всего'} />
                    </div>
                    <div className='deputy-statistics-item2'>
                        <StatiscticsBlock key={7} value={events?.not_viewed_questions ?? 0} title={'Не прочитано'} />
                    </div>
                    <div className='deputy-questions-item'>
                        <StatiscticsBlock key={8} value={events?.users_count_questions ?? 0} title={'Человек задали вопросы'} />
                    </div>
                </div>
            </div>
        </>);
};

export default CoordinatorStatistics;
