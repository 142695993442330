import React from 'react';
import { Modal } from 'antd';
import FolkButton from 'ui-kit/FolkButtons';
import styles from './ConfirmModel.module.scss';

interface IProps {
    onYesAction: () => void;
    onNoAction?: () => void;
    title: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    yesActionLoading?: boolean;
}

const ConfirmModal: React.FC<IProps> = (props) => {
    const onNoButtonClick = () => {
        props.setIsOpen(false);
        props.onNoAction?.();
    };

    return <Modal
        className={styles['confirmModal']}
        open={props.isOpen}
        footer={<>
            <FolkButton loading={props.yesActionLoading} onClick={props.onYesAction}>Да</FolkButton>
            <FolkButton onClick={onNoButtonClick}>Нет</FolkButton>
        </>}
        onCancel={onNoButtonClick}
    >
        {props.title}
    </Modal>;
};

export default ConfirmModal;
