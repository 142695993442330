import { Badge, Button, Divider, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import Icon from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as actionCreators from 'ducks/chat/actionCreators';
import { IDeputyPoint } from 'api/types/v1.0/deputyPoints';
import OrangeButton from 'ui-kit/OrangeButton/OrangeButton';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { getDeputyQuestions } from 'api/v1.0/deputyQuestions';
import { handleApiError } from 'api/v1.0/common';
import { CalendarSvg } from 'ui-kit/Svg/CalendarSvg';
import { UnionSvg } from 'ui-kit/Svg/UnionSvg';
import { PeopleSvg } from 'ui-kit/Svg/PeopleSvg';
import { IActivitiesSchedulerPageState } from 'pages/ActivitiesScheduler';
import { SettingsSvg } from 'ui-kit/Svg/SettingsSvg';
import { StatsSvg } from 'ui-kit/Svg/StatsSvg';
import { IDeputyQuestion } from 'api/types/v1.0/deputyQuestions';
import './DeputyPoint.scss';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import OrangeButtonsModal from 'ui-kit/OrangeButtonsModal';
import NotReportedActivity from 'ui-kit/NotReportedActivity';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { getDeputyActivitiesMain } from 'api/v1.0/deputyActivities';
import { getDeputyActivitiesFilterForReports, getDeputyActivitiesSort } from 'api/bars-request-objects';
import { INewsGeneratorPageLocationState } from 'pages/NewsGenerator/NewsGeneratorPage';
import NotRespondedQuestion from 'ui-kit/NotRespondedQuestion';
import { eventStatuses } from 'constants/eventStatuses';
import { IPointConstructorState } from 'pages/PointConstructor';

export interface IProps {
    data: IDeputyPoint,
    onSelectedChange: (pointId: number) => void,
    clearSelected: boolean,
    compact?: boolean,
}

const DeputyPoint: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const [isNotReportedActivitiesOpen, setIsNotReportedActivitiesOpen] = useState<boolean>(false);
    const [notReportedActivities, setNotReportedActivities] = useState<IDeputyActivity[]>([]);
    const [notReportedActivitiesLoading, setNotReportedActivitiesLoading] = useState<boolean>(true);

    const [isNotRespondedQuestionsOpen, setIsNotRespondedQuestionsOpen] = useState<boolean>(false);
    const [notRespondedQuestions, setNotRespondedQuestions] = useState<IDeputyQuestion[]>([]);
    const [notRespondedQuestionsLoading, setNotRespondedQuestionsLoading] = useState<boolean>(true);

    const [isSelected, setSelected] = useState<boolean>(false);

    const setSelectedItem = () => {
        setSelected(!isSelected);
        props.onSelectedChange(props.data.Id);
    };

    const openInPointConstructor = (scrollToId?: string) => {
        browserHistory.push({
            pathname: `${Pages.PointConstructor.url}/${props.data.Id ?? '' }`,
            state: {
                projectId: props.data.project_id,
                scrollToId : scrollToId,
            } as IPointConstructorState,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const openMediaEditor = () => {
        openInPointConstructor('edit-point-media-button');
        setTimeout(()=> {
            document.getElementById('edit-point-media-button')?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        },200);
    };

    const onAboutObjectClick = () => {
        browserHistory.push(`${Pages.PointInfo.url}/${props.data?.Id ?? 0}`);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const openActivitiesScheduler = () => {
        browserHistory.push({
            pathname: Pages.ActivitiesScheduler.url,
            state: {
                projectId: props.data.project_id,
                pointId: props.data.Id,
            } as IActivitiesSchedulerPageState,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const onNotReportedActivityClick = (activity: IDeputyActivity) => {
        browserHistory.push({
            pathname: Pages.NewsGenerator.url,
            state: {
                pointId: props.data.Id,
                activityId: activity.object_event_id,
                projectId: activity.project_id,
            } as INewsGeneratorPageLocationState,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const onNotAnsweredQuestionClick = (question: IDeputyQuestion) => {
        setIsNotRespondedQuestionsOpen(false);
        isMobile
            ? browserHistory.push(`${Pages.Chat.url}/${question.object_id.toString()}`)
            : dispatch(actionCreators.setChatModalState(true));
        dispatch(actionCreators.setIsResponse(true));
        dispatch(actionCreators.setSelectedChatId(question.object_id));
        dispatch(actionCreators.setSelectedMessageId(question.Id));
        dispatch(actionCreators.setSelectedProjectId(question.project_id));
    };

    const refreshNotReportedActivities = async () => {
        try {
            setNotReportedActivitiesLoading(true);
            const response = await getDeputyActivitiesMain({
                dataFilter: getDeputyActivitiesFilterForReports(props.data.project_id, props.data.Id,
                    null, props.data.deputy_id.toString(),
                    null, false, eventStatuses.completed),
                sort: getDeputyActivitiesSort(),
            });
            setNotReportedActivities(response.data);
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setNotReportedActivitiesLoading(false);
        }
    };

    const refreshNotRespondedQuestions = async () => {
        try {
            setNotRespondedQuestionsLoading(true);
            const response = await getDeputyQuestions({
                deputyId: props.data.deputy_id,
                objectId: props.data.Id,
                start: notRespondedQuestions.length,
            });
            setNotRespondedQuestions((prev) => [...prev, ...response.data]);
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setNotRespondedQuestionsLoading(false);
        }
    };

    useEffect(() => {
        if (isNotReportedActivitiesOpen) {
            void refreshNotReportedActivities();
        }

        if (isNotRespondedQuestionsOpen) {
            void refreshNotRespondedQuestions();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isNotReportedActivitiesOpen,
        isNotRespondedQuestionsOpen,
    ]);

    return (
        <div>
            <div onClick={setSelectedItem} className={ isSelected ? 'coordinator-point-body-sel' : 'coordinator-point-body'}>
                <div className='coordinator-point-header'>
                    <div className='title'>{props.data.title}</div>
                    <div className='region-name'>&middot; {props.data.address}</div>
                </div>
                <div className='coordinator-point-statistics'>
                    <div className='coordinator-rating'>
                        <Icon component={UnionSvg} className='rating-svg' /> Рейтинг {props.data.rating}%
                    </div>
                    <div className='coordinator-group' onClick={() => {
                        dispatch(actionCreators.setSelectedChatId(props.data.Id));
                        dispatch(actionCreators.setIsResponse(true));
                        dispatch(actionCreators.setGroupMembersState(true));
                        isMobile
                            ? browserHistory.push(`${Pages.Chat.url}/${props.data.Id.toString()}`)
                            : dispatch(actionCreators.setChatModalState(true));
                    }}>
                        <Icon component={PeopleSvg} className='rating-svg' /> В
                        группе {props.data.group_participants_count}
                    </div>
                </div>
                <div className='coordinator-buttons'>
                    <Badge size='default' dot={props.data.new_photos_count > 0}>
                        <div onClick={openMediaEditor} className='coordinator-button-report'>Новые фото</div>
                    </Badge>
                    <div className='coordinator-orange-buttons'>
                        <div>
                            <OrangeButtonsModal
                                open={isNotReportedActivitiesOpen}
                                onClose={() => setIsNotReportedActivitiesOpen((prevState) => !prevState)}
                                title={props.data.title}
                                subtitle={'Ниже представлены мероприятия, по которым еще не сформирован отчет'}
                            >
                                <OnLoadingSpinner className={'modal-spinner'} isLoading={notReportedActivitiesLoading} content={
                                    notReportedActivities.map(
                                        (activity) =>
                                            (
                                                <NotReportedActivity
                                                    key={activity.object_event_id}
                                                    activity={activity}
                                                    onClick={() => onNotReportedActivityClick(activity)}
                                                />
                                            )
                                    )
                                } />
                            </OrangeButtonsModal>
                            {props.data.not_reported_activities_count > 0 &&
                                <div className='deputy-point-not-loaded-report'>
                                    <OrangeButton
                                        onClick={() => setIsNotReportedActivitiesOpen((prevState) => !prevState)}
                                        type={'primary'}
                                    >
                                        {!props.compact && 'Не загружено отчетов: '}{props.data.not_reported_activities_count}
                                    </OrangeButton>
                                </div>
                            }
                        </div>
                        <div>
                            <OrangeButtonsModal
                                open={isNotRespondedQuestionsOpen}
                                onClose={() => {
                                    setIsNotRespondedQuestionsOpen((prevState) => !prevState);
                                    setNotRespondedQuestions([]);
                                }}
                                title={'Вопросы без ответа'}
                            >
                                <OnLoadingSpinner isLoading={notRespondedQuestionsLoading} content={
                                    <div id="scrollDiv" className={'deputy-point-question'}>
                                        <InfiniteScroll
                                            dataLength={
                                                notRespondedQuestions?.length? notRespondedQuestions?.length : 0
                                            }
                                            next={refreshNotRespondedQuestions}
                                            hasMore={notRespondedQuestions
                                                ? notRespondedQuestions?.length <
                                                props.data.not_responded_questions_count
                                                : false
                                            }
                                            scrollableTarget="scrollDiv"
                                            height={400}
                                            loader={''}
                                        >
                                            <List
                                                className={'not-reported-activities'}
                                                dataSource={notRespondedQuestions}
                                                renderItem={(question: IDeputyQuestion) => (
                                                    <NotRespondedQuestion
                                                        question={question}
                                                        onClick={() => onNotAnsweredQuestionClick(question)}
                                                    />
                                                )}
                                            />
                                        </InfiniteScroll>
                                    </div>
                                } />
                            </OrangeButtonsModal>
                            {props.data.not_responded_questions_count > 0 &&
                                <div>
                                    <OrangeButton
                                        className="orange-button" type={'primary'}
                                        onClick={() => setIsNotRespondedQuestionsOpen((prevState) => !prevState)}
                                    >
                                        {!props.compact && 'Не отвечено вопросов: '}{props.data.not_responded_questions_count}
                                    </OrangeButton>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='coordinator-icons'>
                    <div className='coordinator-icons-buttons'>
                        <Button className='coordinator-icon-button' onClick={openActivitiesScheduler} type='primary' shape="circle" size="large" icon={<Icon component={CalendarSvg} />} />
                        <Button className='coordinator-icon-button' onClick={onAboutObjectClick} type='primary' shape="circle" icon={<Icon component={StatsSvg} />} size="large" />
                        <Button className='coordinator-icon-button' onClick={() => openInPointConstructor()} type='primary' shape="circle" size="large" icon={<Icon component={SettingsSvg} />} />
                    </div>
                </div>
            </div>
            <Divider className={'coordinator-divider'} />
        </div>
    );
};

export default DeputyPoint;
