import { NotificationPlacement } from 'antd/lib/notification';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, notification, Skeleton, Space } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    IFavoritePointCreateRequest,
    IFavoritePointDeleteRequest,
    IFavoritePointItem,
} from 'api/types/v1.0/favoritePoint';
import {
    setTotalCount as setCitizenMenuTotalCount,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/reducer';
import { addFavoritePoint, deleteFavoritePoint } from 'api/v1.0/favoritePoint';
import { RootState } from 'App/root/rootReducer';
import FavoriteItem from './components/FavoriteItem/FavoriteItem';
import './FavoriteList.scss';

interface IProps {
    favorites: IFavoritePointItem[] | null;
    totalCount: number;
    refresh: (input?: string, isDeleteRefresh?: boolean) => void;
    deleteFavorite: (favoriteId: number) => void;
    setTotalCount: (totalCount: number) => void;
    setFavorites: (favorites: IFavoritePointItem[] | null) => void;
}

const FavoriteList: React.FC<IProps> = ({ favorites, totalCount,
    setFavorites, refresh, deleteFavorite, setTotalCount }) => {
    const { user } = useSelector((state: RootState) => state.account);
    const [api, contextHolder] = notification.useNotification();
    const globalDispatch = useDispatch();

    const handleCancelClicked = async (favorite: IFavoritePointItem) => {
        if (favorite && favorite.ObjectId_Ref_Id && user) {
            const createRequest = {
                modelData: {
                    CitizenId: { Id: Number(user?.sub) },
                    ObjectId: { Id: favorite.ObjectId_Ref_Id },
                },
            } as IFavoritePointCreateRequest;

            setTotalCount(totalCount);
            const newFavorite = await addFavoritePoint(createRequest);
            //Оптимизация с запоминанием стейта без загрузки с сервера каждый раз при удалении
            favorite['Id'] = newFavorite.data.Id;
            setFavorites([{ ...favorite } as IFavoritePointItem]);
            globalDispatch(setCitizenMenuTotalCount(totalCount));
        }
    };

    const openNotification = (placement: NotificationPlacement, favorite: IFavoritePointItem) => {
        notification.destroy();
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button
                    type="primary"
                    size="small"
                    onClick={async () => {
                        await handleCancelClicked(favorite);
                        notification.close(key);
                    }}
                >
                    Отмена
                </Button>
            </Space>
        );

        api.info({
            message: 'Объект удален из избранного',
            btn,
            key,
            placement,
        });
    };

    const handleStarClicked = async (favorite: IFavoritePointItem) => {
        if (!favorites) {
            return;
        }

        setTotalCount(totalCount - 1);
        globalDispatch(setCitizenMenuTotalCount(totalCount - 1));
        openNotification('bottom', favorite);
        deleteFavorite(favorite.Id);
        await deleteFavoritePoint({ records: [favorite.Id] } as IFavoritePointDeleteRequest);
    };

    return (
        <>
            {contextHolder}
            <div className={'favorite-list'}>
                <InfiniteScroll
                    dataLength={favorites ? favorites.length : 0}
                    next={refresh}
                    hasMore={favorites ? favorites?.length < totalCount : false}
                    loader={<Skeleton paragraph={{ rows: 2 }} active />}
                    scrollableTarget="scrollableDiv">
                    <List
                        itemLayout="horizontal"
                        dataSource={favorites ?? undefined}
                        renderItem={(item, index) => (
                            <List.Item className={'favorite-list-item'}>
                                <FavoriteItem
                                    handleStarClicked={handleStarClicked}
                                    favorite={item}
                                    key={index}
                                />
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </>
    );
};

export default FavoriteList;
