import { Badge, Divider, Menu } from 'antd';
import React from 'react';
import { IProfileTabKeyInfo } from 'components/Layout/components/LayoutHeader/interfaces/ITabKeyInfo';
import { logoutTabInfo } from 'components/Layout/components/LayoutHeader/LayoutHeaderTabsInfo';
import { Role } from 'types/account';
import useTotalCount from './hooks/useTotalCount';

import styles from './MobileTabsMenu.module.scss';

interface IProps {
    tabs: IProfileTabKeyInfo[];
    userId: string | undefined;
    userRole: string;
    hasNewNotifications: boolean | undefined;
}

const MobileTabsMenu: React.FC<IProps> = ({ userRole, userId, tabs, hasNewNotifications }) => {
    const isCitizenRole = userRole === Role.Citizen;
    const totalCount: Record<string, number> = useTotalCount(userId);

    const menuTab = (tabName: string) => {
        if (isCitizenRole) {
            return (
                <>
                    {tabName} {totalCount[tabName]}
                </>
            );
        }

        return <>{tabName}</>;
    };

    return (
        <Menu className={styles['menu']}>
            {tabs.map((info) => (
                <>
                    <Menu.Item
                        className={styles['menu-item']}
                        key={info.key}
                        onClick={info.onClick}
                        disabled={info.disabled as boolean}
                    >
                        {menuTab(info.tabName)}
                    </Menu.Item>
                    {info.tabName === 'Уведомления' && hasNewNotifications ?
                        <Badge status="warning" className={styles['notification-badge']} /> :<></>}
                    <Divider className={styles['divider']} />
                </>
            ))}
            {!isCitizenRole && (
                <Menu.Item className={styles['menu-item']} key={logoutTabInfo.key} onClick={logoutTabInfo.onClick}>
                    {logoutTabInfo.tabName}
                </Menu.Item>
            )}
        </Menu>
    );
};

export default MobileTabsMenu;
