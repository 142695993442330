import { useEffect, useReducer } from 'react';
import { getPointInfoAsync } from 'api/v1.0/pointInfo';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

export const useEditPointForm = (pointId?: number | null, projectId?: number | null) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refreshPointInfo = () => {
        const fetchPointInfo = async () => {
            dispatch(actionCreators.editPointInfoFetch());
            try {
                if (pointId) {
                    const response = await getPointInfoAsync({ id: pointId });
                    dispatch(actionCreators.editPointInfoSet(response.data));
                }
            }
            catch (exc)
            {
                const error = (exc as Error).message;
                dispatch(actionCreators.editPointInfoError(error));
            }
            finally {
                dispatch(actionCreators.editPointInfoLoad());
            }
        };

        void fetchPointInfo();
    };

    useEffect(() => {
        refreshPointInfo();
        //eslint-disable-next-line
    }, [pointId, projectId]);

    return {
        state,
        refreshPointInfo,
    };
};
