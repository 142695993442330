import React, { useState } from 'react';
import { TabType } from 'ducks/mainPage';
import './ObjectViewSwitch.scss';

interface IProps {
    pointsView: TabType;
    changeView: (type: TabType) => void;
}

const ObjectViewSwitch: React.FC<IProps> = ({ pointsView, changeView }) => {
    const [isMap, setIsMap] = useState(pointsView === TabType.map);

    const toggle = () => {
        if (pointsView === TabType.map)
        {
            changeView(TabType.list);
            setIsMap(false);
        } else {
            changeView(TabType.map);
            setIsMap(true);
        }
    };

    return (
        <div className='toggle-switch'>
            <label>
                <input type='checkbox' onChange={toggle} defaultChecked={pointsView !== TabType.map} />
                <span className='slider'>
                    <div className={'toggle'} unselectable={'on'}>
                        <span>
                            {
                                !isMap && pointsView !== TabType.map ? 'Список' : 'Карта'
                            }
                        </span>
                    </div>
                </span>
                <div className={'plate'}>
                    {!isMap ? <span className={'map'} unselectable={'on'}>Карта</span>
                        : <span style={{ color:'white' }} className={'map'} unselectable={'on'}>Карта</span>}
                    {isMap ? <span className={'list'} unselectable={'on'}>Список</span>
                        : <span style={{ color:'white' }} className={'list'} unselectable={'on'}>Список</span>}
                </div>
            </label>
        </div>
    );
};

export default ObjectViewSwitch;
