import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';
import React from 'react';
import styles from './FolkDivider.module.scss';

const FolkDivider: React.FC<DividerProps> = (props) => {
    const {
        type,
        orientation,
        orientationMargin,
        prefixCls,
        children,
    } = props;

    return (
        <Divider
            orientationMargin={orientationMargin}
            orientation={orientation}
            prefixCls={prefixCls}
            children={children}
            type={type}
            className={styles.divider} />
    );
};

export default FolkDivider;
