import './NotReportedActivity.scss';
import React from 'react';
import { formatToDayMonthAndTime } from 'utils/dateTime';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';

export interface IProps {
    activity: IDeputyActivity;
    onClick?: () => void;
}

const NotReportedActivity: React.FC<IProps> = (props: IProps) => {
    return (
        <div
            className={`list-item not-reported-activity ${props.onClick ? 'active' : ''}`}
            key={props.activity.object_event_id}
            onClick={props.onClick}
        >
            <span className={'activity-date-time'}>{formatToDayMonthAndTime(props.activity.date_time)}</span> <span  className={'activity-name'}>{props.activity.event_name}</span>
        </div>
    );
};

export default NotReportedActivity;
