const Tg = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7260_7842)">
            <rect width="32" height="32" rx="6" fill="url(#paint0_linear_7260_7842)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.24334 15.8311C11.9077 13.7989 15.0179 12.4592 16.5742 11.8119C21.0175 9.96372 21.9408 9.64267 22.5426 9.63207C22.675 9.62974 22.9709 9.66254 23.1626 9.81809C23.3245 9.94944 23.369 10.1269 23.3903 10.2514C23.4117 10.3759 23.4382 10.6596 23.4171 10.8813C23.1763 13.4112 22.1344 19.5508 21.6044 22.3844C21.3801 23.5835 20.9385 23.9855 20.5109 24.0248C19.5818 24.1103 18.8762 23.4108 17.9763 22.8209C16.5681 21.8978 15.7725 21.3231 14.4056 20.4224C12.8259 19.3814 13.85 18.8092 14.7503 17.8742C14.9859 17.6294 19.0797 13.9058 19.1589 13.568C19.1689 13.5257 19.1781 13.3682 19.0845 13.2851C18.9909 13.2019 18.8529 13.2304 18.7532 13.253C18.612 13.285 16.3624 14.772 12.0043 17.7138C11.3658 18.1522 10.7874 18.3659 10.2692 18.3547C9.69791 18.3423 8.599 18.0317 7.78206 17.7661C6.78006 17.4404 5.98369 17.2682 6.05304 16.715C6.08916 16.4269 6.48592 16.1323 7.24334 15.8311Z" fill="white"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_7260_7842" x1="16" y1="0" x2="16" y2="31.7627" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2AABEE"/>
                <stop offset="1" stopColor="#229ED9"/>
            </linearGradient>
            <clipPath id="clip0_7260_7842">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Tg;
