import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BarChartOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { ProjectType } from 'api/types/v1.0/activityInfo';
import { IActivityEditRequest, IAnswer, IDeputyActivity, IInterview } from 'api/types/v1.0/deputyActivities';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import { IFilterStyleType, ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { DatePickerField } from 'ui-kit/Form/FormFields/DatePickerField';
import { TextInputAreaField } from 'ui-kit/Form/FormFields/TextInputAreaField';
import { TimePickerField } from 'ui-kit/Form/FormFields/TimePickerField';
import { SubmitButton } from 'ui-kit/Form/SubmitButton/SubmitButton';
import { RootState } from 'App/root/rootReducer';
import { Role } from 'types/account';
import { hasRole } from 'utils/user';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { isSameOrBeforeToday, isToday } from 'utils/dateTime';
import { FolkFormInput } from 'ui-kit/FolkInput/FolkFormInput/FolkFormInput';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { deleteActivityInterview } from 'api/v1.0/activityInterviewDelete';
import { editActivity } from 'api/v1.0/deputyActivities';
import { handleApiError } from 'api/v1.0/common';
import { validationMessages } from 'constants/validationMessages';
import { usePointInfo } from 'hooks/usePointInfo';
import { hasCoordinatorRole, hasMunicipalRole } from 'helpers/account';
import { eventStatuses } from 'constants/eventStatuses';
import { ActivityCardActivity } from './ActivityCardActivity/ActivityCardActivity';
import { ActivityCardPollForm } from './ActivityCardPoll/ActivityCardPollForm';
import styles from './ActivityCardFormFields.module.scss';

export interface IActivityCardFormFields {
    activity: IDeputyActivity;
    onSubmit: () => void;
    setPoll: (interview: IInterview | null) => void;
    setActivityAnswer: (answers: IAnswer[]) => void;
    isEdit: boolean;
    refresh?: (take: number, skip: number) => void;
    currentPage?: number;
    setIsModalOpen?: (isModalOpen: boolean) => void;
    loading?: boolean;
}

const ActivityCardFormFields: React.FC<IActivityCardFormFields> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isPollEditable, setIsPollEditable] = useState<boolean>(false);
    const { setFieldValue, values } = useFormikContext<IDeputyActivity>();
    const [form] = Form.useForm();
    const { projectId } = usePointInfo(values.object_id);
    const { user } = useSelector((state: RootState) => state.account);
    const [projectType, setProjectType] = useState<ProjectType | null>(null);
    const isTookPart = props.activity.Id ? props.activity.state_id === eventStatuses.completed : false;
    const [isValid, setIsValid] = useState(false);
    const [interview, setInterview] = useState<IInterview | undefined>(props.activity.interview);
    const [isDeleteInterview, setIsDeleteInterview] = useState(false);
    const [selectedProject, setSelectedProject] = useState<number | undefined>(projectId);

    const userIsMunDep = hasMunicipalRole(user);
    const userIsCoord = hasCoordinatorRole(user);

    useEffect(() => {
        form.setFieldValue('object_id', values.object_id);
        setSelectedProject(form.getFieldValue('project_id'));
    }, [form, values]);

    useEffect(() => {
        if (projectId !== undefined) {
            setFieldValue('project_id', projectId);
        }
    }, [projectId, setFieldValue]);

    let poll = (
        <div className={styles['activity-card-poll-text']}>
            <div className={styles['activity-card-poll-text-title']}>Добавить опрос</div>
            <div className={styles['activity-card-poll-text-desc']}>
                Выберите два или несколько вариантов для голосования
            </div>
        </div>
    );

    if (interview) {
        poll = (
            <div className={styles['activity-card-poll-text']}>
                <div className={styles['activity-card-poll-text-title']}>{interview.question}</div>
                {interview.answers
                    .sort((prevInter, nextInter) => prevInter.id - nextInter.id)
                    .map((x, index) => {
                        return (
                            <div key={index} className={styles['activity-card-poll-text-desc']}>
                                {x.answer} <br />
                            </div>
                        );
                    })}
            </div>
        );
    }

    const onInterviewFinish = (interviewValues: IInterview) => {
        const answers = interviewValues.answers as unknown as string[];
        props.activity['interview'] = interviewValues;
        const setAnswers = answers.map((x) => {
            return { answer: x, id: 0, rating: 0 } as IAnswer;
        });
        props.setActivityAnswer(setAnswers);
        props.setPoll(props.activity.interview);
        setInterview(interviewValues);
        setIsModalOpen(false);
        setIsPollEditable(true);
    };

    const updateProjectType = (type_project: ProjectType) => {
        setProjectType(type_project);
    };

    const deleteInterview = async (id: number) => {
        await deleteActivityInterview({ records: [id] });
    };

    const handleOnSubmit = () => {
        const handleSubmission = () => {
            props.onSubmit();
            setTimeout(() => browserHistory.push(Pages.ActivitiesScheduler.url), 1000);
        };

        if (isDeleteInterview) {
            void deleteInterview(props.activity.interview.id)
                .then(handleSubmission);
        } else {
            handleSubmission();
        }
    };

    const cancelActivity = (activity: IDeputyActivity) => {
        const formData = {
            Canceled: 1,
            Id: activity.object_event_id.toString(),
            date_time: activity.date_time.toISOString(),
            deputy_oe: { Id: activity.Id },
            description: activity.description,
            event_o: { Id: activity.event_id },
            link: activity.link,
            newEventName: activity.event_name,
            object_e: { Id: activity.object_id },
            project: { Id: activity.project_id },
        } as IActivityEditRequest;
        if (props.setIsModalOpen) {
            props.setIsModalOpen(false);
        }

        editActivity(formData)
            .then(() => browserHistory.push(Pages.ActivitiesScheduler.url))
            .catch((e) => handleApiError(e));
    };

    const handleDeleteInterview = () => {
        if (props.activity.interview?.id) {
            setIsDeleteInterview(true);
        }

        props.setPoll(null);
        setInterview(undefined);
    };

    useEffect(() => {
        const isObjectValid = (userIsMunDep && !!values.object_id) || userIsCoord;
        const isCommonFieldsValid = !!(
            values.project_id &&
            values.description &&
            values.event_name &&
            values.date &&
            values.time
        );
        setIsValid(isObjectValid && isCommonFieldsValid);
    }, [values, user, userIsMunDep, userIsCoord]);

    return (
        <Form form={form} className={styles['activity-form-fields-wrapper']} onFinish={handleOnSubmit}>
            <div>
                <div className={styles['activity-card-poll-title']}>Принадлежность:</div>
                <div className={styles['activity-filters']}>
                    <Row gutter={[24, 24]}>
                        <Col span={24} xl={{ span: 12 }}>
                            <Form.Item name={'project_id'}>
                                <ProjectFilter
                                    type={IFilterStyleType.outlined}
                                    userId={user?.sub}
                                    disabled={props.isEdit}
                                    onChange={(newProjectId) => {
                                        setFieldValue('project_id', newProjectId);
                                        setFieldValue('object_id', undefined);
                                    }}
                                    updateProjectType={updateProjectType}
                                    defaultProjectId={values.project_id}
                                />
                            </Form.Item>
                            {values.project_id === undefined && (
                                <div className={'ant-form-item-explain-error'}>
                                    Необходимо выбрать проект
                                </div>
                            )}
                        </Col>
                        <Col span={24} xl={{ span: 12 }}>
                            <Form.Item name="object_id" rules={[{ required: userIsMunDep && !props.isEdit, message: validationMessages.point }]}>
                                <PointFilter
                                    type={IFilterStyleType.outlined}
                                    userId={user?.sub}
                                    defaultPointId={values.object_id ?? undefined}
                                    onChange={(newPointId, newProjectId) => {
                                        setFieldValue('object_id', newPointId);
                                        setFieldValue('project_id', newProjectId);
                                    }}
                                    projectId={selectedProject}
                                    disabled={props.isEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={styles['activity-card-poll']}>
                <div className={styles['activity-card-poll-title']}>Данные мероприятия:</div>
                <ActivityCardActivity
                    isTookPart={isTookPart}
                    name={'event_name'}
                    onChange={(activity) => {
                        setFieldValue('event_id', activity.activityId);
                        setFieldValue('event_name', activity.activityName);
                    }}
                    projectType={projectType ?? undefined}
                    isEdit={props.isEdit}
                />
                <TextInputAreaField name={'description'} placeholder={'Описание'} disabled={isTookPart} />
            </div>
            <div className={styles['activity-card-button-group']}>
                <div className={styles['activity-card-button']}>
                    <div className={styles['activity-card-poll-title']}>Дата:</div>
                    <DatePickerField
                        format={DEFAULT_DATE_FORMAT}
                        placeholder={'Дата'}
                        name={'date'}
                        disabled={isTookPart}
                        disabledDate={(date) => isSameOrBeforeToday(date) && !isToday(date)}
                    />
                </div>
                <div className={styles['activity-card-button']}>
                    <div className={styles['activity-card-poll-title']}>Время:</div>
                    <TimePickerField placeholder={'Время'} name={'time'} disabled={isTookPart} />
                </div>
            </div>
            {!props.isEdit && !hasRole(Role.Municipal, user?.role as Role) && (
                <div>
                    <FolkFormInput placeholder={'Ссылка на трансляцию'} name={'link'} />
                </div>
            )}
            <div className={styles['activity-card-poll-title']}>
                Опрос:
                {interview && !isTookPart && (
                    <div className={styles['activity-delete-interview']} onClick={handleDeleteInterview}>
                        Удалить опрос
                    </div>
                )}
            </div>
            <Divider />
            <Tooltip placement={'topLeft'} title={interview && !isTookPart ? 'Чтобы добавить новый опрос, сначала удалите существующий' : ''}>
                <div
                    className={styles[`activity-card-poll-survey-${isTookPart ? 'disabled' : 'active'}`]}
                    onClick={() => {
                        if (!isTookPart && !interview) {
                            setIsModalOpen(true);
                        }
                    }}
                >
                    <div className={styles['activity-card-poll-icon']}>
                        <BarChartOutlined />
                    </div>
                    {poll}
                </div>
            </Tooltip>
            <Divider />
            <Divider />
            <ActivityCardPollForm
                setModalIsOpen={setIsModalOpen}
                onFinish={onInterviewFinish}
                disabled={!isPollEditable ? interview && interview.answers.length !== 0 : false}
                interview={interview}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                open={isModalOpen}
            />
            {props.isEdit ? (
                <Row className={styles['activity-card-submit']} gutter={24}>
                    <Col span={24} sm={{ span: 12 }}>
                        <SubmitButton disabled={isTookPart} buttonText={'Сохранить изменения'} />
                    </Col>
                    <Col span={24} sm={{ span: 12 }}>
                        <Button
                            block
                            disabled={isTookPart}
                            className={styles['activity-card-cancel']}
                            onClick={() => {
                                cancelActivity(props.activity);
                            }}
                        >
                            Отменить мероприятие
                        </Button>
                    </Col>
                </Row>
            ) : (
                <div className={styles['activity-card-submit-new']}>
                    <SubmitButton
                        disabled={!isValid}
                        buttonText={'Создать мероприятие'}
                        loading={props.loading}
                    />
                </div>
            )}
        </Form>
    );
};

export default ActivityCardFormFields;
