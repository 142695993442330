import React, { useEffect, useState } from 'react';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { handleApiError } from 'api/v1.0/common';
import DeputySortPoints from '../DeputySortPoints';
import { useRegionalCoordinatorList } from '../../hooks/useRegionalCoordinatorList';
import RegionCoordinator from '../RegionalCoordinator';
import CoordinatorPoint from '../CoordinatorPoint/CoordinatorPoint';
import { IPointsState } from '../../hooks';
import { DeputySortType, PointSortType, ViewType } from './hooks/types';
import useFederalCoordinatorPoints from './hooks/useFederalCoordinatorPoints';

interface IDeputyPointsProps {
    deputyId: number,
    changePoint: (point: number) => void;
    clearPoints: () => void;
    statisticsState: IPointsState;
    compact?: boolean;
}

const DeputyPoints: React.FC<IDeputyPointsProps> = (props) => {
    const [viewType, setViewType] = useState<ViewType>(ViewType.list);
    const [deputySortType, setDeputySortType] = useState<DeputySortType>(DeputySortType.rating);
    const [pointSortType, setPointSortType] = useState<PointSortType>(PointSortType.rating);
    const [clearSelected, setClearSelected] = useState(false);

    const { state, changeSort, refresh } = useFederalCoordinatorPoints({
        deputyId: props.deputyId,
        sortType: pointSortType,
        start: 0,
        limit: 10,
    });
    const { stateDeputies, changeDeputySort, refreshCoordinators } = useRegionalCoordinatorList({
        deputyId: props.deputyId,
        sortType: deputySortType,
        start: 0,
        limit: 10,
    });

    const lengthPoints = state.points ? state.points.length : 0;

    const hasMorePoints = state.points ? state.points?.length < state.totalCount : false;

    useEffect(() => {
        props.clearPoints();
        setClearSelected(!clearSelected);
        //eslint-disable-next-line
    }, [viewType, deputySortType, pointSortType]);

    const loadMoreData = () => {
        if (viewType === ViewType.list){
            void refresh(state.points?.length ?? 0);
        }
        else {
            refreshCoordinators(stateDeputies.coordinators?.length ?? 0);
        }
    };

    return (
        <>
            <DeputySortPoints
                onKeyChange={(val) => {
                    changeSort(val).then((_) => {
                        setPointSortType(val === PointSortType.rating ? PointSortType.rating : PointSortType.title);
                    }).catch((e) => handleApiError(e));
                }}
                onDeputyKeyChange={(val) => {
                    changeDeputySort(val).then((_) => {
                        setDeputySortType(val === DeputySortType.rating ?
                            DeputySortType.rating : DeputySortType.surname_n_p);
                    }).catch((e) => handleApiError(e));
                }}
                onViewKeyChange={(val) => {
                    setViewType(val === ViewType.deputy ? ViewType.deputy : ViewType.list);
                }}
            />
            <div className='deputy-points-wrapper'>
                {
                    viewType === ViewType.deputy
                        ? <InfiniteScroll
                            dataLength={stateDeputies.coordinators?.length ?? 0}
                            next={loadMoreData}
                            hasMore=
                                {stateDeputies.coordinators
                                    ? stateDeputies.coordinators?.length < stateDeputies.totalCount
                                    : false}
                            loader={<Skeleton paragraph={{ rows: 2 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={stateDeputies.coordinators ?? undefined}
                                renderItem={(coordinator) => (
                                    <List.Item>
                                        <div key={coordinator.regional_coordinator_id} className='deputy-list-item'>
                                            <RegionCoordinator
                                                data={coordinator}
                                                compact={props.compact} changePoint={props.changePoint}
                                                selectedPoints={props.statisticsState.selectedPoints}
                                                clearPoints={props.clearPoints} />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                        : <InfiniteScroll
                            dataLength={lengthPoints}
                            next={loadMoreData}
                            hasMore={hasMorePoints}
                            loader={<Skeleton paragraph={{ rows: 2 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={state.points ?? undefined}
                                renderItem={(item) => (
                                    <List.Item>
                                        <div key={item.Id} className='deputy-list-item'>
                                            <CoordinatorPoint data={item} onSelectedChange={(val) => {
                                                props.changePoint(val);
                                            }} clearSelected={clearSelected} compact={props.compact} />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                }

            </div>
        </>);
};

export default DeputyPoints;
