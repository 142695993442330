import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { Button, Modal, notification } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import Icon from 'ui-kit/Icon';
import FolkButton from 'ui-kit/FolkButtons';
import { validateValuesWithSchema } from 'utils/validation';
import {
    IControlPointCreateFields,
    IControlPointCreateRequest, IControlPointEditFields,
    IControlPointEditRequest,
} from 'api/types/v1.0/controlPointForm';
import { controlPointAdd } from 'api/v1.0/controlPointAdd';
import { ErrorType } from 'api/v1.0/common';
import { controlPointEdit } from 'api/v1.0/controlPointEdit';
import { formatDateRequest } from 'utils/dateTime';
import { controlPointValidationSchema } from './controlPointValidationSchema';
import ControlPointModalFields from './ControlPointModalFields/ControlPointModalFields';
import styles from './ControlPointModal.module.scss';

export interface IControlPointModalValues {
    name: string;
    planDate: string | Date | null;
    factDate: string | Date | null;
    isCompleted: boolean;
    isDisplayed: boolean;
    required: boolean;
    id: number;
    previousLastAvailableDate?: Date;
    nextLastAvailableDate?: Date;
    controlPointDates?: Date[];
}

interface IControlPointModalProps {
    isOpen: boolean;
    onClose(): void;
    onUpdate(): void;
    formValue: IControlPointModalValues | null;
    deputyId: number;
    objectId: number;
    currentPointType: number;
    controlPointDates?: Date[];
}

const ControlPointModal: React.FC<IControlPointModalProps> = ({
    isOpen,
    onClose,
    formValue,
    deputyId,
    objectId,
    onUpdate,
    currentPointType,
    controlPointDates,
}) => {
    const [loading, setLoading] = useState(false);

    const isEditing = !!formValue?.id;

    const modalInitialValues = formValue ?? {
        planDate: null,
        factDate: null,
        isDisplayed: false,
        isCompleted: false,
        name: '',
        controlPointDates: controlPointDates,
    } as IControlPointModalValues;

    const onControlPointSubmitApiError = (error: AxiosError<ErrorType>) => {
        if (error.response) {
            const { data } = error.response;

            const messagedData = data as { message?: string };

            notification.error({
                type: 'error',
                message: messagedData.message,
                duration: 4,
                placement: 'bottom',
            });
        }

        setLoading(false);
    };

    const onModalClose = (formikHelpers: FormikHelpers<IControlPointModalValues>) => {
        formikHelpers.resetForm();
        onClose();
    };

    const submitForm = (values: IControlPointModalValues, formikHelpers: FormikHelpers<IControlPointModalValues>) => {
        const formatFactDate = values.factDate ? formatDateRequest(values.factDate.toString()) : null;
        const formatPlanDate = values.planDate ? formatDateRequest(values.planDate.toString()) : null;

        const createRequestFieldValues = {
            Finished: Number(values.isCompleted),
            Displayed: Number(values.isDisplayed),
            Deputy: { Id: deputyId },
            newCheckpointName: values.name,
            Object: { Id: objectId },
            DateFact: values.factDate instanceof Date ? values.factDate
                : formatFactDate,
            DataPlan: values.planDate instanceof Date ? values.planDate
                : formatPlanDate,
        } as IControlPointCreateFields;

        const editRequestFieldValues = {
            ...createRequestFieldValues,
            newCheckpointName: currentPointType ? values.name : null,
            Id: values.id,
        } as IControlPointEditFields;

        const request: IControlPointCreateRequest | IControlPointEditRequest = {
            modelData: isEditing ? editRequestFieldValues : createRequestFieldValues,
            clientTimezoneParams: {
                ClientTimeZoneOffset: new Date().getTimezoneOffset() / 60,
            },
        };

        setLoading(true);

        const resetForm = () => {
            formikHelpers.resetForm();
            onClose();
            onUpdate();
            setLoading(false);
        };

        if (isEditing) {
            controlPointEdit(request as IControlPointEditRequest)
                .then(() => resetForm())
                .catch(onControlPointSubmitApiError);
        }
        else {
            controlPointAdd(request)
                .then(() => resetForm())
                .catch(onControlPointSubmitApiError);
        }
    };

    return (
        <Formik<IControlPointModalValues>
            initialValues={modalInitialValues}
            enableReinitialize
            onSubmit={submitForm}
            validateOnMount
            validate={(values) =>
                validateValuesWithSchema<IControlPointModalValues>(values, controlPointValidationSchema)}
        >
            {
                (formProps) => (
                    <Modal
                        open={isOpen}
                        onCancel={() => onModalClose(formProps)}
                        footer={null}
                        className={styles.controlPointModal}
                    >
                        <div className={styles.modalContainer}>
                            <div className={styles.modalHeaderContainer}>
                                <div className={styles.modalHeader}>
                                    {isEditing ? 'Редактирование' : 'Добавление'} этапа
                                </div>
                                <Icon onClick={() => onModalClose(formProps)} className={styles.closeModal} type={'Close'} />
                            </div>
                            <div className={styles.modalFormContainer}>
                                <Form onSubmit={formProps.handleSubmit}>
                                    <ControlPointModalFields pointType={currentPointType} />
                                    <div className={styles.modalFormFooterContainer}>
                                        <div className={styles.modalFormFooter}>
                                            <FolkButton
                                                loading={loading}
                                                className={styles.submitButton}
                                                disabled={!formProps.isValid}
                                                type={'primary'}
                                                htmlType={'submit'}>{isEditing ? 'Сохранить' : 'Добавить'}</FolkButton>
                                            <Button
                                                className={styles.cancelButton}
                                                type={'link'}
                                                onClick={() => onModalClose(formProps)}>Отмена</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </Formik>
    );
};

export default ControlPointModal;
