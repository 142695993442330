import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    usersToInvite: [],
    isLoading: false,
    selectedProjectId: null,
    selectedPointId: null,
    selectedUsers: [],
    totalCount: 0,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_USERS_TO_INVITE:
            return {
                ...state,
                usersToInvite: [...state.usersToInvite ?? [], ...action.payload ?? []],
            };
        case actionTypes.DELETE_USERS_TO_INVITE:
            return {
                ...state,
                usersToInvite: [],
            };
        case actionTypes.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProjectId: action.payload,
            };
        case actionTypes.SET_SELECTED_POINT:
            return {
                ...state,
                selectedPointId: action.payload,
            };
        case actionTypes.SET_SELECTED_USERS:
            return {
                ...state,
                selectedUsers: action.payload,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.CLEAR_STATE:
            return {
                ...defaultState,
                selectedPointId: state.selectedPointId,
                selectedProjectId: state.selectedProjectId,
            };
    }
};

export default reducer;
