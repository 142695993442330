import React, { useEffect, useState } from 'react';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    DeputySortType,
    PointSortType,
    ViewFrom,
    ViewType,
} from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';
import DeputySortPoints from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputySortPoints';
import Deputy from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/Deputy';
import { useDeputyList } from 'pages/DeputyProfile/components/RegionCoordinatorProfile/hooks/useDeputyList';
import CoordinatorPoint from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/CoordinatorPoint';
import { handleApiError } from 'api/v1.0/common';
import { IPointsState } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/hooks';
import useRegionalCoordinatorPoints from './hooks';

interface IDeputyPointsProps{
    deputyId: number,
    changePoint: (point: number) => void;
    clearPoints: () => void;
    compact?: boolean;
    statisticsState: IPointsState;
}

const DeputyPoints: React.FC<IDeputyPointsProps> = (props) => {
    const [viewType, setViewType] = useState<ViewType>(ViewType.list);
    const [deputySortType, setDeputySortType] = useState<DeputySortType>(DeputySortType.rating);
    const [pointSortType, setPointSortType] = useState<PointSortType>(PointSortType.rating);
    const [clearSelected,setClearSelected] = useState(false);

    const { state, changeSort, refresh } = useRegionalCoordinatorPoints({
        deputyId: props.deputyId,
        sortType: pointSortType,
        start: 0,
        limit: 10,
    });
    const { stateDeputies, changeDeputySort, refreshDeputies } = useDeputyList({
        deputyId: props.deputyId,
        sortType: deputySortType,
        start: 0,
        limit: 10,
    });

    useEffect( () => {
        props.clearPoints();
        setClearSelected(!clearSelected);
        //eslint-disable-next-line
    },[viewType,deputySortType,pointSortType]);

    const loadMoreData = () => {
        if (viewType === ViewType.list){
            void refresh(state.points?.length ?? 0);
        }
        else {
            refreshDeputies(stateDeputies.deputies?.length ?? 0);
        }
    };

    return (
        <>
            <DeputySortPoints
                onKeyChange={(val) => {
                    changeSort(val)
                        .then((_) => {
                            setPointSortType(val === PointSortType.rating ? PointSortType.rating: PointSortType.title);
                        })
                        .catch((e)=> handleApiError(e));
                }}
                onDeputyKeyChange={(val) => {
                    changeDeputySort(val)
                        .then((_)=> {
                            setDeputySortType(val === DeputySortType.rating
                                ? DeputySortType.rating
                                : DeputySortType.surname_n_p);
                        })
                        .catch((e)=> handleApiError(e));
                }}
                onViewKeyChange={(val) => {
                    setViewType(val === ViewType.deputy ? ViewType.deputy : ViewType.list);
                }}
            />
            <div className='deputy-points-wrapper'>
                {
                    viewType === ViewType.deputy
                        ? <InfiniteScroll
                            dataLength={stateDeputies.deputies?.length ?? 0}
                            next={loadMoreData}
                            hasMore={!!stateDeputies.deputies
                                && stateDeputies.deputies?.length < stateDeputies.totalCount}
                            loader={<Skeleton paragraph={{ rows: 2 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={stateDeputies.deputies ?? undefined}
                                renderItem={(deputy) => (
                                    <List.Item className={'favorite-list-item'}>
                                        <div key={deputy.municipal_response_id} className='deputy-list-item'>
                                            <Deputy
                                                changePoint={props.changePoint} clearPoints={props.clearPoints}
                                                data={deputy} selectedPoints={props.statisticsState.selectedPoints}
                                                view={ViewFrom.regional} />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                        : <InfiniteScroll
                            dataLength={state.points?.length ?? 0}
                            next={loadMoreData}
                            hasMore={!!state.points && state.points?.length < state.totalCount}
                            loader={<Skeleton paragraph={{ rows: 2 }} active />}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={state.points ?? undefined}
                                renderItem={(item) => (
                                    <List.Item className={'favorite-list-item'}>
                                        <div key={item.Id} className='deputy-list-item'>
                                            <CoordinatorPoint
                                                data={item}
                                                onSelectedChange={(val) => {
                                                    props.changePoint(val);
                                                }}
                                                clearSelected={clearSelected}
                                                compact={props.compact}
                                            />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                }
            </div>
        </>);
};

export default DeputyPoints;
