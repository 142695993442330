import React, { ReactElement, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Role } from 'types/account';
import { COLOR_FOLK_MAIN, COLOR_GREY } from '../../constants/colors';
import CoordinatorMenu from './components/CoordinatorMenu';
import DeputyMenu from './components/DeputyMenu';
import { UserMenu } from './components/UserMenu';

interface IProps {
    role?: string;
    disabled?: boolean;
}

const BurgerMenu: React.FC<IProps> = ({ role, disabled }) => {
    const [isOpen, setOpen] = useState(false);
    const getMenu = () => {
        switch (role) {
            case Role.Federal:
            case Role.Regional:
                return <CoordinatorMenu />;
            case Role.Municipal:
                return <DeputyMenu />;
            case Role.Citizen:
                return <UserMenu />;
        }
    };
    const openChange = () => {
        setOpen((prevIsOpen) => !prevIsOpen);
    }
    const getButtonStyle = (flag: boolean | undefined) =>
        flag
            ? { color: COLOR_FOLK_MAIN }
            : { color: COLOR_GREY };

    return (
        <Dropdown onOpenChange={openChange} disabled={disabled} overlay={getMenu() as ReactElement} trigger={[ 'click' ]} placement={'bottomLeft'}>
            <a>
                <Space>
                    <MenuOutlined style={getButtonStyle(isOpen)}/>
                </Space>
            </a>
        </Dropdown>
    )
};

export default BurgerMenu;
