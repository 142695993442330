import { Progress } from 'antd';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import './ProgressCard.scss';

interface IProps {
    interview: IInterview;
}

const ProgressCard: React.FC<IProps> = ({ interview }) => {
    return (
        <div className={'progress-card-wrapper'}>
            {interview?.answers
                .sort((prevAnswer, nextAnswer) => prevAnswer.id - nextAnswer.id)
                .map((answer) => {
                return (
                    <div className={'progess-card-container'} key={answer?.id}>
                        {
                            interview.answer_id === answer?.id &&
                            <CheckOutlined className={'progress-card-check-icon'} />
                        }
                        <span className={'progress-card-description'}>{answer?.answer} - {answer?.rating}%</span>
                        <Progress percent={answer?.rating} size="small" showInfo={false} />
                    </div>
                )
            })}
        </div>
    )
}

export default ProgressCard;
