import React from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { useField } from 'formik';
import { ProjectType } from 'api/types/v1.0/activityInfo';
import { validationMessages } from 'constants/validationMessages';
import { useActivityCardActivity } from './hooks/useActivityCardActivity';

const { Option } = Select;
interface IActivitySelect {
    activityId: number | null;
    activityName: string | null;
}

export interface IActivityCardActivityProps {
    projectType?: ProjectType;
    onChange: (activitySelect: IActivitySelect) => void;
    name: string;
    isTookPart?: boolean;
    isEdit: boolean;
}

export const ActivityCardActivity: React.FC<IActivityCardActivityProps> = (props) => {
    const { onChange } = props;
    const { activities } = useActivityCardActivity({ projectType: props.projectType, isEdit: props.isEdit });
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField(name);

    return (
        <Form.Item
            name={'event_name'}
            initialValue={field.value as string | undefined}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: true, message: validationMessages.activity_name }]}
        >
            <AutoComplete
                value={field.value as string | undefined}
                className={props.isTookPart ? 'select-disabled' : 'select-field'}
                showSearch
                placeholder='Название (выберите из списка или введите вручную)'
                optionFilterProp='children'
                filterOption
                allowClear
                disabled={props.isTookPart}
                onSearch={(value: string | undefined) => {
                    if (value)
                    {
                        setTouched(true);
                        onChange({ activityName: null, activityId: null });
                        setValue(value);
                    }
                }}
                onChange={
                    (value: string | undefined) => {
                        setValue(value);
                        setTouched(true);
                        onChange({
                            activityName: activities
                                ? activities.find((data) => data.name.toString() === value)?.name
                                : null,
                            activityId: activities
                                ? activities.find((data) => data.name.toString() === value)?.Id
                                : null } as IActivitySelect);
                    }
                }
            >
                {
                    activities && activities.map((activity) =>
                        <Option
                            key={activity.Id}
                            value={activity.name}
                        >
                            {activity.name}
                        </Option>
                    )
                }
            </AutoComplete>
        </Form.Item>
    );
};
