import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';
import Page from 'components/Page';
import DeputyMenu from 'components/Layout/components/Sidebar/DeputyMenu/DeputyMenu';
import styles from '../FederalCoordinatorProfile/FederalCoordinator.module.scss';
import { useDeputyProfilePage } from './hooks/useDeputyProfilePage';
import DeputyPoints from './components/DeputyPoints';
import DeputyStatistics from './components/DeputyStatistics';
import { useDeputySelectedPoints } from './hooks/useDeputySelectedPoints';

interface IDeputyProps {
    deputyId: number;
}

export const DeputyProfile: React.FC<IDeputyProps> = ({ deputyId }) => {
    const { state } = useDeputyProfilePage(deputyId);
    const { statisticsState,
        changeSelectedPoint,
        clearPoints } = useDeputySelectedPoints(deputyId);
    return (
        <Page
            content={
                <Spin spinning={state.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                    {
                        isMobile && <div className='deputy-statistics-container'>
                            <span className='deputy-statistics-header'>Общий рейтинг&nbsp;
                                <span className='statistics-value'>
                                    {state.profile?.rating}%
                                </span>
                            </span>
                        </div>
                    }
                    <div className={isDesktop ? styles.body : styles.bodyMobile}>
                        <div className={styles.mainContainer}>
                            <div className={styles.pointsContainer}>
                                <div className={styles.textTitle}>Объекты</div>
                                <DeputyPoints deputyId={deputyId}
                                    changePoint={changeSelectedPoint}
                                    clearPoints={clearPoints}
                                    compact={!isDesktop} />
                            </div>
                        </div>
                        <div className={styles.statisticContainer}>
                            {isDesktop && <DeputyMenu />}
                            <DeputyStatistics
                                data={statisticsState.statistics}
                                Id={deputyId}
                                rating={state.profile?.rating} />
                        </div>
                    </div>
                </Spin>
            }
        />
    );
};
