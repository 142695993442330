import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getGroupMembersList, recoverGroupMember, removeGroupMember } from 'api/v1.0/GroupMembersList';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useGroupMembersList = (selectedGroup_Id: number) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = (start?: number) => {
        dispatch(actionCreators.setIsGroupMembersLoading(true));

        const request = {
            object_id: selectedGroup_Id,
            start,
        };

        getGroupMembersList(request)
            .then((response) => {
                if (response) {
                    if (state.groupMembersList?.data && start) {
                        dispatch(actionCreators.setGroupMembersList({ ...response.data, data:
                                [...state.groupMembersList?.data as [], ...response.data.data] }));
                    } else {
                        dispatch(actionCreators.setGroupMembersList(response?.data));
                    }

                    dispatch(actionCreators.setIsGroupMembersLoading(false));
                }
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsGroupMembersLoading(false)));
    };

    const removeFromGroup = (
        object_id: number,
        citizen_id: number,
        reason_for_removal: number,
        Id: number
    ) => {
        void removeGroupMember({ object_id, citizen_id, reason_for_removal, Id })
            .then(() => {
                dispatch(actionCreators.removeGroupMember(citizen_id));
            });
    };

    const recoverMember = (
        object_id: number,
        citizen_id: number,
        Id: number
    ) => {
        void recoverGroupMember({ object_id, citizen_id, Id })
            .then(() => {
                dispatch(actionCreators.recoverGroupMember(citizen_id));
            });
    };

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup_Id]);

    return {
        ...state,
        removeFromGroup,
        recoverMember,
        refresh,
    };
};

export default useGroupMembersList;
