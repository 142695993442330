import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Skeleton } from 'antd';
import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { IRegionalCoordinatorForList } from 'api/types/v1.0/federalCoordinatorRegionalCoordinators';
import FolkButton from 'ui-kit/FolkButtons';
import { getRegionalCoordinatorDeputies } from 'api/v1.0/regionalCoordinatorDeputies';
import { handleApiError } from 'api/v1.0/common';
import { IRegionalCoordinatorDeputy } from 'api/types/v1.0/regionalCoordinatorDeputies';
import Deputy from '../Deputy';
import { DeputySortType, ViewFrom } from '../DeputyPoints/hooks/types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RegionCoordinator.scss';

export interface IDeputyProps {
    data: IRegionalCoordinatorForList,
    compact?: boolean,
    changePoint: (point: number) => void;
    clearPoints: () => void;
    selectedPoints: number[];
}

export const RegionCoordinator: React.FC<IDeputyProps> = (props: IDeputyProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deputies, setDeputies] = useState<IRegionalCoordinatorDeputy[]>();

    useEffect( () => {
        if (isOpen) {
            setIsLoading(true);
            getRegionalCoordinatorDeputies({
                deputyId: props.data.regional_coordinator_id,
                sortType: DeputySortType.rating }, true)
                .then((result) =>{
                    setDeputies(result.data);
                    setIsLoading(false);
                })
                .catch((err) => (handleApiError(err)));
        }
    },[isOpen,props.data.regional_coordinator_id]);

    return (
        <div className={isOpen ? 'backgroundSelected' : ''}>
            <div className='rowDeputy'>
                <div>
                    <Avatar
                        size={55}
                        className={'listAvatar'}
                        alt={props.data.surname_n_p}
                        icon={<UserOutlined />}
                        src={props.data?.preview}
                    />
                </div>
                <div className='descColumn'>
                    <div className={'deputyName'}>{props.data.surname_n_p}</div>
                    {props.data.rating}% общий рейтинг<br />
                    <div className='opacity-50'>{props.data.region_name}<br /></div>
                </div>
                <div className='arrowButton'>
                    <FolkButton
                        type={'default'}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        icon={isOpen ? <UpOutlined color='$color-folk-main' /> :
                            <DownOutlined color='$color-folk-main' />}
                    />
                </div>
            </div>
            <Divider className='deputyDivider' />
            <div>
                {isOpen &&
                    deputies?.map((coordinator) =>
                        <div key={coordinator.municipal_response_id} className='deputy-list-item'>
                            <Deputy data={coordinator}
                                compact={props.compact}
                                changePoint={props.changePoint} clearPoints={props.clearPoints}
                                selectedPoints={props.selectedPoints} view={ViewFrom.federal} />
                        </div>)
                }
                {isLoading && <Skeleton paragraph={{ rows: 2 }} active />}
            </div>
        </div>
    );
};

export default RegionCoordinator;
