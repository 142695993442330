import { axiosCachePostRequest, axiosPost } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IHasFilters } from '../bars-request-objects';
import { IActivityInfoResponse } from '../types/v1.0/activityInfo';

export const eventsEntityList = async (request?: IHasFilters): Promise<IActivityInfoResponse> => {
    try {
        if (request) {
            const result = await axiosCachePostRequest<IHasFilters, IActivityInfoResponse>(
                ApiPaths.EventsEntityList, request);
            return result.data;
        }
        else
        {
            const result = await axiosPost<IActivityInfoResponse>(
                ApiPaths.EventsEntityList);
            return result.data;
        }
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
