import { IGroupsMessageItem } from 'api/types/v1.0/groupsList';
import { messageSeen } from 'api/v1.0/getChatInfo';

export const sendMessagesSeenHelper = async (messages: IGroupsMessageItem[]) => {
    // Таким образом обходим дедлок бэка
    messages[0]?.viewed === false && await messageSeen(messages[0]?.Id);
    messages[1]?.viewed === false && await messageSeen(messages[1]?.Id);
    messages[2]?.viewed === false && await messageSeen(messages[2]?.Id);
    messages[3]?.viewed === false && await messageSeen(messages[3]?.Id);
    messages[4]?.viewed === false && await messageSeen(messages[4]?.Id);
};
