import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { QUERY_FORMAT } from 'constants/dateTime';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import { FolkTabs } from 'ui-kit/FolkTabs/FolkTabs';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import { CitizenEventsList } from './components/CitizenEventsList';
import { CitizenEventsCalendar } from './components/CitizenEventsCalendar';
import useCitizenEventsPage from './hooks';
import './CitizenEventsPage.scss';

const CitizenEventsPage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);
    const userId = user?.sub;

    const {
        citizenEvents,
        refresh,
        selectedProjectId,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedDate,
        clearFiltersForCalendar,
        clearFiltersForList,
        selectedDate,
        totalCount,
        isLoading,
        monthCitizenEvents,
        changeSelectedMonth,
        changeSelectedYear,
        pageByDate,
    } = useCitizenEventsPage(userId as string);

    return <Page
        content={
            <Page.Content sidebar={'Main'}>
                <div className={'citizen-events-page-wrapper'}>
                    <div className={'citizen-events-page-title-block'}>
                        <div className={'citizen-events-page-title'}>
                            Мои мероприятия
                        </div>
                    </div>
                    <div className={'citizen-events-content'}>
                        <div className={'citizen-events-filters-block'}>
                            <div className={'filter-with-title-block'}>
                                <span className={'filter-title'}>Проект:</span>
                                <ProjectFilter
                                    alwaysShowSuffix={true}
                                    userId={userId}
                                    useCitizenProjectList={true}
                                    onChange={changeSelectedProject}
                                />
                            </div>
                            <div className={'filter-with-title-block'}>
                                <span className={'filter-title'}>Объект:</span>
                                <PointFilter
                                    alwaysShowSuffix={true}
                                    userId={userId}
                                    useCitizenObjectList={true}
                                    onChange={changeSelectedPoint}
                                    projectId={selectedProjectId ?? undefined}
                                />
                            </div>
                        </div>
                        <div className={'navigation-tabs'}>
                            <FolkTabs
                                actionsList={[
                                    clearFiltersForList,
                                    () => clearFiltersForCalendar(selectedDate
                                        ? selectedDate
                                        : moment().format(QUERY_FORMAT)),
                                ]}
                                items={[
                                    {
                                        label: 'Все',
                                        key: '0',
                                        children:
                                            <OnLoadingSpinner
                                                isLoading={isLoading}
                                                content={<CitizenEventsList
                                                    citizenEvents={citizenEvents}
                                                    refresh={refresh}
                                                    loading={isLoading}
                                                    totalCount={totalCount}
                                                    pageByDate={pageByDate}
                                                />}
                                            />,
                                    },
                                    {
                                        label: 'Календарь',
                                        key: '1',
                                        children:
                                            <OnLoadingSpinner
                                                isLoading={isLoading}
                                                content={<CitizenEventsCalendar
                                                    citizenEvents={citizenEvents}
                                                    changeDate={changeSelectedDate}
                                                    isLoading={isLoading}
                                                    totalCount={totalCount}
                                                    monthCitizenEvents={monthCitizenEvents}
                                                    changeSelectedMonth={changeSelectedMonth}
                                                    changeSelectedYear={changeSelectedYear}
                                                    refresh={refresh}
                                                    pageByDate={pageByDate}
                                                />}
                                            />,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Page.Content>
        }
    />;
};

export default CitizenEventsPage;
