import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import {
    IDeputyProfileRequest,
    IDeputyProfileResponse,
} from '../types/v1.0/deputyProfile';
import { ICoordinatorStatisticsResponse } from '../types/v1.0/coordinatorStatistics';
import { IStatisticsByObjectsRequest } from './regionalCoordinatorProfile';

export const getDeputyProfile = async (req: IDeputyProfileRequest) => {
    const request = await axiosPostRequest< IDeputyProfileRequest , IDeputyProfileResponse>(ApiPaths.Municipal, req);
    return request.data;
};

export const getDeputyStatistics = async (req: IStatisticsByObjectsRequest) => {
    const request = await axiosPostRequest< IStatisticsByObjectsRequest,
        ICoordinatorStatisticsResponse>(ApiPaths.MunicipalStatistics, req);
    return request.data;
};
