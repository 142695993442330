import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IState } from './types';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

export const defaultState: IState = {
    news: null,
    isLoading: false,
    selectedRegionId: null,
    selectedProjectId: null,
    selectedObjectId: null,
    selectedDeputyId: null,
    selectedStartDate: null,
    selectedEndDate: null,
    totalCount: 0,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_NEWS:
            return {
                ...state,
                news: [...state.news ?? [], ...action.payload ?? []],
            };

        case actionTypes.SET_SELECTED_REGION:
            return {
                ...state,
                selectedRegionId: action.payload,
            };

        case actionTypes.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProjectId: action.payload,
            };

        case actionTypes.SET_SELECTED_OBJECT:
            return {
                ...state,
                selectedObjectId: action.payload,
            };

        case actionTypes.SET_SELECTED_DEPUTY:
            return {
                ...state,
                selectedDeputyId: action.payload,
            };

        case actionTypes.SET_SELECTED_START_DATE:
            return {
                ...state,
                selectedStartDate: action.payload,
            };

        case actionTypes.SET_SELECTED_END_DATE:
            return {
                ...state,
                selectedEndDate: action.payload,
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.DELETE_NEWS:
            return {
                ...state,
                news: [],
                totalCount: 0,
            };

        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
