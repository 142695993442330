import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'App/root/rootReducer';
import { handleApiError } from 'api/v1.0/common';
import { addCitizenVoteForInterview } from 'api/v1.0/CitizenVotePoll';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import { getDeputyActivitiesFilter } from 'api/bars-request-objects';
import { getDeputyActivities } from 'api/v1.0/deputyActivities';
import { getGroupMembersList } from 'api/v1.0/GroupMembersList';
import { IGroupMember } from 'api/types/v1.0/groupMembersList';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

const useActivityCard = (initialInterview: IInterview, pointId: number, statesmanId: number,
    objectEventId: number) => {
    const [activityCardState, dispatch] = useReducer(reducer, defaultState);
    const { user } = useSelector((state: RootState) => state.account);
    const [isUserBanned, setIsUserBanned] = useState(false);

    useEffect(() => {
        setInterview(initialInterview);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (pointId) {
            void getGroupMembers(pointId);
        }
        //eslint-disable-next-line
    }, []);

    const sendVote = (answerId: number, interviewId: number) => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                'functionArguments[0]': Number(user?.sub),
                'functionArguments[1]': answerId,
                'functionArguments[2]': interviewId,
            };

            void addCitizenVoteForInterview(request)
                .then(() => {
                    const secondRequest = {
                        dataFilter: getDeputyActivitiesFilter(null,
                            Number(pointId) ?? null, null, String(statesmanId), objectEventId),
                    };

                    void getDeputyActivities(secondRequest)
                        .then((res) => {
                            dispatch(actionCreators.setInterview(res.data
                                .filter((i) => i.interview && i.interview.id === initialInterview.id)[0].interview));
                            dispatch(actionCreators.setLoading(false));
                        });
                });
        } catch (exception) {
            handleApiError(exception);
        }
    };

    const setInterview = (interview: IInterview | null) => {
        dispatch(actionCreators.setInterview(interview));
    };

    const getGroupMembers = async (object_id: number) => {
        try {
            const request = {
                object_id: object_id,
                noLimit: true,
            };

            user && await getGroupMembersList(request).then((response) => {
                if (response) {
                    setIsUserBanned(response.data.data.some((item: IGroupMember) =>
                        (item.citizen_id === parseInt(user?.sub ?? '0') && item.remove_from_group === 1)
                    ));
                }
            });
        } catch (exception) {
            handleApiError(exception);
        }
    };

    return {
        ...activityCardState,
        isUserBanned,
        getGroupMembers,
        sendVote,
    };
};

export default useActivityCard;
