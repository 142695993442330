import React from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { COLOR_FOLK_MAIN } from 'constants/colors';
import { IProfileTabKeyInfo } from '../interfaces/ITabKeyInfo';
import styles from './LayoutHeaderTabs.module.scss';

interface IProps {
    selectedTabKey: number | undefined;
    tabKeyInfos: IProfileTabKeyInfo[];
}

const ProfileLayoutHeaderTabs: React.FC<IProps> = ({ tabKeyInfos, selectedTabKey }) => {
    const getButtonStyle = (tabKey: number) =>
        selectedTabKey === tabKey
            ? { cursor: 'pointer', borderBottom: `1px solid ${COLOR_FOLK_MAIN}` }
            : { cursor: 'default' };

    const tabKeyInfosToShow = tabKeyInfos.filter((x) => !x.hideTab);

    return (
        <>
            <div className={styles['layout-header-tabs']}>
                <div className={styles['title-menu']}>
                    {tabKeyInfosToShow.map((info, index) =>
                        <React.Fragment key={info.key}>
                            <div style={getButtonStyle(info.key)} className={styles['element']}>
                                <Button onClick={() => info.onClick()} type="link">
                                    <span className={styles['active']}>{info.tabName}</span>
                                </Button>
                            </div>
                            {
                                tabKeyInfosToShow.length > index + 1 &&
                                <div className={styles['element']}>
                                    <RightOutlined />
                                </div>
                            }
                        </React.Fragment>
                    )}
                </div>
            </div>
        </>);
};

export default ProfileLayoutHeaderTabs;

