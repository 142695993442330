import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { DeputySortType } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IDeputiesState } from './types';

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

export const defaultState: IDeputiesState = {
    deputies: null,
    isLoading: false,
    sortType: DeputySortType.rating,
    descending: true,
    totalCount: 0,
};

const deputiesReducer: Reducer<IDeputiesState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_DEPUTIES:
            return {
                ...state,
                deputies: [...state.deputies ?? [], ...action.payload ?? []],
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_TOTALCOUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.CLEAR_DEPUTIES:
            return {
                ...state,
                totalCount: 0,
                deputies: null,
            };
        case actionTypes.SET_SORT_BY_ALPHABET:
            return {
                ...state,
                sortType: DeputySortType.surname_n_p,
            };
        case actionTypes.SET_SORT_BY_RATING:
            return {
                ...state,
                sortType: DeputySortType.rating,
            };

        default:
            return state;
    }
};

export default deputiesReducer;
