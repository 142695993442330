import React from 'react';
import ExpandableListHeaderForMenu from 'ui-kit/ExpandableListForMenu';
import useLikesCitizenMenuList from './hooks/useLikesCitizenMenuList';

const LikesCitizenMenuList: React.FC = () => {
    const {
        totalCount,
        isLoading,
    } = useLikesCitizenMenuList();

    return (
        <ExpandableListHeaderForMenu
            listName={'Активность'}
            count={Number(totalCount)}
            isExpanded={false}
            markAsSelected={false}
            isLoading={isLoading}
            hideExpandButton={true}
        />
    );
};

export default LikesCitizenMenuList;
