import React, { useState } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';
import { OnLoadingSpinner } from '../OnLoadingSpinner';
import styles from './MediaCamera.module.scss';

interface IProps {
    videoContentUrl: string,
    isMainVideo?: boolean,
    preview?: boolean
    isForEditPoint?: boolean,
    handleOnPreview?: () => void,
    customLoading?: boolean,
    setCustomLoading?: (customLoading: boolean) => void;
}

const modalSettings = {
    centered: true,
    footer: false,
    destroyOnClose: true,
}

export const MediaCamera: React.FC<IProps> = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(props.customLoading === undefined ? true : props.customLoading);

    const onReady = () => {
        props.setCustomLoading === undefined ? setLoading(false) : props.setCustomLoading(false);
    }

    const styleEditPoint = props.isForEditPoint ? { margin: '0 5px', width: '200px' } : {};

    return (
        <OnLoadingSpinner isLoading={props.customLoading === undefined ? loading : props.customLoading} content={<>
            {!props.isMainVideo
                ? <div
                    className={styles['player-wrapper']}
                    style={styleEditPoint}
                    onClick={() => {
                        setModalOpen(true);
                        props.handleOnPreview && props.handleOnPreview();
                    }}>
                    <ReactPlayer onReady={onReady} width={'100%'} height={'100%'} url={props.videoContentUrl} />
                    <VideoCameraOutlined className={styles['video-icon']} />
                </div>
                : <div className={styles['player-wrapper']} onClick={() => setModalOpen(true)}>
                    <ReactPlayer style={{cursor: 'pointer'}} onReady={onReady} width={'100%'} height={'100%'} url={props.videoContentUrl} />
                    <VideoCameraOutlined className={styles['video-icon']} />
                </div>
            }
            <Modal className={styles['modal-style']} {...modalSettings} open={props.preview ? modalOpen : false}
                   onCancel={() => setModalOpen(false)}>
                <ReactPlayer
                    onReady={onReady}
                    width={'100%'} height={'80vh'} controls
                    url={props.videoContentUrl} />
            </Modal>
        </>}>
        </OnLoadingSpinner>);
};
