import { IState } from './types';
import {
    DELETE_GROUP,
    DELETE_GROUPS,
    SET_CITIZEN_GROUPS,
    SET_CONFIRM_MODAL_OPEN,
    SET_LOADING,
    SET_TOTAL_COUNT,
} from './actionTypes';

export const setGroups = (groups: IState['groups']) =>
    ({ type: SET_CITIZEN_GROUPS, payload: groups } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const deleteGroups = () =>
    ({ type: DELETE_GROUPS } as const);

export const deleteGroup = (groupId: number) =>
    ({ type: DELETE_GROUP, payload: groupId } as const);

export const setConfirmModalOpen = (isOpen: boolean, objectId?: number) =>
    ({ type: SET_CONFIRM_MODAL_OPEN, payload: { isOpen, objectId } } as const);
