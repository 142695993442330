import { IPointMediaFile } from 'api/types/v1.0/pointMedia';
import {
    EDIT_POINT_MEDIA_ADD,
    EDIT_POINT_MEDIA_ERROR,
    EDIT_POINT_MEDIA_FETCH,
    EDIT_POINT_MEDIA_LOAD,
    EDIT_POINT_MEDIA_REMOVE,
    EDIT_POINT_MEDIA_SET,
} from './actionTypes';

export const editPointMediaFetch = () =>
    ({ type: EDIT_POINT_MEDIA_FETCH } as const);

export const editPointMediaLoad = () =>
    ({ type: EDIT_POINT_MEDIA_LOAD } as const);

export const editPointMediaError = (error: string) =>
    ({ type: EDIT_POINT_MEDIA_ERROR, payload: error } as const);

export const editPointMediaSet = (media: IPointMediaFile[]) =>
    ({ type: EDIT_POINT_MEDIA_SET, payload: media } as const);

export const editPointMediaAdd = () =>
    ({ type: EDIT_POINT_MEDIA_ADD } as const);

export const editPointMediaRemove = () =>
    ({ type: EDIT_POINT_MEDIA_REMOVE } as const);
