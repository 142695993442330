import { IState } from './types';
import { DELETE_DEPUTIES, SET_LOADING, SET_DEPUTIES, SET_TOTAL_COUNT, SET_SEARCH_INPUT } from './actionTypes';

export const setDeputies = (deputies: IState['deputies']) =>
    ({ type: SET_DEPUTIES, payload: deputies } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const deleteDeputies = () =>
    ({ type: DELETE_DEPUTIES } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const setSearchInput = (input: string | null) =>
    ({ type: SET_SEARCH_INPUT, payload: input });
