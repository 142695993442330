import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IPointDocumentsResponse } from 'api/types/v1.0/pointDocuments';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    pointDocuments: null,
    isLoading: false,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_POINT_DOCUMENTS:
            Object.assign(state.pointDocuments || {}, action.payload);
            return {
                ...state,
                pointDocuments: action.payload as IPointDocumentsResponse,
            };
        case actionTypes.SET_LOADING:
            Object.assign(state.isLoading, action.payload);
            return {
                ...state,
                isLoading: action.payload as boolean,
            };

        default:
            return state;
    }
};

export default reducer;
