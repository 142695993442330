export enum StatesmanTypes {
    'FederalDeputy' = 0,
    'RegionalCoordinator' = 1,
    'MunicipalDeputy' = 2,
}

export interface IStatesman {
    Id: string;
    deputy_avatar: string;
    deputy_lvl: StatesmanTypes;
    full_name: string;
    region_id: number;
    region_name: string;
    surname_n_p: string;
    rating: number;
}

export interface IStatesmanRequest {
    id: number;
}

export interface IStatesmanResponse {
    data: IStatesman;
    success: boolean;
}
