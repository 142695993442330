import JSZip from 'jszip';
import { handleApiError } from '../api/v1.0/common';

export const downloadBlobFile = (file: Blob, fileName: string): void => {
    const downloadUrl = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const downloadZipFiles = (
    files: {
        name: string;
        blob: Blob;
    }[],
    zipName: string
): void => {
    const zip = new JSZip();
    files.forEach((file) => {
        zip.file(file.name, file.blob);
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
        downloadBlobFile(content, `${zipName}.zip`);
    }).catch((e) => handleApiError(e));
};

export const getExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
};
