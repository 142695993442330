import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICitizenGroupRequest } from 'api/types/v1.0/citizenGroup';
import { getCitizenGroup } from 'api/v1.0/citizenGroup';
import { handleApiError } from 'api/v1.0/common';
import { RootState } from 'App/root/rootReducer';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import * as actionCreators from '../reducer';
import { setIsExpanded } from '../reducer';

const useCitizenGroupsCitizenMenuList = (userId?: number) => {
    const globalState = useSelector((state: RootState) => state);
    const citizenGroupsState = globalState.citizenMenuCitizenGroupsReducer;
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: 0,
            limit: COUNT_OF_ELEMENTS_TO_SHOW,
            CitizenId: userId,
        } as ICitizenGroupRequest;

        void getCitizenGroup(request)
            .then((response) => {
                dispatch(actionCreators.deleteGroups());
                dispatch(actionCreators.setGroups(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
                dispatch(actionCreators.setLoading(false));
            }).catch((exception) => {handleApiError(exception);
                dispatch(actionCreators.setLoading(false));});
    };

    const deleteGroup = (groupId: number) => {
        dispatch(actionCreators.deleteGroup(groupId));
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    const changeIsExpanded = (isExpanded: boolean) => {
        dispatch(setIsExpanded(isExpanded));
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...citizenGroupsState,
        refresh,
        deleteGroup,
        setTotalCount,
        changeIsExpanded,
    };
};

export default useCitizenGroupsCitizenMenuList;
