import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import { citizenProjectSave } from 'api/v1.0/citizenProjectSave';
import { IRegistrationValues } from './UserRegistrationFormFields';

export interface IRegistrationRequest {
    BirthDate?: string;
    email?: string;
    fact_address?: string;
    FirstName?: string;
    Id?: number;
    ObtainEventNotification?: boolean;
    Patronymic?: string;
    phone?: string;
    RegionId?: { Id: number };
    Surname?: string;
    building?: string;
    street?: string;
    city?: string;
}

export const setRegistrationData = async (data: IRegistrationValues, projects: IProjectListItem[] | undefined) => {
    const request = {
        BirthDate: data.birthdate,
        email: data.email,
        address: data.address,
        FirstName: data.name,
        Id: data.sub,
        Patronymic: data.patronymic,
        phone: data.phone,
        RegionId: { Id: data.region },
        Surname: data.surname,
        building: data.building,
        street: data.street,
        city: data.city,
    } as IRegistrationRequest;

    const allIdProject = projects?.reduce((acc: [] | number[], project) => [...acc, project.Id], []);

    const disabledProjects = [];
    const dataProjects = data.projects ? data.projects : [];

    if (allIdProject) {
        for (const idProject of allIdProject) {
            if (dataProjects.indexOf(idProject) === -1) {
                disabledProjects.push(idProject);
            }
        }
    }

    const formData = new FormData();
    formData.append('functionName', 'upd_citizen_proj');
    formData.append('functionArguments[0]', `'${data.sub!}'`);
    formData.append('functionArguments[1]', `'${dataProjects.join(',')}'`);
    formData.append('functionArguments[2]', `'${data.sub!}'`);
    formData.append('functionArguments[3]', `'${disabledProjects.join(',')}'`);

    await citizenProjectSave(formData);

    await axiosPostRequest(ApiPaths.CitizenUpdate, {
        modelData: request,
        clientTimezoneParams: {
            ClientTimeZoneOffset: new Date().getTimezoneOffset() / 60,
        },
    });
};
