import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IHasFilters } from '../bars-request-objects';
import { IDeputyProjectsResponse } from '../types/v1.0/deputyProjects';

export const deputyProjects = async (request: IHasFilters): Promise<IDeputyProjectsResponse> => {
    try {
        const result = await axiosCachePostRequest<IHasFilters, IDeputyProjectsResponse>(
            ApiPaths.DeputyProjects, request);
        return result.data;
    } catch (error) {
        throw Error(error);
    }
};
