import React, { useState } from 'react';
import { Avatar, Button, Card, Skeleton } from 'antd';
import { useLocation } from 'react-router-dom';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IStatesman, StatesmanTypes } from 'api/types/v1.0/statesman';
import { IState } from 'pages/StatesmanPage/hooks/types';
import { IAccount } from 'types/account';
import { hasDeputyRole } from 'helpers/account';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import { ReactComponent as RateIcon } from '../../assets/Union.svg';
import ModalOffer from './components/ModalOffer';
import './StatesmanCard.scss';

export interface IStatesmanCardProps {
    statesman: IStatesman;
    statesmanType: StatesmanTypes;
    user: IAccount | null;
    pointRating: number;
}

export const StatesmanCard: React.FC<IStatesmanCardProps> = (props) => {
    const location = useLocation();
    const statesmanPosition = getStatesmanPositionByType(props.statesmanType);
    const { isLoading } = useSelector((state: IState) => ({ isLoading: state.isLoading }));
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const hasDeputyRoleVar = hasDeputyRole(props.user);
    const isUnauthorized = props.user === null;
    const handleOnClickOpenModal = () => {
        setIsOpenModal(true);
    };

    return (
        <>
            {isLoading ? (
                <Card className={'statesman-loading-card'}>
                    <Skeleton loading={isLoading} avatar title active />
                </Card>
            ) : (
                <>
                    <div className={'statesman-card'}>
                        <div>
                            <Avatar
                                className={'statesman-avatar'}
                                src={props.statesman.deputy_avatar}
                                alt={props.statesman.surname_n_p}
                                icon={<UserOutlined />}
                            />
                        </div>
                        <div className={'statesman-info'}>
                            <div className={'statesman-title'}>{props.statesman.surname_n_p}</div>
                            <div className={'statesman-position'}>{statesmanPosition}</div>
                            <ModalOffer stateIsOpen={[isOpenModal, setIsOpenModal]} deputyId={props.statesman.Id} />
                            {props.statesmanType === StatesmanTypes.MunicipalDeputy && (
                                <div className={'statesman-rating'}>
                                    <RateIcon className="rate-icon" />
                                    <span>{`Рейтинг ${props.pointRating}%`}</span>
                                </div>
                            )}
                            {props.statesmanType === StatesmanTypes.RegionalCoordinator && (
                                <div className={'statesman-region'}>{props.statesman.region_name}</div>
                            )}
                        </div>
                        {location.pathname.split('/')[2] !== 'deputy' && !hasDeputyRoleVar && (
                            <div className={'feedback-button-wrapper'}>
                                <Button
                                    type={'primary'}
                                    shape={'circle'}
                                    icon={<MailOutlined className={'icon'} />}
                                    className={'icon-button'}
                                    onClick={() => isUnauthorized
                                        ? redirect(AuthHelper.getAuthorizeCodeUrl(), false)
                                        : handleOnClickOpenModal()}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

const getStatesmanPositionByType = (type: StatesmanTypes) => {
    let statesmanPosition;
    switch (type) {
        case StatesmanTypes.MunicipalDeputy:
            statesmanPosition = 'Ответственный от Единой России';
            break;
        case StatesmanTypes.RegionalCoordinator:
            statesmanPosition = 'Региональный координатор';
            break;
        case StatesmanTypes.FederalDeputy:
            statesmanPosition = 'Федеральный координатор';
            break;
    }

    return statesmanPosition;
};
