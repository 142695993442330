import { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { handleApiError } from 'api/v1.0/common';
import { getFavoritePoint } from 'api/v1.0/favoritePoint';
import { IFavoritePointItem, IFavoritePointRequest } from 'api/types/v1.0/favoritePoint';
import {
    setIsExpanded,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/reducer';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useFavoritePage = (userId?: string) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const globalDispatch = useDispatch();

    const refresh = (input?: string) => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: state.favorites ? state.favorites.length : 0,
            limit: 10,
            ObjectName: input,
            CitizenId: userId,
        } as IFavoritePointRequest;

        void getFavoritePoint(request)
            .then((response) => {
                dispatch(actionCreators.setFavorites(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
                dispatch(actionCreators.setLoading(false));
            }).catch((exception) => {handleApiError(exception);
                dispatch(actionCreators.setLoading(false));});
    };

    const setFavoritesByInput = (input: string) => {
        dispatch(actionCreators.deleteFavorites());
        refresh(input);
    };

    const deleteFavorite = (favoriteId: number) => {
        if (state.favorites) {
            dispatch(actionCreators.deleteFavorite(favoriteId));
        }
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    const setFavorites = (favorites: IFavoritePointItem[] | null) => {
        dispatch(actionCreators.setFavorites(favorites));
    };

    useEffect(() => {
        globalDispatch(setIsExpanded(false));
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...state,
        refresh,
        setFavoritesByInput,
        deleteFavorite,
        setTotalCount,
        setFavorites,
    };
};

export default useFavoritePage;
