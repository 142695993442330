import React, { ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import useScrollingToHash from 'hooks/useScrollingToHash';
import BadLink from 'pages/BadLink';
import NotFound from 'pages/NotFound';
import { Pages } from 'constants/links';
import Layout from 'components/Layout';
import { CommunityFormationPage } from 'pages/CommunityFormation';
import CommonQuestionsPage from 'pages/CommonQuestions';
import { Chat, ChatsPage } from 'pages/Chat';
import { ActivitiesSchedulerPage } from 'pages/ActivitiesScheduler';
import { DeputyProfilePage } from 'pages/DeputyProfile';
import NewsGeneratorPage from 'pages/NewsGenerator';
import { PointConstructorPage } from 'pages/PointConstructor';
import UserSettingsPage from 'pages/UserSettings';
import FavoritePage from 'pages/Favorite/FavoritePage';
import CitizenGroupsPage from 'pages/CitizenGroups/CitizenGroupsPage';
import CitizenEventsPage from 'pages/CitizenEvents/CitizenEventsPage';
import NotificationCenterPage from 'pages/NotificationCenter';
import QuestionsAndSuggestsPage from 'pages/QuestionsAndSuggests/QuestionsAndSuggestsPage';
import { ActivitiesConstructorPage } from 'pages/ActivitiesScheduler/components/ActivitiesConstructor/ActivitiesConstructor';
import { IAccount } from 'types/account';
import {
    hasCitizenRole,
    hasCoordinatorRole,
    hasDeputyRole,
    hasMunicipalRole,
} from '../helpers/account';
import { RootState } from './root/rootReducer';

const wrapPageToLayout =
    (PageComponent: ComponentType, PostHeader: JSX.Element | undefined = undefined) =>
        () =>
            (
                <Layout postHeader={PostHeader}>
                    <PageComponent />
                </Layout>
            );

const PrivateApp: React.FC = () => {
    useScrollingToHash();
    const user = useSelector((state: RootState) => state.account.user);
    const routes = generateRoutes(user);

    return (
        <Switch>
            {routes}
        </Switch>
    );
};

const generateRoutes = (user: IAccount | null) => {
    const userIsCitizen = hasCitizenRole(user);
    const userIsMunicipalDeputy = hasMunicipalRole(user);
    const userIsCoordinator = hasCoordinatorRole(user);
    const userIsDeputy = hasDeputyRole(user);

    const routes = [];

    if (userIsCoordinator) {
        routes.push(
            <Route path={Pages.QuestionsAndSuggestions.url} component={wrapPageToLayout(QuestionsAndSuggestsPage)} />
        );
    }

    if (userIsMunicipalDeputy) {
        routes.push(
            <Route path={Pages.CommunityFormation.url} component={wrapPageToLayout(CommunityFormationPage)} />
        );
    }

    if (userIsDeputy) {
        routes.push(
            <Route path={Pages.ActivitiesScheduler.url} component={wrapPageToLayout(ActivitiesSchedulerPage)} />,
            <Route path={Pages.ActivitiesCreate.url} component={wrapPageToLayout(ActivitiesConstructorPage)} />,
            <Route path={Pages.ActivitiesEdit.url} component={wrapPageToLayout(ActivitiesConstructorPage)} />,
            <Route path={`${Pages.PointConstructor.url}/:id`} component={wrapPageToLayout(PointConstructorPage)} />,
            <Route path={`${Pages.PointConstructor.url}`} component={wrapPageToLayout(PointConstructorPage)} />,
            <Route path={`${Pages.DeputyProfile.url}`} component={wrapPageToLayout(DeputyProfilePage)} />,
            <Route path={Pages.NewsGenerator.url} component={wrapPageToLayout(NewsGeneratorPage)} />,
            <Route path={Pages.QuestionsAndAnswers.url} component={wrapPageToLayout(CommonQuestionsPage)} />
        );
    }

    if (userIsCitizen) {
        routes.push(
            <Route path={Pages.NotificationCenter.url} exact component={wrapPageToLayout(NotificationCenterPage)} />,
            <Route path={Pages.UserSettings.url} component={wrapPageToLayout(UserSettingsPage)} />,
            <Route path={Pages.Favorite.url} component={wrapPageToLayout(FavoritePage)} />,
            <Route path={Pages.CitizenEvents.url} component={wrapPageToLayout(CitizenEventsPage)} />,
            <Route path={Pages.CitizenGroups.url} component={wrapPageToLayout(CitizenGroupsPage)} />
        );
    }

    if (!isDesktop) {
        routes.push(
            <Route path={Pages.Chats.url} exact component={wrapPageToLayout(ChatsPage)} />,
            <Route path={`${Pages.Chat.url}/:selectedChatId`} component={wrapPageToLayout(Chat)} />
        );
    }

    routes.push(
        <Route path={Pages.BadLink.url} component={wrapPageToLayout(BadLink)} />,
        <Route component={wrapPageToLayout(NotFound)} />
    );

    return routes;
};

export default PrivateApp;
