import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { IDeputyListRequest } from 'api/types/v1.0/deputyList';
import { getDeputyList } from 'api/v1.0/deputyList';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

const useDeputyFilter = () => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = (forceRefresh?: boolean) => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: forceRefresh || !state.deputies ? 0 : state.deputies.length,
            limit: 10,
            search: forceRefresh ? null : state.searchInput,
        } as IDeputyListRequest;

        void getDeputyList(request)
            .then((response) => {
                dispatch(actionCreators.setDeputies(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
                dispatch(actionCreators.setLoading(false));
            }).catch((exception) => {handleApiError(exception);
                dispatch(actionCreators.setLoading(false));});
    };

    const onSearch = (value: string) => {
        dispatch(actionCreators.deleteDeputies());
        dispatch(actionCreators.setSearchInput(value));
    };

    const clearSearchInput = () => {
        dispatch(actionCreators.deleteDeputies());
        dispatch(actionCreators.setSearchInput(null));
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (state.searchInput !== null) {
                refresh();
            }
        }, 500);

        return () => clearTimeout(getData);
    }, [state.searchInput]);

    return {
        ...state,
        refresh,
        onSearch,
        clearSearchInput,
    }
}

export default useDeputyFilter;
