import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import {
    ActionsType,
    IState,
} from './types';

export const defaultState: IState = {
    info: [],
    isLoading: false,
    error: null,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.EDIT_POINT_MEDIA_FETCH:
        case actionTypes.EDIT_POINT_MEDIA_ADD:
        case actionTypes.EDIT_POINT_MEDIA_REMOVE:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.EDIT_POINT_MEDIA_LOAD:
            return {
                ...state,
                isLoading: false,
            };

        case actionTypes.EDIT_POINT_MEDIA_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case actionTypes.EDIT_POINT_MEDIA_SET:
        {
            return {
                ...state,
                info: action.payload,
            };
        }

        default:
            return state;
    }
};

export default reducer;
