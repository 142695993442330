import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App/root/rootReducer';
import { handleApiError } from 'api/v1.0/common';
import { addCitizenVoteForInterview } from 'api/v1.0/CitizenVotePoll';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import { getCitizenEventsFilter } from 'api/bars-request-objects';
import { getCitizenEvents } from 'api/v1.0/citizenEvents';
import {
    updateInterview as updateMenuInterview,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/reducer';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

const useCitizenEventItem = (initialInterview: IInterview, eventId: number) => {
    useEffect(() => {
        setInterview(initialInterview);
        //eslint-disable-next-line
    }, []);

    const [eventItemState, dispatch] = useReducer(reducer, defaultState);
    const { user } = useSelector((state: RootState) => state.account);

    const globalDispatch = useDispatch();

    const sendVote = (answerId: number, interviewId: number) => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                'functionArguments[0]': Number(user?.sub),
                'functionArguments[1]': answerId,
                'functionArguments[2]': interviewId,
            };

            void addCitizenVoteForInterview(request)
                .then(() => {
                    const secondRequest = {
                        dataFilter: getCitizenEventsFilter(user?.sub as string, eventId),
                    };

                    void getCitizenEvents(secondRequest)
                        .then((secondResponse) => {
                            const [citizenEvent] = secondResponse.data;
                            dispatch(actionCreators.setInterview(citizenEvent.interview));
                            dispatch(actionCreators.setLoading(false));
                            globalDispatch(updateMenuInterview(eventId, citizenEvent.interview));
                        });
                });
        } catch (exception) {
            dispatch(actionCreators.setLoading(false));
            handleApiError(exception);
        }
    };

    const setIsExpanded = (isExpanded: boolean) => {
        dispatch(actionCreators.setIsExpanded(isExpanded));
    };

    const setInterview = (interview: IInterview | null) => {
        dispatch(actionCreators.setInterview(interview));
    };

    return {
        ...eventItemState,
        setIsExpanded,
        sendVote,
    };
};

export default useCitizenEventItem;
