import axios from 'axios';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest, getAxiosConfigAuthToken } from 'utils/axiosClient';
import { ISettingsRequest } from 'pages/UserSettings/components/UserSettingsForm/SetSettingsData';
import { IUserSettings } from '../types/v1.0/userSettings';

export const getUserSettings = async () => {
    const res = await axios.get<IUserSettings>(ApiPaths.UserInfo, getAxiosConfigAuthToken());
    return res.data;
};

export const updateSettings = async (data: ISettingsRequest) => {
    await axiosPostRequest(ApiPaths.CitizenUpdate, { modelData: data, clientTimezoneParams: {
        ClientTimeZoneOffset: new Date().getTimezoneOffset() / 60 },
    });
};
