import React, { useState, useEffect } from 'react';
import { Avatar, Divider, Skeleton } from 'antd';
import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import FolkButton from 'ui-kit/FolkButtons';
import { getRegionalCoordinatorDeputiesPoints } from 'api/v1.0/regionalCoordinatorDeputiesPoints';
import { IRegionalCoordinatorDeputiesPoint } from 'api/types/v1.0/regionalCoordinatorDeputiesPoints';
import { handleApiError } from 'api/v1.0/common';
import { IRegionalCoordinatorDeputy } from 'api/types/v1.0/regionalCoordinatorDeputies';
import { RootState } from 'App/root/rootReducer';
import { DeputySortType, ViewFrom } from '../DeputyPoints/hooks/types';
import DeputyPoint from '../DeputyPoint';
import './Deputy.scss';
import '../RegionalCoordinator/RegionCoordinator.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export interface IDeputyProps
{
    data: IRegionalCoordinatorDeputy,
    compact?: boolean,
    changePoint: (point: number) => void;
    clearPoints: () => void;
    selectedPoints: number[];
    view: ViewFrom;
}

export const Deputy: React.FC<IDeputyProps> = (props:IDeputyProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deputies, setDeputies] = useState<IRegionalCoordinatorDeputiesPoint[]>();
    const { user } = useSelector((state: RootState) => state.account);
    const userId = parseInt(user?.sub as string);
    const userRole = user?.role;

    useEffect( () => {
        if (isOpen) {
            setIsLoading(true);
            getRegionalCoordinatorDeputiesPoints({
                deputyId: props.data.municipal_response_id,
                coordinatorId: props.data.Id,
                userId: userId,
                userRole: userRole,
                sortType: DeputySortType.rating }, true)
                .then((result) =>{
                    setDeputies(result.data);
                    setIsLoading(false);
                })
                .catch((err) => (handleApiError(err)));
        }
    },[isOpen, props.data.Id, props.data.municipal_response_id, userId, userRole]);

    return (
        <div className={isOpen ? 'backgroundSelected' : ''}>
            <div className='rowDeputy'>
                <div>
                    <Avatar
                        size={45}
                        className={'listAvatar-deputy'}
                        alt={props.data.surname_n_p}
                        icon={<UserOutlined />}
                        src={props.data?.preview}
                    />
                </div>
                <div className={`descColumn ${props.view}`}>
                    <div className={'deputyName'}>{props.data.surname_n_p}</div>
                    {props.data.rating}% общий рейтинг<br />
                </div>
                <div className='arrowButton'>
                    <FolkButton
                        type={'default'}
                        onClick={()=>{setIsOpen(!isOpen);}}
                        icon={isOpen ? <UpOutlined color='$color-folk-main' /> : <DownOutlined color='$color-folk-main' />}
                    />
                </div>
            </div>
            <Divider className='deputyDivider' />
            <div>
                {isOpen && deputies?.map((point) =>
                    <DeputyPoint data={point} key={point.object_id} compact={props.compact}
                        changePoint={props.changePoint}
                        isSelected={props.selectedPoints.includes(point.object_id)} />)}
                {isLoading && <Skeleton paragraph={{ rows: 2 }} active />}
            </div>
        </div>
    );
};

export default Deputy;
