import React from 'react';
import styles from './CardTitle.module.scss';

interface ICardTitle {
    cardTitle: string;
    actions?: React.ReactNode | React.ReactNode[];
}

const CardTitle: React.FC<ICardTitle> = (props) => {
    return (
        <div className={styles['cardTitleBody']}>
            <span className={styles['cardTitleText']}>{props.cardTitle}</span>
            <div className={styles['cardTitleActionBar']}>
                {props.actions}
            </div>
        </div>
    );
};

export default CardTitle;
