import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getPointInfoAsync } from 'api/v1.0/pointInfo';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

export interface IProps {
    id: number;
}

const usePointInfoText = ({ id }: IProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getPointInfoAsync({ id: id })
            .then((response) =>
                dispatch(actionCreators.setPointInfo(response.data)))
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
    }, [id]);

    useEffect(() => {
        if (id > 0) {
            refresh();
        }
    }, [refresh, id]);

    return {
        ...state,
        refresh,
    };
};

export default usePointInfoText;
