import { handleApiError } from 'api/v1.0/common';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { MAX_INT } from 'constants/numbers';

export const getOfferNPHashtagList = async () => {
    try {
        const response = await axiosCachePostRequest(ApiPaths.OfferNPHashtagList, {
            start: 0,
            limit: MAX_INT,
        });

        return response.data;
    } catch (exception) {
        handleApiError(exception);
    }
};
