import { INewsItem } from 'api/types/v1.0/news';
import { InferValueTypes } from 'utils/types';
import * as actionCreators from './actionCreators';

export interface IState {
    federalNews: INewsItem[] | null;
    regionalNews: INewsItem[] | null;
    isLoading: boolean;
    totalCount: number;
}

export interface IProps {
    news: INewsItem[];
    isLoading: boolean;
}

export const enum COUNT_OF_NEWS_TO_SHOW {
    Desktop = 3,
    Mobile = 3,
}

export enum TypeOfNews {
    Federal = 0,
    Regional = 1,
}

export type NewsType = ReturnType<InferValueTypes<typeof actionCreators>>;
