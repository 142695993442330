export const Pages = {
    RegistrationLink: {
        name: 'registration',
        url: '/registration',
    },
    RegistrationCallbackLink: {
        name: 'callback',
        url: '/callback',
    },
    BadLink: {
        name: 'bad-link',
        url: '/bad-link',
    },
    NotificationCenter: {
        name: 'notification-center',
        url: '/notification-center',
    },
    DeputyLink: {
        name: 'deputy',
        url: '/deputy',
        municipalDeputy: 'deputy',
        flowCoordinator: 'flow-coordinator',
        regionalCoordinator: 'regional-coordinator',
    },
    PointInfo: {
        name: 'point-info',
        url: '/point-info',
    },
    ProjectInfo: {
        name: 'project-info',
        url: '/project-info',
    },
    Main: {
        name: 'main',
        url: '/',
    },
    UserProfile: {
        name: 'user-profile',
        url: '/user-profile',
    },
    UserSettings: {
        name: 'user-settings',
        url: '/user-settings',
    },
    DeputyProfile: {
        name: 'deputy-profile',
        url: '/deputy-profile',
    },
    FederalCoordinatorProfile: {
        name: 'federal-coordinator-profile',
        url: '/federal-coordinator-profile',
    },
    RegionCoordinatorProfile: {
        name: 'region-coordinator-profile',
        url: '/region-coordinator-profile',
    },
    PointConstructor: {
        name: 'point-constructor',
        url: '/point-constructor',
    },
    ActivitiesScheduler: {
        name: 'activities-scheduler',
        url: '/activities-scheduler',
    },
    ActivitiesCreate: {
        name: 'activities-create',
        url: '/activities-create',
    },
    ActivitiesEdit: {
        name: 'activities-edit',
        url: '/activities-edit',
    },
    NewsGenerator: {
        name: 'news-generator',
        url: '/news-generator',
    },
    RedirectMobile: {
        name: 'redirect-mobile',
        url: '/redirect-mobile',
    },
    CommunityFormation: {
        name: 'community-formation',
        url: '/community-formation',
    },
    QuestionsAndAnswers: {
        name: 'questions-and-answers',
        url: '/questions-and-answers',
    },
    Favorite: {
        name: 'favorite',
        url: '/favorite',
    },
    CitizenGroups: {
        name: 'citizen-groups',
        url: '/citizen-groups',
    },
    CitizenEvents: {
        name: 'citizen-events',
        url: '/citizen-events',
    },
    News: {
        name: 'news',
        url: '/news',
    },
    NewsDetails: {
        name: 'news-details',
        url: '/news-details',
    },
    ReasonToRegistration: {
        name: 'reason-to-registration',
        url: '/reason-to-registration',
    },
    Hints: {
        name: 'hints',
        url: '/hints',
    },
    QuestionsAndSuggestions: {
        name: 'questions-and-suggestions',
        url: '/questions-and-suggestions',
    },
    Chats: {
        name: 'chats',
        url: '/chats',
    },
    Chat: {
        name: 'chat',
        url: '/chat',
    },
};
