//TODO: set urls when ready.
export const ExternalRedirectLinks = {
    AndroidAppStore: {
        url: 'https://play.google.com/store/apps',
    },

    IosAppStore: {
        url: 'https://www.apple.com/ru/app-store/',
    },

    FolkApp: {
        url:'folk-app://app.example.com/',
        redirectPath: 'redirecturl',
        selectedObject: 'selected_object',
        objectIdKey: 'objectId',
        newsPage: 'news_page',
        newsIdKey: 'newsId',
    },

    AndroidAppMobileStore: {
        url: 'market://details',
    },

    IosAppMobileStore: {
        url: 'itms-apps://itunes.apple.com/app/',
    },
};
