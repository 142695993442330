import React from 'react';
import { Card, Spin, Timeline } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from 'ui-kit/Icon';
import { getDayMonthYearString } from 'utils/dateTime';
import { IPointControlPoint } from 'api/types/v1.0/pointControlPoints';
import usePointControlPoints from './hooks/usePointControlPoints';
import styles from './PointControlPoints.module.scss';

export interface IProps {
    id: number;
}

const PointControlPoints: React.FC<IProps> = ({ id }: IProps) => {
    const { controlPoints, isLoading } = usePointControlPoints({ id: id, limit: 200 });

    const getPointDate = (point: IPointControlPoint) => {
        if (!point.finished && point.date_plan) {
            return getDayMonthYearString(new Date(point.date_plan));
        }

        if (point.finished) {
            if (point.date_fact) {
                return getDayMonthYearString(new Date(point.date_fact));
            }

            if (point.date_plan) {
                return getDayMonthYearString(new Date(point.date_plan));
            }
        }
    };

    return (
        <div className={styles['wrapper']}>
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Card className={styles['status-content']}>
                    <div className={styles['st-title']}>Этапы работ:</div>
                    <Timeline className={styles['st']}>
                        {controlPoints?.map((point, index) =>
                            <Timeline.Item
                                key={index}
                                dot={point.finished > 0 ?
                                    <Icon className={styles['st-check-icon']} type={'Check'} /> :
                                    <div className={styles['st-check-empty']} />}>
                                <div>
                                    <span className={styles['st-date']}>
                                        {getPointDate(point)}
                                    </span>
                                    <span className={styles['st-text']}> {point.checkpoint_name || point.newCheckpointName} </span>
                                </div>
                            </Timeline.Item>)}
                    </Timeline>
                </Card>
            </Spin>
        </div>);
};

export default PointControlPoints;
