interface IRequest {
    dataFilter: unknown,
    sort: unknown;
    start?: number;
    limit? : number;
}

export const getCoordinatorPageRequest = (request:IRequest) => {
    return {
        dataFilter : request.dataFilter,
        sort: request.sort,
        start: request.start,
        limit: request.limit,
    };
};
