export type SortType = 'rating' | 'title' | 'region' | 'project_name' | 'dateTime';
export type SortDirection = 'DESC' | 'desc' | 'ASC' | 'asc';

export interface IBarsSort {
    property: string | SortType;
    direction: SortDirection;
}

export interface IHasSorting {
    sort?: IBarsSort[] | null;
}

export const getCitizenEventsSort = () => {
    return [
        {
            property: 'event_date_time',
            direction: 'DESC',
        },
    ];
};

export const getCitizenFavoritesSort = () => {
    return [
        {
            property: 'ObjectId_object_name',
            direction: 'ASC',
        },
    ];
};

export const getDeputyQuestionsSort = () => {
    return [
        {
            property: 'date_time',
            direction: 'DESC',
        },
    ];
};

