import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getPointControlPointsAsync } from 'api/v1.0/pointControlPoints';
import { IPointControlPoint } from 'api/types/v1.0/pointControlPoints';

export interface IProps
{
    id?: number;
    limit?: number,
    page?: number,
    start?: number,
}

const usePointControlPoints = ({ id, limit, page, start } : IProps) => {
    const [controlPoints, setControlPoints] = useState<IPointControlPoint[] | null>(null);
    const [isLoading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        setLoading(true);

        getPointControlPointsAsync({
            id: id ? id : 0,
            limit: limit,
            page: page,
            start: start,
            sort: [{ property: 'DateFact', direction: 'ASC' }],
        })
            .then((response) => setControlPoints(response.data))
            .catch((e) => handleApiError(e))
            .finally(() => setLoading(false));
    }, [id, limit, page, start]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        controlPoints,
        isLoading,
        refresh,
    };
};

export default usePointControlPoints;
