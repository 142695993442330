import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

interface IFile {
    name: string;
}

export const getBeforeFileUploadHandler = (
    files: RcFile[] | File[] | IFile[],
    maxFiles: number,
    onUpload: (fileUrl: string, file: RcFile) => void,
    duplicatesForbidden = true,
    availableFileTypes?: string[],
    additionalCheckFileInvalid?: (file: RcFile) => boolean | string
) => {
    const showErrorMessage = (msgText: string) => {
        // .error() возвращает PromiseLike, у которого нет .catch() метода, поэтому не можем обрабоботать выполнение.
        /* eslint-disable-next-line */
        message.error({
            content: msgText,
            style: {
                marginTop: '90vh',
            },
        });
    };

    return (file: RcFile) => {
        const moreThenMaxEnabledFilesUploaded = files.length >= (maxFiles - files.length);
        const fileDuplicated = duplicatesForbidden && files.find((img) => file.name === img.name);
        if (moreThenMaxEnabledFilesUploaded || fileDuplicated) {
            return Upload.LIST_IGNORE;
        }

        const fileInvalidOrErrorMessage = additionalCheckFileInvalid && additionalCheckFileInvalid(file);
        if (fileInvalidOrErrorMessage) {
            if (typeof fileInvalidOrErrorMessage === 'string') {
                showErrorMessage(fileInvalidOrErrorMessage);
            }

            return Upload.LIST_IGNORE;
        }

        if (availableFileTypes && !availableFileTypes.includes(file.type)) {
            const readableFileTypes = availableFileTypes.map(
                (type) => type.includes('/') ? type.split('/')[1] : type
            );
            const msgText = `Вы можете загружать только файлы с расширением: ${readableFileTypes.join(', ')}`;
            showErrorMessage(msgText);
            return Upload.LIST_IGNORE;
        }

        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => onUpload(e.target?.result as string, file);

        reader.readAsDataURL(file);

        return false;
    };
};
