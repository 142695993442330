import * as yup from 'yup';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';

export const registrationValidationSchema = yup.object().shape(
    {
        name: yup.string().required(`${alerts.REQUIRED}`),
        surname: yup.string().required(`${alerts.REQUIRED}`),
        patronymic: yup.string().required(`${alerts.REQUIRED}`),
        birthdate: yup
            .string()
            .nullable()
            .required(`${alerts.REQUIRED}`),
        phone: yup
            .string()
            .required(`${alerts.REQUIRED}`),
        email: yup
            .string()
            .email(`${alerts.EMAIL}`)
            .nullable(),
        region: yup.string().required(`${alerts.REQUIRED}`),
        address: yup
            .string()
            .notRequired(),
    }
);
