import React, { useEffect, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useActivityList } from 'hooks/useActivityList';
import Icon from '../../Icon';
import { IFilterStyleType } from '../ProjectFilter';
import styles from '../SelectorFilter.module.scss';
import { compareFilterSearchOptions } from 'utils/comparers';

const { Option } = Select;

export interface IActivityFilterProps {
    userId: string;
    placeholder: string;
    pointId: number | undefined;
    projectId: number | undefined;
    defaultActivityId?: number;
    type?: IFilterStyleType;
    onChange: (activityId: number | null) => void;
    alwaysShowSuffix?: boolean;
}

export const ActivityFilter: React.FC<IActivityFilterProps> = (props) => {
    const { userId, projectId, pointId, defaultActivityId, onChange } = props;
    const [activityId, setActivityId] = useState<number | undefined>(defaultActivityId)
    const { activities, loading } = useActivityList({ userId, pointId, projectId });
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            setActivityId(undefined);
            onChange(null);
        }
    }, [projectId, pointId]);

    const getSuffixIcon = () => {
        return loading
            ? <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <Icon className={styles['icon']} type={'DropDownArrow'} />;
    };

    return (
        <div className={props.type === IFilterStyleType.outlined ? styles['selector-wrapper-secondary'] : styles['selector-wrapper']}>
            <Select
                className={styles['selector']}
                suffixIcon={(!activityId || props.alwaysShowSuffix) && getSuffixIcon()}
                value={loading ? undefined : activityId}
                showSearch
                placeholder={props.placeholder}
                optionFilterProp='children'
                allowClear
                onChange={
                    (value: number | undefined) => {
                        setActivityId(value);
                        onChange(value ?? null);
                    }
                }
                filterOption={compareFilterSearchOptions}
            >
                {
                    activities && activities.map((activity) =>
                        <Option
                            key={activity.object_event_id}
                            value={activity.object_event_id}
                        >
                            {activity.event_name}
                        </Option>
                )
            }
            </Select>
        </div>
    );
};
