import { Reducer } from 'redux';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import * as actionTypes from './actionTypes';
import { ActionsType } from './types';

export interface IProjectsList {
    projects: IProjectListItem[];
}

const defaultState: IProjectsList = {
    projects: [],
};

const reducer: Reducer<IProjectsList, ActionsType> = (state, action): IProjectsList => {
    state = state || defaultState;

    if (action.type === actionTypes.SET_LIST_PROJECTS) {
        return {
            ...state,
            projects: action.payload,
        };
    }

    return state;
};

export default reducer;
