import React, { useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from 'ui-kit/Icon';
import useDeputyFilter from './hooks';
import { IFilterStyleType } from '../ProjectFilter';
import { InfiniteScrollSelect } from './components/InfiniteScrollSelect';
import styles from '../SelectorFilter.module.scss';

export interface IDeputyFilterProps {
    defaultDeputyId?: number;
    onChange?: (deputyId: number | null) => void;
    placeholder?: string;
    disabled?: boolean;
    type?: IFilterStyleType;
    managedValue?: number | null;
    alwaysShowSuffix?: boolean;
    needClearDeputyId?: boolean;
}

export const DeputyFilter: React.FC<IDeputyFilterProps> = (props) => {
    const { deputies, refresh, totalCount, isLoading, onSearch, clearSearchInput } = useDeputyFilter();

    const { defaultDeputyId, onChange } = props;
    const [selectedDeputyId, setSelectedDeputyId] = useState<number | undefined>(defaultDeputyId);

    const value = !!props.managedValue ? props.managedValue : (props.needClearDeputyId ? undefined : selectedDeputyId);

    const getSuffixIcon = () => {
        return isLoading
            ? <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <Icon className={styles['icon']} type={'DropDownArrow'} />;
    };

    const onClear = () => {
        clearSearchInput();
        refresh(true);
    };

    return (
        <div className={props.type === IFilterStyleType.outlined ? styles['selector-wrapper-secondary'] : styles['selector-wrapper']}>
            <InfiniteScrollSelect
                loading={isLoading}
                data={deputies ?? []}
                hasMore={deputies ? deputies.length < totalCount : false}
                loadMore={refresh}
                className={styles['selector']}
                suffixIcon={(!selectedDeputyId || props.alwaysShowSuffix) && getSuffixIcon()}
                value={isLoading ? undefined : value}
                showSearch
                onSearch={onSearch}
                placeholder={props.placeholder || 'Депутат'}
                optionFilterProp='children'
                allowClear
                onClear={onClear}
                disabled={props.disabled}
                onChange={
                    (newValue: number | undefined) => {
                        setSelectedDeputyId(newValue);
                        if (onChange) {
                            onChange(newValue ?? null);
                        }
                    }
                }
            />
        </div>
    );
};

export default DeputyFilter;
