import React from 'react';
import { Card } from 'antd';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import QuestionsCard from './components/QuestionsCard/QuestionsCard';
import ProgressCard from './components/ProgressCard/ProgressCard';
import './SurveyCard.scss';

interface IProps {
    interview: IInterview;
    isTookPart: boolean;
    onVote: (answerId: number, interviewId: number) => void;
    canVote: boolean;
}

const SurveyCard: React.FC<IProps> = ({ interview, isTookPart, onVote, canVote }) => {
    return (
        <div className={'survey-card-wrapper'}>
            <Card>
                <div className={'questions-card-title'}>
                    <span>Опрос</span>
                </div>
                <div className={'questions-card-description'}>
                    <span>{interview?.question}</span>
                </div>
                {isTookPart || !canVote
                    ? <ProgressCard interview={interview} />
                    : <QuestionsCard interview={interview} onVote={onVote} canVote={canVote} />
                }
            </Card>
        </div>
    )
}

export default SurveyCard;