import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export const citizenProjectSave = async (request: FormData) => {
    try {
        (await axiosPostRequest(ApiPaths.DataUsingSqlFunction, request));
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
