import { handleApiError } from 'api/v1.0/common';
import { getNewsListFilter } from 'api/bars-request-objects';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { INewsFilterValues } from 'pages/News/components/NewsFilter/NewsFilter';

export const getNewsList = async (values: INewsFilterValues, start?: number, limit?: number) => {
    try {
        const response = await axiosPostRequest(ApiPaths.NewsList, {
            dataFilter: getNewsListFilter({ ...values }),
            start: start || 0,
            limit: limit || 10,
        });

        return response.data;
    } catch (exception) {
        handleApiError(exception);
    }
};
