import { IRegionalCoordinatorProfile } from 'api/types/v1.0/regionalCoordinatorProfile';
import {
    DEPUTY_PROFILE_FETCH,
    DEPUTY_PROFILE_LOAD,
    DEPUTY_PROFILE_SET,
    DEPUTY_PROFILE_ERROR,
} from './actionTypes';

export const deputyProfileFetch = () =>
    ({ type: DEPUTY_PROFILE_FETCH } as const);

export const deputyProfileLoad = () =>
    ({ type: DEPUTY_PROFILE_LOAD } as const);

export const deputyProfileSet = (profile: IRegionalCoordinatorProfile) =>
    ({ type: DEPUTY_PROFILE_SET, payload: profile } as const);

export const deputyProfileError = (error: string) =>
    ({ type: DEPUTY_PROFILE_ERROR, payload: error } as const);
