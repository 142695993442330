import React from 'react';
import { Menu } from 'antd';
import { DeputySortType, PointSortType, ViewType } from '../DeputyPoints/hooks/types';
import './DeputySortPoints.scss';

export interface IProps {
    onKeyChange: (activeKey: PointSortType) => void;
    onDeputyKeyChange: (activeKey: DeputySortType) => void;
    onViewKeyChange: (activeViewKey: ViewType) => void;
}

const DeputySortPoints: React.FC<IProps> = (props) => {
    const setByDeputy = () => {
        props.onViewKeyChange(ViewType.deputy);
    };

    const setByList = () => {
        props.onViewKeyChange(ViewType.list);
    };

    const setByAlphabet = () => {
        props.onKeyChange(PointSortType.title);
        props.onDeputyKeyChange(DeputySortType.surname_n_p);
    };

    const setByRating = () => {
        props.onKeyChange(PointSortType.rating);
        props.onDeputyKeyChange(DeputySortType.rating);
    };

    return (
        <div>
            <Menu
                className='sort-menu'
                mode='horizontal'
                defaultSelectedKeys={['list']}
                items={[
                    {
                        className: 'deputy-sort-item',
                        label: 'Список',
                        key: 'list',
                        onClick: (()=>{setByList();}),
                    },
                    {
                        className: 'deputy-tab',
                        label: 'Ответственные',
                        key: 'deputy',
                        onClick: (()=>{setByDeputy();}),
                    },
                ]}>
            </Menu>
            <Menu
                className='sort-menu'
                mode='horizontal'
                defaultSelectedKeys={['rating']}
                items={[
                    {
                        className: 'deputy-sort-item',
                        label: 'По рейтингу',
                        key: 'rating',
                        onClick: (()=>{setByRating();}),
                    },
                    {
                        className: 'deputy-sort-item',
                        label: 'По алфавиту',
                        key: 'title',
                        onClick: (()=>{setByAlphabet();}),
                    },
                ]}>
            </Menu>
        </div>);
};

export default DeputySortPoints;
