import React, { useEffect, useState, useCallback } from 'react';
import {
    Map,
    ObjectManager,
    TypeSelector,
    YMaps,
    ZoomControl,
    SearchControl,
    MapState,
} from 'react-yandex-maps';
import YandexMaps from 'yandex-maps';
import { ICoordinates, IPoint } from 'api/types/v1.0/points';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { mapZoom } from 'constants/mapZoom';
import { isString, isStringWithValue } from 'utils/typeChecking';
import { ISelectedPoint } from 'ducks/selectedPoint';
import mapPointIcon from './assets/point-icon.svg';
import mapClusterIcon from './assets/cluster-icon.png';
import defaultImage from './images/defaultPointImage.jpg';
import './PointsMap.scss';

export interface IMapProps {
    points: IPoint[];
    center: ICoordinates;
    geocodedPoint: string | null;
    selectedPoint: ISelectedPoint | null;
    changeMapCenter: (center: ICoordinates) => void;
    setSearchControlRef: (ref: YandexMaps.control.SearchControl | undefined) => void;
    dropSelectedRegion: () => void;
    onPointClick: (point: IPoint) => void;
}

export const PointsMap: React.FC<IMapProps> = (props) => {
    const [mapState, setMapState] = useState<MapState>({
        center: [props.center.lat, props.center.lng],
        zoom: props.center.zoom || mapZoom.defaultZoomOnCountry,
    });
    const [isClusterBallonOpen, setIsClusterBallonOpen] = useState<boolean>(false);
    const [clusteredPoints, setClusteredPoints] = useState<IPoint[]>([]);
    // eslint-disable-next-line
    const [map, setMap] = useState<any>();
    // eslint-disable-next-line
    const [ymaps, setYmaps] = useState<any>([]);
    const [searchControl, setSearchControl] = useState<YandexMaps.control.SearchControl>();

    const searchControlMeasuredRef = useCallback((instanceRef) => {
        setSearchControl(instanceRef);
    }, []);

    useEffect(() => {
        props.setSearchControlRef(searchControl);
        // eslint-disable-next-line
    }, [props.setSearchControlRef, searchControl]);

    useEffect(() => {
        setMapState((prevState) => {
            return {
                ...prevState,
                center: [props.center.lat, props.center.lng],
                zoom: props.center.zoom || mapZoom.defaultZoomOnCountry,
                bounds: undefined,
            };
        });
    }, [props.center]);

    /* eslint-disable */
    const handleClusterClick = (e: any) => {
        const tempClusteredPoints: IPoint[] = [];
        let pointId = 0;
        e.preventDefault();
        e.stopPropagation();

        if (e.originalEvent.currentTarget._objectManager._mapChildComponent._map._zoom === mapZoom.maxZoom) {
            e.originalEvent.currentTarget._collectionComponent._childList.first.obj.features.map((s: any) => {
                tempClusteredPoints.push(props.points.find((point) => point.id === s.id) as IPoint)
                pointId = s.id;
            })

            setIsClusterBallonOpen(true);
            setClusteredPoints(tempClusteredPoints)
            e.originalEvent.currentTarget._objectManager.objects.balloon.open(pointId);
        }
    }
    /* eslint-disable */

    const handlePointClick = () => {
        setIsClusterBallonOpen(false);
    }

    function closeCurrentBalloon() {
        const close = document.querySelector('ymaps[class$="-balloon__close-button"]') as HTMLElement;
        if (close !== null && close !== undefined) {
            close.click();
        }
        setIsClusterBallonOpen(false);
        setClusteredPoints([]);
    }

    useEffect(() => {
        if (props.geocodedPoint && !props.selectedPoint && ymaps && ymaps.geocode && map && searchControl) {
            const searchControlInputValue = searchControl.state.get('inputValue', {});
            if (isStringWithValue(searchControlInputValue)) {
                return;
            }

            // eslint-disable-next-line
            ymaps.geocode(props.geocodedPoint, { results: 1 })
                // eslint-disable-next-line
                .then(function (res: any) {
                    // eslint-disable-next-line
                    const geoObject = res.geoObjects.properties.get(0);
                    const geoObjectBounds = geoObject.metaDataProperty.GeocoderResponseMetaData.boundedBy;
                    setMapState((prevState) => ({
                        ...prevState,
                        bounds: [[geoObjectBounds[0][1], geoObjectBounds[0][0]], [geoObjectBounds[1][1], geoObjectBounds[1][0]]],
                        // eslint-disable-next-line
                        center: undefined,
                        zoom: undefined,
                    }));

                    const centerCoordinates = map.getCenter();
                    const zoom = map.getZoom();

                    props.changeMapCenter({
                        lng: centerCoordinates[1],
                        lat: centerCoordinates[0],
                        zoom: zoom,
                    });
                });
        }
    }, [props.geocodedPoint, props.selectedPoint, ymaps, map, searchControl]);

    // Переделан переход по карте. Теперь по реализации также как и переход через список.
    // Снимает сразу часть проблем с неактуальным состоянием выбранного объекта
    //
    // Решение взято отсюда, при том там аналогичная проблема
    // https://github.com/gribnoysup/react-yandex-maps/issues/130
    //
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).setPoint = function (id: string) {
        const point = props.points.find((x) => x.id.toString() === id.toString());
        if (point) {
            props.onPointClick(point);

            browserHistory.push(`${Pages.PointInfo.url}/${point.id}`);
        }
    };

    const address = localStorage.getItem('mapAddress');

    useEffect(() => {
        if (searchControl) {
            // Сохраняем ссылку на searchControl для замыкания в saveSearchInput.
            const searchControlRef = searchControl;

            const saveSearchInput = () => {
                const searchControlInputValue = searchControlRef.state.get('inputValue', {});
                localStorage.setItem('mapAddress', isString(searchControlInputValue) ? searchControlInputValue : '');
            };

            // Вызываем saveSearchInput при закрытии/перезагрузке страницы.
            window.addEventListener('beforeunload', saveSearchInput);

            // cleanup функция вызывается как componentWillUnmount.
            return () => {
                saveSearchInput();
                window.removeEventListener('beforeunload', saveSearchInput);
            }
        }
    }, [searchControl]);

    const handleSearchResultSelect = (e: any) => {
        const map = e.originalEvent.target.getMap();

        // Ждем когда карта отрисует выбранный адрес.
        map.action.events.once('end', () => {
            const centerCoordinates = map.getCenter();
            const zoom = map.getZoom();

            props.changeMapCenter({
                lng: centerCoordinates[1],
                lat: centerCoordinates[0],
                zoom: zoom,
            });
        });

        props.dropSelectedRegion();
    };

    return (
        <div>
            <YMaps query={{ lang: 'ru_RU', apikey: '7a7d2718-d3ff-44c3-92eb-9b5df88f85d9' }}>
                <Map
                    className={'yandex-map'}
                    options={{
                        yandexMapDisablePoiInteractivity: true,
                        maxAnimationZoomDifference: Infinity,
                     }}
                    state={{ ...mapState }}
                    onClick={closeCurrentBalloon}
                    modules={['geocode']}
                    onBoundsChange={(e: ymaps.Event) => {
                        mapState.zoom = e.get('newZoom')
                    }}
                    onLoad={(ymapsApi) => setYmaps(ymapsApi)}
                >
                    <ObjectManager
                        options={{
                            clusterize: true,
                        }}
                        clusters={{
                            clusterIcons: [
                                {
                                    href: mapClusterIcon,
                                    size: [48, 48],
                                    offset: [-24, -24],
                                },
                            ],
                        }}
                        instanceRef={(ref: any) => {
                            if (ref) {
                                setMap(ref.getMap());

                                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                                ref.clusters.events.add('click', handleClusterClick);
                                ref.objects.events.add('click', handlePointClick);
                            }
                        }}
                        objects={{
                            iconLayout: 'default#image',
                            iconImageHref: mapPointIcon,
                            iconImageSize: [24, 24],
                            iconImageOffset: [-12, -12],
                            groupByCoordinates: false,
                        }}
                        features={props.points.map((point) => {
                            const objectMedia = point.object_media === '0' ? defaultImage : point.object_media;
                            return {
                                type: 'Feature',
                                id: point.id,
                                geometry: {
                                    type: 'Point',
                                    coordinates: [point.coordinates.lat, point.coordinates.lng],
                                },
                                properties: {
                                    balloonShadow: false,
                                    balloonContent: `${ isClusterBallonOpen
                                        ? `<div class="cluster-balloon-content baloon-content-list">
                                            ${clusteredPoints.map((clusterPoint) => {
                                                return `<div>
                                                    <span class="point-title">${clusterPoint?.title ?? 'Ошибка загрузки данных'}</span>
                                                    <br/>
                                                    <span class="point-address">${clusterPoint?.address ?? 'Ошибка загрузки данных'}</span>
                                                    <div><button onclick="window.setPoint(${clusterPoint?.id});"
                                                        id="redirect-to-point-button"
                                                        type="button"
                                                        class="ant-btn point-info-button"
                                                    >
                                                        Перейти
                                                    </button></div>
                                                    <div class="divider-line"></div>
                                                </div>`
                                            }).join('')}
                                        </div>`
                                        : `<div class="balloon-content">
                                            <div class="point-image">
                                                <img alt='' height="156px" src="${objectMedia}"/>
                                            </div>
                                            <div class="point-title">
                                                <tooltip title='${point.title}'>
                                                    ${point.title ?? 'Ошибка загрузки данных'}
                                                </tooltip>
                                            </div>
                                            <div class="point-address">
                                                <tooltip title='${point.address}'>
                                                    ${point.address ?? 'Ошибка загрузки данных'}
                                                </tooltip>
                                            </div>
                                            <button onclick="window.setPoint(${point.id});"
                                                id="redirect-to-point-button"
                                                type="button"
                                                class="ant-btn point-info-button"
                                            >
                                                Перейти
                                            </button>
                                        </div>`}
                                    `,
                                },
                            };
                        })}
                        modules={['objectManager.addon.objectsBalloon', 'objectManager.addon.objectsHint']}
                    />
                    <SearchControl
                        options={{
                            provider: 'yandex#map',
                            noPlacemark: true,
                            noSuggestPanel: true,
                        }}
                        defaultState={{
                            inputValue: address,
                        }}
                        instanceRef={searchControlMeasuredRef}
                        onResultSelect={handleSearchResultSelect}
                    />
                    <ZoomControl />
                    <TypeSelector />
                </Map>
            </YMaps>
        </div>
    );
};
