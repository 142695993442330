import { Reducer } from 'redux';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import * as actionTypes from './actionTypes';
import { ActionsType } from './types';

export interface IProjectsState {
    selectedProjects: IProjectListItem[];
    settingsProjects: IProjectListItem[];
}

const defaultState: IProjectsState = {
    selectedProjects: [],
    settingsProjects: [],
};

const reducer: Reducer<IProjectsState, ActionsType> = (state, action): IProjectsState => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.HANDLE_SELECT_PROJECT:
        {
            return {
                ...state,
                selectedProjects: action.payload ? [action.payload] : [],
            };
        }

        case actionTypes.HANDLE_SETTINGS_PROJECT:
        {
            return {
                ...state,
                settingsProjects: action.payload ? action.payload : [],
            };
        }

        case actionTypes.RESET_SELECT_PROJECT:
        {
            return defaultState;
        }

        default:
            return state;
    }
};

export default reducer;
