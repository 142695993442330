import { IActivityInterviewDeleteRequest } from 'api/types/v1.0/activityInterviewDelete';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosDeleteNoResponse } from 'utils/axiosClient';

export const deleteActivityInterview = async (request: IActivityInterviewDeleteRequest) => {
    try {
        await axiosDeleteNoResponse<IActivityInterviewDeleteRequest>(ApiPaths.ActivityInterviewDelete, request);
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
