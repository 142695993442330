import { Button } from 'antd';
import React from 'react';
import { InvitationResponses } from '../../../../constants';
import styles from '../../NotificationCard.module.scss';

export interface IProps {
    notificationStatus: number | undefined;
    onAcceptClick: () => void;
    onRejectClick: () => void;
}

const InvitationButtons: React.FC<IProps> = ({
    notificationStatus,
    onAcceptClick,
    onRejectClick,
}) => {
    switch (notificationStatus) {
        case InvitationResponses.accepted:
            return (
                <div className={styles.invitationResponse}>
                    Принято
                </div>
            );
        case InvitationResponses.rejected:
            return (
                <div className={styles.invitationResponse}>
                    Отклонено
                </div>
            );
        case InvitationResponses.notResponded:
            return (
                <div className={styles.invitationRespondButtons}>
                    <Button
                        onClick={onAcceptClick}
                        className={styles.acceptInvitationButton}
                    >
                        Принять
                    </Button>
                    <Button
                        onClick={onRejectClick}
                        className={styles.declineInvitationButton}
                    >
                        Отклонить
                    </Button>
                </div>
            );
        default:
            return null;
    }
};

export default InvitationButtons;
