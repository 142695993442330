import { handleApiError } from 'api/v1.0/common';
import { getGroupMembersListFilter, IBarsFilter, IHasFilters } from 'api/bars-request-objects';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { GetRequest, IGroupMembersResponse, RecoverRequest, RemoveRequest } from 'api/types/v1.0/groupMembersList';

export const getGroupMembersList = async ({ object_id, filter_name, start, noLimit }: GetRequest) => {
    try {
        const filter: {dataFilter: IBarsFilter, start: number, limit?: number} = {
            dataFilter: getGroupMembersListFilter(object_id, filter_name),
            start: start || 0,
        };

        if (!noLimit) {
            filter.limit = 10;
        }

        return await axiosPostRequest<IHasFilters, IGroupMembersResponse>(
            ApiPaths.GroupMembersList,
            filter
        );
    } catch (exception) {
        handleApiError(exception);
    }
};

export const removeGroupMember = async ({ object_id, citizen_id, reason_for_removal, Id }: RemoveRequest) => {
    try {
        const request = {
            object_id: {
                Id: object_id,
            },
            Id,
            citizen_id: {
                Id: citizen_id,
            },
            reason_for_removal,
            remove_from_group: 1,
        };

        const formData = new FormData();
        formData.append('modelData', JSON.stringify(request));

        return await axiosPostRequest(ApiPaths.GroupMembersListRemove, formData);
    } catch (exception) {
        handleApiError(exception);
    }
};

export const recoverGroupMember = async ({ object_id, citizen_id, Id }: RecoverRequest) => {
    try {
        const request = {
            object_id: {
                Id: object_id,
            },
            Id,
            citizen_id: {
                Id: citizen_id,
            },
            remove_from_group: 0,
        };

        const formData = new FormData();
        formData.append('modelData', JSON.stringify(request));

        return await axiosPostRequest(ApiPaths.GroupMembersListRecover, formData);
    } catch (exception) {
        handleApiError(exception);
    }
};
