import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FavoriteIcon } from 'ui-kit/FavoriteIcon/FavoriteIcon';
import { RootState } from 'App/root/rootReducer';
import { addFavoritePoint, deleteFavoritePoint, getFavoritePoint, getFavoritesPoints } from 'api/v1.0/favoritePoint';
import {
    deleteFavorite,
    setFavorites,
    setTotalCount,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/reducer';
import {
    IFavoritePointCreateRequest,
    IFavoritePointDeleteRequest,
    IFavoritePointRequest,
} from 'api/types/v1.0/favoritePoint';
import { handleApiError } from 'api/v1.0/common';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import { PointInfoContext, PointInfoContextType } from '../../context/PointInfoContext';

interface IProps {
    objectId: number | null;
    userId: number | null;
}
export const PointFavorite: React.FC<IProps> = (props) => {
    const { isActive, setIsActive, favoriteId, setFavoriteId, setRefreshFavoritesDueToDeletion } =
        React.useContext(PointInfoContext) as PointInfoContextType;
    const isAuthorized = !!props.userId;
    const [isLoading, setIsLoading] = useState(false);

    const citizenMenuState = useSelector((state: RootState) => state.citizenMenuFavoritesReducer);
    const globalDispatch = useDispatch();

    useEffect(() => {
        const fetch = async () => {
            if (props.userId && props.objectId)
            {
                const request = { CitizenId: props.userId, ObjectId: props.objectId } as IFavoritePointRequest;
                const response = await getFavoritePoint(request);
                if (response.data.length !== 0) {
                    setIsActive(true);
                    setFavoriteId(response.data[0].Id);
                }
                else {
                    setIsActive(false);
                }
            }
        };

        void fetch();
        //eslint-disable-next-line
    }, [props.userId, props.objectId, isActive]);

    const onUnAuthorizedClick = () => {
        redirect(AuthHelper.getAuthorizeCodeUrl(), false);
    };

    const onAuthorizedClick = async () => {
        setIsLoading(true);

        try {
            if (isActive)
            {
                const deleteRequest = { records: [favoriteId] } as IFavoritePointDeleteRequest;
                await deleteFavoritePoint(deleteRequest);
                setIsActive(false);
                globalDispatch(setTotalCount(citizenMenuState.totalCount - 1));
                globalDispatch(deleteFavorite(favoriteId));
                setRefreshFavoritesDueToDeletion(true);
                setIsLoading(false);
            }
            else
            {
                if (props.userId && props.objectId)
                {
                    const createRequest = {
                        modelData: { CitizenId: { Id: props.userId },
                            ObjectId: { Id: props.objectId } } }as IFavoritePointCreateRequest;
                    const response = await addFavoritePoint(createRequest);
                    setFavoriteId(response.data.Id);

                    const request = { CitizenId: props.userId } as IFavoritePointRequest;
                    const sortedFavoritePoints = await getFavoritesPoints(request);

                    globalDispatch(setFavorites(sortedFavoritePoints.data));

                    globalDispatch(setTotalCount(citizenMenuState.totalCount + 1));
                    setIsActive(true);
                    setRefreshFavoritesDueToDeletion(true);
                }
            }
        } catch (e) {
            handleApiError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FavoriteIcon
            disabled={isLoading}
            isActive={isActive}
            onClick={isAuthorized ? onAuthorizedClick : onUnAuthorizedClick}
        />);
};
