import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { handleSelectPoint, ISelectedPoint } from 'ducks/selectedPoint';
import { RootState } from 'App/root/rootReducer';
import { IPoint } from 'api/types/v1.0/points';
import { getProjectList } from 'api/v1.0/projectList';
import { setListProjects } from 'ducks/projectsList/actionCreators';

const useSelectedPoint = () => {
    const selectedPointState = useSelector((state: RootState) => state.selectedPoint);
    const dispatch = useDispatch();
    const projects = useSelector((state: RootState) => state.projectsList.projects);

    const getAllProjects = async () => {
        const response = await getProjectList({ sort:'project_name' });
        dispatch(setListProjects(response.data));
    };

    useEffect(() => {
        void getAllProjects();
    //eslint-disable-next-line
    }, []);

    const setSelectedPoint = (point : IPoint) => {
        const currentProject = projects?.find((x) => x.Id === point.projectId);
        const deputyId = currentProject ? currentProject.deputy_Ref_Id : 0;
        const projectName = currentProject ? currentProject.project_name : '';
        const newSelectedPoint: ISelectedPoint = { projectName: projectName, deputyId:deputyId, ...point };
        dispatch(handleSelectPoint(newSelectedPoint));
    };

    return {
        ...selectedPointState,
        setSelectedPoint,
    };
};

export default useSelectedPoint;
