import { Dispatch } from 'redux';
import { ICitizenGroupCreateResponse, ICitizenGroupItem } from 'api/types/v1.0/citizenGroup';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import {
    IState,
    setGroups,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/reducer';
import {
    setGroupsTotalCount,
} from 'components/Layout/components/LayoutHeader/components/Chat/hooks/actionCreators';

export const updateCitizenMenuGroups = (
    citizenMenuGroupsState: IState,
    dispatch: Dispatch,
    response: ICitizenGroupCreateResponse
) => {
    const addedObjectInfo = {
        Id: response.data.Id,
        object_id: response.data.object_id.Id,
        address: response.data.object_id.address_o,
        object_name: response.data.object_id.object_short_name,
    } as ICitizenGroupItem;

    if (citizenMenuGroupsState.totalCount < COUNT_OF_ELEMENTS_TO_SHOW) {
        dispatch(setGroups([addedObjectInfo]));
    }

    dispatch(setGroupsTotalCount(citizenMenuGroupsState.totalCount + 1));
};
