import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import { useField } from 'formik';
import '../FolkInput/FolkInput.scss'

export interface IProps {
    placeholder: string;
    name: string;
    disabled?: boolean;
    textAlign?: string;
}

export const FolkFormInput: React.FC<IProps> = (props) => {
    const { name } = props;
    const [field, meta, { setValue, setTouched }] = useField<string | undefined>(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            name={props.name}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}>
            <Input
                className={props.textAlign === 'left'
                    ? 'folk-input-left'
                    : 'folk-input'}
                value={field.value}
                onChange={onChange} placeholder={props.placeholder} />
        </Form.Item>);
};
