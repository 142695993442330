import { FC } from 'react';
import { Carousel } from 'antd';
import './FolkCarousel.scss';

interface ICarouselProps {
    content?: JSX.Element[];
}

const carouselSpeed = 5000;

const FolkCarousel: FC<ICarouselProps> = ({ content }) => {
    return (
        <Carousel
            autoplay autoplaySpeed={carouselSpeed}
            pauseOnHover fade draggable
            className={'carousel'}>
            {content}
        </Carousel>
    );
}

export default FolkCarousel;