import React, { useState } from 'react';
import { Card, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import Page from 'components/Page';
import MessageCard from 'ui-kit/MessageCard/ChatMessageCard/MessageCard';
import { IndustryFilter } from 'ui-kit/filters/IndustryFilter';
import { readQuestionsAndSuggests } from 'api/v1.0/questionsAndSuggests';
import { IReadMessageRequest } from 'api/types/v1.0/questionsAndSuggests';
import styles from './QuestionsAndSuggestsPage.module.scss';
import useQuestionsAndSuggestsPage from './hooks';

const QuestionsAndSuggestsPage: React.FC = () => {
    const [economyId, setEconomyId] = useState<number | null>();
    const {
        messages,
        totalCount,
        refresh } = useQuestionsAndSuggestsPage(economyId);

    const readMessage = async (id: number) => {
        const request: IReadMessageRequest = {
            Id: id,
        };
        await readQuestionsAndSuggests(request);
    };

    const sortedMessages = messages?.sort((a, b) => a.dateTime < b.dateTime ? 1 : -1);

    return <Page
        content={
            <Page.Content sidebar={'Main'}>
                <Card className={styles.questionsPageCard}>
                    <div className={styles.questionsHeader}>
                        Обращения
                    </div>
                    <Divider />
                    <div className={styles.questionsPageFilters}>
                        <IndustryFilter onChange={setEconomyId} />
                    </div>
                    <InfiniteScroll
                        dataLength={messages ? messages.length : 0}
                        next={refresh}
                        hasMore={messages ? messages?.length < totalCount : false}
                        loader={<Skeleton paragraph={{ rows: 2 }} active />}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            itemLayout="horizontal"
                            dataSource={sortedMessages ?? undefined}
                            renderItem={(item, index) => (
                                <div key={index}>
                                    {index !== 0 && <Divider />}
                                    <MessageCard readMessage={readMessage} messageInfo={item} />
                                </div>
                            )}
                        />
                    </InfiniteScroll>
                </Card>
            </Page.Content>
        }
    />;
};

export default QuestionsAndSuggestsPage;
