import { axiosPostNoResponse, axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IQuestionsAndSuggestsRequest,
    IQuestionsAndSuggestsResponse,
    IReadMessageRequest,
} from '../types/v1.0/questionsAndSuggests';

export const getQuestionsAndSuggests = async (request: IQuestionsAndSuggestsRequest):
    Promise<IQuestionsAndSuggestsResponse> => {
    try {
        const result = await axiosPostRequest<IQuestionsAndSuggestsRequest, IQuestionsAndSuggestsResponse>(
            ApiPaths.QuestionsAndSuggestions, request);
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};

export const readQuestionsAndSuggests = async (request: IReadMessageRequest) => {
    try {
        await axiosPostNoResponse<IReadMessageRequest>(ApiPaths.ReadQuestionsAndSuggests, request);
    } catch (e) {
        throw new Error('Ошибка при загрузке данных.');
    }
};
