export const getFormatNumbers = (number: number | undefined) => {
    if (!number) {
        return 0;
    }

    const formatNumberToString = String(number);
    const lengthNumber = formatNumberToString.length;
    let resultNumber = formatNumberToString;

    if (lengthNumber > 3 && lengthNumber <= 6) {
        resultNumber = `${Number((number / 1000).toFixed(1))} тыс`;
    } else if (lengthNumber > 6 && lengthNumber <= 9) {
        resultNumber = `${Number((number / 1000000).toFixed(1))} млн`;
    }

    return resultNumber;
};
