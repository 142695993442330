import { Card } from 'antd';
import React from 'react';
import styles from './CitizenMenu.module.scss';
import FavoritesCitizenMenuList from './components/FavoritesCitizenMenuList';
import CitizenGroupsCitizenMenuList from './components/CitizenGroupsCitizenMenuList';
import CitizenEventsCitizenMenuList from './components/CitizenEventsCitizenMenuList';
import AnswersCitizenMenuList from './components/AnswersCitizenMenuList';
import QuestionsCitizenMenuList from './components/QuestionsCitizenMenuList';
import LikesCitizenMenuList from './components/LikesCitizenMenuList';

const CitizenMenu: React.FC = () => {
    return (
        <>
            <Card className={styles.citizenMenu}>
                <div className={styles.titleBlock}>Избранное</div>
                <FavoritesCitizenMenuList />
                <CitizenGroupsCitizenMenuList />
                <CitizenEventsCitizenMenuList />
                <QuestionsCitizenMenuList />
                <AnswersCitizenMenuList />
                <LikesCitizenMenuList />
            </Card>
        </>
    );
};

export default CitizenMenu;
