import React, { useCallback } from 'react';
import { Checkbox, Form } from 'antd';
import { useField } from 'formik';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './CheckBoxField.scss';

export interface ICheckBoxFieldProps {
    name: string;
    title: string;
    disabled?: boolean;
}

export const CheckBoxField: React.FC<ICheckBoxFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField<boolean | undefined>(name);

    const onChange = useCallback(
        (e: CheckboxChangeEvent) => {
            const value = e.target.checked;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <Checkbox
                className={'checkbox-field'}
                checked={field.value}
                onChange={onChange}
                disabled={props.disabled}
            >
                <span className={props.disabled ? 'checkbox-label-disabled' : 'checkbox-label'}>{props.title}</span>
            </Checkbox>
        </Form.Item>
    );
};
