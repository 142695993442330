import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IRegionalCoordinatorPointsRequest,
    IRegionalCoordinatorPointsResponse,
} from '../types/v1.0/regionalCoordinatorPoints';
import { getBarsCoordinatorPointsFilter, getBarsCoordinatorsSort } from '../bars-request-objects';
import { getCoordinatorPageRequest } from './requestCreator';

export const getRegionalCoordinatorPoints = async (request: IRegionalCoordinatorPointsRequest,
    descending: boolean) => {
    const dataFilter = getBarsCoordinatorPointsFilter(0,request.deputyId,'Id');
    const sort = getBarsCoordinatorsSort(request.sortType, descending ? 'DESC' : 'ASC');
    const { start, limit } = request;
    const formData = getCoordinatorPageRequest({ dataFilter,sort,start,limit });
    const res = await axiosPostRequest(ApiPaths.RegionalCoordinatorPoints, formData);
    return res.data as IRegionalCoordinatorPointsResponse;
};
