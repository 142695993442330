export const SET_GROUPS_LIST = 'groups/SET_GROUPS_LIST' as const;
export const SET_FILTERED_GROUPS_LIST = 'groups/SET_FILTERED_GROUPS_LIST' as const;
export const SET_GROUP_MESSAGES = 'groups/SET_GROUP_MESSAGES' as const;
export const SET_GROUPS_TOTALCOUNT = 'groups/SET_GROUPS_TOTALCOUNT' as const;
export const SET_MESSAGES_TOTALCOUNT = 'groups/SET_MESSAGES_TOTALCOUNT' as const;
export const SET_GROUP_MEMBERS_COUNT = 'groups/SET_GROUP_MEMBERS_COUNT' as const;
export const SEND_MESSAGE = 'groups/SEND_MESSAGE' as const;
export const DELETE_MESSAGE = 'groups/DELETE_MESSAGE' as const;
export const SET_IS_CHAT_MESSAGES_LOADING = 'groups/SET_IS_CHAT_MESSAGES_LOADING' as const;
export const SET_IS_PREVIEW_LOADING = 'groups/SET_IS_PREVIEW_LOADING' as const;
export const SET_IS_SEND_MESSAGE_LOADING = 'groups/SET_IS_SEND_MESSAGE_LOADING' as const;
export const SET_MESSAGES_SEEN = 'groups/SET_MESSAGE_SEEN' as const;
export const SET_MESSAGES_SORT_TYPE = 'groups/SET_MESSAGES_SORT_TYPE' as const;
export const SET_MESSAGES_SORT_DIRECTION = 'groups/SET_MESSAGES_SORT_DIRECTION' as const;
