import { useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import {
    getEventsEntityListFilters,
} from 'api/bars-request-objects';
import { IActivityInfoItem, ProjectType } from 'api/types/v1.0/activityInfo';
import { eventsEntityList } from 'api/v1.0/eventsEntityList';

export interface IUseActivityCardActivityProps {
    projectType?: ProjectType;
    isEdit: boolean;
}

export const useActivityCardActivity = ({ projectType, isEdit }: IUseActivityCardActivityProps) => {
    const [activities, setActivities] = useState<IActivityInfoItem[]>();

    useEffect(() => {
        const fetch = async () => {
            try {
                if (projectType !== undefined) {
                    const response = await eventsEntityList({
                        dataFilter: getEventsEntityListFilters(projectType),
                    });
                    setActivities(response.data);
                }

                if (!isEdit && projectType === undefined)
                {
                    const response = await eventsEntityList();
                    setActivities(response.data);
                }
            } catch (exception) {
                handleApiError(exception);
            }
        };

        void fetch();
    }, [isEdit, projectType]);

    return {
        activities,
    };
};
