import { useCallback, useEffect, useReducer } from 'react';
import { getBarsPointInfoFilter } from 'api/bars-request-objects';
import { getPointMediaFiles } from 'api/v1.0/pointMedia';
import { sortMediaByDate } from 'utils/sortMediaByDate';
import { postRemoveFileAsync } from 'api/v1.0/removeFile';
import { postPointMediaAddAsync } from 'api/v1.0/pointMediaAdd';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';
import {
    editPointMediaAdd,
    editPointMediaRemove,
} from './actionCreators';

export interface IUseEditPointMediaProps {
    pointId: number | undefined;
}

export const useEditPointMedia = ({ pointId }: IUseEditPointMediaProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const fetchPointMedia = async () => {
        dispatch(actionCreators.editPointMediaFetch());
        try
        {
            const pointFilter = getBarsPointInfoFilter([pointId ? pointId : 0]);
            const media = await getPointMediaFiles({ dataFilter: pointFilter });
            media.data.sort(sortMediaByDate);
            dispatch(actionCreators.editPointMediaSet(media.data));
            dispatch(actionCreators.editPointMediaLoad());
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointMediaError(error));
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshPointMedia = useCallback(() => void fetchPointMedia(), []);

    const fetchRemoveMedia = async (id: number) => {
        dispatch(editPointMediaRemove());
        try
        {
            await postRemoveFileAsync({ records: [id] });
            await fetchPointMedia();
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointMediaError(error));
        }
        finally {
            dispatch(actionCreators.editPointMediaLoad());
        }
    };

    const removeMedia = (mediaPointId: number) => void fetchRemoveMedia(mediaPointId);

    const fetchAddMedia = async (media: FormData) => {
        dispatch(editPointMediaAdd());
        try
        {
            await postPointMediaAddAsync(media);
            await fetchPointMedia();
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointMediaError(error));
        }
        finally {
            dispatch(actionCreators.editPointMediaLoad());
        }
    };

    const addMedia = (media: FormData) => void fetchAddMedia(media);

    useEffect(() => {
        refreshPointMedia();
    }, [refreshPointMedia]);

    return {
        state,
        addMedia,
        removeMedia,
    };
};
