import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export interface IGetUserProjectsResponse
{
    data? : IUserProject[];
    success: boolean;
    summaryData: string;
    totalCount: number;
}

export interface IUserProject
{
    CitizenLink_name: string,
    CitizenLink_Ref_Id: string,
    Id: number,
    ProjectChoice: number,
    ProjectLink_project_name: string,
    ProjectLink_Ref_Id: number,
    _EditorName: string,
    _TypeUid: string,
}

export const GetUserProjects = async (userId: string) =>
{
    const request = {
        dataFilter: {
            Group: 2,
            Filters: [
                {
                    Operand: 0,
                    Value: userId,
                    DataIndex: 'CitizenLink.Id',
                },
            ],
        },
    };
    const res = await axiosPostRequest(ApiPaths.CitizenProjectList, request);
    return res.data as IGetUserProjectsResponse;
};
