import React from 'react';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ICitizenGroupItem } from 'api/types/v1.0/citizenGroup';
import { IAccount } from 'types/account';
import CitizenGroupItem from './components/CitizenGroupItem';

interface IProps {
    groups: ICitizenGroupItem[] | null;
    totalCount: number;
    refresh: () => void;
    deleteGroup: (groupId: number) => void;
    setTotalCount: (totalCount: number) => void;
    user: IAccount | null;
    setConfirmModalOpen: (isOpen: boolean, objectId: number) => void;
}

const CitizenGroupsList: React.FC<IProps> = ({
    groups,
    totalCount,
    refresh,
    setConfirmModalOpen,
}) => {
    return (
        <div className={'favorite-list'}>
            <InfiniteScroll
                dataLength={groups ? groups.length : 0}
                next={refresh}
                hasMore={groups ? groups?.length < totalCount : false}
                loader={<Skeleton paragraph={{ rows: 2 }} active />}
                scrollableTarget="scrollableDiv"
            >
                <List
                    itemLayout="horizontal"
                    dataSource={groups ?? undefined}
                    renderItem={(item, index) => (
                        <List.Item className={'favorite-list-item'}>
                            <CitizenGroupItem
                                groupId={item.object_id}
                                title={item.object_name}
                                address={item.address}
                                key={index}
                                objectId={item.object_id}
                                setConfirmModalOpen={setConfirmModalOpen}
                            />
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>);
};

export default CitizenGroupsList;
