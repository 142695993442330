import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IGroupsMessageItem } from 'api/types/v1.0/groupsList';
import { RootState } from 'App/root/rootReducer';
import { hasRole } from 'utils/user';
import { Role } from 'types/account';
import { UploadedImage } from '../../types';
import ChatMessageCard from '../ChatMessageCard';
import './ChatMessagesList.scss';

export interface IProps {
    group_id: number;
    groupMessages: IGroupsMessageItem[] | null;
    messagesTotalCount: number;
    getGroupMessagesList: (object_id: number, start?: number, limit?: number) => void;
    replyMessage: (req: {object_id: number, message_id: number, start: number}) =>
        Promise<IGroupsMessageItem[] | undefined>;
    removeMessage: (message_id: number) => void;
    isEditDisabled?: boolean;
    uploadedImages: UploadedImage[] | null;
    sendMessageSeen: (message: IGroupsMessageItem[]) => void;
    answeringMessage: IGroupsMessageItem | null;
    setAnsweringMessage: React.Dispatch<React.SetStateAction<IGroupsMessageItem | null>>;
    getGroupMembersCount: (group_id: number) => void;
    getInitialGroupMessageList: (object_id: number) => void;
    resetInputs: () => void;
}

const ChatMessagesList: React.FC<IProps> = ({
    group_id,
    groupMessages,
    messagesTotalCount,
    getGroupMessagesList,
    isEditDisabled,
    replyMessage,
    removeMessage,
    uploadedImages,
    sendMessageSeen,
    answeringMessage,
    setAnsweringMessage,
    getGroupMembersCount,
    getInitialGroupMessageList,
    resetInputs,
}) => {
    const { selectedMessageId } = useSelector((state: RootState) => state.chat);
    const { user } = useSelector((state: RootState) => state.account);
    const isCoordinator = hasRole([Role.Federal, Role.Regional], user?.role as Role);
    const isMundep = hasRole([Role.Municipal], user?.role as Role);
    const infinityScrollRef = useRef<HTMLDivElement>(null);
    const hasUploadedImages = (uploadedImages !== null && uploadedImages.length !== 0);
    const styleAnsweringMessage = answeringMessage
        ? 'chat-conversation-card-messages-reply'
        : 'chat-conversation-card-messages';
    const styleCardMessage = hasUploadedImages
        ? 'chat-conversation-card-messages-afterupload'
        : styleAnsweringMessage;
    const styleMessages = hasUploadedImages && answeringMessage
        ? 'chat-conversation-card-messages-afterupload-reply'
        : styleCardMessage;

    const scrollToMessage = async () => {
        const messageId = selectedMessageId as number;
        let element = document.getElementById(`${messageId}`);

        if (!element) {
            await replyMessage({
                message_id: messageId,
                object_id: group_id,
                start: messagesTotalCount,
            });
        }

        element = document.getElementById(`${messageId}`);
        element?.scrollIntoView();
        setTimeout(()=>{window.scrollTo(0, -3000);},10);
    };

    useEffect(() => {
        selectedMessageId && scrollToMessage();
        infinityScrollRef.current?.scrollTo(0, 0);
        resetInputs();
        getGroupMembersCount(group_id);
        !selectedMessageId && getInitialGroupMessageList(group_id);
        //eslint-disable-next-line
    }, [group_id]);

    useEffect(() => {
        groupMessages && sendMessageSeen(groupMessages.slice(-5));
        //eslint-disable-next-line
    }, [groupMessages?.length]);

    const loadMoreMessages = () => {
        getGroupMessagesList(group_id, groupMessages?.length, 5);
    };

    return (
        <div
            className={(isCoordinator || isEditDisabled)
                ? 'chat-conversation-card-messages-coordinator'
                : styleMessages
            }
            id="scrollableDiv"
            ref={infinityScrollRef}
            style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowAnchor: 'none',
            }}
        >
            <InfiniteScroll
                dataLength={groupMessages?.length ? groupMessages?.length : 0}
                next={loadMoreMessages}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                hasMore={groupMessages ? groupMessages?.length < messagesTotalCount : false}
                scrollableTarget="scrollableDiv"
                inverse={true}
                loader={''}
            >
                <List
                    className={'messages-list'}
                    locale={{ emptyText: 'Сообщений пока нет' }}
                    dataSource={
                        (groupMessages&& [...groupMessages].reverse()) ?? []
                    }
                    renderItem={(message: IGroupsMessageItem) => (
                        <ChatMessageCard
                            selected={selectedMessageId === message.Id}
                            message={message}
                            isEditDisabled={isEditDisabled}
                            isCoordinator={isCoordinator}
                            isMundep={isMundep}
                            messagesTotalCount={messagesTotalCount}
                            replyMessage={replyMessage}
                            setAnsweringMessage={setAnsweringMessage}
                            removeMessage={removeMessage}
                        />
                    )}
                />
            </InfiniteScroll>
        </div>
    );
};

export default ChatMessagesList;
