import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IRegionalCoordinatorDeputiesPointsRequest,
    IRegionalCoordinatorDeputiesPointsResponse,
} from '../types/v1.0/regionalCoordinatorDeputiesPoints';
import {
    getBarsCoordinatorDeputyFilter,
    getBarsCoordinatorsSort,
} from '../bars-request-objects';
import { Role } from '../../types/account';
import { getCoordinatorPageRequest } from './requestCreator';

export const getRegionalCoordinatorDeputiesPoints = async (request: IRegionalCoordinatorDeputiesPointsRequest,
    descending: boolean) => {
    const coordinatorId = request.userRole === Role.Federal ? request.coordinatorId : request.userId;
    const dataFilter = getBarsCoordinatorDeputyFilter(0, request.deputyId, coordinatorId);
    const sort = getBarsCoordinatorsSort(request.sortType, descending ? 'DESC' : 'ASC');
    const { start, limit } = request;
    const formData = getCoordinatorPageRequest({ dataFilter,sort,start, limit });
    const res = await axiosPostRequest(ApiPaths.RegionalCoordinatorDeputiesPoints, formData);
    return res.data as IRegionalCoordinatorDeputiesPointsResponse;
};
