import React from 'react';
import { Form, UploadFile } from 'antd';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'App/root/rootReducer';
import { IHasId } from 'api/types/v1.0/deputyActivities';
import { SubmitButton } from 'ui-kit/Form/SubmitButton/SubmitButton';
import UploadImage from 'ui-kit/Form/FormFields/UploadImage/UploadImage';
import { IFilterStyleType, ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import { ActivityFilter } from 'ui-kit/filters/ActivityFilter/ActivityFilter';
import { Role } from 'types/account';
import { ControlPointNameField } from './fields/HeaderTextInputField/HeaderTextInputField';
import { DescriptionTextAreaField } from './fields/DescriptionTextAreaField/DescriptionTextAreaField';
import styles from './NewsGeneratorFormFields.module.scss';

export interface INewsGeneratorValues {
    deputy_id?: IHasId;
    projectId?: number;
    pointId?: number;
    activityId?: number;
    title?: string;
    description?: string;
    file?: UploadFile;
    deputyRole?: Role
}

const NewsGeneratorFormFields: React.FC = () => {
    const { isValid, values, setFieldValue, setFieldTouched } = useFormikContext<INewsGeneratorValues>();
    const user = useSelector((state: RootState) => state.account.user);

    return (
        <div className={styles.newsGeneratorFieldsContainer}>
            <div className={styles.newsGeneratorFormFieldsWrapper}>
                <div className={styles.newsGeneratorSubtitle}>Принадлежность:</div>
                <div className={styles.newsGeneratorSelectors}>
                    <Form.Item
                        className={styles.selectorWrapper}
                        name='projectId'
                    >
                        <div className={styles.projectFilter}>
                            <ProjectFilter
                                defaultProjectId={values.projectId}
                                type={IFilterStyleType.outlined}
                                alwaysShowSuffix={true}
                                userId={user?.sub}
                                onChange={
                                    (value) => {
                                        setFieldValue('projectId', value ?? undefined);
                                        setFieldTouched('projectId', true, false);
                                    }
                                }
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        className={styles.selectorWrapper}
                        name='pointId'
                    >
                        <div className={styles.pointFilter}>
                            <PointFilter
                                projectId={values.projectId}
                                defaultPointId={values.pointId}
                                type={IFilterStyleType.outlined}
                                userId={user?.sub}
                                alwaysShowSuffix={true}
                                onChange={
                                    (value) => {
                                        setFieldValue('pointId', value ?? undefined);
                                        setFieldTouched('pointId', true, false);
                                    }
                                }
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        className={styles.selectorWrapper}
                        name='activityId'
                    >
                        <div className={styles.activityFilter}>
                            <ActivityFilter
                                placeholder={'Выберите мероприятие'}
                                userId={String(user?.sub)}
                                pointId={values.pointId}
                                projectId={values.projectId}
                                defaultActivityId={values.activityId}
                                type={IFilterStyleType.outlined}
                                alwaysShowSuffix={true}
                                onChange={
                                    (value) => {
                                        setFieldValue('activityId', value ?? undefined);
                                        setFieldTouched('activityId', true, false);
                                    }
                                }
                            />
                        </div>
                    </Form.Item>
                </div>
                <div className={styles.newsGeneratorSubtitle}>Новость:</div>
                <ControlPointNameField />
                <DescriptionTextAreaField />
                <div className={styles.uploadImage}>
                    <UploadImage name={'file'} />
                </div>
            </div>
            <div className={styles.newsButtonWrapper}>
                <div className={styles.newsButton}>
                    <SubmitButton disabled={!isValid} buttonText={'Опубликовать новость'} />
                </div>
            </div>
        </div>
    );
};

export default NewsGeneratorFormFields;
