import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IDeputyQuestionsRequest, IDeputyQuestionsResponse } from 'api/types/v1.0/deputyQuestions';
import { getDeputyQuestionsFilter, getDeputyQuestionsSort } from '../bars-request-objects';

export const getDeputyQuestions = async (request: IDeputyQuestionsRequest) => {
    const dataFilter = getDeputyQuestionsFilter(
        request.deputyId.toString(),
        request.objectId.toString(),
        request.isCoordinator
    );
    const sort = getDeputyQuestionsSort();
    const req = {
        dataFilter,
        sort,
        start: request?.start || 0,
        limit: 10,
    };

    const res = await axiosPostRequest(
        request.isCoordinator ? ApiPaths.CoordinatorQuestions : ApiPaths.DeputyQuestions,
        req
    );
    return res.data as IDeputyQuestionsResponse;
};
