import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export interface IOnLoadSpinnerProps {
    isLoading: boolean;
    content: React.ReactNode;
    className?: string;
}

export const OnLoadingSpinner: React.FC<IOnLoadSpinnerProps> = (props) => {
    return (
        <Spin className={props?.className}
            spinning={props.isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
            {props.content}
        </Spin>
    );
};
