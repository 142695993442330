import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import {
    getFederalCoordinatorStatistics,
} from 'api/v1.0/federalCoordinatorProfile';
import {
    getRegionalCoordinatorStatistics,
} from 'api/v1.0/regionalCoordinatorProfile';
import { Role } from 'types/account';
import * as actionCreators from './actionCreators';
import SelectedPointsReducer, { defaultState } from './pointsReducer';

export const useCoordinatorSelectedPoints = (deputyId: number,role:Role) => {
    const [state, dispatch] = useReducer(SelectedPointsReducer, defaultState);
    const refresh = () => {
        dispatch(actionCreators.deputyProfileFetch());
        if (role === Role.Federal){
            void getFederalCoordinatorStatistics({ deputy_id: deputyId, object_id: state.selectedPoints })
                .then((response)=>{
                    dispatch(actionCreators.coordinatorPointsSet(response.data));
                }).catch((exception) => {handleApiError(exception);
                    dispatch(actionCreators.deputyProfileLoad());});
        }
        else {
            void getRegionalCoordinatorStatistics({ deputy_id: deputyId, object_id: state.selectedPoints })
                .then((response)=>{
                    dispatch(actionCreators.coordinatorPointsSet(response.data));
                }).catch((exception) =>handleApiError(exception))
                .finally(() =>actionCreators.deputyProfileLoad());
        }
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    },[state.selectedPoints]);

    const addSelectedPoint = (point:number) => {
        dispatch(actionCreators.coordinatorSelectedPointAdd(point));
    };

    const removeSelectedPoint = (point:number) => {
        dispatch(actionCreators.coordinatorSelectedPointRemove(point));
    };

    const clearPoints = () => {
        if (state.selectedPoints.length !== 0){
            dispatch(actionCreators.coordinatorSelectedPointsClear());
        }
    };

    const changeSelectedPoint = (point: number) => {
        if (state.selectedPoints.includes(point)) {
            removeSelectedPoint(point);
        }
        else {
            addSelectedPoint(point);
        }
    };

    return {
        statisticsState: state,
        changeSelectedPoint,
        clearPoints,
    };
};
