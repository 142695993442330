import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, notification, Space } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import { StarFilled } from '@ant-design/icons';
import { RootState } from 'App/root/rootReducer';
import ExpandableListHeaderForMenu from 'ui-kit/ExpandableListForMenu';
import ExpandableListShowAllButton from 'ui-kit/ExpandableListForMenu/components/ExpandableListShowAllButton';
import { Pages } from 'constants/links';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import {
    IFavoritePointCreateRequest,
    IFavoritePointDeleteRequest, IFavoritePointItem,
} from 'api/types/v1.0/favoritePoint';
import { addFavoritePoint, deleteFavoritePoint } from 'api/v1.0/favoritePoint';
import browserHistory from 'App/root/browserHistory';
import { PointInfoContext } from 'pages/PointInfo/context/PointInfoContext';
import styles from './FavoritesCitizenMenuList.module.scss';
import useFavoritesCitizenMenuList from './hooks/useFavoritesCitizenMenuList';

const FavoritesCitizenMenuList: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);

    const [api, contextHolder] = notification.useNotification();

    const pageInfoContext = React.useContext(PointInfoContext);

    const location = useLocation();

    const relatedPointId = location.pathname.includes(Pages.PointInfo.url)
        ? location.pathname.split('/').slice(-1)[0]
        : null;

    const {
        favorites,
        isExpanded,
        changeIsExpanded,
        isLoading,
        totalCount,
        refresh,
        setTotalCount,
        deleteFavorite,
    } = useFavoritesCitizenMenuList(Number(user?.sub));

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    const handleCancelClicked = async (objectId: number) => {
        if (objectId && user) {
            const createRequest = {
                modelData: {
                    CitizenId: { Id: Number(user?.sub) },
                    ObjectId: { Id: objectId },
                },
            } as IFavoritePointCreateRequest;

            const response = await addFavoritePoint(createRequest);

            if (pageInfoContext !== null && relatedPointId !== null && Number(relatedPointId) === objectId) {
                pageInfoContext.setIsActive(true);
                pageInfoContext.setFavoriteId(response.data.Id);
            }

            refresh();
        }
    };

    const openNotification = (placement: NotificationPlacement, objectId: number) => {
        notification.destroy();
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button
                    type="primary"
                    size="small"
                    onClick={async () => {
                        await handleCancelClicked(objectId);
                        notification.close(key);
                    }}
                >
                    Отмена
                </Button>
            </Space>
        );

        api.info({
            message: 'Объект удален из избранного',
            btn,
            key,
            placement,
        });
    };

    const handleListItemClick = (x: IFavoritePointItem) => {
        browserHistory.push(`${Pages.PointInfo.url}/${x.ObjectId_Ref_Id.toString()}`);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const handleStarClicked = async (favoriteId: number, objectId: number) => {
        setTotalCount(totalCount - 1);
        openNotification('bottom', objectId);
        deleteFavorite(favoriteId);
        await deleteFavoritePoint({ records: [favoriteId] } as IFavoritePointDeleteRequest);
        refresh();

        if (pageInfoContext !== null && relatedPointId !== null && Number(relatedPointId) === objectId) {
            pageInfoContext.setIsActive(false);
        }
    };

    const listName = 'Объекты';
    const redirectUrl = Pages.Favorite.url;

    return (
        <>
            {contextHolder}
            <ExpandableListHeaderForMenu
                listName={listName}
                count={totalCount}
                isExpanded={isExpanded}
                setIsExpanded={changeIsExpanded}
                markAsSelected={location.pathname === redirectUrl}
                isLoading={isLoading}
            />
            <div className={styles.expandableListExpandedElementsBlockWrapper}>
                {
                    isExpanded && favorites && favorites.slice(0, COUNT_OF_ELEMENTS_TO_SHOW).map((x, index) => {
                        return <div key={index} className={styles.expandedElementContainer}>
                            <div
                                onClick={()=>{handleListItemClick(x);}}
                                className={styles.expandedElementItem}
                                key={index}>
                                <div className={styles.titleText}>
                                    {x.ObjectId_object_name}
                                </div>
                                <div className={styles.descriptionText}>
                                    {x.ObjectId_address_o}
                                </div>
                            </div>
                            <StarFilled className={styles.favoriteIcon} onClick={(e) => {
                                e.stopPropagation();
                                void handleStarClicked(x.Id, x.ObjectId_Ref_Id);
                            }} />
                        </div>;
                    })
                }
                { isExpanded &&
                    <ExpandableListShowAllButton
                        redirectUrl={redirectUrl}
                        listName={listName}
                    />
                }
            </div>
        </>
    );
};

export default FavoritesCitizenMenuList;
