import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
    defaultState as defaultCitizenEventsState,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/reducer/reducer';
import {
    defaultState as defaultCitizenGroupsState,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/reducer/reducer';
import {
    defaultState as defaultCitizenFavoritesState,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/reducer/reducer';
import {
    defaultState as defaultCitizenLikesState,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/LikesCitizenMenuList/reducer/reducer';
import {
    defaultState as defaultNewsSidebarState,
} from 'components/Layout/components/Sidebar/News/hooks/newsSidebarReducer';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware);

const cachedReduxState = localStorage.getItem('reduxState') ?? '{}';

const store = createStore(rootReducer, JSON.parse(cachedReduxState), enhancer);

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

window.addEventListener('beforeunload', () => {
    const stateOnBeforeUnload = store.getState();

    // mark data as not persisted
    stateOnBeforeUnload.citizenMenuCitizenEventsReducer = defaultCitizenEventsState;
    stateOnBeforeUnload.citizenMenuCitizenGroupsReducer = defaultCitizenGroupsState;
    stateOnBeforeUnload.citizenMenuFavoritesReducer = defaultCitizenFavoritesState;
    stateOnBeforeUnload.citizenMenuLikesReducer = defaultCitizenLikesState;
    stateOnBeforeUnload.newsSidebarReducer = defaultNewsSidebarState;
    stateOnBeforeUnload.account.isTokenChecked = false;

    localStorage.setItem('reduxState', JSON.stringify(stateOnBeforeUnload));
});

sagaMiddleware.run(rootSaga);

export default store;
