import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export interface SetAvatarResponse {
    data:{
        FileOriginal: Blob,
        Avatar: string,
    }
}
export const SetAvatar = async (data: FormData) => {
    return axiosPostRequest<FormData, SetAvatarResponse>(ApiPaths.CitizenAvatarUpdate, data);
}