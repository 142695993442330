import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiError } from 'api/v1.0/common';
import { getUserProfile } from 'api/v1.0/userProfile';
import { RootState } from 'App/root/rootReducer';
import * as actionCreators from '../reducer';

const useLikesCitizenMenuList = () => {
    const globalState = useSelector((state: RootState) => state);
    const citizenLikesState = globalState.citizenMenuLikesReducer;
    const { user } = globalState.account;
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(actionCreators.setLoading(true));
        void getUserProfile(user?.sub as string)
            .then((response) => {
                dispatch(actionCreators.setTotalCount(response.data.like_mark));
                dispatch(actionCreators.setLoading(false));
            }).catch((exception) => {
                handleApiError(exception);
                dispatch(actionCreators.setLoading(false));
            });
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...citizenLikesState,
        refresh,
    };
};

export default useLikesCitizenMenuList;
