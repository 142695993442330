import {
    SET_CITIZEN_MENU_FAVORITES,
    SET_CITIZEN_MENU_LOADING,
    SET_CITIZEN_MENU_TOTAL_COUNT,
    DELETE_CITIZEN_MENU_FAVORITES,
    DELETE_CITIZEN_MENU_FAVORITE,
    SET_CITIZEN_MENU_FAVORITES_IS_EXPANDED,
    CLEAR_CITIZEN_MENU_FAVORITES,
} from './actionTypes';
import { IState } from './types';

export const setFavorites = (favorites: IState['favorites']) =>
    ({ type: SET_CITIZEN_MENU_FAVORITES, payload: favorites } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_CITIZEN_MENU_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_CITIZEN_MENU_TOTAL_COUNT, payload: totalCount } as const);

export const deleteFavorites = () =>
    ({ type: DELETE_CITIZEN_MENU_FAVORITES } as const);

export const deleteFavorite = (favoriteId: number) =>
    ({ type: DELETE_CITIZEN_MENU_FAVORITE, payload: favoriteId } as const);

export const setIsExpanded = (isExpanded: IState['isExpanded']) =>
    ({ type: SET_CITIZEN_MENU_FAVORITES_IS_EXPANDED, payload: isExpanded } as const);

export const clear = () =>
    ({ type: CLEAR_CITIZEN_MENU_FAVORITES } as const);
