import { Pages } from 'constants/links';

const personalPages = [
    Pages.UserSettings.url,
    Pages.CitizenGroups.url,
    Pages.CitizenEvents.url,
    Pages.Favorite.url,
    Pages.NotificationCenter.url,
    Pages.ActivitiesScheduler.url,
    Pages.CommunityFormation.url,
    Pages.NewsGenerator.url,
    Pages.QuestionsAndAnswers.url,
    Pages.PointConstructor.url,
    Pages.UserProfile.url,
    Pages.DeputyProfile.url,
    Pages.FederalCoordinatorProfile.url,
    Pages.RegionCoordinatorProfile.url,
    Pages.Chats.url,
    Pages.Chat.url,
];

const pagesWithoutTabs = [
    Pages.News.url,
    Pages.RegistrationLink.url,
    Pages.ReasonToRegistration.url,
];

const pagesWithoutTabsWithQueryParams = [
    Pages.NewsDetails.url,
    Pages.Hints.url,
];

export const isUserOnMainMages = (currentLocation: string) => {
    if (currentLocation.includes(Pages.DeputyProfile.url)) {return false;}

    return (currentLocation === Pages.Main.url ||
    currentLocation.includes(Pages.DeputyLink.regionalCoordinator) ||
    currentLocation.includes(Pages.DeputyLink.flowCoordinator) ||
    currentLocation.includes(Pages.DeputyLink.municipalDeputy) ||
    currentLocation.includes(Pages.PointInfo.url) ||
    currentLocation.includes(Pages.ProjectInfo.url));
};

export const isOnPersonalPages = (currentLocation: string) => {
    return personalPages.includes(currentLocation);
};

export const isOnPageWithoutTabs = (currentLocation: string) => {
    return pagesWithoutTabs.includes(currentLocation) ||
        pagesWithoutTabsWithQueryParams.some((x) => currentLocation.startsWith(x));
};
