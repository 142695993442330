import { FormikErrors } from 'formik/dist/types';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { Role } from 'types/account';
import { INewsGeneratorValues } from './NewsGeneratorFormFields';
import { newsGeneratorValidationSchema } from './newsGeneratorValidationSchema';

export const validateNewsGeneratorValues = (
    values: INewsGeneratorValues,
    deputyRole: Role | undefined
): FormikErrors<INewsGeneratorValues> => {
    let errors: FormikErrors<INewsGeneratorValues> = {};

    try {
        // В параметрах выбрано синхронное выполнение функции.
        /* eslint-disable-next-line */
        validateYupSchema(values, newsGeneratorValidationSchema(deputyRole), true, values);
    } catch (err) {
        errors = yupToFormErrors(err);
    }

    return errors;
};
