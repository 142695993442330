export const SET_LIST_POINTS = 'points/SET_LIST_POINTS' as const;
export const CLEAR_LIST_POINTS = 'points/CLEAR_LIST_POINTS' as const;
export const SET_TOTAL_ITEMS = 'points/SET_TOTAL_ITEMS' as const;
export const SET_LIST_PAGING = 'points/SET_LIST_PAGING' as const;
export const SET_LIST_LOADING = 'points/SET_LIST_LOADING' as const;

export const SET_MAP_POINTS = 'points/SET_MAP_POINTS' as const;
export const SET_MAP_LOADING = 'points/SET_MAP_LOADING' as const;
export const SET_MAP_CENTER = 'points/SET_MAP_CENTER' as const;
export const SET_MAP_REGION_NAME = 'points/SET_MAP_REGION_NAME' as const;
export const SET_ID_OF_FILTERED_REGIONS = 'points/SET_ID_OF_FILTERED_REGIONS' as const;
export const SET_SELECTED_REGION = 'points/SET_SELECTED_REGION' as const;

export const SET_POINTS_VISUALISATION = 'points/SET_POINTS_VISUALISATION' as const;
