import {
    DELETE_DEPUTY_ACTIVITIES,
    SET_DEPUTY_ACTIVITIES,
    SET_LOADING,
    SET_SELECTED_POINT,
    SET_SELECTED_PROJECT,
    SET_SELECTED_DATE,
    SET_TOTAL_COUNT,
    SET_MONTH_ACTIVITIES,
    SET_SELECTED_MONTH,
    SET_SELECTED_YEAR,
    SET_CURRENT_PAGE,
} from './actionTypes';
import { IState } from './types';

export const setDeputyActivities = (activities: IState['activities']) =>
    ({ type: SET_DEPUTY_ACTIVITIES, payload: activities } as const);

export const setSelectedProject = (selectedProjectId: IState['selectedProjectId']) =>
    ({ type: SET_SELECTED_PROJECT, payload: selectedProjectId } as const);
export const setSelectedPoint = (selectedPointId: IState['selectedPointId']) =>
    ({ type: SET_SELECTED_POINT, payload: selectedPointId } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const deleteDeputyActivities = () =>
    ({ type: DELETE_DEPUTY_ACTIVITIES } as const);

export const setSelectedDate = (selectedDate: IState['selectedDate']) =>
    ({ type: SET_SELECTED_DATE, payload: selectedDate } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const setSelectedMonth = (selectedMonth: IState['selectedMonth']) =>
    ({ type: SET_SELECTED_MONTH, payload: selectedMonth } as const);

export const setSelectedYear = (selectedYear: IState['selectedYear']) =>
    ({ type: SET_SELECTED_YEAR, payload: selectedYear } as const);

export const setMonthActivities = (monthActivities: IState['monthActivities']) =>
    ({ type: SET_MONTH_ACTIVITIES, payload: monthActivities } as const);

export const setCurrentPage = (currentPage: IState['currentPage']) =>
    ({ type: SET_CURRENT_PAGE, payload: currentPage } as const);
