import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { getSort, IHasFilters } from 'api/bars-request-objects';
import { IRegionList } from '../types/v1.0/regionList';

export const getRegionsList = async (): Promise<IRegionList> => {
    try {
        const formData = new FormData();
        const sort = getSort('region', 'ASC');
        formData.append('sort', sort);
        const result = await axiosCachePostRequest<FormData, IRegionList>(ApiPaths.Regions, formData);
        return result.data;
    } catch (error) {
        throw Error(error);
    }
};

export const postRegionsList = async (request: IHasFilters | undefined): Promise<IRegionList> => {
    try {
        const result = await axiosCachePostRequest<IHasFilters | undefined, IRegionList>(ApiPaths.Regions, request);
        return result.data;
    } catch (error) {
        throw Error(error);
    }
};
