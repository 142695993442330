import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import { isDesktop as isDesktopDetect } from 'react-device-detect';
import { Col, Row } from 'antd';
import { ICalculationResult } from 'components/HeightAdjuster';
import Layout from 'components/Layout';
import useResponsiveBreakpoint from 'hooks/useResponsiveBreakpoint';
import MainSidebar from 'components/Layout/components/Sidebar/Main';
import styles from './PageConetnt.module.scss';

const DESKTOP_PADDING_SIZE = 40; // если изменяется значение необходимо согласовать с PageContext.scss
const TABLET_PADDING_SIZE = 80; // если изменяется значение необходимо согласовать с PageContext.scss
const MOBILE_PADDING_SIZE = 0; // если изменяется значение необходимо согласовать с PageContext.scss

interface IProps extends Omit<ComponentProps<typeof Layout.HeightAdjuster>, 'calculate'> {
    noBottomPadding?: boolean;
    calculate?: (layoutHeight: number) => ICalculationResult;
    withSubstrate?: boolean;
    subtitle?: string | JSX.Element;
    sidebar?: SidebarType;
}

export type SidebarType =
    | 'Main'
    | 'MainWithoutNews';

const PageContent: React.FC<IProps> = ({
    children,
    calculate,
    noBottomPadding = false,
    withSubstrate = false,
    subtitle,
    sidebar,
}) => {
    const { isDesktop, isTablet } = useResponsiveBreakpoint();

    const getPaddingsSize = (): number => {
        const paddingCount = noBottomPadding ? 1 : 2;

        if (isDesktop) {
            return DESKTOP_PADDING_SIZE * paddingCount;
        } else if (isTablet) {
            return TABLET_PADDING_SIZE * paddingCount;
        } else {
            return MOBILE_PADDING_SIZE * paddingCount;
        }
    };

    const calculateHeight = (layoutHeight: number) => {
        const pagePaddingsSize = getPaddingsSize();
        const pageHeight = layoutHeight - pagePaddingsSize;
        return calculate ? calculate(pageHeight) : {};
    };

    const getSidebar = () => {
        switch (sidebar) {
            case 'Main':
                return <MainSidebar />;
            case 'MainWithoutNews':
                return <MainSidebar hideNews={true} />;
        }
    };

    const layoutDetector = isDesktopDetect ? (
        <Row gutter={24}>
            <Col span={17}>{children}</Col>
            <Col span={7}>{getSidebar()}</Col>
        </Row>
    ) : (
        <Row gutter={12}>
            {children}
            {getSidebar()}
        </Row>
    );

    return (
        <div className={classnames(styles['page-content'], noBottomPadding && styles['no-bottom-padding'])}>
            <Layout.HeightAdjuster calculate={calculateHeight}>
                <div className={classnames(withSubstrate ? styles.substrate : styles['no-substrate'])}>
                    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                    {sidebar ? (
                        layoutDetector
                    ) : (
                        children
                    )}
                </div>
            </Layout.HeightAdjuster>
        </div>
    );
};

export default PageContent;
