import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Divider, Skeleton } from 'antd';
import { RootState } from 'App/root/rootReducer';
import Page from 'components/Page';
import { StatesmanTypes } from 'api/types/v1.0/statesman';
import { Pages } from 'constants/links';
import CardTitle from 'ui-kit/Title';
import usePointInfoText from '../PointInfo/components/PointText/hooks';
import { StatesmanCard } from './components/StatesmanCard';
import { ActivitiesList } from './components/ActivitiesList';
import useStatesmanPage from './hooks';
import './index.scss';
import ChatButton from './components/ChatButton';

const StatesmanPage: React.FC = () => {
    const { deputyType, id } = useParams<{ deputyType: string; id: string }>();
    const statesmanType = getStatesmanTypeByRoleName(deputyType);
    const selectedPoint = useSelector((state: RootState) => state.selectedPoint.selectedPoint);
    const { user } = useSelector((state: RootState) => state.account);
    const { statesman, activities, loadMore, totalCount } = useStatesmanPage({
        statesmanId: Number(id),
        statesmanType,
    });
    const { pointInfo } = usePointInfoText({ id: selectedPoint ? Number(selectedPoint.id) : 0 });

    const getActionsByStatesmanType = (type: StatesmanTypes): React.ReactNode | React.ReactNode[] | undefined => {
        if (type === StatesmanTypes.MunicipalDeputy) {
            return <ChatButton
                pointId={Number(selectedPoint?.id ?? 0)}
                responsibleDepId={selectedPoint?.municipaCoordId ?? 0}
                isUserSubscribedToGroup={pointInfo?.is_user_subscribed_to_group ?? false}
                isUserDeletedFromGroup={pointInfo?.is_user_deleted_from_group ?? true}
            />;
        }

        return undefined;
    };

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <Card className={'deputy-page-wrapper'}>
                        {statesman ? (
                            <div>
                                <CardTitle
                                    cardTitle={getStatesmanTitleByType(statesmanType)}
                                    actions={getActionsByStatesmanType(statesmanType)}
                                />
                                <Divider className={'divider'} />
                                <StatesmanCard
                                    statesman={statesman}
                                    user={user}
                                    statesmanType={statesmanType}
                                    pointRating={pointInfo ? pointInfo.rating : 0}
                                />
                                <Divider className={'divider'} />
                                <ActivitiesList
                                    totalCount={totalCount}
                                    loadMore={loadMore}
                                    activities={activities}
                                    statesmanType={statesmanType}
                                    isUserInGroup={pointInfo?.is_user_subscribed_to_group
                                    && !pointInfo?.is_user_deleted_from_group}
                                />
                            </div>
                        ) : (
                            <div>
                                <Skeleton loading active paragraph={{ rows: 0 }} style={{ marginBottom: 24 }} />
                                <Skeleton avatar={{ size: 80 }} loading active paragraph={{ rows: 1 }} />
                            </div>
                        )}
                    </Card>
                </Page.Content>
            }
        />
    );
};

const getStatesmanTitleByType = (type: StatesmanTypes) => {
    let statesmanPosition;
    switch (type) {
        case StatesmanTypes.MunicipalDeputy:
            statesmanPosition = 'Ответственный за объект';
            break;
        case StatesmanTypes.RegionalCoordinator:
            statesmanPosition = 'Региональный координатор';
            break;
        case StatesmanTypes.FederalDeputy:
            statesmanPosition = 'Федеральный координатор';
            break;
    }

    return statesmanPosition;
};

const getStatesmanTypeByRoleName = (statesmanTypeStr: string) => {
    let statesmanType;
    switch (statesmanTypeStr) {
        case Pages.DeputyLink.municipalDeputy:
            statesmanType = StatesmanTypes.MunicipalDeputy;
            break;
        case Pages.DeputyLink.regionalCoordinator:
            statesmanType = StatesmanTypes.RegionalCoordinator;
            break;
        case Pages.DeputyLink.flowCoordinator:
            statesmanType = StatesmanTypes.FederalDeputy;
            break;
        default:
            throw new Error('Не удалось определить тип депутата');
    }

    return statesmanType;
};

export default StatesmanPage;
