import axios, { AxiosResponse } from 'axios';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getAccount, logout as logoutFromServer } from 'api/v1.0/account';
import { handleApiError, handleUnauthorizedApiError } from 'api/v1.0/common';
import * as jwt from 'App/root/jwt';
import { IAccount } from 'types/account';
import store from 'App/root/store';
import { resetSelectedPoint } from '../selectedPoint';
import { resetSelectProject } from '../selectedProjects';
import { resetSelectRegion } from '../selectedRegion';
import { FETCH_ACCOUNT, INIT_ACCOUNT, LOGIN, LOGOUT } from './actionTypes';
import * as actionCreators from './actionCreators';

type ILoginAction = ReturnType<typeof actionCreators.login>;
type IInitAccountAction = ReturnType<typeof actionCreators.initAccount>;

function* initAccount({ payload: isForTokenCheck }: IInitAccountAction) {
    yield put(actionCreators.setIsLoading(true));
    try {
        const token = (yield call([jwt, 'get'])) as string | null;
        if (token) {
            yield put(actionCreators.login(token, isForTokenCheck));
        }
    } catch (exception) {
        yield call(handleUnauthorizedApiError, exception);
    } finally {
        yield put(actionCreators.setIsLoading(false));
    }
}

export function* login({ payload }: ILoginAction) {
    yield call([jwt, 'set'], payload.token);
    try {
        if (!payload.isForTokenCheck) {
            store.dispatch(resetSelectedPoint());
            store.dispatch(resetSelectProject());
            store.dispatch(resetSelectRegion());
        }

        const response = (yield call(getAccount, axios.create())) as AxiosResponse<IAccount>;
        yield put(actionCreators.saveAccount(response.data));
        yield put(actionCreators.setIsTokenChecked(true));
    } catch (exception) {
        yield call([jwt, 'clear']);
        yield put(actionCreators.logout());
        yield put(actionCreators.setIsTokenChecked(false));
        yield call(handleUnauthorizedApiError, exception);
    }
}

function* fetchAccount() {
    yield put(actionCreators.setIsLoading(true));
    try {
        const response = (yield call(getAccount)) as AxiosResponse<IAccount>;
        yield put(actionCreators.saveAccount(response.data));
    } catch (exception) {
        yield call(handleUnauthorizedApiError, exception);
    } finally {
        yield put(actionCreators.setIsLoading(false));
    }
}

function* logout() {
    yield put(actionCreators.setIsLoading(true));
    try {
        yield call(logoutFromServer);
        yield call([jwt, 'clear']);
    } catch (exception) {
        yield call(handleApiError, exception);
    } finally {
        yield put(actionCreators.setIsLoading(false));
        yield put(actionCreators.setIsTokenChecked(false));
    }
}

export default function* watch() {
    yield all([
        takeLatest(INIT_ACCOUNT, initAccount),
        takeLatest(LOGIN, login),
        takeLatest(FETCH_ACCOUNT, fetchAccount),
        takeLatest(LOGOUT, logout),
    ]);
}
