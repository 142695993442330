import { IPointDocument } from 'api/types/v1.0/pointDocuments';
import {
    EDIT_POINT_DOCS_FETCH,
    EDIT_POINT_DOCS_LOAD,
    EDIT_POINT_DOCS_ERROR,
    EDIT_POINT_DOCS_SET,
    EDIT_POINT_DOCS_ADD,
    EDIT_POINT_DOCS_REMOVE,
} from './actionTypes';

export const editPointDocsFetch = () =>
    ({ type: EDIT_POINT_DOCS_FETCH } as const);

export const editPointDocsLoad = () =>
    ({ type: EDIT_POINT_DOCS_LOAD } as const);

export const editPointDocsError = (error: string) =>
    ({ type: EDIT_POINT_DOCS_ERROR, payload: error } as const);

export const editPointDocsSet = (docs: IPointDocument[]) =>
    ({ type: EDIT_POINT_DOCS_SET, payload: docs } as const);

export const editPointDocsAdd = () =>
    ({ type: EDIT_POINT_DOCS_ADD } as const);

export const editPointDocsRemove = () =>
    ({ type: EDIT_POINT_DOCS_REMOVE } as const);
