export enum fileTypes {
    Image,
    Video
}

export const fileTypesDictionary: { [key: string]: fileTypes } = {
    'jpg': fileTypes.Image,
    'png': fileTypes.Image,
    'tiff': fileTypes.Image,
    'webp': fileTypes.Image,
    'jpeg': fileTypes.Image,
    'mp4': fileTypes.Video,
    'avi': fileTypes.Video,
    'mkv': fileTypes.Video,
};
