import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'App/root/rootReducer';
import { getStatesman } from 'api/v1.0/statesman';
import { handleApiError } from 'api/v1.0/common';
import { getDeputyActivitiesMain } from 'api/v1.0/deputyActivities';
import { getDeputyActivitiesFilter, getDeputyActivitiesSort } from 'api/bars-request-objects';
import { StatesmanTypes } from 'api/types/v1.0/statesman';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

export interface IUseDeputyPageProps {
    statesmanId: number;
    statesmanType: StatesmanTypes;
}

const useStatesmanPage = ({ statesmanId, statesmanType }: IUseDeputyPageProps) => {
    const [pageState, dispatch] = useReducer(reducer, defaultState);
    const point = useSelector((state: RootState) => state.selectedPoint.selectedPoint);

    const refresh = () => {
        dispatch(actionCreators.setIsLoading(true));
        try {
            const request = {
                start: 0,
                limit: 10,
                sort: getDeputyActivitiesSort(),
                dataFilter: statesmanType === StatesmanTypes.MunicipalDeputy
                    ? getDeputyActivitiesFilter(null, Number(point?.id) ?? null, null, String(statesmanId))
                    : getDeputyActivitiesFilter(null, null, null, String(statesmanId)),
            };
            void getDeputyActivitiesMain(request).then((res) => {
                dispatch(actionCreators.setActivities(res.data));
                dispatch(actionCreators.setTotalCount(res.totalCount));
            });
            void getStatesman({ id: statesmanId }).then((res) => {
                dispatch(actionCreators.saveStatesman(res.data));
            });
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setIsLoading(false));
        }
    };

    const loadMore = () => {
        dispatch(actionCreators.setIsLoading(true));
        try {
            const request = {
                start: pageState.activities.length,
                limit: 10,
                sort: getDeputyActivitiesSort(),
                dataFilter: statesmanType === StatesmanTypes.MunicipalDeputy
                    ? getDeputyActivitiesFilter(null, Number(point?.id) ?? null, null, String(statesmanId))
                    : getDeputyActivitiesFilter(null, null, null, String(statesmanId)),
            };
            void getDeputyActivitiesMain(request).then((res) => {
                dispatch(actionCreators.addActivities(res.data));
                dispatch(actionCreators.setTotalCount(res.totalCount));
            });
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setIsLoading(false));
        }
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, [statesmanId, point]);

    return {
        ...pageState,
        refresh,
        loadMore,
    };
};

export default useStatesmanPage;
