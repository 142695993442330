import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCitizenEventsFilter, getCitizenEventsSort } from 'api/bars-request-objects';
import { handleApiError } from 'api/v1.0/common';
import { getCitizenEvents } from 'api/v1.0/citizenEvents';
import {
    setIsExpanded,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/reducer';
import { MAX_INT } from 'constants/numbers';
import { defaultPageSize } from '../../MainPage/components/const/pagination';
import { initialValues } from '../../ActivitiesScheduler/components/ActivitiesList/constants';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useCitizenEventsPage = (userId: string) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const globalDispatch = useDispatch();
    const [readyToFetchNewData, setReadyToFetchNewData] = useState(false);
    const [pageByDate, setPageByDate] = useState(1);

    const refresh = (take = defaultPageSize, skip = 0) => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                start: skip,
                limit: take,
                sort: getCitizenEventsSort(),
                dataFilter: getCitizenEventsFilter(userId, null, state.selectedProjectId,
                    state.selectedPointId, state.selectedDate),
            };

            void getCitizenEvents(request)
                .then((response) => {
                    dispatch(actionCreators.setCitizenEvents(response.data));
                    dispatch(actionCreators.setTotalCount(response.totalCount));
                    setPageByDate(response.currentPageByDate);
                    dispatch(actionCreators.setLoading(false));
                });
        } catch (exception) {
            handleApiError(exception);
            dispatch(actionCreators.setLoading(false));
        } finally {
            setReadyToFetchNewData(true);
        }
    };

    const getActivitiesByDate = () => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                start: initialValues.initialSkip,
                limit: defaultPageSize,
                sort: getCitizenEventsSort(),
                dataFilter: getCitizenEventsFilter(userId, null, state.selectedProjectId,
                    state.selectedPointId, state.selectedDate),
            };

            void getCitizenEvents(request)
                .then((response) => {
                    refresh(initialValues.initialTake, (response.currentPageByDate - 1) * defaultPageSize);
                    setPageByDate(response.currentPageByDate);
                    dispatch(actionCreators.setLoading(false));
                });
        } catch (exception) {
            handleApiError(exception);
            dispatch(actionCreators.setLoading(false));
        } finally {
            setReadyToFetchNewData(true);
        }
    };

    const fetchMonthEvents = () => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                start: 0,
                limit: MAX_INT,
                dataFilter: getCitizenEventsFilter(userId, null, state.selectedProjectId,
                    state.selectedPointId, state.selectedDate, true),
            };

            void getCitizenEvents(request)
                .then((response) => {
                    dispatch(actionCreators.setMonthCitizenEvents(response.data));
                    dispatch(actionCreators.setLoading(false));
                });
        } catch (exception) {
            handleApiError(exception);
            dispatch(actionCreators.setLoading(false));
        }
    };

    useEffect(() => {
        globalDispatch(setIsExpanded(false));
        getActivitiesByDate();
        //eslint-disable-next-line
    }, [state.selectedProjectId, state.selectedPointId, state.selectedDate]);

    useEffect(() => {
        if (state.selectedMonth !== null) {
            fetchMonthEvents();
        }
        //eslint-disable-next-line
    }, [state.selectedProjectId, state.selectedPointId, state.selectedMonth, state.selectedYear]);

    const changeSelectedProject = (id: number | null) => {
        deleteCitizenEvents();
        dispatch(actionCreators.setSelectedProject(id));
    };

    const changeSelectedPoint = (id: number | null) => {
        deleteCitizenEvents();
        dispatch(actionCreators.setSelectedPoint(id));
    };

    const changeSelectedDate = (date: string | null) => {
        deleteCitizenEvents();
        dispatch(actionCreators.setSelectedDate(date));
    };

    const deleteCitizenEvents = () => {
        dispatch(actionCreators.deleteCitizenEvents());
    };

    const clearFiltersForCalendar = (date: string) => {
        deleteCitizenEvents();
        dispatch(actionCreators.setSelectedDate(date));
    };

    const clearFiltersForList = () => {
        deleteCitizenEvents();
        dispatch(actionCreators.setSelectedDate(null));
    };

    const changeSelectedMonth = (month: number) => {
        dispatch(actionCreators.setSelectedMonth(month));
    };

    const changeSelectedYear = (year: number) => {
        dispatch(actionCreators.setSelectedYear(year));
    };

    const setCurrentPage = (pageNumber: number) => {
        dispatch(actionCreators.setCurrentPage(pageNumber));
    };

    return {
        ...state,
        refresh,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedDate,
        deleteCitizenEvents,
        clearFiltersForCalendar,
        clearFiltersForList,
        changeSelectedMonth,
        changeSelectedYear,
        readyToFetchNewData,
        setCurrentPage,
        pageByDate,
    };
};

export default useCitizenEventsPage;
