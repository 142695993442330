import { Badge, Button, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import NotificationCard from 'pages/NotificationCenter/components/NotificationCard/NotificationCard';
import useNotificationCenterPage from 'pages/NotificationCenter/hooks';
import { INotification, INotificationsResponse } from 'api/types/v1.0/notifications';
import useUserSettingsPage from 'pages/UserSettings/hooks';
import { RootState } from 'App/root/rootReducer';
import { notificationEventType, notificationGroupType } from 'constants/notificationTypes';
import { ReactComponent as NotificationIcon } from '../../assets/notification-icon.svg';
import styles from './Notifications.module.scss';

interface INotifications {
    notifications: INotificationsResponse | null;
    respondToInvitation: (invitation_id: number, invitationResponse: number) => void;
}

const Notifications: React.FC = () => {
    const { notifications, respondToInvitation, updateIndicator } = useNotificationCenterPage();
    const { notificationIndicator } = useSelector((state: RootState) => state.notificationIndicatorReducer);
    const hasNewEvents = notificationIndicator?.data?.some((item: INotification) =>
        notificationEventType.includes(item.type));
    const hasNewInvitations = notificationIndicator?.data?.some((item: INotification) =>
        notificationGroupType.includes(item.type));
    const { user } = useUserSettingsPage();
    const notificationsEventsEnabled = user?.isReceiveEventMessages;
    const notificationsGroupEnabled = user?.isReceiveGroupMessages;

    const menuItems = ({ notifications, respondToInvitation }: INotifications) => {
        const handleOnClick = () => {
            browserHistory.push(Pages.NotificationCenter.url);
        };

        return [
            {
                key: '1',
                type: 'group',
                label: 'Центр уведомлений',
                children: notifications
                    ? [
                        ...notifications?.data?.map((item: INotification) => {
                            return {
                                label: <NotificationCard
                                    updateIndicator={updateIndicator}
                                    notification={item}
                                    respondToInvitation={respondToInvitation} />,
                                key: item.Id,
                            };
                        }),
                        { label: <div className={styles['notification-show-all']} onClick={handleOnClick}>Показать все уведомления</div>, key: 'all' },
                    ]
                    : null,
            },
        ];
    };

    return (
        <Dropdown
            placement="bottom"
            overlayClassName={styles['dropdown']}
            overlay={<Menu items={menuItems({ notifications, respondToInvitation })} />}
            trigger={['click']}
        >
            <Button
                className={styles['notification-button']}
                icon={
                    <>
                        <NotificationIcon className={styles['notification-icon']} />
                        {((notificationsEventsEnabled && hasNewEvents) ||
                                (hasNewInvitations && notificationsGroupEnabled)) &&
                            <Badge status="warning" className={styles['notification-badge']} />}
                    </>
                }
            />
        </Dropdown>
    );
};

export default Notifications;
