import { all, spawn, call } from 'redux-saga/effects';
import { saga as account } from 'ducks/account';

// https://redux-saga.js.org/docs/advanced/RootSaga.html
function* rootSaga() {
    const sagas = [account];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (exception) {
                        window.console.error(exception);
                    }
                }
            })
        )
    );
}

export default rootSaga;
