import React from 'react';
import { Button, Typography, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from 'ducks/chat/actionCreators';
import { RootState } from 'App/root/rootReducer';
import BurgerMenu from 'ui-kit/BurgerMenu/BurgerMenu';
import { IAccount, Role } from 'types/account';
import { isOnPersonalPages } from 'utils/location';
import { Pages } from 'constants/links';
import browserHistory from 'App/root/browserHistory';
import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg';
import Notifications from './Notifications/Notifications';
import styles from './HeaderProfile.module.scss';

interface IProps {
    user: IAccount | null;
}

const HeaderProfile: React.FC<IProps> = ({ user }) => {
    const isChatOpen = useSelector((state: RootState) => state.chat.isChatOpen);
    const dispatch = useDispatch();
    const nav = useHistory();
    const role = user?.role;

    return (
        <div className={styles['profile-menu']}>
            <div className={styles['chat-icon-button']}>
                <Button
                    onClick={() => {
                        dispatch(actionCreators.setChatModalState(!isChatOpen));
                        dispatch(actionCreators.setSelectedMessageId(null));
                    }}
                    className={styles['chat-button']}
                    icon={<ChatIcon className={styles['chat-icon']} />}
                >
                    Чаты
                </Button>
            </div>
            {role === Role.Citizen &&
                <div className={styles['notification-icon-button']}>
                    <Notifications />
                </div>
            }
            <div className={styles['person-data']}>
                <Typography
                    className={isOnPersonalPages(nav.location.pathname)
                        ? [styles['person-name'], styles['in-personal-pages']].join(' ')
                        : styles['person-name']
                    }
                >
                    <div className={styles['personal-area']}onClick={role === Role.Citizen ?
                        ()=>browserHistory.push(Pages.Favorite.url):
                        ()=>browserHistory.push(Pages.DeputyProfile.url)}>
                        Личный кабинет {user?.surname} {user?.name[0]}.{user?.phantomic[0]}.</div>
                </Typography>
            </div>
            <div className={styles['person-avatar']}>
                <Avatar
                    className={styles['person-avatar-icon']}
                    src={user?.avatar}
                    alt={user?.name}
                    icon={<UserOutlined />}
                />
            </div>
            <div className={styles['burger-menu']}>
                <BurgerMenu disabled={!role} role={role} />
            </div>
        </div>
    );
};

export default HeaderProfile;
