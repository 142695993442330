import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Divider } from 'antd';
import { isDesktop } from 'react-device-detect';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import { FolkTabs } from 'ui-kit/FolkTabs/FolkTabs';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import useProjectList from 'hooks/useProjectList';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import useActivitiesSchedulerPage from './hooks';
import { ActivitiesCalendar } from './components/ActivitiesCalendar';
import { ActivitiesList } from './components/ActivitiesList';
import { ReactComponent as AddIcon } from './assets/AddIcon.svg';
import styles from './ActivitiesSchedulerPage.module.scss';
import DesktopContainerFilter from './components/DesktopContainerFilter';
import MobileContainerFilter from './components/MobileContainerFilter';

export interface IActivitiesSchedulerPageState {
    projectId: number;
    pointId: number;
}

interface IButtonProps {
    projectId: number | null;
    pointId: number | null;
}

export interface IActivityCardFormLocationState {
    projectId: number;
    pointId: number;
}

export const ActivitiesSchedulerPage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);
    const userId = user?.sub;

    const history = useHistory<IActivitiesSchedulerPageState | undefined>();
    const pageState = history.location.state;

    if (pageState) {
        window.history.replaceState({}, document.title);
    }

    const {
        activities,
        isLoadingActivities,
        selectedProjectId,
        selectedPointId,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedDate,
        refresh,
        clearFiltersForCalendar,
        clearFiltersForList,
        selectedDate,
        totalCount,
        readyToFetchNewData,
        monthActivities,
        changeSelectedMonth,
        changeSelectedYear,
        pageByDate,
    } = useActivitiesSchedulerPage(userId, pageState?.projectId, pageState?.pointId);
    const { data } = useProjectList();

    const AddActivityButton: React.FC<IButtonProps> = (buttonProps) => {
        return (
            <Button
                className={styles.AddActivityButton}
                onClick={() => {
                    browserHistory.push({
                        pathname: Pages.ActivitiesCreate.url,
                        state: {
                            projectId: buttonProps.projectId,
                            pointId: buttonProps.pointId,
                        } as IActivityCardFormLocationState,
                    });
                }}
            >
                {<AddIcon />}{isDesktop && 'Добавить мероприятие'}
            </Button>
        );
    };

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={styles.activitiesSchedulerCardWrapper}>
                        <Card>
                            <div className={styles.activitiesSchedulerPageWrapper}>
                                <div className={styles.activitiesSchedulerPageTitle}>Планировщик мероприятий</div>
                                <Divider />
                                {
                                    isDesktop ?
                                        <DesktopContainerFilter
                                            userId={userId}
                                            changeSelectedProject={changeSelectedProject}
                                            changeSelectedPoint={changeSelectedPoint}
                                            selectedProjectId={selectedProjectId ?? undefined}
                                            defaultProjectId={pageState?.projectId}
                                            defaultPointId={pageState?.pointId}
                                        /> :
                                        <MobileContainerFilter
                                            userId={userId}
                                            changeSelectedProject={changeSelectedProject}
                                            changeSelectedPoint={changeSelectedPoint}
                                            selectedProjectId={selectedProjectId ?? undefined}
                                            defaultProjectId={pageState?.projectId}
                                            defaultPointId={pageState?.pointId}
                                        />
                                }
                                <div className={styles.activitiesCollectionWrapper}>
                                    <FolkTabs
                                        tabBarExtraContent={{ right:
                                                <AddActivityButton
                                                    projectId={selectedProjectId}
                                                    pointId={selectedPointId}
                                                /> }}
                                        actionsList={[
                                            clearFiltersForList,
                                            clearFiltersForCalendar,
                                        ]}
                                        items={[
                                            {
                                                label: 'Все',
                                                key: '0',
                                                children: (
                                                    <OnLoadingSpinner
                                                        isLoading={isLoadingActivities}
                                                        content={
                                                            <ActivitiesList
                                                                totalCount={totalCount}
                                                                loading={isLoadingActivities}
                                                                refresh={refresh}
                                                                activities={activities}
                                                                pageByDate={pageByDate}
                                                            />
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                label: 'Календарь',
                                                key: '1',
                                                children: (
                                                    <OnLoadingSpinner
                                                        isLoading={isLoadingActivities}
                                                        content={
                                                            <ActivitiesCalendar
                                                                selectedDate={selectedDate}
                                                                refresh={refresh}
                                                                projectList={data}
                                                                totalCount={totalCount}
                                                                changeDate={changeSelectedDate}
                                                                isLoading={isLoadingActivities}
                                                                activities={activities}
                                                                readyToFetchNewData={readyToFetchNewData}
                                                                monthActivities={monthActivities}
                                                                changeSelectedMonth={changeSelectedMonth}
                                                                changeSelectedYear={changeSelectedYear}
                                                                pageByDate={pageByDate}
                                                            />
                                                        }
                                                    />
                                                ),
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Page.Content>
            }
        />
    );
};
