import {
    SET_IS_JOIN_GROUP_MODAL_OPEN,
    SET_LOADING,
    SET_IS_TAKE_PART_MODAL_OPEN,
    SET_IS_NEED_TO_HIDE_BUTTON,
    SET_JOIN_GROUP_YES_BUTTON_LOADING,
} from './actionTypes';

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const setNeedToHideButton = (needToHideButton: boolean) =>
    ({ type: SET_IS_NEED_TO_HIDE_BUTTON, payload: needToHideButton } as const);

export const setIsJoinGroupModalOpen = (isJoinGroupModalOpen: boolean) =>
    ({ type: SET_IS_JOIN_GROUP_MODAL_OPEN, payload: isJoinGroupModalOpen } as const);

export const setIsTakePartModalOpen = (isTakePartModalOpen: boolean) =>
    ({ type: SET_IS_TAKE_PART_MODAL_OPEN, payload: isTakePartModalOpen } as const);

export const setJoinGroupYesButtonLoading = (joinGroupYesButtonLoading: boolean) =>
    ({ type: SET_JOIN_GROUP_YES_BUTTON_LOADING, payload: joinGroupYesButtonLoading } as const);
