export const getNotificationTitle = (type?: number) => {
    switch (type) {
        case 0:
            return 'Удаление из группы';
        case 1:
            return 'Отмена мероприятия';
        case 2:
            return 'Перенос мероприятия';
        case 3:
            return 'Напоминание о мероприятии';
        case 5:
            return 'Создание мероприятия';
        case 6:
            return 'Приглашение в группу объекта';
        default:
            return false;
    }
};
