import React from 'react';
import { Card, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INotification } from 'api/types/v1.0/notifications';
import Page from 'components/Page';
import NotificationCard from './components/NotificationCard/NotificationCard';
import useNotificationCenterPage from './hooks/useNotificationCenterPage';
import styles from './NotificationCenterPage.module.scss';

const NotificationCenterPage: React.FC = () => {
    const { notifications, refresh, respondToInvitation, updateIndicator } = useNotificationCenterPage();

    const loadMoreData = () => {
        refresh(notifications?.data.length);
    };

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <Card className={styles.notificationCenterPageCard}>
                        <div className={styles.notificationCenterPageWrapper}>
                            <div className={styles.notificationCenterHeader}>
                                Центр уведомлений
                            </div>
                            <div className={styles.notificationCenterNotificationsList}>
                                <InfiniteScroll
                                    dataLength={notifications ? notifications.data.length : 0}
                                    next={loadMoreData}
                                    hasMore={!!notifications && notifications.data.length < notifications.totalCount}
                                    loader={<div className={'loader'}>
                                        <Skeleton title={false} active />
                                    </div>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <List
                                        dataSource={notifications?.data}
                                        renderItem={(notification: INotification) => (
                                            <div>
                                                <Divider />
                                                <NotificationCard
                                                    notification={notification}
                                                    respondToInvitation={respondToInvitation}
                                                    updateIndicator={updateIndicator}
                                                />
                                            </div>
                                        )}
                                    />
                                </InfiniteScroll>
                            </div>
                        </div>
                    </Card>
                </Page.Content>
            }
        />
    );
};

export default NotificationCenterPage;
