import React, { useEffect } from 'react';
import { Button, Image, notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { videoTypes } from 'constants/videoTypes';
import { imageTypes } from 'constants/imageTypes';
import { useEditPointMedia } from './hooks';
import EditPointMediaItem from './EditPointMediaItem';
import { ReactComponent as UploadIcon } from './assets/UploadIcon.svg';
import './EditPointMedia.scss';

export interface IEditPointMediaProps
{
    pointId: number | undefined;
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditPointMedia: React.FC<IEditPointMediaProps> = ({ pointId, setIsLoaded } : IEditPointMediaProps) =>
{
    const { state, addMedia, removeMedia } = useEditPointMedia({ pointId });
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setIsLoaded(!state.isLoading);
    }, [state.isLoading, setIsLoaded]);

    const handlerRemoveMedia =(id: number) =>
    {
        removeMedia(id);
    };

    const handlerUploadMediaWrapper = () =>
    {
        if (hiddenFileInput.current)
        {
            hiddenFileInput.current.click();
        }
    };

    const openIncorrectTypesNotification = () => {
        api.info({
            message: 'Разрешены файлы форматов tiff, png, jpg, avi, mkv, mp4',
            placement: 'bottom',
        });
    };

    const handlerUploadMedia =(event: React.FormEvent<HTMLInputElement>) =>
    {
        const fileMedia = event.currentTarget.files?.item(0);
        const isCorrectMediaType = videoTypes.some((file) => file === fileMedia?.type)
            || imageTypes.some((file) => file === fileMedia?.type);
        if (!isCorrectMediaType)
        {
            openIncorrectTypesNotification();
        }
        else if (fileMedia)
        {
            const form = document.getElementById('formMediaFile') as HTMLFormElement;
            if (form)
            {
                const data = new FormData();
                data.append('modelData', `{'ContentType': ${2}, 'Object': {'Id': ${pointId ? pointId : 0}}}`);
                const dataBlob = event.currentTarget.files?.item(0) as Blob;
                data.append('FileOriginal', dataBlob);
                addMedia(data);
            }
        }
    };

    return (
        <Spin
            spinning={state.isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
            <div>
                {contextHolder}
                <div className={'edit-point-media-title'}>Медиа:</div>
                <div className={'edit-point-media-group'}>
                    <Image.PreviewGroup>
                        <div className={'edit-point-media-group'}>
                            {
                                state.info.map((value, index) =>
                                    <EditPointMediaItem
                                        deleteMedia={()=>{handlerRemoveMedia(value.Id);}}
                                        key={index} item={value}
                                    />
                                )
                            }
                        </div>
                    </Image.PreviewGroup>
                </div>
                <div id={'edit-point-media-button'}>
                    <form id='formMediaFile' method='POST' encType='multipart/form-data'>
                        <Button
                            className={'upload-media-button'}
                            onClick={handlerUploadMediaWrapper}
                            icon={<UploadIcon className='upload-icon' />}
                        >
                            Прикрепить фото/видео
                        </Button>
                        <input
                            type="file"
                            name='FIleOriginal'
                            ref={hiddenFileInput}
                            onChange={handlerUploadMedia}
                            style={{ display: 'none' }}
                        />
                    </form>
                </div>
            </div>
        </Spin>
    );
};

export default EditPointMedia;
