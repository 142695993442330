import { IProjectListResponse, IProjectListItem } from 'api/types/v1.0/projectList';
import { IObjectPoint } from 'api/types/v1.0/points';
import { ICitizenEventsResponse } from 'api/types/v1.0/citizenEvent';

export const mapToProjectList = (input: ICitizenEventsResponse): IProjectListResponse => {
    const mappedData = input.data?.map((x) => ({
        Id: Number(x.project_id),
        project_name: x.project_name,
    })) as IProjectListItem[];

    const distinct: IProjectListItem[] = [];
    mappedData.forEach((value) => {
        if (!!value.Id && !!value.project_name
            && distinct.findIndex((x) => x.Id === value.Id) === -1) {
            distinct.push(value);
        }
    });
    return {
        success: input.success,
        summaryData: input.summaryData,
        totalCount: input.totalCount,
        data: distinct,
    };
};

export const mapToObjectList = (input: ICitizenEventsResponse): IObjectPoint[] => {
    const mappedData = input.data?.map((x) => ({
        object_id: x.object_id?.toString(),
        object_name: x.object_name,
        Id: x.object_id,
    })) as IObjectPoint[];

    const distinct: IObjectPoint[] = [];
    mappedData.forEach((value) => {
        if (!!value.object_id && !!value.object_name
            && distinct.findIndex((x) => x.Id === value.Id) === -1) {
            distinct.push(value);
        }
    });
    return distinct;
};
