import { IPointMediaFile } from 'api/types/v1.0/pointMedia';

export const sortMediaByDate = (l:IPointMediaFile, r:IPointMediaFile ):number =>
{
    if (l.FIleOriginal_ObjectCreateDate === null && r.FIleOriginal_ObjectCreateDate !== null) {return 1;}

    if (l.FIleOriginal_ObjectCreateDate !== null && r.FIleOriginal_ObjectCreateDate === null) {return -1;}

    if (l.FIleOriginal_ObjectCreateDate !== null && r.FIleOriginal_ObjectCreateDate !== null)
    {
        if (l.FIleOriginal_ObjectCreateDate === r.FIleOriginal_ObjectCreateDate)
        {
            return 0;
        }
        else
        {
            if (l.FIleOriginal_ObjectCreateDate > r.FIleOriginal_ObjectCreateDate)
            {
                return 1;
            }
            else
            {
                return -1;
            }
        }
    }

    return 0;
};
