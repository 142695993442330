import {
    IRegionalCoordinatorForList,
} from 'api/types/v1.0/federalCoordinatorRegionalCoordinators';
import { IFederalCoordinatorsPoint } from 'api/types/v1.0/federalCoordinatorPoints';
import {
    SET_LOADING,
    SET_SORT_BY_ALPHABET,
    SET_SORT_BY_RATING,
    SET_REGIONAL_COORDINATORS,
    SET_FEDERAL_COORDINATORS_POINTS,
    SET_TOTALCOUNT,
    CLEAR_COORDINATOR_LIST,
} from './actionTypes';

export const setFederalCoordinatorPoints = (deputyObjects: IFederalCoordinatorsPoint[] | null) =>
    ({ type: SET_FEDERAL_COORDINATORS_POINTS, payload: deputyObjects } as const);
export const setRegionalCoordinators = (coordinators: IRegionalCoordinatorForList[] | null) =>
    ({ type: SET_REGIONAL_COORDINATORS, payload: coordinators } as const);

export const setTotalCount = (totalCount: number) =>
    ({ type: SET_TOTALCOUNT, payload: totalCount });

export const clearCoordinatorList = () => ({ type: CLEAR_COORDINATOR_LIST });

export const setIsLoading = (isLoading: boolean) => ({ type: SET_LOADING, payload: isLoading } as const);

export const setSortByAlphabet = () => ({ type: SET_SORT_BY_ALPHABET } as const);
export const setSortByRating = () => ({ type: SET_SORT_BY_RATING } as const);
