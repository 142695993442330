import React, { useState } from 'react';
import { Card, Divider } from 'antd';
import { FolkTabs } from 'ui-kit/FolkTabs/FolkTabs';
import FolkButton from 'ui-kit/FolkButtons';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import useNewsSidebar from './hooks/useNewsSidebar';
import NewsItems from './NewsItem/NewsItems';
import styles from './NewsSidebar.module.scss';

const NewsSidebar: React.FC = () => {
    const [keyTabNews, setKeyTabNews] = useState<number>(0);
    const { state, clearNewsList, refresh } = useNewsSidebar(keyTabNews);

    const handleOnClick = (key: string) => {
        if (Number(key) !== keyTabNews) {
            clearNewsList();
        }

        setKeyTabNews(Number(key));
    };

    return (
        <div>
            <Card className={styles['newsStyle']}>
                <div className={styles['headerStyle']}>Новости</div>
                <div className={styles['folkTabsWrapper']}>
                    <FolkTabs onClick={handleOnClick} items={[
                        {
                            label: 'Федеральные',
                            key: '0',
                            children: <NewsItems isLoading={state.isLoading} news={state.federalNews ?? []} />,
                        },
                        {
                            label: 'Региональные',
                            key: '1',
                            children: <NewsItems isLoading={state.isLoading} news={state.regionalNews ?? []} />,
                        }]}
                    />
                </div>
                <div className={styles['divStyle']}>
                    <Divider className={styles['antDividerHorizontal']} />
                    <div className={styles['divButton']}>
                        <FolkButton
                            className={styles['buttonStyle']}
                            onClick={refresh}
                        >
                            Показать еще
                        </FolkButton>
                        <FolkButton
                            className={styles['buttonStyle']}
                            onClick={() =>{browserHistory.push(Pages.News.url);
                                setTimeout(()=>{window.scrollTo(0, -3000);},100);}}
                        >
                            Все новости
                        </FolkButton>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default NewsSidebar;
