import { Calendar, Divider } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { QUERY_FORMAT } from 'constants/dateTime';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import { distinct } from 'utils/array';
import { utcDateToLocal } from 'utils/dateTime';
import { ActivitiesList } from '../ActivitiesList';
import './ActivitiesCalendar.scss';

export interface IActivitiesCalendarProps {
    selectedDate: string | null;
    activities: IDeputyActivity[] | null;
    refresh?: () => void;
    isLoading: boolean;
    changeDate: (date: string | null) => void;
    totalCount: number;
    projectList?: IProjectListItem[];
    readyToFetchNewData: boolean;
    monthActivities: IDeputyActivity[] | null;
    changeSelectedMonth: (month: number) => void;
    changeSelectedYear: (year: number) => void;
    pageByDate: number;
}

export const ActivitiesCalendar: React.FC<IActivitiesCalendarProps> = (props: IActivitiesCalendarProps) => {
    const [selectedDate, setSelectedDate] = useState<Moment>(moment());

    const daysWithActivities = props.monthActivities
        ?.map((activity) => {
            const date = new Date(activity.date_time);
            utcDateToLocal(date);
            return date.getDate();
        })
        .filter(distinct);

    const getDateFullCellRender = (date: Moment) => {
        const style = getCellStyle(date);

        const formattedDay = date.toDate().toLocaleDateString('ru-RU', {
            day: '2-digit',
        });

        return <div className='ant-picker-cell-inner ant-picker-calendar-date'>
            <div className={style}>{formattedDay}</div>
        </div>;
    };

    const onSelect = (newDate: Moment) => {
        const isCurrentDaySelected = selectedDate.format(QUERY_FORMAT) === newDate.format(QUERY_FORMAT);

        if (isCurrentDaySelected) {
            return;
        }

        if (selectedDate.year() !== newDate.year()) {
            props.changeSelectedYear(newDate.year());
        }

        if (selectedDate.month() !== newDate.month()) {
            props.changeSelectedMonth(newDate.month());
        }

        props.changeDate(newDate.format(QUERY_FORMAT));

        setSelectedDate(newDate);
    };

    const getCellStyle = (date: Moment) => {
        const day = date.toDate().getDate();
        const isDateFromCurrentMonth = selectedDate.month() === date.month();
        const isCurrentDaySelected = selectedDate.toDate().getDate() === day;
        const isDayWithEvent = daysWithActivities?.some((x) => x === day);
        return isDayWithEvent && !isCurrentDaySelected && isDateFromCurrentMonth
            ? 'ant-picker-calendar-date-value calendar-day-with-event'
            : 'ant-picker-calendar-date-value';
    };

    useEffect(() => {
        props.changeSelectedMonth(selectedDate.month());
        props.changeSelectedYear(selectedDate.year());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.selectedDate) {
            setSelectedDate(moment(props.selectedDate, QUERY_FORMAT));
        }
    }, [props.selectedDate]);

    return (
        <div className='site-calendar-demo-card'>
            <Calendar
                fullscreen={false}
                value={selectedDate}
                onSelect={onSelect}
                dateFullCellRender={getDateFullCellRender}
                onPanelChange={onSelect}
            />
            <Divider />
            <div className='activityListWrapper'>
                {
                    props.activities && props.activities.length !== 0
                        ? <ActivitiesList
                            totalCount={props.totalCount}
                            refresh={props.refresh}
                            loading={props.isLoading}
                            activities={props.activities}
                            pageByDate={props.pageByDate}
                        />
                        : <div className={'no-activities-message'}>На эту дату нет назначенных мероприятий</div>
                }
            </div>
        </div>
    );
};
