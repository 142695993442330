import { handleApiError } from 'api/v1.0/common';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { MAX_INT } from 'constants/numbers';

export const getBranchEconomyList = async () => {
    try {
        const response = await axiosCachePostRequest(ApiPaths.BranchEconomyList, {
            sort: [{ property: 'Name', direction: 'ASC' }],
            start: 0,
            limit: MAX_INT,
        });

        return response.data;
    } catch (exception) {
        handleApiError(exception);
    }
};
