import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { IPointControlPoint } from 'api/types/v1.0/pointControlPoints';
import { getControlPointsForEditingAsync } from 'api/v1.0/controlPointsForEditing';

const useControlPointsForEditing = (objectId: number) => {
    const [controlPoints, setControlPoints] = useState<IPointControlPoint[]>([]);
    const [isLoading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        setLoading(true);

        getControlPointsForEditingAsync(objectId)
            .then((response) => setControlPoints(response.data))
            .catch((e) => handleApiError(e))
            .finally(() => setLoading(false));
    }, [objectId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        controlPoints,
        isLoading,
        refresh,
    };
};

export default useControlPointsForEditing;
