import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import './UploadAvatar.scss';
import { imageTypes } from 'constants/imageTypes';
import { mb_1 } from 'constants/size';
import { SetAvatar } from './SetAvatar';
import { useDispatch } from 'react-redux';
import { fetchAccount } from 'ducks/account';
import { useLocation } from 'react-router-dom';
import { getBeforeFileUploadHandler } from 'utils/getBeforeFileUploadHandler';
import { RcFile } from 'antd/lib/upload';

export interface UploadAvatarProps {
    imageUrl?: string;
    sub?: string;
    disableHints?: boolean;
    setImageFile?: (imageFile: File | null) => void;
}

export const UploadAvatar: React.FC<UploadAvatarProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>(props.imageUrl);
    const dispatch = useDispatch();
    const location = useLocation();
    const [uploadedImage, setUploadedImage] = useState<{url: string, name: string} | null>(null);
    const isOnRegisterPage = location.pathname === '/callback';
    const srcImage = isOnRegisterPage ? uploadedImage?.url : imageUrl;

    useEffect(() => {
        setImageUrl(props.imageUrl);
    }, [props.imageUrl]);

    const handleFileUpload = (fileUrl: string, file: RcFile) => {
        if (!isOnRegisterPage) {
            setLoading(true);
            const fromData = new FormData();
            fromData.append('FileOriginal', file as Blob);
            const modelData = '{"Citizen":{"Id":' + props.sub + '},"ContentType":2}';
            fromData.append('modelData', modelData.toString())
            void SetAvatar(fromData).then((data) => {
                setImageUrl(data.data.data.Avatar);
                dispatch(fetchAccount());
                setLoading(false);
            })
        } else {
            setUploadedImage({
                url: fileUrl,
                name: file.name,
            });
            props.setImageFile?.(file);
        }
    };

    const beforeUpload = getBeforeFileUploadHandler(
        uploadedImage ? [uploadedImage] : [],
        1,
        handleFileUpload,
        true,
        imageTypes,
        (file) => {
            const isLt2M = file.size / mb_1 < 2;
            if (!isLt2M) {
                return 'Размер изображения не должен превышать 2MB!';
            }

            return false;
        },
    );

    const layoutAvatar = (
        imageUrl
            ? <Avatar
                size={96}
                src={srcImage}
                icon={ <div className={'avatar-upload-button'}><UserOutlined/></div>} />
            : <div className={'avatar-upload-button'}><UserOutlined/></div>
    );

    return (
        <div className={'avatar-upload-wrapper upload-avatar-wrap'}>
            <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                beforeUpload={beforeUpload}
            >
                {loading
                    ? <div className={'avatar-upload-button'}><LoadingOutlined /></div>
                    : layoutAvatar
                }
                <PlusOutlined className={'avatar-upload-plus-button'} />
            </Upload>
            {
                !props.disableHints &&
                <div className={'upload-avatar-upload'}>
                    {imageUrl ? 'Заменить фотографию' : 'Загрузить фотографию'}
                </div>
            }
        </div>
    );
};
