export const validationMessages = {
    project: 'Необходимо выбрать проект',
    point: 'Необходимо выбрать объект',
    activity: 'Необходимо выбрать мероприятие',
    activity_name: 'выберите из списка или укажите вручную название',
    description: 'Необходимо добавить описание',
    date: 'Необходимо выбрать дату',
    time: 'Необходимо выбрать время мероприятия',
    question: 'Необходимо задать вопрос',
    industry: 'Необходимо выбрать отрасль',
};
