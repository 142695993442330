import { FormikErrors } from 'formik/dist/types';
import { validateYupSchema, yupToFormErrors } from 'formik';
import * as yup from 'yup';

// https://dev.to/jpoehnelt/strongly-typed-yup-schema-in-typescript-15bc Для строгой типизации схемы валидации
export type ConditionalSchema<T> = T extends string
    ? yup.StringSchema
    : T extends number
    ? yup.NumberSchema
    : T extends boolean
    ? yup.BooleanSchema
    //eslint-disable-next-line
    : T extends Record<any, any>
    ? yup.AnyObjectSchema
    //eslint-disable-next-line
    : T extends Array<any>
    //eslint-disable-next-line
    ? yup.ArraySchema<any, any>
    : yup.AnySchema;

export type Shape<Fields> = {
    [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

export const validateValuesWithSchema = <T>(
    values: T,
    validationSchema: ConditionalSchema<T>
): FormikErrors<T> => {
    let errors: FormikErrors<T> = {};

    try {
        // В параметрах выбрано синхронное выполнение функции.
        /* eslint-disable-next-line */
        validateYupSchema(values, validationSchema, true, values);
    } catch (err) {
        errors = yupToFormErrors(err);
    }

    return errors;
};
