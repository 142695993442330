import * as yup from 'yup';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';
import { Shape } from 'utils/validation';
import { IControlPointModalValues } from './ControlPointModal';

export const controlPointValidationSchema = yup.object<Shape<IControlPointModalValues>>(
    {
        name: yup.string().required(`${alerts.REQUIRED}`),
        id: yup.number(),
        isCompleted: yup.boolean(),
        isDisplayed: yup.boolean(),
        required: yup.boolean(),
        factDate: yup.string().nullable(),
        planDate: yup.string().nullable(),
    }
);
