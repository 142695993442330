import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import { useField } from 'formik';
import './TextInputField.scss';

export interface ITextInputFieldProps {
    disabled?: boolean;
    name: string;
    placeholder: string;
    maxLength?: number;
    height?: string;
}

export const TextInputField: React.FC<ITextInputFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <Input
                style={{height: props.height}}
                maxLength={props.maxLength}
                disabled={props.disabled}
                className={props.disabled ? 'text-input-field-disabled' : 'text-input-field'}
                type={'text'}
                placeholder={props.placeholder}
                value={field.value as string | number | undefined}
                onChange={onChange}
            />
        </Form.Item>
    );
};
