import React, { useState } from 'react';
import { Input } from 'antd';
import { Form, Formik } from 'formik';
import { isDesktop } from 'react-device-detect';
import FolkButton from 'ui-kit/FolkButtons';
import { editPointPower } from 'api/v1.0/editPointPower';
import { handleApiError } from 'api/v1.0/common';
import { validateValuesWithSchema } from 'utils/validation';
import { IPointCapacity, IPointInfo } from 'api/types/v1.0/pointInfo';
import { pointPowerValidationSchema } from './pointPowerValidationSchema';
import { PowerPointField } from './PointPowerField/PowerPointField';
import './PointPowerForm.scss';

export interface IPointPowerFormProps {
    pointId?: number;
    value: IPointInfo;
    onSubmit: () => void;
}

export interface IPointPowerFormValue {
    power: string | null;
}

export const PointPowerForm: React.FC<IPointPowerFormProps> = (props) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values: IPointPowerFormValue) => {
        setLoading(true);
        editPointPower({ Pwr: values.power, Id: props.pointId?.toString() } as IPointCapacity)
            .then(() => {
                setLoading(false);
                props.onSubmit();
            })
            .catch(handleApiError);
    };

    return (
        <div className={'point-power-form-container'}>
            <Formik<IPointPowerFormValue>
                initialValues={{ power: props.value.pwr }}
                enableReinitialize
                onSubmit={handleSubmit}
                validateOnMount
                validate={(values) =>
                    validateValuesWithSchema<IPointPowerFormValue>(values, pointPowerValidationSchema)}
            >
                {
                    (formProps) => (
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className={'point-power-field-title'}>Мощность объекта:</div>
                            <div className={isDesktop ? 'point-input-field-wrapper' : 'point-input-field-wrapper mobile-point-input-field-wrapper'}>
                                <PowerPointField placeholder='Указать' name={'power'} />
                                <Input
                                    className={'power-measure-name'}
                                    placeholder={props.value.pwr_measure_name}
                                    disabled={true}
                                />
                            </div>
                            <FolkButton
                                className={isDesktop ? 'point-power-submit-button' : 'point-power-submit-button mobile-point-power-submit-button'}
                                disabled={!formProps.isValid}
                                type={'primary'}
                                htmlType={'submit'}
                                loading={loading}
                            >Сохранить</FolkButton>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
};
