import { Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import IndicatorButtons from './components/IndicatorButtons/IndicatorButtons';
import IndicatorDatePickers from './components/IndicatorDatePickers/IndicatorDatePickers';
import IndicatorMultiSelectRegions from './components/IndicatorMultiSelectRegions/IndicatorMultiSelectRegions';
import IndicatorStatisticAll from './components/IndicatorStatistics/IndicatorStatisticAll/IndicatorStatisticAll';
import { useProjectIndicators } from './hooks';

import styles from './Indicator.module.scss';

interface IDatePicker {
    start?: string;
    end?: string;
}

export type SelectedIndicatorType = 'all' | 'region' | 'period';

const Indicators: React.FC = () => {
    const [selectedRegion, setSelectedRegion] = useState<number[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<{ start?: string; end?: string; }>({});
    const [selectedTypeIndicator, setSelectedTypeIndicator] = useState<SelectedIndicatorType>('all');
    const { id } = useParams<{ id: string }>();
    const { data } = useProjectIndicators({
        id,
        selectedIndicatorType: selectedTypeIndicator,
        selectedRegion,
        selectedPeriod,
    });

    return (
        <div className={styles['indicator-container']}>
            <div className={styles['indicator-title']}>Показатель</div>
            <IndicatorButtons setTypeIndicator={setSelectedTypeIndicator} />
            <div className={styles['indicator-content']}>
                {selectedTypeIndicator === 'region' && (
                    <IndicatorMultiSelectRegions
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                    />
                )}
                {selectedTypeIndicator === 'period' && (
                    <Formik<IDatePicker>
                        initialValues={{ start: selectedPeriod.start ?? '', end: selectedPeriod.end ?? '' }}
                        onSubmit={(_, actions) => {
                            actions.setSubmitting(false);
                        }}
                    >
                        {() => <IndicatorDatePickers setSelectedPeriod={setSelectedPeriod} />}
                    </Formik>
                )}
                {data[selectedTypeIndicator] ? (<IndicatorStatisticAll data={data[selectedTypeIndicator]} />)
                    : <div className={styles['indicators-moq']}></div>}
            </div>
        </div>
    );
};

export default Indicators;
