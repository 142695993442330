import {
    SET_QUESTIONS,
    SET_LOADING,
    SET_TOTAL_COUNT,
    DELETE_QUESTIONS,
} from './actionTypes';
import { IState } from './types';

export const setQuestions = (questions: IState['questions']) =>
    ({ type: SET_QUESTIONS, payload: questions } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const deleteQuestions = () =>
    ({ type: DELETE_QUESTIONS } as const);
