import { IBarsFilter, IBarsFilterData } from 'api/bars-request-objects';
import { IRegionalDeputyInfo } from 'api/types/v1.0/regionalDeputyInfo';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';

const getFilter = (projectId: number | null, regionId: number | null) => {
    const secondGroupFilters: IBarsFilterData[] = [];

    projectId && secondGroupFilters
        .push({ 'Operand':0,'Value':projectId,'DataIndex':'project_id' });

    regionId && secondGroupFilters
        .push({ 'Operand':0,'Value':regionId,'DataIndex':'region_id' });

    return {
        dataFilter: {
            Group: 2,
            Filters: [{
                Group: 2,
                Filters: secondGroupFilters,
            } as IBarsFilter],
        } as IBarsFilter,
    };
};

export const getRegionalDeputyId = async (projectId: number | null, regionId: number | null) => {
    const response = await axiosPostRequest<{dataFilter: IBarsFilter},
        { data: IRegionalDeputyInfo[] }>(ApiPaths.RegionalDeputyPage, getFilter(projectId, regionId));
    return response.data.data[0]?.regional_coord_id;
};
