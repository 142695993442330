import { HANDLE_SELECT_REGION, HANDLE_SELECT_REGION_NAME, RESET_SELECT_REGION, SET_REGION_COORD } from './actionTypes';

export const handleSelectRegion = (regionId: number | null) =>
    ({ type: HANDLE_SELECT_REGION, payload: regionId } as const);

export const setRegionCoordId = (coord_id: number | null) =>
    ({ type: SET_REGION_COORD, payload: coord_id } as const);

export const handleSelectRegionName = (region_name: string) =>
    ({ type: HANDLE_SELECT_REGION_NAME, payload: region_name } as const);

export const resetSelectRegion = () =>
    ({ type: RESET_SELECT_REGION } as const);
