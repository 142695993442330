import React, { ComponentType, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PrivateRoute from 'components/PrivateRoute';
import useRefreshTokenSimulator from 'hooks/useRefreshTokenSimulator';
import ChatModal from 'components/Layout/components/LayoutHeader/components/Chat/ChatModal';
import ResponsiveBreakpointProvider from 'ui-kit/ResponsiveBreakpointProvider';
import ViewportHeightProvider from 'ui-kit/ViewportHeightProvider';
import Layout from 'components/Layout/Layout';
import { Pages } from 'constants/links';
import RegistrationPage from 'pages/Auth';
import MainPage from 'pages/MainPage';
import PointInfoPage from 'pages/PointInfo';
import ProjectInfoPage from 'pages/ProjectInfo';
import StatesmanPage from 'pages/StatesmanPage';
import NewsPage from 'pages/News/NewsPage';
import NewsDetails from 'pages/News/components/NewsDetails/NewsDetails';
import ReasonToRegistrationPage from 'pages/ReasonToRegistration/ReasonToRegistrationPage';
import HintsPage from 'pages/Hints/HintsPage';
import PrivateApp from './PrivateApp';
import 'antd/dist/antd.css';
import 'react-modal-video/scss/modal-video.scss';
import './App.scss';
import 'moment/locale/ru';
import { RootState } from './root/rootReducer';

const wrapPageToLayout =
    (PageComponent: ComponentType, PostHeader: JSX.Element | undefined = undefined) =>
        () =>
            (
                <Layout postHeader={PostHeader}>
                    <PageComponent />
                </Layout>
            );

const App: React.FC = () => {
    const isChatOpen = useSelector((state: RootState) => state.chat.isChatOpen);
    moment.locale('ru');
    useRefreshTokenSimulator();

    const history = useHistory();
    useEffect(() => {
        return history.listen((location) => {
            if (window.ym) {
                window.ym(64941991, 'hit', location.pathname);
            }

            setTimeout(() => {
                window.scrollTo({ top: -3000, behavior: 'auto' });
            }, 100);
        });
    }, [history]);

    return (
        <ResponsiveBreakpointProvider>
            <ViewportHeightProvider>
                <ConfigProvider locale={locale}>
                    {isChatOpen && <ChatModal />}
                    <Switch>
                        <Route
                            path={Pages.RegistrationLink.url}
                            component={wrapPageToLayout(RegistrationPage)} />
                        <Route
                            path={Pages.RegistrationCallbackLink.url}
                            component={wrapPageToLayout(RegistrationPage)} />
                        <Route
                            exact
                            path={Pages.Main.url}
                            component={wrapPageToLayout(MainPage)} />
                        <Route
                            path={`${Pages.PointInfo.url}/:id`}
                            component={wrapPageToLayout(PointInfoPage)} />
                        <Route
                            path={`${Pages.ProjectInfo.url}/:id`}
                            component={wrapPageToLayout(ProjectInfoPage)} />
                        <Route path={`${Pages.DeputyLink.url}/:deputyType/:id`} component={wrapPageToLayout(StatesmanPage)} />
                        <Route path={Pages.News.url} component={wrapPageToLayout(NewsPage)} />
                        <Route path={`${Pages.NewsDetails.url}/:id`} exact component={wrapPageToLayout(NewsDetails)} />
                        <Route path={Pages.ReasonToRegistration.url}
                            exact
                            component={wrapPageToLayout(ReasonToRegistrationPage)} />
                        <Route path={Pages.Hints.url} exact component={wrapPageToLayout(HintsPage)} />
                        <PrivateRoute component={PrivateApp} />
                    </Switch>
                </ConfigProvider>
            </ViewportHeightProvider>
        </ResponsiveBreakpointProvider>
    );
};

export default App;
