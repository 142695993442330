import { Select } from 'antd';
import { useRegionList } from 'hooks/useRegionList';

interface IProps {
    selectedRegion: number[];
    setSelectedRegion: React.Dispatch<React.SetStateAction<number[]>>;
}

const { Option } = Select;

const IndicatorMultiSelectRegions: React.FC<IProps> = ({ selectedRegion, setSelectedRegion }) => {
    const { data } = useRegionList();

    return (
        <Select
            mode="multiple"
            allowClear
            optionFilterProp="children"
            showSearch={true}
            showArrow
            maxTagCount={'responsive'}
            placeholder="Выберите регион"
            onChange={setSelectedRegion}
            value={selectedRegion}
        >
            {data &&
                data.map((region) => (
                    <Option key={region.Id} value={region.Id}>
                        {region.region}
                    </Option>
                ))}
        </Select>
    );
};

export default IndicatorMultiSelectRegions;
