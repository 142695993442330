import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getDeputyStatistics } from 'api/v1.0/deputyProfile';
import SelectedPointsReducer, { defaultState } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/hooks/pointsReducer';
import * as actionCreators from 'pages/DeputyProfile/components/FederalCoordinatorProfile/hooks/actionCreators';

export const useDeputySelectedPoints = (deputyId: number) => {
    const [state, dispatch] = useReducer(SelectedPointsReducer, defaultState);
    const refresh = () => {
        dispatch(actionCreators.deputyProfileFetch());
        void getDeputyStatistics({ deputy_id: deputyId ,object_id: state.selectedPoints })
            .then((response)=>{
                dispatch(actionCreators.coordinatorPointsSet(response.data));
            }).catch((exception) =>handleApiError(exception))
            .finally(() =>actionCreators.deputyProfileLoad());
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    },[state.selectedPoints]);

    const addSelectedPoint = (point:number) => {
        dispatch(actionCreators.coordinatorSelectedPointAdd(point));
    };

    const removeSelectedPoint = (point:number) => {
        dispatch(actionCreators.coordinatorSelectedPointRemove(point));
    };

    const clearPoints = () => {
        if (state.selectedPoints.length !== 0){
            dispatch(actionCreators.coordinatorSelectedPointsClear());
        }
    };

    const changeSelectedPoint = (point: number) => {
        if (state.selectedPoints.includes(point)) {
            removeSelectedPoint(point);
        }
        else {
            addSelectedPoint(point);
        }
    };

    return {
        statisticsState: state,
        changeSelectedPoint,
        clearPoints,
    };
};
