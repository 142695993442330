import { RESET_CHAT, SET_CHAT_MODAL_STATE, SET_GROUP_MEMBERS_STATE, SET_IS_RESPONSE, SET_SELECTED_CHAT_ID, SET_SELECTED_MESSAGE_ID, SET_SELECTED_PROJECT_ID } from './actionTypes';

export const setChatModalState = (state: boolean) => ({ type: SET_CHAT_MODAL_STATE, payload: state } as const);

export const setGroupMembersState = (state: boolean) =>
    ({ type: SET_GROUP_MEMBERS_STATE, payload: state } as const);

export const setSelectedChatId = (Id: number | null) => ({ type: SET_SELECTED_CHAT_ID, payload: Id } as const);

export const setIsResponse = (isResponse: boolean) => ({ type: SET_IS_RESPONSE, payload: isResponse } as const);

export const setSelectedMessageId = (messageId: number | null) => ({
    type: SET_SELECTED_MESSAGE_ID,
    palyload: messageId,
} as const);

export const setSelectedProjectId = (projectId: number | null) => ({
    type: SET_SELECTED_PROJECT_ID,
    palyload: projectId,
} as const);

export const resetChat = () => ({
    type: RESET_CHAT,
} as const);
