import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IRegionalCoordinatorListRequest,
    IRegionalCoordinatorListResponse,
} from '../types/v1.0/federalCoordinatorRegionalCoordinators';
import { getBarsCoordinatorsFilter, getBarsCoordinatorsSort } from '../bars-request-objects';
import { getCoordinatorPageRequest } from './requestCreator';

export const getFederalCoordinatorRegionalCoordinators = async (request: IRegionalCoordinatorListRequest,
    descending: boolean) => {
    const dataFilter = getBarsCoordinatorsFilter(0,request.deputyId,'Id');
    const sort = getBarsCoordinatorsSort(request.sortType, descending ? 'DESC' : 'ASC');
    const { start, limit } = request;
    const formData = getCoordinatorPageRequest({ dataFilter,sort, start, limit });
    const res = await axiosPostRequest(ApiPaths.FederalCoordinatorRegionalCoordinators, formData);
    return res.data as IRegionalCoordinatorListResponse;
};
