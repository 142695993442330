import { combineReducers, Reducer } from 'redux';
import app, { ActionsType as AppActionsType } from 'ducks/app';
import account, { ActionsType as AccountActionsType } from 'ducks/account';
import selectedProjects, { ActionsType as SelectedProjectsActionsType } from 'ducks/selectedProjects';
import selectedPoint, { ActionsType as SelectedPointActionsType } from 'ducks/selectedPoint';
import notificationIndicatorReducer, { ActionsType as IndicatorActionsType } from 'ducks/notificationIndicator';
import selectedRegion, { ActionsType as SelectedRegionActionsType } from 'ducks/selectedRegion';
import chat, { ActionsType as ChatActionsType } from 'ducks/chat';
import projectsList, { ActionsType as ProjectsListActionsType } from 'ducks/projectsList';
import citizenMenuCitizenEventsReducer, { ActionsType as CitizenEventsCitizenMenuActionsType }
    from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/reducer';
import citizenMenuCitizenGroupsReducer, { ActionsType as CitizenGroupsCitizenMenuActionsType }
    from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/reducer';
import citizenMenuFavoritesReducer, { ActionsType as FavoritesCitizenMenuActionsType }
    from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/reducer';
import citizenMenuLikesReducer, { ActionsType as LikesCitizenMenuActionsType }
    from 'components/Layout/components/Sidebar/CitizenMenu/components/LikesCitizenMenuList/reducer';
import newsSidebarReducer, { ActionsType as newsSidebarActionsType }
    from 'components/Layout/components/Sidebar/News/hooks/newsSidebarReducer';
import mainPage, { ActionsType as mainPageActionsType } from 'ducks/mainPage';

const rootReducer = combineReducers({
    app,
    account,
    selectedProjects,
    selectedPoint,
    selectedRegion,
    projectsList,
    chat,
    citizenMenuCitizenEventsReducer,
    citizenMenuCitizenGroupsReducer,
    citizenMenuFavoritesReducer,
    citizenMenuLikesReducer,
    newsSidebarReducer,
    mainPage,
    notificationIndicatorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type ActionsType = AppActionsType
    | AccountActionsType
    | SelectedProjectsActionsType
    | SelectedPointActionsType
    | ProjectsListActionsType
    | SelectedRegionActionsType
    | FavoritesCitizenMenuActionsType
    | CitizenGroupsCitizenMenuActionsType
    | CitizenEventsCitizenMenuActionsType
    | LikesCitizenMenuActionsType
    | ChatActionsType
    | newsSidebarActionsType
    | IndicatorActionsType
    | mainPageActionsType;

export default rootReducer as Reducer<RootState, ActionsType>;
