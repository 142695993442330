import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { IRegionalCoordinatorDeputiesRequest } from 'api/types/v1.0/regionalCoordinatorDeputies';
import { getRegionalCoordinatorDeputies } from 'api/v1.0/regionalCoordinatorDeputies';
import * as actionCreators from '../components/DeputyPoints/hooks/actionCreators';
import { setSortByAlphabet, setSortByRating } from '../components/DeputyPoints/hooks/actionCreators';
import deputiesReducer, { defaultState } from '../components/DeputyPoints/hooks/deputiesReducer';
import { DeputySortType } from '../../FederalCoordinatorProfile/components/DeputyPoints/hooks/types';

export const useDeputyList = (data: IRegionalCoordinatorDeputiesRequest) => {
    const [state, dispatch] = useReducer(deputiesReducer,defaultState);

    const refreshDeputies = useCallback((start: number) => {
        dispatch(actionCreators.setIsLoading(true));

        data.start = start;
        getRegionalCoordinatorDeputies(data, state.descending)
            .then((response) => {
                dispatch(actionCreators.setDeputies(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refreshDeputies(0);
    }, [refreshDeputies]);

    const changeDeputySort = async (sortType: DeputySortType) => {
        dispatch(actionCreators.clearDeputies());
        switch (sortType) {
            case DeputySortType.surname_n_p: {
                data.sortType = DeputySortType.surname_n_p;
                state.descending = !state.descending;
                const pointsResponse = await getRegionalCoordinatorDeputies(data, state.descending);
                dispatch(actionCreators.setDeputies(pointsResponse.data));
                dispatch(actionCreators.setTotalCount(pointsResponse.totalCount));
                dispatch(setSortByAlphabet());
                break;
            }

            case DeputySortType.rating: {
                data.sortType = DeputySortType.rating;
                state.descending = !state.descending;
                const respRat = await getRegionalCoordinatorDeputies(data, state.descending);
                dispatch(actionCreators.setDeputies(respRat.data));
                dispatch(actionCreators.setTotalCount(respRat.totalCount));
                dispatch(setSortByRating());
                break;
            }
        }
    };

    return {
        stateDeputies: state,
        refreshDeputies,
        changeDeputySort,
    };
};
