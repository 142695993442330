import React from 'react';
import { Menu } from 'antd';
import './DeputySortPoints.scss';
import { PointSortType } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';

export interface IProps {
    onKeyChange: (key: PointSortType) => void;
}

const DeputySortPoints: React.FC<IProps> = (props) => {
    const setByAlphabet = () =>
    {
        props.onKeyChange(PointSortType.title);
    };

    const setByRating = () =>
    {
        props.onKeyChange(PointSortType.rating);
    };

    return (
        <div>
            <Menu
                defaultSelectedKeys={['rating']}
                mode='horizontal'
                items={[
                    {
                        className: 'menu-button',
                        label: 'По рейтингу',
                        key: 'rating',
                        onClick: (()=>{setByRating();}),
                    },
                    {
                        className: 'menu-button',
                        label: 'По алфавиту',
                        key: 'title',
                        onClick: (()=>{setByAlphabet();}),
                    },
                ]}>
            </Menu>
        </div>);
};

export default DeputySortPoints;
