import { isMobile } from 'react-device-detect';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import './ChatConversationHeader.scss';
import { ReactComponent as GroupMembersIcon } from 'components/Layout/components/LayoutHeader/components/Chat/assets/GroupMembersIcon.svg';

export interface IProps {
    groupMembersCount: number;
    onGroupMembersClick: () => void;
}

const ChatConversationHeader: React.FC<IProps> = ({
    groupMembersCount,
    onGroupMembersClick,
}) => {
    return (
        <div className={'chat-conversation-card-header-wrapper'}>
            <div className={'chat-conversation-card-header-and-arrow'}>
                {isMobile && <div className={'chat-conversation-card-header-arrow'}>
                    <ArrowLeftOutlined
                        onClick={() => browserHistory.push(Pages.Chats.url)}
                    />
                </div>}
                <div className={'chat-conversation-card-header'}>
                    <span className={'chat-conversation-card-title'}>Сообщения</span>
                    <div className={'chat-conversation-card-members'}
                        onClick={onGroupMembersClick}
                    >
                        <GroupMembersIcon />  {groupMembersCount}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatConversationHeader;
