import {
    SET_MESSAGES,
    SET_LOADING,
    SET_TOTAL_COUNT,
    DELETE_MESSAGES,
} from './actionTypes';
import { IState } from './types';

export const setMessages = (messages: IState['messages']) =>
    ({ type: SET_MESSAGES, payload: messages } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const deleteMessages = () =>
    ({ type: DELETE_MESSAGES } as const);
