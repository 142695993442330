import { IDeputyListRequest, IDeputyListResponse } from 'api/types/v1.0/deputyList';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { getDeputyListFilter, IHasFilters } from '../bars-request-objects';

export const getDeputyList = async (request: IDeputyListRequest):Promise<IDeputyListResponse> =>
{
    try {
        const filterRequest = {
            dataFilter: getDeputyListFilter(request.search),
            start: request.start,
            limit: request.limit,
            sort: [{ property: 'Id', direction: 'ASC' }],
        };

        const result = await axiosCachePostRequest<IHasFilters, IDeputyListResponse>(
            ApiPaths.DeputyList,
            filterRequest
        );
        return result.data;
    } catch (error) {
        throw Error(error);
    }
};
