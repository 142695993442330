import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { sortRegionsAlphabetiacally } from 'utils/sortRegionsAlphabetically';
import { useRegionListFilter } from 'hooks/useRegionListFilter';
import Icon from 'ui-kit/Icon';
import { IFilterStyleType } from '../ProjectFilter';
import styles from '../SelectorFilter.module.scss'
import { LoadingOutlined } from "@ant-design/icons";
import store from 'App/root/store';
import * as regionActionCreators from 'ducks/selectedRegion/actionCreators';

export interface IRegionFilterProps {
    defaultRegionId?: number;
    onChange?: (regionId: number | null, region_name: string | null) => void;
    disabled?: boolean;
    type?: IFilterStyleType;
    placeholder?: string;
    managedValue?: number | null;
    alwaysShowSuffix?: boolean;
    handleClearSearchCallback?: () => void;
}

export const RegionFilter: React.FC<IRegionFilterProps> = (props) => {
    const { defaultRegionId, onChange } = props;
    const { data, loading } = useRegionListFilter();
    const [regionId, setRegionId] = useState(defaultRegionId);
    const isManagedValue = props.managedValue !== undefined;
    const value = isManagedValue ? props.managedValue : regionId;

    const getSuffixIcon = () => {
        return loading
            ? <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <Icon className={styles['icon']} type={'DropDownArrow'} />;
    }

    return (
        <div className={props.type === IFilterStyleType.outlined ? styles['selector-wrapper-secondary'] : styles['selector-wrapper']}>
            <Select
                className={styles['selector']}
                suffixIcon={(!defaultRegionId || props.alwaysShowSuffix) && getSuffixIcon()}
                value={loading ? undefined : value}
                showSearch
                placeholder={props.placeholder || "Все регионы"}
                optionFilterProp={'label'}
                filterOption
                allowClear
                showArrow={true}
                disabled={props.disabled}
                onChange={(newValue, option) => {
                    setRegionId(newValue);
                    if (onChange) {
                        if (!Array.isArray(option)) {
                            onChange(newValue ?? null, option?.label);
                            store.dispatch(regionActionCreators.handleSelectRegionName(option?.label));
                        }
                    }
                    if (props.handleClearSearchCallback) {
                        props.handleClearSearchCallback();
                    }
                }}
                options={ data ? sortRegionsAlphabetiacally(data).map(region => ({label:region.region, value: region.Id})) : [] }
            />
        </div>
    );
};

export default RegionFilter;
