import { FormikErrors } from 'formik/dist/types';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { userSettingsValidationSchema } from './userSettingsValidationSchema';
import { IUserSettingsFormValues } from './UserSettingsForm';

export const validateUserSettingsValues = (
    values: IUserSettingsFormValues
): FormikErrors<IUserSettingsFormValues> => {
    let errors: FormikErrors<IUserSettingsFormValues> = {};

    try {
        // В параметрах выбрано синхронное выполнение функции.
        /* eslint-disable-next-line */
        validateYupSchema(values, userSettingsValidationSchema, true, values);
    } catch (err) {
        errors = yupToFormErrors(err);
    }

    return errors;
};
