import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { IUserClaims } from 'api/types/v1.0/account';
import { RootState } from 'App/root/rootReducer';
import browserHistory from 'App/root/browserHistory';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import * as jwt from 'App/root/jwt';
import { Pages } from 'constants/links';
import { isOnPageWithoutTabs, isOnPersonalPages, isUserOnMainMages } from 'utils/location';
import styles from './LayoutHeader.module.scss';
import LayoutHeaderTabs from './components/LayoutHeaderTabs';
import HeaderProfile from './components/HeaderProfile';

interface IProps {
    user?: IUserClaims;
    postHeader?: JSX.Element;
}

const LayoutHeader: React.FC<IProps> = ({ postHeader }) => {
    const { user } = useSelector((state: RootState) => state.account);
    const isLoggedIn = !!jwt.get() && !!user;
    const role = user ? user.role : '';
    const nav = useHistory();

    const userOnMainPages = isUserOnMainMages(nav.location.pathname);

    return (
        <>
            <div className={styles.layoutContainer}>
                <div className={styles['layout-header']}>
                    <div className={styles['logo']}></div>
                    <div className={styles['title-menu']}>
                        <div className={
                            userOnMainPages
                                ? [styles.element, styles.selected].join(' ')
                                : styles.element }>
                            <span onClick={() => nav.push(Pages.Main.url)} className={styles['text']}>Главная</span>
                        </div>
                    </div>
                    <div className={styles['right-menu']}>
                        {isLoggedIn && (
                            <HeaderProfile user={user} />
                        )}
                        <Button
                            hidden={isLoggedIn}
                            type="primary"
                            onClick={() => redirect(AuthHelper.getAuthorizeCodeUrl(), false)}
                            className={styles['primaryBtn']}>
                            <span className={styles['text']}>Войти или зарегистрироваться</span>
                        </Button>

                        <Button
                            hidden={isLoggedIn}
                            type="link"
                            className={styles['linkButton']}
                        >
                            <span onClick={() => browserHistory.push(Pages.ReasonToRegistration.url)} className={styles['text']}>Зачем?</span>
                        </Button>
                    </div>
                </div>
                {!isOnPersonalPages(nav.location.pathname) && !isOnPageWithoutTabs(nav.location.pathname) &&
                    <LayoutHeaderTabs userRole={role} />}
                {postHeader &&
                    <div>{postHeader}</div>}
            </div>
        </>
    );
};

export default LayoutHeader;
