import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import {
    ActionsType,
    IPointDocumentsState,
} from './types';

export const defaultState: IPointDocumentsState = {
    pointDocuments: [],
    isLoading: false,
    error: null,
};

const reducer: Reducer<IPointDocumentsState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.EDIT_POINT_DOCS_FETCH:
        case actionTypes.EDIT_POINT_DOCS_ADD:
        case actionTypes.EDIT_POINT_DOCS_REMOVE:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.EDIT_POINT_DOCS_LOAD:
            return {
                ...state,
                isLoading: false,
            };

        case actionTypes.EDIT_POINT_DOCS_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case actionTypes.EDIT_POINT_DOCS_SET:
        {
            return {
                ...state,
                pointDocuments: action.payload,
            };
        }

        default:
            return state;
    }
};

export default reducer;
