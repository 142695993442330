import React, { useState } from 'react';
import { Button, Form, Upload, UploadProps, Image } from 'antd';
import { useField } from 'formik';
import { imageTypes } from 'constants/imageTypes';
import { ReactComponent as UploadIcon } from './assets/UploadIcon.svg';
import { ReactComponent as RemoveIcon } from './assets/RemoveIcon.svg';
import { getBeforeFileUploadHandler } from 'utils/getBeforeFileUploadHandler';
import './UploadImage.scss';

export interface IProps {
    disabled?: boolean;
    name: string;
}

export interface UploadedImage {
    url: string;
    name: string;
}

const UploadImage: React.FC<IProps> = (props) => {
    const { name } = props;
    const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(null);
    const [, meta, { setValue, setTouched }] = useField(name);

    const removeImage = () => {
        setValue(meta.initialValue)
        setTouched(true, false);
        setUploadedImage(null);
    }

    const uploadProps: UploadProps = {
        beforeUpload: getBeforeFileUploadHandler(
            uploadedImage ? [uploadedImage] : [],
            1,
            (fileUrl, file) => {
                setUploadedImage({
                    url: fileUrl,
                    name: file.name,
                });

                setValue(file);
                setTouched(true, false);
            },
            true,
            imageTypes,
        ),
        onRemove: (_) => {
            setValue(meta.initialValue)
            setTouched(true, false);
        },
        multiple: false,
        showUploadList: false,
    };

    return (
        <Form.Item
            className={'upload-image-form'}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            {uploadedImage ? <div>
                <div className={'upload-title'}>Медиа:</div>
                <div className={'image-wrapper'}>
                    <Image
                        className={'uploaded-image'}
                        preview={false}
                        src={uploadedImage?.url}
                    ></Image>
                    <div className="remove-icon" onClick={removeImage}>
                        <RemoveIcon />
                    </div>
                </div>
            </div>
            : <Upload maxCount={1} {...uploadProps}>
                <Button className={'upload-image-button'} icon={<UploadIcon className={'upload-icon'}/>}>
                    Прикрепить изображение
                </Button>
            </Upload>}
        </Form.Item>
    );
};

export default UploadImage;
