import React, { useCallback } from 'react';
import { Form, Select } from 'antd';
import { useField } from 'formik';
import './MultipleSelect.scss';

export interface ISelectFieldsProps {
    name: string;
    options?: ISelectOption[];
    placeholder: string;
    disabled?: boolean;
}

export interface ISelectOption {
    value: string | number;
    label: string;
}

export const MultipleSelect: React.FC<ISelectFieldsProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField<string[]>(name);

    const onSelect = useCallback(
        (newValue: string | number, option: ISelectOption) => {
            if (option.value)
            {
                const fieldValue = option.value;
                if (field.value) {
                    field.value.push(fieldValue.toString());
                    setValue(field.value);
                }
                else {
                    setValue([fieldValue.toString()])
                }
                setTouched(true, false);
            }
            else if ( newValue ) {
                if (field.value) {
                    field.value.push(newValue.toString());
                    setValue(field.value);
                }
                else {
                    setValue([newValue.toString()])
                }
                setTouched(true, false);
            }
        },
        [setValue, setTouched]
    );

    const onDeselect = useCallback(
        (newValue: string | number, option: ISelectOption) => {
            if (option.value)
            {
                const fieldValue = option.value;
                setValue(field.value.filter((value) => value != fieldValue.toString()));
                setTouched(true, false);
            }
            else if ( newValue ) {
                setValue(field.value.filter((value) => value != newValue.toString()));
                setTouched(true, false);
            }
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            initialValue={field.value}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <Select
                mode={'tags'}
                getPopupContainer={trigger => trigger.parentNode}
                disabled={props.disabled}
                className={'select-field'}
                placeholder={props.placeholder}
                value={field.value}
                onSelect={onSelect}
                onDeselect={onDeselect}
                filterOption={(inputValue, option) => {
                    return (option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1) >= 0;
                }}
                options={props.options}
                showSearch
            />
        </Form.Item>
    );
};
