import { useEffect, useState } from 'react';
import { getNewsDetails } from 'api/v1.0/newsDetails';
import { INewsDetailsItem } from 'api/types/v1.0/newsDetails';
import { handleApiError } from 'api/v1.0/common';

const useNewsDetails = (id: string) => {
    const [state, setState] = useState<INewsDetailsItem>();
    const [loading, setLoading] = useState<boolean>(false);

    const refresh = async () => {
        try {
            setLoading(true);
            await getNewsDetails(id).then((result) => {
                setState(result?.data);
                setLoading(false);
            });
        } catch (exception) {
            handleApiError(exception);
            setLoading(false);
        }
    };

    useEffect(() => {
        void refresh();
        //eslint-disable-next-line
    }, []);

    return {
        state,
        loading,
    };
};

export default useNewsDetails;
