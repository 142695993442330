import React from 'react';
import { Button, Divider, Space } from 'antd';
import { Cookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { MobileAppStoreIds } from 'constants/mobileAppStoreIds';
import { ExternalRedirectLinks } from 'constants/externalRedirectUrls';
import useSearchParams from 'hooks/useSearchParams';
import { REDIRECT_MOBILE_FLAG_KEY } from 'constants/cookie';
import { redirect } from 'utils/browser';
import { Pages } from 'constants/links';
import Page from 'components/Page';

const MobileDetector: React.FC = ({ children }) => {
    const params = useSearchParams();
    const redirectUrl = params.get(ExternalRedirectLinks.FolkApp.redirectPath) ?? '';
    const cookies = new Cookies();

    const getPointId = (redirectUrl: string) => {
        const regex = /objectId=(\d+)/;
        const match = regex.exec(redirectUrl);
        return match ? match[1] : null;
    };

    const redirectToPoint = () => {
        const pointId = getPointId(redirectUrl);
        if (pointId){
            redirect(`${Pages.PointInfo.url}/${pointId}`);
        }
    };

    const redirectToApp = () => {
        window.open(`${ExternalRedirectLinks.FolkApp.url.toString()}${redirectUrl}`, '_self');
    };

    const redirectToAndroidAppStore = () => {
        window.open(`${ExternalRedirectLinks.AndroidAppMobileStore.url}?id=${MobileAppStoreIds.AndroidAppStore.id}}`, '_self');
    };

    const redirectToIosAppStore = () => {
        window.open(ExternalRedirectLinks.IosAppMobileStore.url + MobileAppStoreIds.IosAppStore.id, '_self');
    };

    const setRedirectCookie = () => {
        cookies.set(REDIRECT_MOBILE_FLAG_KEY, 'false');
        redirectToPoint();
    };

    const isRedirectCookieSet = () => {
        return !!cookies.get(REDIRECT_MOBILE_FLAG_KEY);
    };

    if (isMobile && !isRedirectCookieSet() && redirectUrl !== '') {
        return (
            <Page
                content={
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button type='primary' block onClick={redirectToApp}>Открыть в приложении</Button>
                        <Button type='primary' block onClick={setRedirectCookie}>Остаться в браузере</Button>
                        <Divider />
                        <Button type='primary' block onClick={redirectToAndroidAppStore}>Скачать приложение для
                            Android</Button>
                        <Button type='primary' block onClick={redirectToIosAppStore}>Скачать приложение для IOS</Button>
                    </Space>
                }
            />
        );
    }

    if (isRedirectCookieSet() && redirectUrl !== '') {
        redirectToPoint();
    }

    return (
        <>
            {children}
        </>
    );
};

export default MobileDetector;
