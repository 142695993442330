import {
    IProjectListRequest,
    IProjectListResponse,
} from 'api/types/v1.0/projectList';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosCachePostRequest } from 'utils/axiosClient';
import { getSort } from '../bars-request-objects';

export const getProjectList = async (request: IProjectListRequest):Promise<IProjectListResponse> =>
{
    try
    {
        const formData = new FormData();
        const sort = getSort(request.sort, 'ASC');
        formData.append('sort', sort);
        const result = await axiosCachePostRequest(ApiPaths.Projects,formData);
        return result.data as IProjectListResponse;
    } catch (error)
    {
        throw Error(error);
    }
};
