export const SET_CITIZEN_EVENTS = 'citizenEvents/SET_DEPUTY_ACTIVITIES' as const;
export const SET_SELECTED_PROJECT = 'citizenEvents/SET_SELECTED_PROJECT';
export const SET_SELECTED_POINT = 'citizenEvents/SET_SELECTED_POINT';
export const SET_LOADING = 'citizenEvents/SET_LOADING' as const;
export const DELETE_CITIZEN_EVENTS = 'citizenEvents/DELETE_DEPUTY_ACTIVITIES' as const;
export const SET_SELECTED_DATE = 'citizenEvents/SET_SELECTED_DATE' as const;
export const SET_TOTAL_COUNT = 'citizenEvents/SET_TOTAL_COUNT' as const;
export const SET_SELECTED_MONTH = 'citizenEvents/SET_SELECTED_MONTH' as const;
export const SET_SELECTED_YEAR = 'citizenEvents/SET_SELECTED_YEAR' as const;
export const SET_MONTH_CITIZEN_EVENTS = 'citizenEvents/SET_MONTH_CITIZEN_EVENTS' as const;
export const SET_CURRENT_PAGE = 'citizenEvents/SET_CURRENT_PAGE' as const;
