import { IPointControlPointsResponse } from 'api/types/v1.0/pointControlPoints';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import { IControlPointsForEditingRequest } from '../types/v1.0/controlPointsForEditing';

export const getControlPointsForEditingAsync = async (objectId: number): Promise<IPointControlPointsResponse> => {
    const request = {
        dataFilter:
            {
                Group: 2,
                Filters:
                    [
                        {
                            Operand: 0,
                            Value: objectId,
                            DataIndex: 'Object_Id',
                        },
                    ],
            },
    };

    const result = await axiosPostRequest<
        IControlPointsForEditingRequest, IPointControlPointsResponse>(ApiPaths.ControlPointsForEditing, request);
    return result.data;
};
