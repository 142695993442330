import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ExpandableListHeaderForMenu from 'ui-kit/ExpandableListForMenu';
import ExpandableListShowAllButton from 'ui-kit/ExpandableListForMenu/components/ExpandableListShowAllButton';
import { Pages } from 'constants/links';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import { RootState } from 'App/root/rootReducer';
import browserHistory from 'App/root/browserHistory';
import { ICitizenGroupItem } from 'api/types/v1.0/citizenGroup';
import styles from './CitizenGroupsCitizenMenuList.module.scss';
import useCitizenGroupsCitizenMenuList from './hooks/useCitizenGroupsCitizenMenuList';

const CitizenGroupsCitizenMenuList: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);

    const {
        groups,
        totalCount,
        isExpanded,
        changeIsExpanded,
        isLoading,
    } = useCitizenGroupsCitizenMenuList(Number(user?.sub));

    const location = useLocation();

    const listName = 'Группы';
    const redirectUrl = Pages.CitizenGroups.url;

    const needToDisplayShowAllButton = isExpanded;

    const handleListItemClick = (x: ICitizenGroupItem) => {
        browserHistory.push(`${Pages.PointInfo.url}/${x.object_id.toString()}`);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    return (
        <>
            <ExpandableListHeaderForMenu
                listName={listName}
                count={totalCount}
                isExpanded={isExpanded}
                setIsExpanded={changeIsExpanded}
                markAsSelected={location.pathname === redirectUrl}
                isLoading={isLoading}
            />
            <div className={styles.expandableListExpandedElementsBlockWrapper}>
                {
                    isExpanded && groups && groups.map((x, index) => {
                        if (index < COUNT_OF_ELEMENTS_TO_SHOW) {
                            return <div
                                onClick={()=>{handleListItemClick(x);}}
                                className={styles.expandedElementItem}
                                key={index}
                            >
                                <div className={styles.titleText}>
                                    {x.object_name}
                                </div>
                                <div className={styles.descriptionText}>
                                    {x.address}
                                </div>
                            </div>;
                        }

                        return <Fragment key={index} />;
                    })
                }
                {
                    needToDisplayShowAllButton &&
                    <ExpandableListShowAllButton
                        redirectUrl={redirectUrl}
                        listName={listName}
                    />
                }
            </div>
        </>
    );
};

export default CitizenGroupsCitizenMenuList;
