import React, { useEffect, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import { usePointList } from 'hooks/usePointList';
import useCitizenObjectList from 'hooks/useCitizenObjectList';
import { IFilterStyleType } from '../ProjectFilter';
import Icon from 'ui-kit/Icon';
import styles from '../SelectorFilter.module.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { compareFilterSearchOptions } from 'utils/comparers';

const { Option } = Select;

export interface IPointFilterProps {
    userId?: string;
    projectId: number | undefined;
    defaultPointId?: number;
    onChange?: (pointId: number | null, projectId?: number | null | undefined) => void;
    disabled?: boolean;
    type?: IFilterStyleType;
    placeholder?: string;
    useCitizenObjectList?: boolean;
    disableClearButton?: boolean;
    alwaysShowSuffix?: boolean;
    managedValue?: number | null;
}

export const PointFilter: React.FC<IPointFilterProps> = (props) => {
    const { projectId, defaultPointId, onChange, userId } = props;
    const [pointId, setPointId] = useState<number | undefined>(defaultPointId);
    const { points, loading } = props.useCitizenObjectList
        ? useCitizenObjectList(Number(userId), projectId)
        : usePointList({ projectId, userId });
    const isManagedValue = props.managedValue !== undefined;
    const value = isManagedValue ? props.managedValue : pointId;
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            setPointId(undefined);
        }
    }, [projectId]);

    const getSuffixIcon = () => {
        return loading
            ? <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <Icon className={styles['icon']} type={'DropDownArrow'} />;
    }

    return (
        <div className={props.type === IFilterStyleType.outlined ? styles['selector-wrapper-secondary'] : styles['selector-wrapper']}>
            <Select
                className={props.disabled ? styles['selector-disabled'] : styles['selector']}
                suffixIcon={(!pointId || props.alwaysShowSuffix) && getSuffixIcon()}
                value={loading ? undefined : value}
                showSearch
                placeholder={props.placeholder || "Выберите объект"}
                optionFilterProp="children"
                allowClear={!props.disableClearButton}
                disabled={props.disabled}
                onChange={(newSelectedPointId: number | undefined) => {
                    setPointId(newSelectedPointId);
                    if (onChange) {
                        const newSelectedPoint = points?.find((point) => Number(point.object_id) === newSelectedPointId);
                        onChange(newSelectedPointId ?? null, Number(newSelectedPoint?.project_id) ?? null);
                    }
                }}
                filterOption={compareFilterSearchOptions}
            >
                {points &&
                    points.map((point) => (
                        <Option key={point.object_id || point.Id} value={point.object_id || point.Id}>
                            {point.object_name}
                        </Option>
                    ))}
            </Select>
        </div>
    );
};
