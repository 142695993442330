import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCitizenEventsFilter, getCitizenEventsSort } from 'api/bars-request-objects';
import { getCitizenEvents } from 'api/v1.0/citizenEvents';
import { handleApiError } from 'api/v1.0/common';
import { RootState } from 'App/root/rootReducer';
import { COUNT_OF_ELEMENTS_TO_SHOW } from 'constants/citizenMenu';
import * as actionCreators from '../reducer';
import { setIsExpanded } from '../reducer';

const useCitizenEventsMenuList = (userId: string) => {
    const globalState = useSelector((state: RootState) => state);
    const citizenEventsState = globalState.citizenMenuCitizenEventsReducer;
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(actionCreators.setLoading(true));
        try {
            const request = {
                start: 0,
                limit: COUNT_OF_ELEMENTS_TO_SHOW,
                sort: getCitizenEventsSort(),
                dataFilter: getCitizenEventsFilter(userId),
            };

            void getCitizenEvents(request)
                .then((response) => {
                    dispatch(actionCreators.setCitizenEvents(response.data));
                    dispatch(actionCreators.setTotalCount(response.totalCount));
                    dispatch(actionCreators.setLoading(false));
                });
        } catch (exception) {
            handleApiError(exception);
            dispatch(actionCreators.setLoading(false));
        }
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    const deleteCitizenEvents = () => {
        dispatch(actionCreators.deleteCitizenEvents());
    };

    const changeIsExpanded = (isExpanded: boolean) => {
        dispatch(setIsExpanded(isExpanded));
    };

    return {
        ...citizenEventsState,
        refresh,
        deleteCitizenEvents,
        changeIsExpanded,
    };
};

export default useCitizenEventsMenuList;
