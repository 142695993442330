import { IInviteUsersResponse, IUsersToInviteRequest, IUsersToInviteResponse } from 'api/types/v1.0/communityFormation';
import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';

export const getUsersToInvite = async (request: IUsersToInviteRequest) => {
    const response = await axiosPostRequest<IUsersToInviteRequest,
        IUsersToInviteResponse>(ApiPaths.UsersToInvite, request);
    return response.data;
};

export const inviteUsers = (request: string) => {
    return axiosPostRequest<string, IInviteUsersResponse>(ApiPaths.InviteToGroupObject, request);
};
