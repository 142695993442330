import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType } from './types';

export interface IRegionState {
    id: number | null;
    regional_coord_id: number | null;
    region_name? : string | null;
}

const defaultState: IRegionState = {
    id: null,
    regional_coord_id: null,
    region_name : null,
};

const reducer: Reducer<IRegionState, ActionsType> = (state, action): IRegionState => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.HANDLE_SELECT_REGION:
        {
            return { ...state, id: action.payload };
        }

        case actionTypes.SET_REGION_COORD:
        {
            return { ...state, regional_coord_id: action.payload };
        }

        case actionTypes.HANDLE_SELECT_REGION_NAME:
        {
            return { ...state, region_name: action.payload };
        }

        case actionTypes.RESET_SELECT_REGION:
        {
            return defaultState;
        }

        default:
            return state;
    }
};

export default reducer;
