import { Reducer } from 'redux';
import { IAccount } from 'types/account';
import * as actionTypes from './actionTypes';
import { ActionsType } from './types';

export interface IState {
    user: IAccount | null;
    isLoading: boolean;
    isTokenChecked: boolean;
}

const defaultState: IState = {
    user: null,
    isLoading: false,
    isTokenChecked: false,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SAVE_ACCOUNT:
            return {
                ...state,
                user: action.payload,
            };

        case actionTypes.LOGOUT:
            return defaultState;

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.IS_TOKEN_CHECKED:
            return {
                ...state,
                isTokenChecked: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
