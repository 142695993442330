import * as yup from 'yup';
import { Shape } from 'utils/validation';
import { IPointPowerFormValue } from './PointPowerForm';

export const pointPowerValidationSchema = yup.object<Shape<IPointPowerFormValue>>(
    {
        power: yup.number().test('is-zero', 'Нельзя вводить число ниже нуля', (value) => value as number >= 0)
            .nullable(),
    }
);
