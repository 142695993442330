import { IFederalCoordinatorProfile } from 'api/types/v1.0/federalCoordinatorProfile';
import { ICoordinatorStatistics } from 'api/types/v1.0/coordinatorStatistics';
import {
    DEPUTY_PROFILE_FETCH,
    DEPUTY_PROFILE_LOAD,
    DEPUTY_PROFILE_SET,
    DEPUTY_PROFILE_ERROR,
    COORDINATOR_POINTS_SET,
    COORDINATOR_SELECTED_POINTS_ADD,
    COORDINATOR_SELECTED_POINTS_REMOVE,
    COORDINATOR_SELECTED_POINTS_CLEAR,
} from './actionTypes';

export const deputyProfileFetch = () =>
    ({ type: DEPUTY_PROFILE_FETCH } as const);

export const deputyProfileLoad = () =>
    ({ type: DEPUTY_PROFILE_LOAD } as const);

export const coordinatorPointsSet = (points:ICoordinatorStatistics) =>
    ({ type: COORDINATOR_POINTS_SET, payload: points } as const);

export const coordinatorSelectedPointAdd = (point:number) =>
    ({ type: COORDINATOR_SELECTED_POINTS_ADD, payload: point } as const);

export const coordinatorSelectedPointRemove = (point:number) =>
    ({ type: COORDINATOR_SELECTED_POINTS_REMOVE, payload: point } as const);

export const coordinatorSelectedPointsClear = () =>
    ({ type: COORDINATOR_SELECTED_POINTS_CLEAR } as const);

export const deputyProfileSet = (profile: IFederalCoordinatorProfile) =>
    ({ type: DEPUTY_PROFILE_SET, payload: profile } as const);

export const deputyProfileError = (error: string) =>
    ({ type: DEPUTY_PROFILE_ERROR, payload: error } as const);
