import React, { useState } from 'react';
import ExpandableListHeaderForMenu from 'ui-kit/ExpandableListForMenu';

const QuestionsCitizenMenuList: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const listName = 'Вопросы';

    return (
        <>
            <ExpandableListHeaderForMenu
                listName={listName}
                count={0}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                disabled={true}
                markAsSelected={false}
                isLoading={false}
            />
        </>
    );
};

export default QuestionsCitizenMenuList;
