import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IRegionalCoordinatorDeputiesRequest,
    IRegionalCoordinatorDeputiesResponse,
} from '../types/v1.0/regionalCoordinatorDeputies';
import { getBarsCoordinatorsFilter, getBarsCoordinatorsSort } from '../bars-request-objects';
import { getCoordinatorPageRequest } from './requestCreator';

export const getRegionalCoordinatorDeputies = async (request: IRegionalCoordinatorDeputiesRequest,
    descending: boolean) => {
    const dataFilter = getBarsCoordinatorsFilter(0,request.deputyId,'Id');
    const sort = getBarsCoordinatorsSort(request.sortType, descending ? 'DESC' : 'ASC');
    const formData = getCoordinatorPageRequest({ dataFilter,sort });
    const res = await axiosPostRequest(ApiPaths.RegionalCoordinatorDeputies, formData);
    return res.data as IRegionalCoordinatorDeputiesResponse;
};
