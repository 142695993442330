import { useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { mapToObjectList } from 'helpers/citizenFilterMapHelpers';
import { IObjectPoint } from 'api/types/v1.0/points';
import { getCitizenEvents } from 'api/v1.0/citizenEvents';
import { MAX_INT } from 'constants/numbers';

const useCitizenObjectList = (userId: number, projectId?: number) => {
    const [points, setPoints] = useState<IObjectPoint[] | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const request = {
                    start: 0,
                    limit: MAX_INT,
                    dataFilter: {
                        Group: 2,
                        Filters: [
                            {
                                Operand: 0,
                                Value: userId,
                                DataIndex: 'citizen_id',
                            },
                        ],
                    },
                };
                const response = await getCitizenEvents(request);
                const mappedResponse = mapToObjectList(response);
                setPoints(mappedResponse);
            } catch (exception) {
                handleApiError(exception);
            } finally {
                setLoading(false);
            }
        };

        void fetch();
    }, [userId, projectId]);

    return {
        points,
        loading,
    };
};

export default useCitizenObjectList;
