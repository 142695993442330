import { Form, Switch } from 'antd';
import React, { useCallback } from 'react';
import './SwitchField.scss';
import { useField } from 'formik';

export interface ISwitchFieldProps {
    title: string;
    name: string;
    disabled?: boolean;
    setTouched?: (isTouched: boolean) => void|undefined;
}

export const SwitchField: React.FC<ISwitchFieldProps> = (props) => {
    const { name, title } = props;

    const [field, , { setValue, setTouched }] = useField<boolean>(name);

    const onChange = useCallback(
        (checked: boolean) => {
            props.setTouched?.(true);
            setValue(checked);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item>
            <div className={'switch-field-wrapper'}>
                <div className={'switch'}>
                    <Switch
                        onChange={onChange}
                        checked={field.value}
                        disabled={props.disabled}
                    />
                </div>
                <div className={'switch-field-title'}>{title}</div>
            </div>
        </Form.Item>
    );
};
