import React, { useEffect, useState } from 'react';
import { List, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ICitizenEvent } from 'api/types/v1.0/citizenEvent';
import { defaultCurrent, defaultPageSize } from '../../../MainPage/components/const/pagination';
import { CitizenEventItem } from './components/CitizenEventItem';
import './CitizenEventsList.scss';

export interface ICitizenEventsListProps {
    citizenEvents?: ICitizenEvent[] | null;
    refresh?: (take: number, skip: number) => void;
    loading: boolean;
    totalCount: number;
    pageByDate?: number;
}

export const CitizenEventsList: React.FC<ICitizenEventsListProps> = (props) => {
    const [customPage, setCustomPage] = useState<number | undefined>();

    useEffect(() => {
        setCustomPage(props.pageByDate);
    }, [props.pageByDate]);

    const layoutLoading = (
        props.loading
            ? <Spin spinning={props.loading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            : <div>
                <div className={'no-activities-message'}>Мероприятий нет</div>
            </div>
    );

    return (
        props.citizenEvents && props.citizenEvents.length !== 0 ?
            <div>
                <List
                    className={'citizen-events-list'}
                    dataSource={props.citizenEvents ?? undefined}
                    renderItem={(data) => (
                        <div key={data.Id}>
                            <CitizenEventItem citizenEvent={data} />
                        </div>
                    )}
                />
                <Pagination
                    total={props.totalCount}
                    defaultCurrent={defaultCurrent}
                    current={customPage}
                    pageSizeOptions={[defaultPageSize]}
                    showSizeChanger={false}
                    hideOnSinglePage
                    onChange={(page: number) => {
                        const skip = (page - 1) * defaultPageSize;
                        props.refresh?.(defaultPageSize, skip);
                        setCustomPage(page);
                    }}
                />
            </div>
            :
            layoutLoading
    );
};
