const Vk = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7260_7850)">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.36 0H12C6.34315 0 3.51472 0 1.75736 1.75736C0 3.51472 0 6.34315 0 12V15.36V16.64V20C0 25.6569 0 28.4853 1.75736 30.2426C3.51472 32 6.34315 32 12 32H15.36H16.64H20C25.6569 32 28.4853 32 30.2426 30.2426C32 28.4853 32 25.6569 32 20V16.64V15.36V12C32 6.34315 32 3.51472 30.2426 1.75736C28.4853 0 25.6569 0 20 0H16.64H15.36ZM17.0267 23.0534C9.7334 23.0534 5.57341 18.0534 5.40008 9.7334H9.0534C9.1734 15.8401 11.8667 18.4267 14 18.9601V9.7334H17.4401V15.0001C19.5468 14.7734 21.7599 12.3734 22.5065 9.7334H25.9466C25.3733 12.9867 22.9733 15.3867 21.2666 16.3734C22.9733 17.1734 25.7067 19.2667 26.7467 23.0534H22.96C22.1467 20.5201 20.1201 18.5601 17.4401 18.2934V23.0534H17.0267Z" fill="#0077FF"/>
        </g>
        <defs>
            <clipPath id="clip0_7260_7850">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default Vk;
