import { IAccount } from 'types/account';
import {
    SAVE_ACCOUNT,
    INIT_ACCOUNT,
    FETCH_ACCOUNT,
    LOGOUT,
    SET_LOADING,
    LOGIN,
    IS_TOKEN_CHECKED,
} from './actionTypes';

export const initAccount = (isForTokenCheck: boolean) => ({ type: INIT_ACCOUNT, payload: isForTokenCheck } as const);

export const saveAccount = (user: IAccount) => ({ type: SAVE_ACCOUNT, payload: user } as const);

export const fetchAccount = () => ({ type: FETCH_ACCOUNT } as const);

export const logout = () => ({ type: LOGOUT } as const);

export const login = (token: string, isForTokenCheck?: boolean) =>
    ({ type: LOGIN, payload: { token, isForTokenCheck } } as const);

export const setIsLoading = (isLoading: boolean) => ({ type: SET_LOADING, payload: isLoading } as const);

export const setIsTokenChecked = (isTokenChecked: boolean) =>
    ({ type: IS_TOKEN_CHECKED, payload: isTokenChecked } as const);
