export const SET_DEPUTY_ACTIVITIES = 'deputyActivities/SET_DEPUTY_ACTIVITIES' as const;
export const SET_SELECTED_PROJECT = 'deputyActivities/SET_SELECTED_PROJECT';
export const SET_SELECTED_POINT = 'deputyActivities/SET_SELECTED_POINT';
export const SET_LOADING = 'deputyActivities/SET_LOADING' as const;
export const DELETE_DEPUTY_ACTIVITIES = 'deputyActivities/DELETE_DEPUTY_ACTIVITIES' as const;
export const SET_SELECTED_DATE = 'deputyActivities/SET_SELECTED_DATE' as const;
export const SET_TOTAL_COUNT = 'deputyActivities/SET_TOTAL_COUNT' as const;
export const SET_SELECTED_MONTH = 'deputyActivities/SET_SELECTED_MONTH' as const;
export const SET_SELECTED_YEAR = 'deputyActivities/SET_SELECTED_YEAR' as const;
export const SET_MONTH_ACTIVITIES = 'deputyActivities/SET_MONTH_ACTIVITIES' as const;

export const SET_CURRENT_PAGE = 'deputyActivities/SET_CURRENT_PAGE' as const;
