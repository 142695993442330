import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getPointInfoAsync } from '../api/v1.0/pointInfo';

export const usePointInfo = (pointId: number | undefined) => {
    const [projectId, setProjectId] = useState<number | undefined>();

    const fetch = () => {
        if (pointId) {
            getPointInfoAsync({ id: pointId })
                .then((response) => {
                    setProjectId(response.data.project_id);
                })
                .catch((e) => handleApiError(e));
        }
    };

    const refresh = useCallback(
        () => fetch(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [projectId]
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        projectId,
        setProjectId,
        refresh,
    };
};
