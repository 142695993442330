import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { PageSize } from 'constants/PageSize';
import { IDeputyPointsResponse, IDeputyPointsRequest } from '../types/v1.0/deputyPoints';
import { getDataFilter, getSort } from '../bars-request-objects';

export const getDeputyPoints = async (request: IDeputyPointsRequest,
    descending: boolean, isInfinityScroll: boolean) => {
    const formData = new FormData();
    const dataFilter = getDataFilter(request.deputyId);
    const sort = getSort(request.sortType, descending ? 'DESC' : 'ASC');
    formData.append('dataFilter', dataFilter);
    formData.append('sort', sort);
    formData.append('start', request.page.start?.toString());
    formData.append('end', request.page.end?.toString());
    if (isInfinityScroll)
    {
        formData.append('limit', request.page.end.toString());
    } else {
        formData.append('page', '1');
        formData.append('limit', PageSize.toString());
    }

    const res = await axiosPostRequest(ApiPaths.DeputyPoints, formData);
    return res.data as IDeputyPointsResponse;
};
