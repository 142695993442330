import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { DeputySortType, ICoordinatorsState } from './types';

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

export const defaultState: ICoordinatorsState = {
    coordinators: null,
    isLoading: false,
    sortType: DeputySortType.rating,
    descending: true,
    totalCount: 0,
};

const coordinatorsReducer: Reducer<ICoordinatorsState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_REGIONAL_COORDINATORS:
            return {
                ...state,
                coordinators: [...state.coordinators ?? [], ...action.payload ?? []],
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_TOTALCOUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.CLEAR_COORDINATOR_LIST:
            return {
                ...state,
                coordinators: null,
                totalCount: 0,
            };
        case actionTypes.SET_SORT_BY_ALPHABET:
            return {
                ...state,
                sortType: DeputySortType.surname_n_p,
            };
        case actionTypes.SET_SORT_BY_RATING:
            return {
                ...state,
                sortType: DeputySortType.rating,
            };
        default:
            return state;
    }
};

export default coordinatorsReducer;
