import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { INewsGeneratorValues } from 'pages/NewsGenerator/components/NewsGeneratorForm/NewsGeneratorFormFields';
import { IHasId } from 'api/types/v1.0/deputyActivities';
import { Role } from 'types/account';

export interface INewsCreateRequest {
    deputy_id?: IHasId;
    project_id?: IHasId;
    object_id?: IHasId;
    object_event_id?: IHasId;
    heading?: string;
    text?: string;
    newsType?: number;
}

export interface INewsCreateResponse {
    data: {
        Id: number;
    }
}

export const createNews = async (data: INewsGeneratorValues) =>
{
    const newsType = data.deputyRole === Role.Regional ? 1 : 0;
    const request =
        {
            deputy_id: data.deputy_id,
            project_id: { Id: data.projectId } as IHasId,
            object_id: { Id: data.pointId } as IHasId,
            object_event_id: { Id: data.activityId } as IHasId,
            heading: data.title,
            text: data.description,
            newsType:
                data.deputyRole === undefined || data.deputyRole === Role.Municipal || data.deputyRole === Role.Citizen
                    ? ''
                    : newsType,
        } as INewsCreateRequest;

    const response = await axiosPostRequest<{ modelData: INewsCreateRequest },
        INewsCreateResponse>(ApiPaths.NewsPageCreate, { modelData: request });
    return response.data;
};
