import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Divider, List, Skeleton } from 'antd';
import Page from 'components/Page';
import { INewsItem } from 'api/types/v1.0/news';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import NewsPageHeader from './components/NewsPageHeader/NewsPageHeader';
import MainNews from './components/MainNews/MainNews';
import NewsCard from './components/NewsCard/NewsCard';
import NewsFilter from './components/NewsFilter/NewsFilter';
import useNewsPage from './hooks';
import styles from './NewsPage.module.scss';

const NewsPage: React.FC = () => {
    const {
        news,
        totalCount,
        refresh,
        isLoading,
        selectedDeputyId,
        selectedRegionId,
        changeSelectedRegion,
        selectedProjectId,
        selectedStartDate,
        selectedEndDate,
        selectedObjectId,
        changeSelectedDeputy,
        changeSelectedObject,
        changeSelectedProject,
        changeSelectedStartDate,
        changeSelectedEndDate,
        submitFilterChanges,
        clearFilters,
    } = useNewsPage();

    return (
        <Page
            content={
                <Page.Content>
                    <div className={styles.newsPageWrapper}>
                        <Card className={styles.newsListWrapper}>
                            <OnLoadingSpinner isLoading={isLoading} content={<div>
                                <NewsPageHeader newsCount={totalCount} />
                                <Divider />
                                <div>
                                    <MainNews mainNews={news ? news[0] : undefined} />
                                </div>
                                <div className={styles.newsList}>
                                    <Divider />
                                    <InfiniteScroll
                                        dataLength={news ? news.length : 0}
                                        next={refresh}
                                        hasMore={news ? news.length < totalCount : false}
                                        loader={<Skeleton paragraph={{ rows: 2 }} active />}
                                    >
                                        <List
                                            dataSource={news?.slice(1)}
                                            renderItem={(newsInfo: INewsItem) => (
                                                <div className={styles.newsCardWrapper}>
                                                    <NewsCard newsInfo={newsInfo} />
                                                </div>
                                            )}
                                        />
                                    </InfiniteScroll>
                                </div>
                            </div>}></OnLoadingSpinner>
                        </Card>
                        <Card className={styles.newsFilterWrapper}>
                            <NewsFilter
                                isLoading={isLoading}
                                deputyId={selectedDeputyId}
                                projectId={selectedProjectId}
                                pointId={selectedObjectId}
                                regionId={selectedRegionId}
                                endDate={selectedEndDate}
                                startDate={selectedStartDate}
                                setSelectedRegionId={changeSelectedRegion}
                                setSelectedProjectId={changeSelectedProject}
                                setSelectedDeputyId={changeSelectedDeputy}
                                setSelectedObjectId={changeSelectedObject}
                                setSelectedEndDate={changeSelectedEndDate}
                                setSelectedStartDate={changeSelectedStartDate}
                                clearFilters={clearFilters}
                                submitFilterChanges={submitFilterChanges}
                            />
                        </Card>
                    </div>
                </Page.Content>
            }
        />
    );
};

export default NewsPage;
