import { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { ICitizenGroupRequest } from 'api/types/v1.0/citizenGroup';
import { getCitizenGroup, leaveCitizenGroup } from 'api/v1.0/citizenGroup';
import { handleApiError } from 'api/v1.0/common';
import {
    setIsExpanded,
    deleteCitizenGroupFromGroupsList,
    deleteGroups as deleteCitizenMenuGroups,
    setGroups as setCitizenMenuGroups,
} from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/reducer';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useCitizenGroupsPage = (userId?: number) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const globalDispatch = useDispatch();

    const refresh = (input?: string) => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: state.groups ? state.groups.length : 0,
            limit: 10,
            ObjectName: input,
            CitizenId: userId,
        } as ICitizenGroupRequest;

        void getCitizenGroup(request)
            .then((response) => {
                dispatch(actionCreators.setGroups(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((exception) => handleApiError(exception))
            .finally(() => dispatch(actionCreators.setLoading(false)));
    };

    const setGroupsByInput = (input: string) => {
        dispatch(actionCreators.deleteGroups());
        refresh(input);
    };

    const deleteGroup = (groupId: number) => {
        dispatch(actionCreators.deleteGroup(groupId));
    };

    const setTotalCount = (totalCount: number) => {
        dispatch(actionCreators.setTotalCount(totalCount));
    };

    const setConfirmModalOpen = (isOpen: boolean, objectId?: number) => {
        dispatch(actionCreators.setConfirmModalOpen(isOpen, objectId));
    };

    const leaveGroup = () => {
        dispatch(actionCreators.setLoading(true));
        void leaveCitizenGroup({ objectId: state.selectedObjectId })
            .then((response) => {
                if (response.success) {
                    dispatch(actionCreators.deleteGroup(state.selectedObjectId));
                    dispatch(actionCreators.setTotalCount(state.totalCount - 1));
                    globalDispatch(deleteCitizenGroupFromGroupsList());
                    globalDispatch(deleteCitizenMenuGroups());
                    if (state.groups) {
                        globalDispatch(setCitizenMenuGroups(
                            state.groups.filter((x) => x.object_id !== state.selectedObjectId)));
                    }
                }

                setConfirmModalOpen(false);
            })
            .catch((exception) => handleApiError(exception))
            .finally(() => dispatch(actionCreators.setLoading(false)));
    };

    useEffect(() => {
        globalDispatch(setIsExpanded(false));
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...state,
        refresh,
        setGroupsByInput,
        deleteGroup,
        setTotalCount,
        setConfirmModalOpen,
        leaveGroup,
    };
};

export default useCitizenGroupsPage;
