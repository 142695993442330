import React from 'react';
import browserHistory from 'App/root/browserHistory';
import styles from './ExpandableListShowAllButton.module.scss';

interface IProps {
    redirectUrl: string;
    listName: string;
}

const ExpandableListShowAllButton: React.FC<IProps> = (props) => {
    const buttonText = `Все ${props.listName}`;
    const onShowAllButtonClick = () => {
        browserHistory.push(props.redirectUrl);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    return <div className={styles.expandedElementShowAll}>
        <span onClick={onShowAllButtonClick}>{buttonText}</span>
    </div>
};

export default ExpandableListShowAllButton;
