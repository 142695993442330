import React from 'react';
import { isDesktop } from 'react-device-detect';
import { Card, Row, Skeleton } from 'antd';
import Page from 'components/Page';
import { TabType } from 'ducks/mainPage';
import FolkCarousel from '../../ui-kit/Carousel/FolkCarousel';
import DesktopContainerFilter from './components/containerFilters/DesktopContainerFilter';
import MobileContainerFilter from './components/containerFilters/MobileContainerFilter';
import { PointsMap } from './components/PointsMap';
import { PointsList } from './components/PointsList';
import useMainPage from './hooks';
import BlankContent from './components/BlankContent';

import './MainPage.scss';

const MainPage: React.FC = () => {
    const mainHook = useMainPage();

    const handleClearSearch = () => {
        if (mainHook.searchControl) {
            mainHook.searchControl.clear();
        }
    };

    const dropSelectedRegion = () => {
        mainHook.changeSelectedRegion(null, null);
    };

    const handleSelectPoint = () => {
        handleClearSearch();
    };

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <FolkCarousel content={BlankContent} />
                    <Card className={'main-card'}>
                        <Row gutter={[24, 16]} className={'tool-bar'}>
                            {isDesktop ? (
                                <DesktopContainerFilter
                                    selectedProjects={mainHook.selectedProjects}
                                    changeSelectedProject={mainHook.changeSelectedProject}
                                    selectedRegionId={mainHook.selectedRegionId}
                                    changeSelectedRegion={mainHook.changeSelectedRegion}
                                    changePointsVisualisation={mainHook.changePointsVisualisation}
                                    pointsVisualisation={mainHook.pointsVisualisation}
                                    handleClearSearchCallback={handleClearSearch}
                                />
                            ) : (
                                <MobileContainerFilter
                                    selectedProjects={mainHook.selectedProjects}
                                    changeSelectedProject={mainHook.changeSelectedProject}
                                    selectedRegionId={mainHook.selectedRegionId}
                                    changeSelectedRegion={mainHook.changeSelectedRegion}
                                    changePointsVisualisation={mainHook.changePointsVisualisation}
                                    pointsVisualisation={mainHook.pointsVisualisation}
                                />
                            )}
                        </Row>
                        <div hidden={mainHook.pointsVisualisation !== TabType.map}>
                            <PointsMap
                                points={mainHook.mapPoints}
                                center={mainHook.mapCenter}
                                geocodedPoint={mainHook.mapRegionName}
                                selectedPoint={mainHook.selectedPoint}
                                changeMapCenter={mainHook.changeMapCenter}
                                setSearchControlRef={(ref) => {
                                    mainHook.setSearchControl(ref);
                                }}
                                dropSelectedRegion={dropSelectedRegion}
                                onPointClick={handleSelectPoint}
                            />
                        </div>
                        <div hidden={mainHook.pointsVisualisation === TabType.map}>
                            {
                                mainHook.isListLoading
                                    ? <Skeleton paragraph={{ rows: 1 }} style={{ padding: 20 }} active />
                                    : <PointsList
                                        refresh={mainHook.fetchRefreshList}
                                        listPoints={{ data: mainHook.listPoints, totalCount: mainHook.totalItems }}
                                        onPointClick={handleSelectPoint}
                                    />
                            }
                        </div>
                    </Card>
                </Page.Content>
            }
        />
    );
};

export default MainPage;
