import { useCallback, useEffect, useReducer } from 'react';
import { getFederalCoordinatorRegionalCoordinators } from 'api/v1.0/federalCoordinatorRegionalCoordinators';
import { handleApiError } from 'api/v1.0/common';
import { IRegionalCoordinatorListRequest } from 'api/types/v1.0/federalCoordinatorRegionalCoordinators';
import * as actionCreators from '../components/DeputyPoints/hooks/actionCreators';
import { setSortByAlphabet, setSortByRating } from '../components/DeputyPoints/hooks/actionCreators';
import coordinatorsReducer, { defaultState } from '../components/DeputyPoints/hooks/coordinatorsReducer';
import { DeputySortType } from '../components/DeputyPoints/hooks/types';

export const useRegionalCoordinatorList = (data: IRegionalCoordinatorListRequest) => {
    const [state, dispatch] = useReducer(coordinatorsReducer,defaultState);

    const refreshCoordinators = useCallback((start: number) => {
        dispatch(actionCreators.setIsLoading(true));
        data.start = start;

        getFederalCoordinatorRegionalCoordinators(data, state.descending)
            .then((response) => {
                dispatch(actionCreators.setRegionalCoordinators(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refreshCoordinators(0);
    }, [refreshCoordinators]);

    const changeDeputySort = async (sortType: DeputySortType) => {
        data.start = 0;
        dispatch(actionCreators.clearCoordinatorList());
        switch (sortType) {
            case DeputySortType.surname_n_p: {
                data.sortType = DeputySortType.surname_n_p;
                state.descending = !state.descending;
                const pointsResponse = await getFederalCoordinatorRegionalCoordinators(data, state.descending);
                dispatch(actionCreators.setRegionalCoordinators(pointsResponse.data));
                dispatch(actionCreators.setTotalCount(pointsResponse.totalCount));
                dispatch(setSortByAlphabet());
                break;
            }

            case DeputySortType.rating: {
                data.sortType = DeputySortType.rating;
                state.descending = !state.descending;
                const respRat = await getFederalCoordinatorRegionalCoordinators(data, state.descending);
                dispatch(actionCreators.setRegionalCoordinators(respRat.data));
                dispatch(actionCreators.setTotalCount(respRat.totalCount));
                dispatch(setSortByRating());
                break;
            }
        }
    };

    return {
        stateDeputies: state,
        refreshCoordinators,
        changeDeputySort,
    };
};
