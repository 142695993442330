import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiError } from 'api/v1.0/common';
import { INewsList } from 'api/types/v1.0/news';
import { getNewsList } from 'api/v1.0/getNewslist';
import { RootState } from 'App/root/rootReducer';
import { getCitizenGroup } from 'api/v1.0/citizenGroup';
import { ICitizenGroupRequest } from 'api/types/v1.0/citizenGroup';
import { setFederalNews, setLoading, setRegionalNews } from './actionCreators';
import { TypeOfNews } from './types';

const useNewsSidebar = (type: TypeOfNews) => {
    const newsSidebarState = useSelector((state: RootState) => state.newsSidebarReducer);
    const statePointId = useSelector((state: RootState) => state.selectedPoint).selectedPoint?.id;
    const { user } = useSelector((state: RootState) => state.account);
    const [newsNumber, setNewsNumber] = useState<number>(0);
    const dispatch = useDispatch();

    const isPointNewsPriority = window.location.href.indexOf('point-info') > -1
        || window.location.href.indexOf('deputy/deputy') > -1;
    const pagingOffset = 3;

    const getRegionalNews = async () => {
        let userGroupPoints: number[] | undefined = [];
        if (user?.sub) {
            const request = {
                CitizenId: Number(user?.sub),
            } as ICitizenGroupRequest;

            await getCitizenGroup(request).then((response) => {
                userGroupPoints = response.data.map((group) => group.object_id);
            });
        }

        void getNewsList({
            type,
            pointId: isPointNewsPriority ? Number(statePointId) : undefined,
            groupPointsId: userGroupPoints,
            pageCurrentObject: isPointNewsPriority,
        }, 0, newsNumber + pagingOffset).then((response) => {
            const resp = response as INewsList;
            const news = resp.data;

            setNewsNumber(newsNumber + pagingOffset);
            dispatch(setRegionalNews(news));
        }).catch((exception) => {
            handleApiError(exception);
        });
    };

    const getFederalNews = () => {
        void getNewsList({ type }, 0, newsNumber + pagingOffset).then((response) => {
            const resp = response as INewsList;
            const news = resp.data;

            setNewsNumber(newsNumber + pagingOffset);
            dispatch(setFederalNews(news));
        }).catch((exception) => {
            handleApiError(exception);
        });
    };

    const refresh = () => {
        dispatch(setLoading(true));

        switch (type) {
            case TypeOfNews.Federal:
                getFederalNews();
                break;
            case TypeOfNews.Regional:
                void getRegionalNews();
                break;
        }

        dispatch(setLoading(false));
    };

    const clearNewsList = () => {
        setNewsNumber(0);
        dispatch(setFederalNews([]));
        dispatch(setRegionalNews([]));
    };

    useEffect(() => {
        refresh();
        // eslint-disable-next-line
    }, [type]);

    return {
        state: newsSidebarState,
        clearNewsList,
        refresh,
    };
};

export default useNewsSidebar;
