import React from 'react';
import { Col, Row } from 'antd';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { PointFilter } from 'ui-kit/filters/PointFilter';
import styles from '../../ActivitiesSchedulerPage.module.scss';

interface IProps {
    userId: string | undefined;
    changeSelectedProject: (id: number | null) => void;
    changeSelectedPoint: (id: number | null) => void;
    selectedProjectId: number | undefined;
    defaultProjectId: number | undefined;
    defaultPointId: number | undefined;
}

const DesktopContainerFilter: React.FC<IProps> = ({
    userId,
    changeSelectedProject,
    changeSelectedPoint,
    selectedProjectId,
    defaultProjectId,
    defaultPointId,
}) => {
    return (
        <>
            <Row gutter={24} className={styles['toolBar']}>
                <Col span={12}>
                    <span className={styles['title']}>Проект:</span>
                    <ProjectFilter
                        userId={userId}
                        onChange={changeSelectedProject}
                        alwaysShowSuffix={true}
                        defaultProjectId={defaultProjectId}
                    />
                </Col>
                <Col span={12}>
                    <span className={styles['titleObject']}>Объект:</span>
                    <PointFilter
                        userId={userId}
                        onChange={changeSelectedPoint}
                        alwaysShowSuffix={true}
                        projectId={selectedProjectId}
                        defaultPointId={defaultPointId}
                    />
                </Col>
            </Row>
        </>
    );
};

export default DesktopContainerFilter;
