import { useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getProjectList } from 'api/v1.0/projectList';
import { IProjectListResponse, IProjectListItem } from 'api/types/v1.0/projectList';
import { getBarsProjectDeputyActivityFilter } from 'api/bars-request-objects';
import { deputyProjects } from 'api/v1.0/deputyProjects';

const useProjectList = (userId?: string) => {
    const [state, setState] = useState<IProjectListResponse>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                if (userId)
                {
                    const response = await deputyProjects({
                        dataFilter: getBarsProjectDeputyActivityFilter(userId),
                    });
                    const projectList = response.data.map((project) => {
                        return {
                            project_name: project.project_name,
                            Id: project.project_id,
                            deputy_Ref_Id: project.Id,
                            type_project: project.type_project,
                        } as IProjectListItem;
                    });
                    const data = {
                        data: projectList,
                    } as IProjectListResponse;

                    setState(data);
                } else {
                    const response = await getProjectList({ sort:'project_name' });
                    setState(response);
                }

                setLoading(false);
            } catch (exception) {
                handleApiError(exception);
                setLoading(false);
            }
        };

        void fetch();
    }, [userId]);

    return {
        ...state,
        loading,
    };
};

export default useProjectList;
