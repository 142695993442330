import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    favorites: null,
    isLoading: false,
    totalCount: 0,
    deletedFavorite: null,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_FAVORITES:
            return {
                ...state,
                favorites: [...state.favorites ?? [], ...action.payload ?? []],
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };
        case actionTypes.DELETE_FAVORITES:
            return {
                ...state,
                favorites: [],
            };
        case actionTypes.DELETE_FAVORITE:
            return {
                ...state,
                favorites: state.favorites
                    ? state.favorites.filter((value) => value.Id !== action.payload)
                    : null,
            };
        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
