import React from 'react';
import styles from '../BurgerMenu.module.scss';

interface IProps {
    labelText?: string;
}

const Label: React.FC<IProps> = ({ labelText }) => {
    return (
        <span className={styles['text-burger']}>
            {labelText}
        </span>
    );
}

export default Label;
