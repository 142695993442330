import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { getUsersToInviteFilter } from 'api/bars-request-objects';
import { handleApiError } from 'api/v1.0/common';
import { getUsersToInvite } from 'api/v1.0/communityFormation';
import { RootState } from 'App/root/rootReducer';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const UseCommunityFormationPage = () => {
    const [pageState, dispatch] = useReducer(reducer, defaultState);
    const user = useSelector((state: RootState) => state.account.user);

    const refresh = (startToInvite?: number | undefined) => {
        dispatch(actionCreators.setLoading(true));
        const request = {
            start: startToInvite ?? 0,
            limit: 10,
            dataFilter: getUsersToInviteFilter(pageState.selectedProjectId,
                pageState.selectedPointId, Number(user?.sub)),
        };
        void getUsersToInvite(request)
            .then((response) => {
                dispatch(actionCreators.setUsersToInvite(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            }).catch((exception) => handleApiError(exception))
            .finally(() => dispatch(actionCreators.setLoading(false)));
    };

    useEffect(() => {
        pageState.selectedPointId && refresh();
        //eslint-disable-next-line
    }, [pageState.selectedPointId]);

    const changeSelectedProject = (id: number | null) => {
        dispatch(actionCreators.deleteUsersToInvite());
        dispatch(actionCreators.setTotalCount(0));
        changeSelectedUsers([]);
        dispatch(actionCreators.setSelectedPoint(null));
        dispatch(actionCreators.setSelectedProject(id));
    };

    const changeSelectedPoint = (id: number | null) => {
        dispatch(actionCreators.deleteUsersToInvite());
        dispatch(actionCreators.setTotalCount(0));
        changeSelectedUsers([]);
        dispatch(actionCreators.setSelectedPoint(id));
    };

    const changeSelectedUsers = (userIds: number[]) => {
        dispatch(actionCreators.setSelectedUsers(userIds));
    };

    const clearState = () => {
        dispatch(actionCreators.clearState());
    };

    const onSelectAll = () => {
        dispatch(actionCreators.setLoading(true));
        const requestBody = {
            dataFilter: getUsersToInviteFilter(pageState.selectedProjectId,
                pageState.selectedPointId, Number(user?.sub)),
        };
        void getUsersToInvite(requestBody)
            .then((response) => {
                if (response.data.length > 0 && response.success) {
                    pageState.selectedUsers.length > 0
                        ? changeSelectedUsers([])
                        : changeSelectedUsers(response.data.map((userToInvite) => userToInvite.user_id));
                }
            })
            .catch((exception) => handleApiError(exception))
            .finally(() => dispatch(actionCreators.setLoading(false)));
    };

    return {
        ...pageState,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedUsers,
        onSelectAll,
        clearState,
        refresh,
    };
};

export default UseCommunityFormationPage;
