import { SortType } from 'api/bars-request-objects';
import { IRegionalCoordinatorForList } from 'api/types/v1.0/federalCoordinatorRegionalCoordinators';
import {
    IFederalCoordinatorsPoint,
} from 'api/types/v1.0/federalCoordinatorPoints';

export enum DeputySortType{
    surname_n_p = 'surname_n_p',
    rating = 'rating',
}

export enum ViewType{
  deputy = 'deputy',
  list = 'list',
}

export enum ViewFrom {
    federal = 'fromFederal',
    regional = 'fromRegional',
}

export enum PointSortType{
    rating = 'rating',
    title = 'title',
}

export interface IState {
    points: IFederalCoordinatorsPoint[] | null;
    isLoading: boolean;
    sortType: SortType;
    descending: boolean;
    totalCount: number;
}

export interface ICoordinatorsState {
    coordinators: IRegionalCoordinatorForList[] | null;
    isLoading: boolean;
    sortType: DeputySortType;
    descending: boolean;
    totalCount: number;
}
