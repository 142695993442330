import { Calendar } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { QUERY_FORMAT } from 'constants/dateTime';
import { ICitizenEvent } from 'api/types/v1.0/citizenEvent';
import { distinct } from 'utils/array';
import { utcDateToLocal } from 'utils/dateTime';
import { CitizenEventsList } from '../CitizenEventsList';

export interface ICitizenEventsCalendarProps {
    citizenEvents: ICitizenEvent[] | null;
    monthCitizenEvents: ICitizenEvent[] | null;
    refresh?: () => void;
    isLoading: boolean;
    changeDate: (date: string | null) => void;
    totalCount: number;
    changeSelectedMonth: (month: number) => void;
    changeSelectedYear: (year: number) => void;
    pageByDate?: number;
}

export const CitizenEventsCalendar: React.FC<ICitizenEventsCalendarProps> = (props: ICitizenEventsCalendarProps) => {
    const [selectedDate, setSelectedDate] = useState<Moment>(moment());

    const daysWithEvents = props.monthCitizenEvents
        ?.map((event) => {
            const date = new Date(event.event_date_time);
            utcDateToLocal(date);
            return date.getDate();
        })
        .filter(distinct);

    const getDateFullCellRender = (date: Moment) => {
        const style = getCellStyle(date);

        const formattedDay = date.toDate().toLocaleDateString('ru-RU', {
            day: '2-digit',
        });

        return <div className='ant-picker-cell-inner ant-picker-calendar-date'>
            <div className={style}>{formattedDay}</div>
        </div>;
    };

    const getCellStyle = (date: Moment) => {
        const day = date.toDate().getDate();
        const isDateFromCurrentMonth = selectedDate.month() === date.month();
        const isCurrentDaySelected = selectedDate.toDate().getDate() === day;
        const isDayWithEvent = daysWithEvents?.some((x) => x === day);

        return isDayWithEvent && !isCurrentDaySelected && isDateFromCurrentMonth
            ? 'ant-picker-calendar-date-value calendar-day-with-event'
            : 'ant-picker-calendar-date-value';
    };

    useEffect(() => {
        props.changeSelectedMonth(selectedDate.month());
        props.changeSelectedYear(selectedDate.year());
        //eslint-disable-next-line
    }, [])

    const onCalendarChange = (newDate: Moment) => {
        if (selectedDate.month() !== newDate.month()) {
            props.changeSelectedMonth(newDate.month());
        }

        if (selectedDate.year() !== newDate.year()) {
            props.changeSelectedYear(newDate.year());
        }

        if (selectedDate.day() !== newDate.day()) {
            props.changeDate(newDate.format(QUERY_FORMAT));
        }

        setSelectedDate(newDate);
    };

    return (
        <div className='site-calendar-demo-card'>
            <Calendar
                fullscreen={false}
                value={selectedDate}
                onSelect={onCalendarChange}
                dateFullCellRender={getDateFullCellRender}
            />
            {
                props.citizenEvents && props.citizenEvents.length !== 0
                    ? <CitizenEventsList
                        totalCount={props.totalCount}
                        refresh={props.refresh}
                        loading={props.isLoading}
                        citizenEvents={props.citizenEvents}
                        pageByDate={props.pageByDate}
                    />
                    : <div className={'no-activities-message'}>На эту дату нет назначенных мероприятий</div>
            }
        </div>
    );
};
