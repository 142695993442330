import { useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getNewsList } from 'api/v1.0/newsList';
import { INewsListRequest } from 'api/types/v1.0/newsList';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

const useNewsPage = () => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = (forceRefresh?: boolean, ignoreFilters?: boolean) => {
        dispatch(actionCreators.setLoading(true));

        const request = {
            start: forceRefresh || !state.news ? 0 : state.news.length,
            limit: 10,
            objectId: state.selectedObjectId,
            projectId: state.selectedProjectId,
            endDate: state.selectedEndDate,
            startDate: state.selectedStartDate,
            deputyId: state.selectedDeputyId,
            regionId: state.selectedRegionId,
        } as INewsListRequest;

        void getNewsList(request, !!ignoreFilters)
            .then((response) => {
                dispatch(actionCreators.setNews(response.data));
                dispatch(actionCreators.setTotalCount(response.totalCount));
            })
            .catch((exception) => {
                handleApiError(exception);
            })
            .finally(() => {
                dispatch(actionCreators.setLoading(false));
            });
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    const changeSelectedProject = (id: number | null) => {
        dispatch(actionCreators.setSelectedProject(id));
    };

    const changeSelectedObject = (id: number | null) => {
        dispatch(actionCreators.setSelectedObject(id));
    };

    const changeSelectedRegion = (id: number | null) => {
        dispatch(actionCreators.setSelectedRegion(id));
    };

    const changeSelectedDeputy = (id: number | null) => {
        dispatch(actionCreators.setSelectedDeputy(id));
    };

    const changeSelectedStartDate = (date: string | null) => {
        dispatch(actionCreators.setSelectedStartDate(date));
    };

    const changeSelectedEndDate = (date: string | null) => {
        dispatch(actionCreators.setSelectedEndDate(date));
    };

    const submitFilterChanges = () => {
        dispatch(actionCreators.deleteNews());
        refresh(true);
    };

    const clearFilters = () => {
        changeSelectedStartDate(null);
        changeSelectedEndDate(null);
        changeSelectedObject(null);
        changeSelectedRegion(null);
        changeSelectedProject(null);
        changeSelectedDeputy(null);
        dispatch(actionCreators.deleteNews());
        refresh(true, true);
    };

    return {
        ...state,
        refresh,
        changeSelectedProject,
        changeSelectedDeputy,
        changeSelectedObject,
        changeSelectedRegion,
        changeSelectedStartDate,
        changeSelectedEndDate,
        submitFilterChanges,
        clearFilters,
    };
};

export default useNewsPage;
