import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IPointsState } from './types';

export const defaultState: IPointsState = {
    selectedPoints: [],
    statistics: null,
    isLoading: false,
    error: null,
};

const PointsReducer: Reducer<IPointsState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.COORDINATOR_POINTS_SET:
            return {
                ...state,
                statistics: action.payload,
            };
        case actionTypes.COORDINATOR_SELECTED_POINTS_ADD:
            return {
                ...state,
                selectedPoints: [...state.selectedPoints,action.payload],
            };
        case actionTypes.COORDINATOR_SELECTED_POINTS_REMOVE:
            return {
                ...state,
                selectedPoints: state.selectedPoints.filter((f) => { return f !== action.payload; }),
            };
        case actionTypes.COORDINATOR_SELECTED_POINTS_CLEAR:
            return {
                ...state,
                selectedPoints: [],
            };
        case actionTypes.DEPUTY_PROFILE_LOAD:
            return {
                ...state,
                isLoading:false,
            };
        case actionTypes.DEPUTY_PROFILE_FETCH:
            return {
                ...state,
                isLoading:true,
            };
        case actionTypes.DEPUTY_PROFILE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default PointsReducer;
