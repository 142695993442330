export const getProtectedSourceUrl = async (imageUrl: string) => {
    const response = await fetchWithAuthentication(imageUrl);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

const fetchWithAuthentication = (url: string) => {
    const headers = new Headers();
    headers.set('Authorization', `Basic ${btoa('User0253:User0253')}`);
    return fetch(url, { headers });
};
