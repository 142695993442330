import React, { useEffect, useRef } from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { RootState } from 'App/root/rootReducer';
import { mainTabsInfo } from '../LayoutHeaderTabsInfo';
import { IMainTabKeyInfo } from '../interfaces/ITabKeyInfo';
import styles from './LayoutHeaderTabs.module.scss';

interface IProps {
    selectedTabKey: number | undefined;
}

enum Pages {
    Project, Federal, Regional, Main, Point, Municipal
}

export const MainLayoutHeaderTabs: React.FC<IProps> = ({ selectedTabKey }) => {
    const buttonsRef = useRef<HTMLDivElement>(null);
    const isObjectSelect = useSelector((state: RootState) => !!state.selectedPoint.selectedPoint?.id);
    const isRegionCoordSet = useSelector((state: RootState) => !!state.selectedRegion.regional_coord_id);
    const isProjectSelect = useSelector((state: RootState) => !!state.selectedProjects.selectedProjects[0]?.Id);
    const tabKeyInfos: IMainTabKeyInfo[] = mainTabsInfo;

    const globalState = useSelector((state: RootState) => state);

    const onTabClick = () => {
        if (selectedTabKey) {
            const tab = buttonsRef.current;
            tab?.scrollIntoView({ block: 'center', inline: 'center' });
        }
    };

    useEffect(() => {
        onTabClick();

        // eslint-disable-next-line
    }, [selectedTabKey]);

    const getTextStyle = (tabKey: number): string | undefined =>
        isActive(tabKey) ? styles['active'] : styles['text'];

    const getButtonStyle = (tabKey: number) =>
        selectedTabKey === tabKey ? styles['button-active'] : styles['button'];

    const isActive = (key: number) => {
        switch (key) {
            case Pages.Project:
            case Pages.Federal:
                return isProjectSelect || isObjectSelect;
            case Pages.Regional:
                return (isProjectSelect && isRegionCoordSet) || isObjectSelect;
            case Pages.Municipal:
            case Pages.Point:
                return isObjectSelect;
            case Pages.Main:
                return true;
        }
    };

    const getQueryId = (key: number) => {
        switch (key) {
            case Pages.Project:
                return !isObjectSelect
                    ? globalState.selectedProjects.selectedProjects[0]?.Id
                    : globalState.selectedPoint.selectedPoint?.projectId;
            case Pages.Federal:
                return !isObjectSelect
                    ? globalState.selectedProjects.selectedProjects[0]?.deputy_Ref_Id
                    : globalState.selectedPoint.selectedPoint!.deputyId;
            case Pages.Regional:
                return !isObjectSelect
                    ? globalState.selectedRegion.regional_coord_id
                    : globalState.selectedPoint.selectedPoint?.regionalCoordId;
            case Pages.Point:
                return globalState.selectedPoint.selectedPoint?.id;
            case Pages.Municipal:
                return globalState.selectedPoint.selectedPoint?.municipaCoordId;
            default:
                return null;
        }
    };

    return (
        <div className={styles['layout-header-tabs']}>
            <div id={'title-menu'} className={styles['title-menu']}>
                {tabKeyInfos.map((info, index) => (
                    <React.Fragment key={info.key}>
                        <div ref={selectedTabKey === info.key ? buttonsRef : undefined}
                            className={classnames(getButtonStyle(info.key), styles['element'])}>
                            <Button
                                onClick={() => {
                                    info.onClick(getQueryId(info.key));
                                }}
                                disabled={!info.ignoreSelectedPointLogic && !isActive(info.key)}
                                style={{ padding: '0 4px' }}
                                type="link"
                            >
                                <span className={getTextStyle(info.key)}>{info.tabName}</span>
                            </Button>
                        </div>
                        {tabKeyInfos.length > index + 1 && (
                            <div className={styles['element']}>
                                <RightOutlined />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default MainLayoutHeaderTabs;
