export interface IAccount {
    address : string,
    avatar : string,
    birthDate : Date | undefined,
    email : string,
    isReceiveEventMessages : boolean,
    isReceiveGroupMessages : boolean,
    isRegistered : boolean
    name : string,
    phantomic : string,
    phone : string,
    preview :string,
    regionId : number,
    role : Role,
    sub : string,
    surname : string,
}

export enum Role {
    Citizen = 'citizen',
    Municipal = 'municipal_lvl',
    Regional = 'regional_lvl',
    Federal = 'federal_lvl',
}
