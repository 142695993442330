import SchoolModernizationImage from '../../assets/image/school-modernization-1280х262.png';
import SchoolModernizationImage1110 from '../../assets/image/school-modernization-1110х320.png';
import SchoolModernizationImage368 from '../../assets/image/school-modernization-368х138.png';
import DormitoryModernizationImage from '../../assets/image/dormitory-modernization-1280х262.png';
import DormitoryModernizationImage1110 from '../../assets/image/dormitory-modernization-1110х320.png';
import DormitoryModernizationImage368 from '../../assets/image/dormitory-modernization-368х138.png';
import ModernizationImage from '../../assets/image/modernization-1280х262.png';
import ModernizationImage1110 from '../../assets/image/modernization-1110х320.png';
import ModernizationImage368 from '../../assets/image/modernization-368х138.png';
import MyCareerImage from '../../assets/image/my-career-1280х262.png';
import MyCareerImage1110 from '../../assets/image/my-career-1110х320.png';
import MyCareerImage368 from '../../assets/image/my-career-368х138.png';

const BlankContent = [
    <div key={1} className={'content blankContent'}>
        <picture>
            <source media="(max-width: 1000px)" srcSet={SchoolModernizationImage368} />
            <source media="(max-width: 2200px)" srcSet={SchoolModernizationImage1110} />
            <img src={SchoolModernizationImage} alt="" className="responsive-image" />
        </picture>
    </div>,
    <div key={2} className={'content blankContent'}>
        <picture>
            <source media="(max-width: 1000px)" srcSet={DormitoryModernizationImage368} />
            <source media="(max-width: 2200px)" srcSet={DormitoryModernizationImage1110} />
            <img src={DormitoryModernizationImage} alt="" className="responsive-image" />
        </picture>
    </div>,
    <div key={3} className={'content blankContent'}>
        <picture>
            <source media="(max-width: 1000px)" srcSet={ModernizationImage368} />
            <source media="(max-width: 2200px)" srcSet={ModernizationImage1110} />
            <img src={ModernizationImage} alt="" className="responsive-image" />
        </picture>
    </div>,
    <div key={4} className={'content blankContent'}>
        <picture>
            <source media="(max-width: 1000px)" srcSet={MyCareerImage368} />
            <source media="(max-width: 2200px)" srcSet={MyCareerImage1110} />
            <img src={MyCareerImage} alt="" className="responsive-image" />
        </picture>
    </div>,
];

export default BlankContent;
