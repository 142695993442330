import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { getTimeZone } from 'utils/dateTime';
import {
    ICitizenEventCreateRequest,
    ICitizenEventCreateResponse,
    ICitizenEventsRequest,
    ICitizenEventsResponse,
    ICitizenEventsTimeZoneRequest,
} from '../types/v1.0/citizenEvent';

export const getCitizenEvents = async (request: ICitizenEventsRequest) => {
    const response = await axiosPostRequest<ICitizenEventsTimeZoneRequest, ICitizenEventsResponse>(
        ApiPaths.CitizenEventList,
        {
            dataFilter: request.dataFilter,
            page: request.page,
            limit: request.limit,
            start: request.start,
            clientTimezoneParams: { clientTimeZoneOffset: getTimeZone() },
        }
    );

    return {
        data: response.data.data,
        success: true,
        totalCount: response.data.totalCount,
        currentPageByDate: response.data.currentPageByDate + 1,
    } as ICitizenEventsResponse;
};

export const addCitizenEvent = async (request: ICitizenEventCreateRequest) => {
    try {
        const result = await axiosPostRequest<ICitizenEventCreateRequest, ICitizenEventCreateResponse>(
            ApiPaths.CitizenEventCreate,
            request
        );
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при добавлении пользователя в группу.');
    }
};
