import { IState } from './types';
import {
    DELETE_CITIZEN_MENU_CITIZEN_GROUP,
    DELETE_CITIZEN_MENU_CITIZEN_GROUPS,
    SET_CITIZEN_MENU_CITIZEN_GROUPS_COUNT,
    SET_CITIZEN_MENU_CITIZEN_GROUPS,
    SET_CITIZEN_MENU_CITIZEN_GROUPS_LOADING,
    SET_CITIZEN_MENU_CITIZEN_GROUPS_IS_EXPANDED,
    CLEAR_CITIZEN_MENU_CITIZEN_GROUPS,
    DELETE_CITIZEN_GROUP_FROM_GROUPS_LIST,
} from './actionTypes';

export const setGroups = (groups: IState['groups']) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_GROUPS, payload: groups } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_GROUPS_LOADING, payload: isLoading } as const);

export const deleteGroups = () =>
    ({ type: DELETE_CITIZEN_MENU_CITIZEN_GROUPS } as const);

export const deleteGroup = (groupId: number) =>
    ({ type: DELETE_CITIZEN_MENU_CITIZEN_GROUP, payload: groupId } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_GROUPS_COUNT, payload: totalCount } as const);

export const setIsExpanded = (isExpanded: IState['isExpanded']) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_GROUPS_IS_EXPANDED, payload: isExpanded } as const);

export const clear = () =>
    ({ type: CLEAR_CITIZEN_MENU_CITIZEN_GROUPS } as const);

export const deleteCitizenGroupFromGroupsList = () =>
    ({ type: DELETE_CITIZEN_GROUP_FROM_GROUPS_LIST } as const);
