import * as yup from 'yup';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';
import { Role } from 'types/account';

export const newsGeneratorValidationSchema = (deputyRole: Role | undefined) => {
    const isMunDep = deputyRole === Role.Municipal;

    const yupNullableId = yup.number()
        .nullable()
        .transform((_, val): number | null => val ? Number(val) : null);

    const yupRequiredId = yup.number().required(`${alerts.REQUIRED}`);

    return yup.object().shape(
        {
            title: yup.string().required(`${alerts.REQUIRED}`),
            description: yup.string().required(`${alerts.REQUIRED}`),
            file: yup.mixed().required(`${alerts.REQUIRED}`),
            activityId: yupNullableId,
            pointId: isMunDep ? yupRequiredId : yupNullableId,
            projectId: isMunDep ? yupRequiredId : yupNullableId,
        }
    );
};
