import { DefaultOptionType } from 'antd/lib/select';

interface IComparableById<T> {
    id: T;
}

export const compareById = <TId, TObject extends IComparableById<TId>>(first: TObject, second: TObject) =>
    first.id === second.id;

export const compareFilterSearchOptions = (input: string, option: DefaultOptionType | undefined) =>
    String(option?.children).normalize().toLowerCase().includes(input.normalize().toLowerCase());
