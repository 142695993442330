import {
    SET_GROUP_MEMBERS_LIST,
    REMOVE_GROUP_MEMBER,
    RECOVER_GROUP_MEMBER,
    SET_IS_GROUP_MEMBERS_LOADING,
} from './actionTypes';
import { IState } from './types';

export const setGroupMembersList = (groupMembersList: IState['groupMembersList']) =>
    ({ type: SET_GROUP_MEMBERS_LIST, payload: groupMembersList } as const);

export const removeGroupMember = (citizen_id: number) =>
    ({ type: REMOVE_GROUP_MEMBER, payload: citizen_id } as const);

export const recoverGroupMember = (citizen_id: number) =>
    ({ type: RECOVER_GROUP_MEMBER, payload: citizen_id } as const);

export const setIsGroupMembersLoading = (isGroupMembersLoading: boolean) =>
    ({ type: SET_IS_GROUP_MEMBERS_LOADING, payload: isGroupMembersLoading } as const);
