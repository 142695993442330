import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import classnames from 'classnames';
import './FolkButton.scss';

interface IProps extends Omit<ButtonProps, 'type'>{
    type?: "primary" | "default"
}

const FolkButton: React.FC<IProps> = ({children, type = "primary", className, ...props}) => {
    return <Button type={type} {...props}  className={classnames(className, 'folk-btn-style', `folk-${type}`)}>
        {!props.loading && (children)}
    </Button>;
};

export default FolkButton;
