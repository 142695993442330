import React from 'react';
import Page from 'components/Page';
import { UserSettingsForm } from './components/UserSettingsForm/UserSettingsForm';
import './index.scss';

const UserSettingsPage: React.FC = () => {
    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={'user-settings-form-wrapper'}>
                        <UserSettingsForm />
                    </div>
                </Page.Content>
            }
        />

    );
};

export default UserSettingsPage;
