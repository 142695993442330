import { FormikErrors } from 'formik/dist/types';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { activityCardPollValidationSchema } from './activityCardPollValidationSchema';
import { IActivityCardPollFormValues } from './ActivityCardPollForm';

export const validateActivityCardPollValues = (
    values: IActivityCardPollFormValues
): FormikErrors<IActivityCardPollFormValues> => {
    let errors: FormikErrors<IActivityCardPollFormValues> = {};

    try {
        // В параметрах выбрано синхронное выполнение функции.
        /* eslint-disable-next-line */
        validateYupSchema(values, activityCardPollValidationSchema, true, values);
    } catch (err) {
        errors = yupToFormErrors(err);
    }

    return errors;
};
