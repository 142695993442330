import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState, TabType } from './types';

export const defaultState: IState = {
    listPoints: null,
    totalItems: 0,
    isListLoading: false,
    listPaging: {
        skip: 0,
        take: 10,
    },
    mapPoints: [],
    isMapLoading: false,
    mapCenter: {
        lat: 66.25,
        lng: 94.15,
    },
    pointsVisualisation: TabType.map,
    idOfFilteredRegions: [],
    mapRegionName: null,
};

export type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_LIST_POINTS:
            return {
                ...state,
                listPoints: [...state.listPoints ?? [], ...action.payload ?? []],
            };
        case actionTypes.CLEAR_LIST_POINTS:
            return {
                ...state,
                listPoints: null,
            };
        case actionTypes.SET_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload,
            };
        case actionTypes.SET_LIST_LOADING:
            return {
                ...state,
                isListLoading: action.payload,
            };
        case actionTypes.SET_LIST_PAGING:
            return {
                ...state,
                listPaging: action.payload,
            };

        case actionTypes.SET_MAP_POINTS:
            return {
                ...state,
                mapPoints: action.payload,
            };
        case actionTypes.SET_MAP_LOADING:
            return {
                ...state,
                isMapLoading: action.payload,
            };
        case actionTypes.SET_MAP_CENTER:
            return {
                ...state,
                mapCenter: action.payload,
            };

        case actionTypes.SET_MAP_REGION_NAME:
            return {
                ...state,
                mapRegionName: action.payload,
            };

        case actionTypes.SET_POINTS_VISUALISATION:
            return {
                ...state,
                pointsVisualisation: action.payload,
            };

        case actionTypes.SET_ID_OF_FILTERED_REGIONS:
            return {
                ...state,
                idOfFilteredRegions: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
