import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IPointInfo } from 'api/types/v1.0/pointInfo';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    pointInfo: null,
    isLoading: true,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_POINT_INFO:
            Object.assign(state.pointInfo || {}, action.payload === null ? {} : action.payload);
            return {
                ...state,
                pointInfo: action.payload as IPointInfo,
            };
        case actionTypes.SET_LOADING:
            Object.assign(state.isLoading, action.payload);
            return {
                ...state,
                isLoading: action.payload as boolean,
            };

        default:
            return state;
    }
};

export default reducer;
