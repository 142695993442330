import {
    IIndicatorResponse,
    INotificationsResponse,
} from 'api/types/v1.0/notifications';
import { handleApiError } from 'api/v1.0/common';
import { getIndicatorFilter, getNotificationsFilter, IHasFilters } from 'api/bars-request-objects';
import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';

export const getNotifications = async (id: number, start?: number) => {
    try {
        const request = {
            dataFilter: getNotificationsFilter(id),
            start: start || 0,
            limit: 5,
        };

        return await axiosPostRequest<
            IHasFilters,
            INotificationsResponse
        >(ApiPaths.Notifications, request);
    } catch (exception) {
        handleApiError(exception);
    }
};

export const getIndicator = async (id: number) => {
    try {
        const request = {
            dataFilter: getIndicatorFilter(id),
        };

        return await axiosPostRequest<
            IHasFilters,
            IIndicatorResponse
            >(ApiPaths.Notifications, request);
    } catch (exception) {
        handleApiError(exception);
    }
};

export const acceptOrDeclineInvitation = async (invitation_id: number, invitationResponse: number) => {
    try {
        const formData = new FormData();

        formData.append('functionName', 'InvitationEditResponseStatus');
        formData.append('functionArguments[0]', invitation_id.toString());
        formData.append('functionArguments[1]', invitationResponse.toString());

        return await axiosPostRequest(
            ApiPaths.NoteInvitationResponse,
            formData
        );
    } catch (exception) {
        handleApiError(exception);
    }
};

export const notificationSeen = async (notification_id: number, viewed: number) => {
    try {
        const request = {
            modelData: {
                Id: notification_id,
                Viewed: viewed,
            },
        };

        return await axiosPostRequest(
            ApiPaths.NotificationSeen,
            request
        );
    } catch (exception) {
        handleApiError(exception);
    }
};
