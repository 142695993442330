import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload';
import { axiosLoadFilesRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { IUploadNewsImageRequest } from 'api/types/v1.0/newsUploadContent';

export const uploadNewsImage = async (file: UploadFile, id: number) =>
{
    const request =
        {
            contentType: 2,
            news: {
                Id: id,
            },
        } as IUploadNewsImageRequest;
    const formData = new FormData();
    formData.append('FileOriginal', file as RcFile);
    formData.append('modelData', JSON.stringify(request));
    await axiosLoadFilesRequest(ApiPaths.FileEnEditor3, formData);
};
