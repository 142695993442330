import React, { useState } from 'react';
import { Avatar, Button, Divider, Image, Modal } from 'antd';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { RootState } from 'App/root/rootReducer';
import LikeDislike from 'components/LikeDislike/LikeDislike';
import { sendLike } from 'api/v1.0/likesDislikes';
import { LikeType } from 'api/types/v1.0/likesDislikes';
import { IGroupsMessageItem } from 'api/types/v1.0/groupsList';
import { formatTimeDate } from 'utils/dateTime';
import './ChatMessageCard.scss';

interface IProps {
    selected: boolean;
    message: IGroupsMessageItem;
    isCoordinator: boolean;
    isMundep: boolean;
    messagesTotalCount: number;
    setAnsweringMessage: (answeringMessage: IGroupsMessageItem) => void;
    replyMessage: (req: {object_id: number, message_id: number, start: number}) =>
        Promise<IGroupsMessageItem[] | undefined>;
    removeMessage: (message_id: number) => void;
    isEditDisabled?: boolean;
}

interface RepliedMessageProps {
    message : IGroupsMessageItem,
    repliedMessageHasImages : boolean,
    repliedMessageImage1Available : boolean,
    repliedMessageImage2Available :boolean,
    onReplyClick : (()=>void)
}

interface ImgProps {
    messageImage1Available : boolean ,
    messageImage2Available : boolean ,
    message : IGroupsMessageItem
}

const ChatMessageCard: React.FC<IProps> = ({
    selected,
    message,
    isCoordinator,
    isEditDisabled,
    isMundep,
    messagesTotalCount,
    setAnsweringMessage,
    replyMessage,
    removeMessage,
}) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const messageTime = formatTimeDate(message.date_time);
    const isLiked = message.mark_type === 1;
    const isDisliked = message.mark_type === 2;

    const repliedMessageImage1Available = !!message.replied_image_1 && message.replied_image_1 !== 'null';
    const repliedMessageImage2Available = !!message.replied_image_2 && message.replied_image_2 !== 'null';
    const repliedMessageHasImages = repliedMessageImage1Available || repliedMessageImage2Available;

    const messageImage1Available = !!message.image_1 && message.image_1 !== 'null';
    const messageImage2Available = !!message.image_2 && message.image_2 !== 'null';

    const handleDeleteClick = () => {
        removeMessage(message.Id);
    };

    const handleModalSubmit = () => {
        handleDeleteClick();
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const onReplyClick = async () => {
        let element = document.getElementById(`${message.replied_message_id}`);
        if (!element) {
            await replyMessage({
                message_id: message.replied_message_id,
                object_id: message.object_id,
                start: messagesTotalCount,
            });
        }

        element = document.getElementById(`${message.replied_message_id}`);
        element?.scrollIntoView({ behavior: 'smooth' });
    };

    const { user } = useSelector((state: RootState) => state.account);
    const id = Number(user?.sub);

    const RepliedMessage = ( props:RepliedMessageProps ) => (
        <>
            {!!props.message.replied_message_id &&
                <div className={'chat-message-reply'} onClick={props.onReplyClick}>
                    <Divider type='vertical' className={'chat-message-reply-divider'} />
                    <div className={'chat-message-reply-info'}>
                        <div className={'chat-message-card-reply-username'}>{props.message.replied_message_author_name}</div>
                        {
                            props.repliedMessageHasImages ?
                                <div className={'chat-message-card-reply-attachments'}>
                                    {props.repliedMessageImage1Available &&
                                        <img
                                            className={'chat-message-card-reply-image'}
                                            src={props.message.replied_image_1}
                                            alt={''}
                                        />
                                    }
                                    {props.repliedMessageImage2Available &&
                                        <img
                                            className={'chat-message-card-reply-image'}
                                            src={props.message.replied_image_2}
                                            alt={''}
                                        />
                                    }
                                </div> :
                                <div className={'chat-message-card-reply-text'}>
                                    {props.message.replied_message_text}
                                </div>
                        }
                    </div>
                </div>
            }
        </>
    );

    const MessageImages = (props:ImgProps) => (
        props.messageImage1Available || props.messageImage2Available ? (
            <div className='chat-message-imgs-wrapper'>
                {props.messageImage1Available &&
                    <Image
                        className={'chat-message-img'}
                        src={props.message.image_1}
                    />
                }
                {props.messageImage2Available &&
                    <Image
                        className={'chat-message-img'}
                        src={props.message.image_2}
                    />
                }
            </div>
        ) : null
    );

    return (
        <div className={selected ? classNames(['chat-message-card-wrapper', 'focus']) : 'chat-message-card-wrapper'} >
            <div className={'chat-message-card'} id={`${message.Id}`}>
                <div>
                    <Avatar
                        src={message.message_author_avatar}
                        className={'chat-message-user-icon'}
                        icon={<UserOutlined />}
                    />
                </div>
                <div className={'chat-message-card-info'}>
                    <div className={'chat-message-card-info-header'}>
                        <div>
                            <div className={'chat-message-card-username'}>{message.message_author_name}</div>
                            <RepliedMessage
                                message={message}
                                repliedMessageHasImages={repliedMessageHasImages}
                                repliedMessageImage1Available={repliedMessageImage1Available}
                                repliedMessageImage2Available={repliedMessageImage2Available}
                                onReplyClick={onReplyClick}
                            />
                            <MessageImages
                                messageImage1Available={messageImage1Available}
                                messageImage2Available={messageImage2Available}
                                message={message}
                            />
                            <div className={'chat-message-card-text'}>
                                {message.message_text}
                            </div>
                            <div className={'chat-message-card-time'}>
                                {messageTime}
                            </div>
                        </div>
                        {!isEditDisabled && isMundep && <DeleteOutlined
                            className={'remove-message-icon'}
                            onClick={() => setModalOpen(true)}
                        />}
                    </div>
                    <div className={(isCoordinator || !!isEditDisabled)
                        ? 'chat-message-buttons-coordinator'
                        : 'chat-message-buttons'}>
                        {!(isCoordinator || !!isEditDisabled) && <Button
                            className={'chat-message-answer-button'}
                            onClick={() => {setAnsweringMessage(message);}}
                        >
                            Ответить
                        </Button>}
                        <div className={'chat-message-like-dislike'}>
                            <LikeDislike
                                dislikesCount={message.dislikes}
                                likesCount={message.likes}
                                isLiked={isLiked}
                                isDisliked={isDisliked}
                                onDislikeClick={() => sendLike(message.Id, LikeType.messageLike, false)}
                                onLikeClick={() => sendLike(message.Id, LikeType.messageLike, true)}
                                isDisabled={id === message.message_author_id ||
                                    isCoordinator || !!isEditDisabled || isMundep}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className='confirm-remove-modal'
                onCancel={handleModalClose}
                onOk={handleModalSubmit}
                okText="Да"
                cancelText="Нет"
                title="Удалить сообщение?"
                open={isModalOpen}
            >
                <p>
                    Внимание! Это действие нельзя отменить
                </p>
            </Modal>
            <Divider />
        </div>
    );
};

export default ChatMessageCard;
