import { Menu } from 'antd';
import browserHistory from 'App/root/browserHistory';
import store from 'App/root/store';
import { Pages } from 'constants/links';
import { logout } from 'ducks/account/actionCreators';
import { resetSelectedPoint } from 'ducks/selectedPoint';
import { resetSelectProject } from 'ducks/selectedProjects';
import { resetSelectRegion } from 'ducks/selectedRegion';
import React from 'react';
import styles from '../BurgerMenu.module.scss';
import LogoutLabel from './LogoutLabel';
import MenuDivider from 'antd/es/menu/MenuDivider';
import { resetChat } from 'ducks/chat';
import './DeputyMenu.scss';

const CoordinatorMenu: React.FC = () => {
    const items = [
        {
            key: '1',
            label: (<LogoutLabel />),
            className: 'deputy-menu-item-head',
            onClick: () => {
                browserHistory.push(Pages.Main.url);
                store.dispatch(resetSelectedPoint());
                store.dispatch(resetSelectProject());
                store.dispatch(resetSelectRegion());
                store.dispatch(resetChat());
                store.dispatch(logout());
            },
        },
    ]
    return (
        <Menu
            className={styles["drop-menu"]}>
            {items.map((item) => {
                if (item.label) {
                    return (<Menu.Item className={item.className} key={item.key}
                                       onClick={item.onClick}>{item.label}</Menu.Item>);
                }
                else
                {
                    return (<MenuDivider key={item.key} />);
                }
            })}
        </Menu>
    );
}

export default CoordinatorMenu;