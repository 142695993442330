import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { ICitizenEvent } from 'api/types/v1.0/citizenEvent';
import { LikeDislikeType, sendLike } from 'api/v1.0/likesDislikes';
import { LikeType } from 'api/types/v1.0/likesDislikes';
import { DATE_TIME_FORMAT_SECOND } from 'constants/dateTime';
import { ReactComponent as UpIcon } from 'pages/StatesmanPage/assets/UpIcon.svg';
import { ReactComponent as DownIcon } from 'pages/StatesmanPage/assets/DownIcon.svg';
import LikeDislike from 'components/LikeDislike/LikeDislike';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import SurveyCard from 'ui-kit/SurveyCard/SurveyCard';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import useCitizenEventItem from './hooks/useCitizenEventItem';
import './CitizenEventItem.scss';

interface IProps {
    citizenEvent: ICitizenEvent;
    needToHideActionsBlock?: boolean;
}

export const CitizenEventItem: React.FC<IProps> = ({ citizenEvent, needToHideActionsBlock }) => {
    const {
        sendVote,
        isExpanded,
        setIsExpanded,
        interview,
        isLoading,
    } = useCitizenEventItem(citizenEvent.interview, citizenEvent.Id);
    const isLiked = citizenEvent.mark_type === LikeDislikeType.Like;
    const isDisliked = citizenEvent.mark_type === LikeDislikeType.Dislike;

    const dateTime = moment.utc(citizenEvent.event_date_time).local().format(DATE_TIME_FORMAT_SECOND);

    const needToShowReportButton = citizenEvent.is_finished;
    const isReportButtonActive = citizenEvent.report_id > 0;
    const onReportButtonClick = () => browserHistory.push(`${Pages.NewsDetails.url}/${citizenEvent.report_id}`);

    const needToShowInterviewButton = !!citizenEvent.interview && citizenEvent.interview.answers.length > 0;

    const onVoteAction = (answerId: number, interviewId: number) => {
        sendVote(answerId, interviewId);
    };

    return (
        <div className={'citizen-event-item-wrapper'}>
            <div>
                <span className={'title-line'}>{dateTime} </span>
                <span className={'text-line'}>{citizenEvent.event_name}</span>
            </div>

            {
                citizenEvent.project_name &&
                <div>
                    <span className={'title-line'}>Проект: </span>
                    <span className={'text-line'}>{citizenEvent.project_name}</span>
                </div>
            }

            {
                citizenEvent.object_name &&
                <div>
                    <span className={'title-line'}>Объект: </span>
                    <span className={'text-line'}>{citizenEvent.object_name}</span>
                </div>
            }

            <div className={'actions-block-with-content'}>
                <div hidden={!isExpanded}>
                    {
                        interview &&
                        <OnLoadingSpinner
                            isLoading={isLoading}
                            content={<SurveyCard
                                onVote={onVoteAction}
                                interview={interview}
                                isTookPart={interview.answer_id > 0}
                                canVote={citizenEvent.canVote}
                            />}
                        />
                    }
                </div>
                {
                    !needToHideActionsBlock &&
                    <div className={'actions-block'}>
                        {
                            needToShowInterviewButton && isExpanded &&
                            <UpIcon className={'expand-icon'} onClick={() => setIsExpanded(false)} />
                        }
                        {
                            needToShowInterviewButton && !isExpanded &&
                            <DownIcon className={'expand-icon'} onClick={() => setIsExpanded(true)} />
                        }
                        {
                            needToShowReportButton &&
                            <Button disabled={!isReportButtonActive} className={isReportButtonActive
                                ? 'report-button'
                                : 'report-button-disabled'} onClick={onReportButtonClick}>Отчет</Button>
                        }
                        <div className={'likes-block'}>
                            <LikeDislike
                                dislikesCount={citizenEvent.dislikes}
                                likesCount={citizenEvent.likes}
                                isLiked={isLiked}
                                isDisliked={isDisliked}
                                onDislikeClick={() =>
                                    sendLike(citizenEvent.object_event_id, LikeType.activityLike, false)}
                                onLikeClick={() =>
                                    sendLike(citizenEvent.object_event_id, LikeType.activityLike, true)}
                                isDisabled={!citizenEvent.is_finished}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>);
};
