import React from 'react';
import './NewsPageHeader.scss';

interface IProps {
    newsCount?: number;
}

const NewsPageHeader: React.FC<IProps> = ({ newsCount }) => {
    return (
        <div className={'news-page-header'}>
            <div className={'news-page-title'}>
                Новости
            </div>
            <div className={'news-page-sub-title'}>
                Найдено {newsCount} результатов
            </div>
        </div>
    );
};

export default NewsPageHeader;
