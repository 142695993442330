import {
    SET_USERS_TO_INVITE,
    SET_LOADING,
    SET_SELECTED_PROJECT,
    SET_SELECTED_POINT,
    SET_TOTAL_COUNT,
    DELETE_USERS_TO_INVITE,
    SET_SELECTED_USERS,
    CLEAR_STATE,
} from './actionTypes';
import { IState } from './types';

export const setUsersToInvite = (users: IState['usersToInvite']) =>
    ({ type: SET_USERS_TO_INVITE, payload: users } as const);

export const deleteUsersToInvite = () =>
    ({ type: DELETE_USERS_TO_INVITE } as const);

export const setSelectedProject = (selectedProjectId: IState['selectedProjectId']) =>
    ({ type: SET_SELECTED_PROJECT, payload: selectedProjectId } as const);

export const setSelectedPoint = (selectedPointId: IState['selectedPointId']) =>
    ({ type: SET_SELECTED_POINT, payload: selectedPointId } as const);

export const setSelectedUsers = (usersList: IState['selectedUsers']) =>
    ({ type: SET_SELECTED_USERS, payload: usersList } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const clearState = () =>
    ({ type: CLEAR_STATE } as const);
