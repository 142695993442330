import React, { useCallback } from 'react';
import { Form, Select } from 'antd';
import { useField } from 'formik';
import './SelectField.scss';

export interface ISelectFieldsProps {
    name: string;
    options?: ISelectOption[];
    placeholder?: string;
    disabled?: boolean;
    customPlaceholder?: React.ReactNode;
    customSuffixIcon?: React.ReactNode;
    validationMessage?: string;
    needToSetNameAsUndefined?: boolean;
    setTouched?: (isTouched: boolean) => void|undefined;
}

export interface ISelectOption {
    value: string | number;
    label: string;
}

export const SelectField: React.FC<ISelectFieldsProps> = (props) => {
    const { name } = props;
    
    const [field, meta, { setValue, setTouched }] = useField<string | number>(name);

    const onSelect = useCallback(
        (_: string | number, option: ISelectOption) => {
            if (option)
            {
                const fieldValue = option.value;

                setValue(fieldValue);
                setTouched(true, false);
            }
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            name={props.needToSetNameAsUndefined ? undefined : field.name}
            initialValue={field.value}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: true, message: props.validationMessage }]}
        >
            <Select<string | number, ISelectOption>
                getPopupContainer={trigger => trigger.parentNode}
                disabled={props.disabled}
                className={props.disabled ? 'select-disabled' : 'select-field'}
                placeholder={props.customPlaceholder ?? props.placeholder}
                value={field.value}
                onSelect={onSelect}
                filterOption={(inputValue, option) => {
                    return (option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) ?? -1) >= 0;
                }}
                options={props.options}
                showSearch
                suffixIcon={props.customSuffixIcon}
                onChange={() =>props.setTouched?.(true)}
            />
        </Form.Item>
    );
};
