import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Divider, List, Spin } from 'antd';
import Page from 'components/Page';
import * as actionCreators from 'ducks/chat/actionCreators';
import { ProjectFilter } from 'ui-kit/filters/ProjectFilter';
import { RootState } from 'App/root/rootReducer';
import { IPreviewListItem } from 'api/types/v1.0/groupsList';
import ChatPreviewCard from 'components/Layout/components/LayoutHeader/components/Chat/components/ChatPreviewCard/ChatPreviewCard';
import useChatModal from 'components/Layout/components/LayoutHeader/components/Chat/hooks/useChatModal';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import styles from './ChatsPage.module.scss';

export const ChatsPage: React.FC = () => {
    const { selectedChatId, isResponse, selectedProjectId } = useSelector((state: RootState) => state.chat);
    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState<number | null>(selectedProjectId);
    const { user } = useSelector((state: RootState) => state.account);
    const [selectedGroup, setSelectedGroup] = useState<{ groupId: number, is_response?: boolean }>({
        groupId: selectedChatId as number,
        is_response: true,
    });
    const {
        isPreviewLoading,
        groupsList,
        groupsTotalCount,
        refresh,
    } = useChatModal(user?.sub);

    useEffect(() => {
        refresh(projectId);

        window.addEventListener('beforeunload', handlePageRefresh);

        return () => {
            window.removeEventListener('beforeunload', handlePageRefresh);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        setSelectedGroup({ groupId: selectedChatId as number, is_response: isResponse });
    }, [selectedChatId, isResponse]);

    const handlePageRefresh = () => {
        dispatch(actionCreators.setIsResponse(false));
        dispatch(actionCreators.setSelectedChatId(null));
        dispatch(actionCreators.setSelectedMessageId(null));
        dispatch(actionCreators.setSelectedProjectId(null));
    };

    const loadMoreData = () => {
        refresh(projectId, groupsList?.length);
    };

    const handleChangeProject = (newProjectId: number | null) => {
        setProjectId(newProjectId);
        dispatch(actionCreators.setSelectedProjectId(newProjectId));
    };

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={styles.chatsPageWrapper}>
                        <Spin spinning={isPreviewLoading}>
                            <Card style={{ width: '100%' }}>
                                <div className={styles.chatsHeader}>
                                    Чаты
                                </div>
                                <div className={styles.projectFilter}>
                                    <ProjectFilter
                                        onChange={(value) => handleChangeProject(value)}
                                        managedValue={selectedProjectId}
                                        userId={user?.sub}
                                        onClear={() => {
                                            dispatch(actionCreators.setSelectedProjectId(null));
                                        }}
                                    />
                                </div>
                                <Divider />
                                <InfiniteScroll
                                    className={styles.infiniteScrollComponent}
                                    dataLength={groupsList?.length ? groupsList?.length : 0}
                                    next={loadMoreData}
                                    height={448}
                                    hasMore={groupsList ? groupsList?.length < groupsTotalCount : false}
                                    scrollableTarget="scrollableDiv"
                                    loader={''}
                                >
                                    <List
                                        dataSource={groupsList ?? []}
                                        renderItem={(previewDetails: IPreviewListItem) => (
                                            <div
                                                className={styles.chatPreviewList}
                                                onClick={() => {
                                                    dispatch(actionCreators.setSelectedChatId(
                                                        previewDetails.object_id
                                                    ));
                                                    dispatch(actionCreators.setIsResponse(previewDetails.is_response));
                                                    browserHistory.push(`${Pages.Chat.url}/${previewDetails.object_id.toString()}`);
                                                    dispatch(actionCreators.setSelectedMessageId(null));
                                                }}
                                            >
                                                <ChatPreviewCard
                                                    previewDetails={previewDetails}
                                                    selectedGroup={selectedGroup.groupId}
                                                />
                                            </div>
                                        )}
                                    />
                                </InfiniteScroll>
                            </Card>
                        </Spin>
                    </div>
                </Page.Content>
            }
        />
    );
};
