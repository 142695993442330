import React, { useState } from 'react';
import { Avatar, Button, Drawer, Space, Menu, Divider, Badge } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Role } from 'types/account';
import { RootState } from 'App/root/rootReducer';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import * as jwt from 'App/root/jwt';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { isOnPageWithoutTabs, isOnPersonalPages } from 'utils/location';
import { INotification } from 'api/types/v1.0/notifications';
import useNotificationCenterPage from 'pages/NotificationCenter/hooks';
import {
    citizenProfileTabsInfo,
    coordinatorProfileTabsInfo,
    municipalProfileTabsInfo,
} from '../LayoutHeader/LayoutHeaderTabsInfo';
import LayoutHeaderTabs from '../LayoutHeader/components/LayoutHeaderTabs';
import MenuIcon from './assets/menu.svg';
import MobileLogo from './assets/mobile-logo.svg';
import MobileTabsMenu from './components/MobileTabsMenu';

import styles from './LayoutMobileHeader.module.scss';

const titleRole = {
    [Role.Municipal]: { title: 'Меню депутата', tabs: municipalProfileTabsInfo },
    [Role.Citizen]: { title: 'Меню пользователя', tabs: citizenProfileTabsInfo },
    [Role.Federal]: { title: 'Меню координатора', tabs: coordinatorProfileTabsInfo },
    [Role.Regional]: { title: 'Меню координатора', tabs: coordinatorProfileTabsInfo },
};

const LayoutMobileHeader: React.FC = () => {
    const [open, setOpen] = useState(false);
    const { user } = useSelector((state: RootState) => state.account);
    const { notifications } = useNotificationCenterPage();
    const { notificationIndicator } = useSelector((state: RootState) => state.notificationIndicatorReducer);
    const hasNewEvents = notificationIndicator?.notificationIndicator;
    const notificationsEventsEnabled = user?.isReceiveEventMessages;
    const notificationsGroupEnabled = user?.isReceiveGroupMessages;
    const hasNewInvitations = notifications?.data?.some((item: INotification) => item.type === 6);
    const userRole = !!jwt.get() && !!user && user.role;
    const isLoggedIn = userRole;
    const titleMenu = userRole ? titleRole[userRole].title : 'Меню';
    const nav = useHistory();

    const redirectToPage = () => {
        if (userRole !== Role.Citizen) {browserHistory.push(Pages.DeputyProfile.url);}
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const hasNewNotifications = () => {
        return ((notificationsEventsEnabled && hasNewEvents)
            || (hasNewInvitations && notificationsGroupEnabled));
    };

    return (
        <>
            <div className={styles.layoutContainer}>
                {isLoggedIn && (
                    <div className={styles['person-avatar']} onClick={redirectToPage}>

                        <Avatar
                            className={styles['person-avatar-icon']}
                            src={user?.avatar}
                            alt={user?.name}
                            icon={<UserOutlined />}
                        />
                    </div>
                )}
                <img src={MobileLogo} alt="logo" onClick={() => browserHistory.push(Pages.Main.url)} />
                <img src={MenuIcon} className={styles['menu']} alt="menu" onClick={showDrawer} />
                {hasNewNotifications() && <Badge status="warning" className={styles['notification-badge']} />}
                {!isOnPersonalPages(nav.location.pathname) && !isOnPageWithoutTabs(nav.location.pathname) && (
                    <LayoutHeaderTabs userRole={user?.role ?? ''} />
                )}
            </div>
            <Drawer
                className={styles['drawer']}
                title={titleMenu}
                placement="bottom"
                height={isLoggedIn ? 400 : 280}
                onClose={onClose}
                open={open}
                closable={false}
                closeIcon={false}
                extra={
                    <Space>
                        <CloseOutlined onClick={onClose} className={styles['close-button']} />
                    </Space>
                }
            >
                {userRole ? (
                    <MobileTabsMenu userRole={userRole} userId={user?.sub} tabs={titleRole[userRole].tabs}
                        hasNewNotifications = {hasNewNotifications()} />
                ) : (
                    <Menu>
                        <Menu.Item className={styles['container-title']} onClick={() => nav.push(Pages.Main.url)}>
                            Главная
                        </Menu.Item>
                        <Divider className={styles['divider']} />
                        <Menu.Item className={styles['container-button']}>
                            <Button
                                type="primary"
                                onClick={() => redirect(AuthHelper.getAuthorizeCodeUrl(), false)}
                                className={styles['primaryBtn']}
                            >
                                <span className={styles['text']}>Войти или зарегистрироваться</span>
                            </Button>
                        </Menu.Item>
                        <Menu.Item className={styles['container-button']}>
                            <Button type="link" className={styles['linkButton']}>
                                <span
                                    onClick={() => browserHistory.push(Pages.ReasonToRegistration.url)}
                                    className={styles['text']}
                                >
                                    Зачем?
                                </span>
                            </Button>
                        </Menu.Item>
                    </Menu>
                )}
            </Drawer>
        </>
    );
};

export default LayoutMobileHeader;
