import React from 'react';
import _ from 'lodash';
import { formatMonthAndYear } from 'utils/dateTime';
import usePointInfoText, { IProps } from './hooks/usePointInfoText';
import styles from './PointText.module.scss';

const PointText: React.FC<IProps> = (props: IProps) => {
    const { pointInfo } = usePointInfoText(props);

    return (
        <div className={styles['point-info-text']}>
            <div className={styles['point-info-address']}>
                <span className={styles.pointInfoHeader}>Адрес:</span> {pointInfo?.address}</div>
            <div className={styles['point-infos-row']}>
                {
                    !!pointInfo?.pwr &&
                    <div className={styles['point-info-text-item']}><span className={styles.pointInfoHeader}>Мощность
                    объекта:</span> {pointInfo?.pwr} {pointInfo?.pwr_measure_name}</div>
                }
                <div className={styles['point-info-text-item']}>
                    <span className={styles.pointInfoHeader}>Начало работ:</span>
                    {pointInfo?.date_start
                        ? _.capitalize(formatMonthAndYear(pointInfo?.date_start))
                        : 'Объект не начат'}
                </div>
                <div className={styles['point-info-text-item']}>
                    <span className={styles.pointInfoHeader}>Срок сдачи:</span>
                    {pointInfo?.date_end
                        ? _.capitalize(formatMonthAndYear(pointInfo?.date_end))
                        : 'Объект не закончен'}
                </div>
            </div>
        </div>
    );
};

export default PointText;
