import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import { getProtectedSourceUrl } from 'utils/protectedSourceAccess';
import { IPointDocumentsResponse, IPointDocumentsRequest } from '../types/v1.0/pointDocuments';

export const getPointDocumentsAsync = async (request: IPointDocumentsRequest):Promise<IPointDocumentsResponse> =>
{
    const req = {
        'dataFilter': {
            'Group': 2,
            'Filters': [
                {
                    'Group': 3,
                    'Filters': [
                        {
                            'Operand': 0,
                            'Value': request.id,
                            'DataIndex': 'Object_Id',
                        },
                    ],
                },
            ],
        },
    };

    const result = await axiosPostRequest<typeof req, IPointDocumentsResponse>(ApiPaths.PointDocuments, req);

    const fixedUrls = result.data.data.map(async (doc) => {
        return ({
            documentId: doc.Id,
            document_link: await getProtectedSourceUrl(doc.document_link),
        });
    });

    const fixedDocumentsUrl = await Promise.all(fixedUrls);
    result.data.data.forEach((doc) => {
        doc.document_link =
            fixedDocumentsUrl.find((x) =>x.documentId === doc.Id)?.document_link ?? '';
    });

    return result.data;
};
