import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import classnames from 'classnames';
import './OrangeButton.scss';

interface IProps extends Omit<ButtonProps, 'type'>{
    type: "primary" | "default"
}
const OrangeButton: React.FC<IProps> = ({ type = 'primary', size='large', className, ...props}) => {
    return <Button size={size} type={type} {...props}  className={classnames(className, `orange-button-${type}`)} />;
};
export default OrangeButton;
