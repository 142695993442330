import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import { RootState } from 'App/root/rootReducer';
import useFavoritePage from './hooks';
import FavoriteSearch from './components/FavoriteSearch/FavoriteSearch';
import FavoriteList from './components/FavoriteList/FavoriteList';
import './FavoritePage.scss';

const FavoritePage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);
    const {
        favorites,
        totalCount,
        refresh,
        setFavoritesByInput,
        deleteFavorite,
        setTotalCount,
        setFavorites } = useFavoritePage(user?.sub);

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={'favorite-page-wrapper'}>
                        <div className={'favorite-page-header'}>Избранное</div>
                        <FavoriteSearch setFavoritesByInput={setFavoritesByInput} />
                        <FavoriteList
                            setFavorites={setFavorites}
                            setTotalCount={setTotalCount}
                            deleteFavorite={deleteFavorite}
                            refresh={refresh}
                            totalCount={totalCount}
                            favorites={favorites} />
                    </div>
                </Page.Content>
            }
        />);
};

export default FavoritePage;
