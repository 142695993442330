import React, { useCallback } from 'react';
import { Form, TimePicker } from 'antd';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import { ONLY_TIME_FORMAT } from 'constants/dateTime';
import './TimePickerField.scss';
import { validationMessages } from 'constants/validationMessages';
import { compareDateWithToday, getDisabledHours, getDisabledMinutes } from 'utils/dateTime';

export interface ITimePickerFieldProps {
    placeholder: string;
    name: string;
    disabled?: boolean;
}

export const TimePickerField: React.FC<ITimePickerFieldProps> = (props) => {
    const { name } = props;
    const [field, meta, { setValue, setTouched }] = useField<string | null>(name);
    const [fieldDate] = useField('date');
    const compareWithToday = compareDateWithToday(fieldDate.value?.toString());

    const onChange = useCallback(
        (_: Moment | null, dateString: string) => {
            setValue(dateString);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            name={'time'}
            initialValue={field.value ? moment.utc(field.value, ONLY_TIME_FORMAT) : undefined}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: true, message: validationMessages.time }]}
        >
            <TimePicker
                disabledHours={compareWithToday ? undefined : getDisabledHours}
                disabledMinutes={compareWithToday ? undefined : getDisabledMinutes}
                value={field.value ? moment.utc(field.value, ONLY_TIME_FORMAT) : undefined}
                format={ONLY_TIME_FORMAT}
                className={'time-picker-field'}
                placeholder={props.placeholder}
                showSecond={false}
                onChange={onChange}
                inputReadOnly={true}
                disabled={props.disabled}/>
        </Form.Item>
    );
};
