import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

export const defaultState: IState = {
    isLoading: false,
    totalCount: null,
};

const citizenMenuLikesReducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CITIZEN_MENU_LIKES_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_CITIZEN_MENU_LIKES_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.INCREASE_AMOUNT: {
            const currentTotalCount = Number(state.totalCount);
            return {
                ...state,
                totalCount: currentTotalCount + 1,
            };
        }

        case actionTypes.DECREASE_AMOUNT: {
            const currentTotalCount = Number(state.totalCount);
            return {
                ...state,
                totalCount: currentTotalCount - 1,
            };
        }

        default:
            return state;
    }
};

export default citizenMenuLikesReducer;
