import { useEffect, useReducer } from 'react';
import { getDeputyProfile } from 'api/v1.0/deputyProfile';
import { getProtectedSourceUrl } from 'utils/protectedSourceAccess';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

export const useDeputyProfilePage = (deputyId:number) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
        const fetchProfile = async () => {
            dispatch(actionCreators.deputyProfileFetch());
            try
            {
                const response = await getDeputyProfile({ id: deputyId });
                if (response.data.avatar !== '0')
                {
                    response.data.avatar = await getProtectedSourceUrl(response.data.avatar);
                }

                dispatch(actionCreators.deputyProfileSet(response.data));
            }
            catch (exc)
            {
                const error = (exc as Error).message;
                dispatch(actionCreators.deputyProfileError(error));
            }
            finally {
                dispatch(actionCreators.deputyProfileLoad());
            }
        };

        void fetchProfile();
    }, [deputyId]);

    return {
        state,
    };
};
