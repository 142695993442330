import useCitizenEventsMenuList from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenEventsCitizenMenuList/hooks/useCitizenEventsMenuList';
import useCitizenGroupsCitizenMenuList from 'components/Layout/components/Sidebar/CitizenMenu/components/CitizenGroupsCitizenMenuList/hooks/useCitizenGroupsCitizenMenuList';
import useFavoritesCitizenMenuList from 'components/Layout/components/Sidebar/CitizenMenu/components/FavoritesCitizenMenuList/hooks/useFavoritesCitizenMenuList';
import useLikesCitizenMenuList from 'components/Layout/components/Sidebar/CitizenMenu/components/LikesCitizenMenuList/hooks/useLikesCitizenMenuList';

const useTotalCount = (userId: string | undefined) => {
    const { totalCount: citizenEvents } = useCitizenEventsMenuList(userId ?? '');
    const { totalCount: citizenGroups } = useCitizenGroupsCitizenMenuList(Number(userId));
    const { totalCount: favoritesCitizen } = useFavoritesCitizenMenuList(Number(userId));
    const { totalCount: likesCitizen } = useLikesCitizenMenuList();

    return {
        Избранное: favoritesCitizen || 0,
        Группы: citizenGroups || 0,
        Мероприятия: citizenEvents || 0,
        Активность: likesCitizen || 0,
    };
};

export default useTotalCount;
