import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { sendLike } from 'api/v1.0/likesDislikes';
import { LikeType } from 'api/types/v1.0/likesDislikes';
import { formatNewsTime } from 'utils/dateTime';
import LikeDislike from 'components/LikeDislike/LikeDislike';
import Page from 'components/Page';
import ShareBar from 'ui-kit/ShareBar/ShareBar';
import { RootState } from 'App/root/rootReducer';
import * as jwt from 'App/root/jwt';
import { Role } from 'types/account';
import { ExternalRedirectLinks } from 'constants/externalRedirectUrls';
import useSearchParams from 'hooks/useSearchParams';
import useNewsDetails from './hooks/useNewsDetails';

import './NewsDetails.scss';

const NewsDetails: React.FC = () => {
    const account = useSelector((state: RootState) => state.account);
    const isLoggedIn = !!jwt.get() && !!account;
    const isRoleCitizen = account.user?.role === Role.Citizen;
    const { id } = useParams<{ id: string }>();
    const { state: data, loading } = useNewsDetails(id);
    const searchParams = useSearchParams();
    const needToOpenMobile = searchParams.get('needtoopenmobile');

    const isLiked = data?.mark_type === 1;
    const isDisliked = data?.mark_type === 2;

    useEffect(() => {
        const originPath = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${window.location.pathname}`;
        if (isMobile && needToOpenMobile) {
            setTimeout(() => { window.location.href = originPath; }, 5);
            window.location.href = `${ExternalRedirectLinks.FolkApp.url.toString()}${ExternalRedirectLinks.FolkApp.newsPage.toString()}?${ExternalRedirectLinks.FolkApp.newsIdKey.toString()}=${id.toString()}`;
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Page
            content={
                <Page.Content sidebar={'MainWithoutNews'}>
                    <div className={'news-details-wrapper'}>
                        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                            <div className={'news-details-title-block-wrapper'}>
                                <div className={'news-details-title-block'}>
                                    <div className={'news-details-title'}>
                                        {data?.title}
                                    </div>
                                    <span className={'news-details-date'}>{data && formatNewsTime(data.date_time)}</span>
                                    {
                                        data &&
                                        <LikeDislike
                                            isDisabled={isLoggedIn && !isRoleCitizen}
                                            dislikesCount={data.count_of_dislikes ?? 0}
                                            likesCount={data.count_of_likes ?? 0}
                                            isLiked={isLiked}
                                            isDisliked={isDisliked}
                                            onDislikeClick={() => sendLike(Number(id), LikeType.newsLike, false)}
                                            onLikeClick={() => sendLike(Number(id), LikeType.newsLike, true)}
                                        />
                                    }
                                </div>
                            </div>
                            <div className={'news-details-content-wrapper'}>
                                <img className={'news-image'} alt="" src={data?.image} loading="lazy" />
                                <div className={'news-text'}>{data?.description}</div>
                                <ShareBar title={data?.title} image={data?.image} description={data?.description} />
                            </div>
                        </Spin>
                    </div>
                </Page.Content>
            }
        />
    );
};

export default NewsDetails;
