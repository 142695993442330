import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Divider } from 'antd';
import browserHistory from 'App/root/browserHistory';
import { RootState } from 'App/root/rootReducer';
import { hasMunicipalRole } from 'helpers/account';
import { MunicipalMenuList } from './constants/MunicipalMenuList';
import { CoordinatorMenuList } from './constants/CoordinatorMenuList';
import styles from './DeputyMenu.module.scss';

const DeputyMenu: React.FC = () => {
    const handleListItemClick = (url: string) => {
        browserHistory.push(url);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const user = useSelector((state: RootState) => state.account.user);
    const hasMunicipalRoleVar = hasMunicipalRole(user);

    const activeUrl = window.location.pathname;
    const getActiveStyles = (url: string) => activeUrl === url
        ? styles.deputyMenuItemTextActive
        : styles.deputyMenuItemText;

    const menuList = hasMunicipalRoleVar ? MunicipalMenuList : CoordinatorMenuList;

    return (
        <Card className={styles.deputyMenuCardWrapper}>
            <div className={styles.deputyMenuCardTitle}>Меню</div>
            {
                menuList.map((listItem) => {
                    return (
                        <div
                            className={styles.deputyMenuItem}
                            key={listItem?.text}
                        >
                            <Divider />
                            <div
                                className={getActiveStyles(listItem.url)}
                                onClick={() => activeUrl === listItem.url ? null : handleListItemClick(listItem?.url)}
                            >
                                <span>{listItem?.text}</span>
                            </div>
                        </div>
                    );
                })
            }
        </Card>
    );
};

export default DeputyMenu;
