import React from 'react';
import { Button, Card, Col, Divider, List, Row, Skeleton } from 'antd';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import Page from 'components/Page';
import Icon from 'ui-kit/Icon';
import { RootState } from 'App/root/rootReducer';
import { inviteUsers } from 'api/v1.0/communityFormation';
import { IInviteUsersRequest } from 'api/types/v1.0/communityFormation';
import { handleApiError } from 'api/v1.0/common';
import FolkDivider from 'ui-kit/FolkDivider';
import FolkButton from 'ui-kit/FolkButtons';
import styles from './CommunityFormationPage.module.scss';
import UserInviteCard from './components/UserInviteCard';
import ToolBar from './components/ToolBar';
import useCommunityFormationPage from './hooks/useCommunityFormationPage';

export const CommunityFormationPage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);
    const userId = user?.sub;

    const {
        usersToInvite,
        selectedUsers,
        selectedProjectId,
        selectedPointId,
        totalCount,
        changeSelectedProject,
        changeSelectedPoint,
        changeSelectedUsers,
        onSelectAll,
        isLoading,
        clearState,
        refresh,
    } = useCommunityFormationPage();

    const loadMoreData = () => {
        if (isLoading) {
            return;
        }

        refresh?.(usersToInvite?.length);
    };

    const onPostUsers = () => {
        const requestBody = {
            objectId: `${selectedPointId ?? -1}`,
            citizenId: `[${selectedUsers.join(', ')}]`,
        } as IInviteUsersRequest;

        inviteUsers(new URLSearchParams(Object.entries(requestBody)).toString())
            .catch((exception) => handleApiError(exception))
            .finally(() => {
                clearState();
                setTimeout(() => refresh(), 600);
            });
    };

    const onUserSelect = (id: number) => {
        selectedUsers.includes(id)
            ? changeSelectedUsers(_.remove(selectedUsers, (item) => item !== id))
            : changeSelectedUsers([...selectedUsers, id]);
    };

    return (
        <Page content={
            <Page.Content sidebar={'Main'}>
                <Card className={styles['card']}>
                    <ToolBar
                        onProjectChange={changeSelectedProject}
                        onObjectChange={changeSelectedPoint}
                        isUserSelected={selectedUsers.length > 0}
                        isObjectSelected={!!selectedPointId}
                        projectId={selectedProjectId}
                        pointId={selectedPointId}
                        userId={userId ?? ''}
                        onPost={onPostUsers} />
                    <Divider />
                    {
                        usersToInvite.length > 0 ?
                            (<div className={styles.listHeader}>
                                <Row justify={'space-between'}>
                                    <Col>
                                        <span className={styles.header}>Пользователи</span>
                                    </Col>
                                    <Col>
                                        <Button
                                            onClick={onSelectAll} type={'text'} className={styles.checkAll}
                                            icon={<Icon type={'DoubleCheck'} />}></Button>
                                    </Col>
                                </Row>
                            </div>) : ''
                    }
                    <div id={isMobile ? 'scrollableDiv' : undefined} className={styles['scrollContainer']}>
                        <InfiniteScroll
                            dataLength={usersToInvite ? usersToInvite.length : 0}
                            next={loadMoreData}
                            hasMore={usersToInvite ? usersToInvite.length < totalCount : false}
                            loader={<Skeleton avatar={{ size: 64 }} title={false} active />}
                            scrollableTarget='scrollableDiv'
                        >
                            <List
                                dataSource={usersToInvite ?? undefined}
                                renderItem={(userToInvite) => (
                                    <div>
                                        <UserInviteCard
                                            key={userToInvite.user_id}
                                            userId={userToInvite.user_id}
                                            region={userToInvite.region}
                                            name={userToInvite.surname_np}
                                            onSelect={onUserSelect}
                                            avatar={userToInvite.citizen_avatar ?? ''}
                                            isSelected={selectedUsers.includes(userToInvite.user_id)}
                                        />
                                        {usersToInvite.indexOf(userToInvite) !== usersToInvite.length - 1
                                            && <FolkDivider />
                                        }
                                    </div>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                    { isMobile && <FolkButton
                        className={styles['button']}
                        disabled={!(selectedUsers.length > 0 && !!selectedPointId)}
                        onClick={onPostUsers}>
                        Пригласить
                    </FolkButton> }
                </Card>
            </Page.Content>
        } />
    );
};
