import React from 'react';
import { useHistory } from 'react-router-dom';
import Page from 'components/Page';
import NewsGeneratorForm from './components/NewsGeneratorForm/NewsGeneratorForm';
import styles from './NewsGeneratorPage.module.scss';

export interface INewsGeneratorPageLocationState {
    projectId: number;
    pointId: number;
    activityId: number;
}

const NewsGeneratorPage: React.FC = () => {
    const history = useHistory<INewsGeneratorPageLocationState | undefined>();
    const pageState = history.location.state;

    if (pageState) {
        window.history.replaceState({}, document.title);
    }

    return (
        <Page
            content={
                <Page.Content sidebar={'MainWithoutNews'}>
                    <div className={styles.newsGeneratorPageWrapper}>
                        <div className={styles.newsGeneratorPageWrapperTitleBlock}>
                            <div className={styles.newsGeneratorPageWrapperTitle}>
                                Генератор новостей
                            </div>
                        </div>
                        <NewsGeneratorForm
                            projectId={pageState?.projectId}
                            pointId={pageState?.pointId}
                            activityId={pageState?.activityId}
                        />
                    </div>
                </Page.Content>
            }
        />
    );
};

export default NewsGeneratorPage;
