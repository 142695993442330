import { axiosPostNoResponse } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IEventLikeRequest,
    IMessageLikeRequest,
    IPointLikeRequest,
    LikeType,
} from '../types/v1.0/likesDislikes';

export enum LikeDislikeType
{
    Like = 1,
    Dislike
}

type LikeRequest = IEventLikeRequest | IPointLikeRequest | FormData | IMessageLikeRequest;
export const sendLike = async (id: number, likeType: LikeType, isLike: boolean) => {
    const sendRequest = async (url: string, data: LikeRequest) => {
        await axiosPostNoResponse<LikeRequest>(url, data);
    };

    const markType = isLike ? LikeDislikeType.Like : LikeDislikeType.Dislike;
    const formData = new FormData();
    let request = {} as LikeRequest;
    switch (likeType) {
        case LikeType.activityLike:
            request = {
                modelData: {
                    Likeit: markType,
                    ObjectEvent_id: id,
                },
            } as IEventLikeRequest;
            await sendRequest(ApiPaths.CitizenEventLike, request);
            break;
        case LikeType.pointLike:
            formData.append('id', id?.toString());
            formData.append('mark_type', markType?.toString());
            await sendRequest(ApiPaths.ObjectLike, formData);
            break;
        case LikeType.newsLike:
            formData.append('news_id', id?.toString());
            formData.append('mark_type', markType?.toString());
            await sendRequest(ApiPaths.NewsLike, formData);
            break;
        case LikeType.messageLike:
            formData.append('message_id', id?.toString());
            formData.append('mark_type', markType?.toString());
            await sendRequest(ApiPaths.MessageLike, formData);
            break;
        default:
            break;
    }
};
