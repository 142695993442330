import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

export const defaultState: IState = {
    data: {},
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    if (action.type === actionTypes.SAVE_PROJECT_INDICATORS) {
        state.data[action.payload.name] = action.payload.projectIndicators;

        return {
            ...state,
            data: state.data,
        };
    }

    return state;
};

export default reducer;
