import React, { useEffect, useState } from 'react';
import { List, Pagination } from 'antd';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { defaultCurrent, defaultPageSize } from 'pages/MainPage/components/const/pagination';
import ActivityCard from '../ActivityCard';
import './ActivitiesList.scss';

export interface IActivitiesListProps {
    activities: IDeputyActivity[] | null;
    refresh?: (take: number, skip: number) => void;
    loading: boolean;
    totalCount: number;
    pageByDate?: number;
}

export const ActivitiesList: React.FC<IActivitiesListProps> = (props) => {
    const [customPage, setCustomPage] = useState<number | undefined>();

    useEffect(() => {
        setCustomPage(props.pageByDate);
    }, [props.pageByDate]);

    return (
        props.activities && props.activities.length !== 0 ?
            <div>
                <div>
                    <List
                        bordered
                        dataSource={props.activities}
                        renderItem={(item: IDeputyActivity) => (
                            <List.Item className={'activity-list-item'}>
                                <ActivityCard
                                    activity={item}
                                />
                            </List.Item>
                        )}
                    />
                    <Pagination
                        className={'points-list-pagination'}
                        total={props.totalCount}
                        defaultCurrent={defaultCurrent}
                        current={customPage}
                        pageSizeOptions={[defaultPageSize]}
                        showSizeChanger={false}
                        hideOnSinglePage
                        onChange={(page: number) => {
                            const skip = (page - 1) * defaultPageSize;
                            props.refresh?.(defaultPageSize, skip);
                            setCustomPage(page);
                        }}
                    />
                </div>
            </div>
            :
            <div>
                <div className={'no-activities-message'}>Нет назначенных мероприятий</div>
            </div>
    );
};
