import React, { useCallback } from 'react';
import { Form, DatePicker } from 'antd';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import './DatePickerField.scss';
import { QUERY_FORMAT } from 'constants/dateTime';
import { validationMessages } from 'constants/validationMessages';

export interface IDatePickerFieldProps {
    disabledDate?: (date: Moment) => boolean;
    placeholder: string;
    name: string;
    disabled?: boolean;
    format?: string;
    needToSetNameUndefined?: boolean;
    useReadonlyInput?: boolean;
    onChange?: (dateString: string) => void;
    disableClear?: boolean;
    required?: boolean;
}

export const DatePickerField: React.FC<IDatePickerFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField<string | null>(name);

    const onChange = useCallback(
        (_: Moment | null, dateString: string) => {
            setValue(dateString);
            setTouched(true, false);
            props.onChange?.(dateString);
        },
        [setValue, setTouched]
    );

    const format = props.format ?? QUERY_FORMAT;

    return (
        <Form.Item
            name={props.needToSetNameUndefined ? undefined : field.name}
            initialValue={field.value ? moment.utc(field.value, format) : undefined}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
            rules={[{ required: props.required ?? true, message: validationMessages.date }]}
        >
            <DatePicker
                value={field.value ? moment.utc(field.value, format) : undefined}
                format={format}
                disabledDate={props?.disabledDate}
                className={'date-picker-field'}
                placeholder={props.placeholder}
                onChange={onChange}
                disabled={props.disabled}
                suffixIcon={null}
                inputReadOnly={true}
                allowClear={!props.disableClear}
            />
        </Form.Item>
    );
};
