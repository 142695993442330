import { IPoint, ICoordinates } from 'api/types/v1.0/points';

export interface IPaging {
    skip: number;
    take: number;
}

export enum TabType {
    'map',
    'list',
}

export interface IState {
    listPoints: IPoint[] | null;
    totalItems: number;
    isListLoading: boolean;
    listPaging: IPaging;
    mapPoints: IPoint[];
    isMapLoading: boolean;
    mapCenter: ICoordinates;
    mapRegionName: string | null;
    pointsVisualisation: TabType;
    idOfFilteredRegions: number[];
}
