import { ApiPaths } from 'constants/ApiPaths';
import { axiosPostRequest } from 'utils/axiosClient';
import { IUserProfile } from '../types/v1.0/userProfile';

export interface UserProfileRequest {
    data: IUserProfile,
}
export const getUserProfile = async (userId: string) => {
    const res = await axiosPostRequest<{ Id: string }, UserProfileRequest>(ApiPaths.CitizenInfo, { Id: userId });
    return res.data;
};
