import { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { handleApiError } from 'api/v1.0/common';
import { getUserSettings } from 'api/v1.0/userSettings';
import { IAccount } from 'types/account';
import { GetUserProjects } from 'pages/Auth/components/UserRegistrationForm/UserRegistrationFormFields/UserProjects';
import { RootState } from 'App/root/rootReducer';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

const useUserSettingsPage = () => {
    const [pageState, dispatch] = useReducer(reducer, defaultState);
    const user = useSelector((state: RootState) => state.account.user) as IAccount;

    const refresh = useCallback(
        () => {
            dispatch(actionCreators.setIsLoading(true));

            Promise.all([
                getUserSettings(),
                GetUserProjects(user.sub),
            ])
                .then((results) => {
                    const [settings, userSelectedProject] = results;

                    dispatch(
                        actionCreators.setUserSettings({
                            ...settings,
                            projects: userSelectedProject.data?.map(
                                ({ ProjectLink_Ref_Id, ProjectChoice, ProjectLink_project_name }) => ({
                                    ProjectLink_Ref_Id,
                                    ProjectChoice,
                                    ProjectLink_project_name,
                                })
                            ),
                        })
                    );
                })
                .catch((e) => handleApiError(e))
                .finally(() => dispatch(actionCreators.setIsLoading(false)));
        },
        [user]
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        ...pageState,
        refresh,
    };
};

export default useUserSettingsPage;
