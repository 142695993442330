import React from 'react';
import { RouteProps } from 'react-router-dom';
import * as jwt from 'App/root/jwt';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';

interface IRouteProps extends RouteProps {
    component: React.ComponentType;
}

const PrivateRoute: React.FC<IRouteProps> = ({
    component: Component,
    ...rest
}) => {
    if (!jwt.get()) {
        redirect(AuthHelper.getAuthorizeCodeUrl(), false);
    }

    return <Component {...rest} />;
};

export default PrivateRoute;
