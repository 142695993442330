import React from 'react';

interface IProps {
    content?: React.ReactNode;
}

const Page: React.FC<IProps> = ({ content }) => {
    return (
        <div>{content}</div>
    );
};

export default Page;
