import React from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import './FavoriteIcon.scss'
import { Button } from "antd";

interface IProps {
    onClick: () => void;
    isActive: boolean;
    disabled?: boolean;
}
export const FavoriteIcon: React.FC<IProps> = (props) => {
    if (props.isActive) {
        return (
            <Button
                className={'favorite-icon-button'}
                disabled={props.disabled}
                onClick={props.onClick}
            >
                <StarFilled className={'favorite-icon'} />
            </Button>);
    }
    else {
        return (
            <Button
                className={'favorite-icon-button'}
                disabled={props.disabled}
                onClick={props.onClick}
            >
                <StarOutlined className={'favorite-icon'} />
            </Button>
        );
    }
}