import React from 'react';
import { Badge, Divider, Image, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { IPreviewListItem } from 'api/types/v1.0/groupsList';
import { formatTimeDate } from 'utils/dateTime';
import './ChatPreviewCard.scss';
import { RootState } from 'App/root/rootReducer';
import { hasCoordinatorRole } from 'helpers/account';
import defaultImage from '../../assets/defaultPointImage.jpg';

interface IProps {
    previewDetails: IPreviewListItem;
    selectedGroup: number;
}

const ChatPreviewCard: React.FC<IProps> = ({ previewDetails, selectedGroup }) => {
    const messageTime = formatTimeDate(previewDetails?.date_time, true);
    const user = useSelector((state: RootState) => state.account.user);

    return (
        <div className={`chat-preview-card-wrapper ${selectedGroup === previewDetails.object_id
            ? 'highlighted-preview' : ''}`}>
            <div className={'chat-preview-card'}>
                <Image
                    src={previewDetails?.object_preview}
                    fallback={defaultImage}
                />
                <div className={'chat-preview-details-wrapper'}>
                    <div className={'chat-preview-title-wrapper'}>
                        <Tooltip title={previewDetails?.object_name}>
                            <div className={'chat-preview-title'}> {previewDetails?.object_name} </div>
                        </Tooltip>
                        <Tooltip title={previewDetails?.date_time && messageTime}>
                            <span className={'chat-preview-last-message-time'}>
                                {previewDetails?.date_time && messageTime}
                            </span>
                        </Tooltip>
                    </div>
                    <div className={'chat-preview-subtitle-wrapper'}>
                        <div className={'chat-preview-last-message'}>
                            {previewDetails?.Id ? previewDetails?.message_text || 'изображение' : 'нет сообщений'}
                        </div>
                        {!hasCoordinatorRole(user) && <Badge
                            count={previewDetails?.count_not_viewed > 0 ? previewDetails?.count_not_viewed : null}
                        />}
                    </div>
                </div>
            </div>
            <Divider />
        </div>
    );
};

export default ChatPreviewCard;
