import React, { useState } from 'react';
import { Avatar, Divider, Input, List, Modal, Select, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'App/root/rootReducer';
import { IGroupMember } from 'api/types/v1.0/groupMembersList';
import { hasRole } from 'utils/user';
import { Role } from 'types/account';
import * as actionCreators from 'ducks/chat/actionCreators';
import { ReactComponent as CloseIcon } from '../../assets/CloseIcon.svg';
import { ReactComponent as RemoveIcon } from './assets/RemoveIcon.svg';
import { ReactComponent as RecoverIcon } from './assets/RecoverIcon.svg';
import useGroupMembersList from './hooks/useGroupMembersList';
import { SelectOptions } from './constants/SelectOptions';
import { getReasonOfRemoval } from './helpers/getReasonOfRemoval';
import styles from './GroupListMembers.module.scss';
import { filterFromArray } from './helpers/filterFromArray';

interface IProps {
    setIsGroupMembersListHidden: (groupMembersListHidden: boolean) => void;
    isEditDisabled?: boolean;
    selectedGroup_Id: number;
}

const GroupListMembers: React.FC<IProps> = ({ setIsGroupMembersListHidden, isEditDisabled, selectedGroup_Id }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedMemberToRemove, setSelectedMemberToRemove] = useState<IGroupMember | null>();
    const [reasonOfRemoval, setReasonOfRemoval] = useState<string>('');
    const [filteredMemberName, setFilteredMemberName] = useState<string>('');
    const { user } = useSelector((state: RootState) => state.account);
    const isMundep = hasRole([Role.Municipal], user?.role as Role);
    const {
        groupMembersList,
        isGroupMembersLoading,
        refresh,
        removeFromGroup,
        recoverMember,
    } = useGroupMembersList(selectedGroup_Id);
    const [filteredGroupMembersList, setFilteredGroupMembersList]
        = useState<IGroupMember[] | undefined>(groupMembersList?.data);

    const loadMoreData = () => {
        refresh(groupMembersList?.data.length);
    };

    const handleDeleteIconClick = (groupMember: IGroupMember) => {
        setIsModalOpen(true);
        setSelectedMemberToRemove(groupMember);
    };

    const handleRecoverIconClick = (groupMember: IGroupMember) => {
        recoverMember(
            selectedGroup_Id,
            groupMember?.citizen_id,
            groupMember.Id
        );
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedMemberToRemove(null);
        setReasonOfRemoval('');
    };

    const handleModalSubmit = () => {
        if (selectedMemberToRemove) {
            removeFromGroup(
                selectedGroup_Id,
                selectedMemberToRemove?.citizen_id,
                getReasonOfRemoval(reasonOfRemoval),
                selectedMemberToRemove.Id
            );
        }

        setIsModalOpen(false);
        setSelectedMemberToRemove(null);
        setReasonOfRemoval('');
    };

    return (
        <div>
            <Spin spinning={isGroupMembersLoading}>
                <div className={styles.groupListmembersHeader}>
                    <span>Группа</span>
                    <CloseIcon className={styles.closeIcon} onClick={() => {
                        setIsGroupMembersListHidden(true);
                        dispatch(actionCreators.setGroupMembersState(false));
                    }} />
                </div>
                <div className={styles.groupListmembersFilter}>
                    <Input
                        className={styles.membersFilter}
                        placeholder="Поиск по пользователям"
                        value={filteredMemberName}
                        onChange={(e) => {
                            setFilteredMemberName(e.target.value);
                            setFilteredGroupMembersList(filterFromArray(e.target.value, groupMembersList?.data));
                        }}
                    />
                </div>
                <div
                    id="scrollableDiv"
                    className={styles.infiniteScrollWrapper}
                >
                    <InfiniteScroll
                        dataLength={groupMembersList ? groupMembersList.data.length : 0}
                        next={loadMoreData}
                        hasMore={groupMembersList ? groupMembersList.data.length < groupMembersList.totalCount : false}
                        loader={''}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            className={styles.membersList}
                            dataSource={filteredMemberName ? filteredGroupMembersList : groupMembersList?.data}
                            renderItem={(groupMember: IGroupMember) => ((user?.role === Role.Citizen &&
                                groupMember.remove_from_group === 0) || (user?.role !== Role.Citizen)) && (
                                <div className={styles.groupListmemberCardWrapper}>
                                    <Divider />
                                    <div className={styles.groupListmemberCard}>
                                        <div className={groupMember.remove_from_group
                                            ? styles.groupListmemberRemovedInfo
                                            : styles.groupListmemberInfo
                                        }>
                                            <Avatar
                                                className={styles.groupListmemberIcon}
                                                icon={<UserOutlined />}
                                                src={groupMember.preview}
                                            />
                                            <span className={styles.groupListmemberName}>{groupMember.name}</span>
                                        </div>
                                        {(!isEditDisabled && isMundep && Number(user?.sub) !== groupMember.Id) &&
                                            <div>
                                                {groupMember.remove_from_group
                                                    ? <div className={styles.groupListMemberRecoverIcon}>
                                                        <RecoverIcon
                                                            onClick={() => {
                                                                handleRecoverIconClick(groupMember);
                                                            }}
                                                        />
                                                    </div>
                                                    : <div className={styles.groupListMemberRemoveIcon}>
                                                        <RemoveIcon
                                                            onClick={() => {
                                                                handleDeleteIconClick(groupMember);
                                                            }}
                                                        />
                                                    </div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        />
                    </InfiniteScroll>
                    <Modal
                        className={styles.groupListmemberRemoveModal}
                        onCancel={handleModalClose}
                        onOk={handleModalSubmit}
                        okButtonProps={{ disabled: !reasonOfRemoval }}
                        title="Удаление участника группы"
                        open={isModalOpen}
                        mask={false}
                    >
                        <p className={styles.groupListmemberRemoveModalDescription}>
                            Вы уверены, что хотите удалить
                            пользователя {selectedMemberToRemove?.name} из чата?
                        </p>
                        <Select
                            className={styles.groupListmemberRemoveModal}
                            placeholder='Выберите причину'
                            options={SelectOptions}
                            value={reasonOfRemoval || undefined}
                            onChange={(value) => {
                                setReasonOfRemoval(value);
                            }}
                        />
                    </Modal>
                </div>
            </Spin>
        </div>
    );
};

export default GroupListMembers;
