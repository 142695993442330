import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import browserHistory from 'App/root/browserHistory';
import { IAccountResponse } from 'api/types/v1.0/account';
import useSearchParams from 'hooks/useSearchParams';
import { AuthHelper } from 'utils/AuthHelper';
import { getProtectedSourceUrl } from 'utils/protectedSourceAccess';

const useRegistrationPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isNeedRedirect, setIsNeedRedirect] = useState(false);
    const [registrationInfo, setRegistrationInfo] = useState<IAccountResponse|null>(null);

    const location = useLocation<{ referrer: string }>();
    const returnUrl = location.state?.referrer || '/';

    const refresh = useCallback(() => {
        const fetch = async () => {
            try {
                // eslint-disable-next-line
                const params = useSearchParams();
                const code = params.get('code');
                if (code) {
                    setIsLoading(true);
                    const response = await AuthHelper.Authorize(code);
                    if (response.isRegistered) {
                        browserHistory.push(returnUrl);
                    }

                    response['avatar'] = await getProtectedSourceUrl(response.avatar);

                    setRegistrationInfo(response);
                    setIsLoading(false);
                }
            } catch (e)
            {
                setIsNeedRedirect(true);
            } finally {
                setIsLoading(false);
            }
        };

        void fetch();
    }, [returnUrl]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        isLoading,
        registrationInfo,
        refresh,
        isNeedRedirect,
    };
};

export default useRegistrationPage;
