import { Divider, Radio, Space } from 'antd';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import React, { useState } from 'react';
import './QuestionsCard.scss';
import FolkButton from 'ui-kit/FolkButtons';
import { redirect } from 'utils/browser';
import { AuthHelper } from 'utils/AuthHelper';
import FolkRadio from 'ui-kit/FolkRadio/FolkRadio';

interface IProps {
    interview: IInterview;
    onVote: (answerId: number, interviewId: number) => void;
    canVote: boolean;
}

const QuestionsCard: React.FC<IProps> = ({ interview, onVote, canVote }) => {

    const [selectedAnswerId, setSelectedAnswerId] = useState<number>(0);

    const onVoteClick = () => {
        onVote(selectedAnswerId, interview.id);
    }

    return (
        <div className={'questions-card-wrapper'}>
            <Divider />
            <div>
                <Radio.Group className={'questions-card-options-list'}>
                    <Space direction="vertical" className={'questions-card-options-list'}>
                        {
                            interview?.answers
                                .sort((prevAnswer, nextAnswer) => prevAnswer.id - nextAnswer.id)
                                .map((answer) => {
                                return (
                                    <div key={answer?.id}>
                                        <FolkRadio onClick={() => canVote
                                            ? setSelectedAnswerId(answer?.id)
                                            : redirect(AuthHelper.getAuthorizeCodeUrl(), false)} />
                                        <span className={'questions-card-option-text'}>{answer?.answer}</span>
                                        <Divider />
                                    </div>
                                )
                            })
                        }
                    </Space>
                </Radio.Group>
            </div>
            <div>
                <FolkButton
                    disabled={selectedAnswerId === 0}
                    onClick={() => canVote ? onVoteClick() : redirect(AuthHelper.getAuthorizeCodeUrl(), false)}
                    className={'vote-button'}
                    type='primary' block>
                    Проголосовать
                </FolkButton>
            </div>
        </div>
    )
}

export default QuestionsCard;