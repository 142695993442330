import React from 'react';
import { Card, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import Page from 'components/Page';
import FolkDivider from 'ui-kit/FolkDivider/FolkDivider';
import CardTitle from 'ui-kit/Title';
import Indicators from './components/Indicators/Indicators';
import ProjectInfoText from './components/ProjectInfoText';
import { useProjectInfo } from './hooks';
import styles from './index.module.scss';

const ProjectInfoPage: React.FC = () => {
    const { id } = useParams<{id: string}>();
    const { state } = useProjectInfo(Number(id));
    const description = state.info?.description ?? '';

    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <Card className={styles['info-card']}>
                        <Skeleton loading={state.isLoading} paragraph={{ rows: 6 }} className={styles['skeleton']}>
                            <div className={styles['title-wrapper']}>
                                <CardTitle cardTitle={'Описание проекта'} />
                            </div>
                            <FolkDivider />
                            <ProjectInfoText desc={description} />
                        </Skeleton>
                        <Indicators />
                    </Card>
                </Page.Content>
            }
        />
    );
};

export default ProjectInfoPage;
