import { useCallback, useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getRegionsList } from 'api/v1.0/regionList';
import { IRegionList } from 'api/types/v1.0/regionList';

export const useRegionList = () => {
    const [state, setState] = useState<IRegionList>();
    const fetch = () => {
        getRegionsList()
            .then((response) => setState(response))
            .catch((e) => handleApiError(e));
    };

    const refresh = useCallback(
        () => fetch(),
        []
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        ...state,
        refresh,
    };
};
