import React from 'react';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import * as actionCreators from 'ducks/chat/actionCreators';
import browserHistory from 'App/root/browserHistory';
import Icon from 'ui-kit/Icon';
import { Pages } from 'constants/links';
import styles from './CitizenGroupItem.module.scss';

interface IProps {
    groupId: number;
    title: string;
    address: string;
    objectId: number;
    setConfirmModalOpen: (isOpen: boolean, objectId: number) => void;
}

const CitizenGroupItem: React.FC<IProps> = ({
    groupId,
    title,
    address,
    objectId,
    setConfirmModalOpen,
}) => {
    const dispatch = useDispatch();

    const onRemoveIconClick = () => {
        setConfirmModalOpen(true, objectId);
    };

    return (
        <div className={styles.groupItemWrapper}>
            <div className={styles.groupItem}>
                <div
                    className={styles.groupItemText}
                    onClick={() => {browserHistory.push(`${Pages.PointInfo.url}/${objectId.toString()}`);
                        setTimeout(()=>{window.scrollTo(0, -3000);},100);}}
                >
                    <div className={styles.groupItemTitle}>
                        {title}
                    </div>
                    <div className={styles.groupItemDescription}>
                        {address}
                    </div>
                </div>
                <div className={styles.groupItemActionsBlock}>
                    <Button
                        className={styles.groupItemChatButton}
                        onClick={() => {
                            dispatch(actionCreators.setSelectedChatId(groupId));
                            isMobile
                                ? browserHistory.push(`${Pages.Chat.url}/${groupId.toString()}`)
                                : dispatch(actionCreators.setChatModalState(true));
                        }}
                    >
                        Открыть чат
                    </Button>
                    <Icon onClick={onRemoveIconClick} className={styles.removeIcon} type={'Remove'} />
                </div>
            </div>
        </div>
    );
};

export default CitizenGroupItem;
