export const getMessageFromStatus = (invitation_response_status: number) => {
    let message;
    if (invitation_response_status === 1) {
        message = 'Вы приняли приглашение';
    } else {
        message = 'Вы отклонили приглашение';
    }

    return message;
};
