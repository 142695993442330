import { setup } from 'axios-cache-adapter';
import { AxiosRequestConfig } from 'axios';
import * as localforage from 'localforage';

const forageStore = localforage.createInstance({
    driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
    ],
    name: 'axios-cache',
});

const axiosCacheClient = setup({
    cache: {
        maxAge: 15 * 60 * 1000,
        key: (req: AxiosRequestConfig) => `${req.method!}: ${req.url!}: ${JSON.stringify(req.data)}`,
        exclude: {
            methods: ['put', 'patch', 'delete'],
        },
        store: forageStore,
    },
});

export default axiosCacheClient;
