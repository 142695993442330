import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getProjectInfoAsync } from 'api/v1.0/projectInfo';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

export const useProjectInfo = (id: number, hasSavedProject?: boolean) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getProjectInfoAsync({ id })
            .then((response) =>
                dispatch(actionCreators.setProjectInfo(response.data)))
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
    }, [id, dispatch]);

    useEffect(() => {
        if (id > 0 && !hasSavedProject) {
            refresh();
        }
        // eslint-disable-next-line
    }, [refresh]);

    return {
        state,
    };
};
