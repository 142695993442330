import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import {
    ActionsType,
    IPointInfoState,
} from './types';

export const defaultState: IPointInfoState = {
    info: null,
    isLoading: false,
    error: null,
};

const reducer: Reducer<IPointInfoState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.EDIT_POINT_INFO_FETCH:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.EDIT_POINT_INFO_LOAD:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.EDIT_POINT_INFO_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case actionTypes.EDIT_POINT_INFO_SET:
            return {
                ...state,
                info: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
