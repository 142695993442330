import { useCallback, useEffect, useReducer } from 'react';
import { postRemoveFileAsync } from 'api/v1.0/removeFile';
import { getPointDocumentsAsync } from 'api/v1.0/pointDocuments';
import { sortDocsByDate } from 'utils/sortDocsByDate';
import { postPointDocumentAddAsync } from 'api/v1.0/pointDocumentAdd';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

export interface IUseEditPointDocumentsProps {
    pointId: number | null;
}

const useEditPointDocuments = ({ pointId }: IUseEditPointDocumentsProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const fetchPointDocuments = async () => {
        dispatch(actionCreators.editPointDocsFetch());
        try
        {
            const docs = await getPointDocumentsAsync({ id: pointId ?? 0 });
            docs.data.sort(sortDocsByDate);
            dispatch(actionCreators.editPointDocsSet(docs.data));
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointDocsError(error));
        }
        finally {
            dispatch(actionCreators.editPointDocsLoad());
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshPointDocuments = useCallback(() => void fetchPointDocuments(), []);

    const fetchRemoveDocument = async (id: number) => {
        dispatch(actionCreators.editPointDocsRemove());
        try
        {
            await postRemoveFileAsync({ records: [id] });
            await fetchPointDocuments();
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointDocsError(error));
        }
        finally {
            dispatch(actionCreators.editPointDocsLoad());
        }
    };

    const removeDocument = (documentPointId: number) => void fetchRemoveDocument(documentPointId);

    const fetchAddDocument = async (doc: FormData) => {
        dispatch(actionCreators.editPointDocsAdd());
        try
        {
            await postPointDocumentAddAsync(doc);
            await fetchPointDocuments();
        }
        catch (exc)
        {
            const error = (exc as Error).message;
            dispatch(actionCreators.editPointDocsError(error));
        }
        finally {
            dispatch(actionCreators.editPointDocsLoad());
        }
    };

    const uploadDocument = (doc: FormData) => void fetchAddDocument(doc);

    useEffect(() => {
        refreshPointDocuments();
    }, [refreshPointDocuments]);

    return {
        state,
        uploadDocument,
        removeDocument,
    };
};

export default useEditPointDocuments;
