import { Avatar, Badge, Button, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Icon, { UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as actionCreators from 'ducks/chat/actionCreators';
import { IFederalCoordinatorsPoint } from 'api/types/v1.0/federalCoordinatorPoints';
import OrangeButton from 'ui-kit/OrangeButton/OrangeButton';
import { UnionSvg } from 'ui-kit/Svg/UnionSvg';
import { PeopleSvg } from 'ui-kit/Svg/PeopleSvg';
import { CalendarSvg } from 'ui-kit/Svg/CalendarSvg';
import './CoordinatorPoint.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StatsSvg } from 'ui-kit/Svg/StatsSvg';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { IActivitiesSchedulerPageState } from 'pages/ActivitiesScheduler';
import { getDeputyQuestions } from 'api/v1.0/deputyQuestions';
import { IDeputyQuestion } from 'api/types/v1.0/deputyQuestions';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { getDeputyActivitiesMain } from 'api/v1.0/deputyActivities';
import { getDeputyActivitiesFilterForReports, getDeputyActivitiesSort } from 'api/bars-request-objects';
import { handleApiError } from 'api/v1.0/common';
import OrangeButtonsModal from 'ui-kit/OrangeButtonsModal';
import NotReportedActivity from 'ui-kit/NotReportedActivity';
import NotRespondedQuestion from 'ui-kit/NotRespondedQuestion';
import { eventStatuses } from 'constants/eventStatuses';

interface IProps {
    data: IFederalCoordinatorsPoint,
    onSelectedChange: (pointId:number) => void,
    clearSelected: boolean,
    compact?: boolean,
}

const CoordinatorPoint: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const [isSelected,setSelected] = useState<boolean>(false);
    const [isNotReportedActivitiesOpen, setIsNotReportedActivitiesOpen] = useState<boolean>(false);
    const [notReportedActivities, setNotReportedActivities] = useState<IDeputyActivity[]>([]);
    const [notReportedActivitiesLoading, setNotReportedActivitiesLoading] = useState<boolean>(true);

    const [isNotRespondedQuestionsOpen, setIsNotRespondedQuestionsOpen] = useState<boolean>(false);
    const [notRespondedQuestions, setNotRespondedQuestions] = useState<IDeputyQuestion[]>([]);
    const [notRespondedQuestionsLoading, setNotRespondedQuestionsLoading] = useState<boolean>(true);

    const setSelectedItem = () => {
        setSelected(!isSelected);
        props.onSelectedChange(props.data.point_id);
    };

    const onAboutObjectClick = () => {
        browserHistory.push(`${Pages.PointInfo.url}/${props.data?.point_id ?? 0}`);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const onNotAnsweredQuestionClick = (question: IDeputyQuestion) => {
        setIsNotRespondedQuestionsOpen(false);
        isMobile
            ? browserHistory.push(`${Pages.Chat.url}/${question.object_id.toString()}`)
            : dispatch(actionCreators.setChatModalState(true));
        dispatch(actionCreators.setIsResponse(true));
        dispatch(actionCreators.setSelectedChatId(question.object_id));
        dispatch(actionCreators.setSelectedMessageId(question.Id));
        dispatch(actionCreators.setSelectedProjectId(question.project_id));
    };

    const openActivitiesScheduler = () =>
    {
        browserHistory.push({
            pathname: Pages.ActivitiesScheduler.url,
            state: {
                projectId: props.data.project_id,
                pointId: props.data.point_id,
            } as IActivitiesSchedulerPageState,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const refreshNotRespondedQuestions = async () => {
        try {
            setNotRespondedQuestionsLoading(true);
            const response = await getDeputyQuestions({
                deputyId: props.data.Id,
                objectId: props.data.point_id,
                start: notRespondedQuestions.length,
                isCoordinator: true,
            });
            setNotRespondedQuestions((prev) => [...prev, ...response.data]);
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setNotRespondedQuestionsLoading(false);
        }
    };

    const refreshNotReportedActivities = async () => {
        try {
            setNotReportedActivitiesLoading(true);
            const response = await getDeputyActivitiesMain({
                dataFilter: getDeputyActivitiesFilterForReports(props.data.project_id, props.data.point_id,
                    undefined, props.data.municipal_response_id.toString(),
                    null, false, eventStatuses.completed),
                sort: getDeputyActivitiesSort(),
            });
            setNotReportedActivities(response.data);
        } catch (exception) {
            handleApiError(exception);
        } finally {
            setNotReportedActivitiesLoading(false);
        }
    };

    useEffect(() => {
        if (isNotReportedActivitiesOpen) {
            void refreshNotReportedActivities();
        }

        if (isNotRespondedQuestionsOpen) {
            void refreshNotRespondedQuestions();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isNotReportedActivitiesOpen,
        isNotRespondedQuestionsOpen,
    ]);

    useEffect(()=>{
        setSelected(false);
        //eslint-disable-next-line
    },[props.clearSelected]);

    return (
        <div>
            <div onClick={setSelectedItem} className={ isSelected ? 'coordinator-point-body-sel' : 'coordinator-point-body'}>
                <div className='coordinator-info'>
                    <Avatar
                        size={45}
                        className={'listAvatar-deputy'}
                        alt={props.data.deputy_surname_n_p}
                        icon={<UserOutlined />}
                        src={props.data?.preview}
                    />
                    <div className={'deputy-name'}>{props.data.deputy_surname_n_p}</div>
                </div>
                <div className='coordinator-point-header'>
                    <div className='title'>{props.data.title}</div>
                    <div className='region-name'>&middot; {props.data.address}</div>
                </div>
                <div className='coordinator-point-statistics'>
                    <div className='coordinator-rating'>
                        <Icon component={UnionSvg} className='rating-svg' /> Рейтинг {props.data.rating}%
                    </div>
                    <div className='coordinator-group' onClick={() => {
                        dispatch(actionCreators.setSelectedChatId(props.data.point_id));
                        dispatch(actionCreators.setGroupMembersState(true));
                        isMobile
                            ? browserHistory.push(`${Pages.Chat.url}/${props.data.point_id.toString()}`)
                            : dispatch(actionCreators.setChatModalState(true));
                    }}>
                        <Icon component={PeopleSvg} className='rating-svg' /> В группе {props.data.group_participants_number}
                    </div>
                </div>
                <div className='coordinator-buttons'>
                    <Badge size='default' dot={props.data.new_photos_count > 0}>
                        <div onClick={onAboutObjectClick} className='coordinator-button-report'>Новые фото</div>
                    </Badge>
                    <div className='coordinator-orange-buttons'>
                        <div>
                            <OrangeButtonsModal
                                open={isNotReportedActivitiesOpen}
                                onClose={() => setIsNotReportedActivitiesOpen((prevState) => !prevState)}
                                title={props.data.title}
                                subtitle={'Ниже представлены мероприятия, по которым еще не сформирован отчет'}
                            >
                                <OnLoadingSpinner className={'modal-spinner'} isLoading={notReportedActivitiesLoading} content={
                                    notReportedActivities.map(
                                        (activity) =>
                                            (
                                                <NotReportedActivity
                                                    key={activity.object_event_id}
                                                    activity={activity}
                                                />
                                            )
                                    )
                                } />
                            </OrangeButtonsModal>
                            {props.data.not_reported_activities_count > 0 &&
                                <div className='deputy-point-not-loaded-report'>
                                    <OrangeButton
                                        className='orange-button'
                                        onClick={() => setIsNotReportedActivitiesOpen((prevState) => !prevState)}
                                        type={'primary'}
                                    >
                                        {!props.compact && 'Не загружено отчетов: '}{props.data.not_reported_activities_count}
                                    </OrangeButton>
                                </div>
                            }
                        </div>
                        <div>
                            <OrangeButtonsModal
                                open={isNotRespondedQuestionsOpen}
                                onClose={() => setIsNotRespondedQuestionsOpen((prevState) => !prevState)}
                                title={'Вопросы без ответа'}
                            >
                                <OnLoadingSpinner isLoading={notRespondedQuestionsLoading} content={
                                    <div
                                        id="scrollDiv"
                                        className={'deputy-point-question'}
                                    >
                                        <InfiniteScroll
                                            dataLength={
                                                notRespondedQuestions?.length ? notRespondedQuestions?.length : 0
                                            }
                                            next={refreshNotRespondedQuestions}
                                            hasMore={notRespondedQuestions
                                                ? notRespondedQuestions?.length <
                                                props.data.not_responded_questions_count
                                                : false
                                            }
                                            scrollableTarget="scrollDiv"
                                            height={400}
                                            loader={''}
                                        >
                                            <List
                                                className={'not-reported-activities'}
                                                dataSource={notRespondedQuestions}
                                                renderItem={(question: IDeputyQuestion) => (
                                                    <NotRespondedQuestion
                                                        question={question}
                                                        onClick={() => onNotAnsweredQuestionClick(question)}
                                                    />
                                                )}
                                            />
                                        </InfiniteScroll>
                                    </div>
                                } />
                            </OrangeButtonsModal>
                            {props.data.not_responded_questions_count > 0 &&
                                <div>
                                    <OrangeButton
                                        className='orange-button' type={'primary'}
                                        onClick={() => setIsNotRespondedQuestionsOpen((prevState) => !prevState)}
                                    >
                                        {!props.compact && 'Не отвечено вопросов: '}{props.data.not_responded_questions_count}
                                    </OrangeButton>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='coordinator-icons'>
                    <div className='coordinator-icons-buttons'>
                        <Button
                            onClick={openActivitiesScheduler}
                            className='coordinator-icon-button'
                            type='primary' shape='circle' icon={<Icon component={CalendarSvg} />} size='large' />
                        <Button
                            onClick={onAboutObjectClick}
                            className='coordinator-icon-button'
                            type='primary' shape='circle' icon={<Icon component={StatsSvg} />} size='large' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoordinatorPoint;
