import React from 'react';
import styles from '../BurgerMenu.module.scss';

const LogoutLabel: React.FC = () => {
    return (
        <span className={styles['exit-text']}>
            Выйти
        </span>
    );
}

export default LogoutLabel;
