import { FormikErrors } from 'formik/dist/types';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { registrationValidationSchema } from './registrationValidationSchema';
import { IRegistrationValues } from './UserRegistrationFormFields';

export const ValidateRegistrationValues = (
    values: IRegistrationValues
): FormikErrors<IRegistrationValues> => {
    let errors: FormikErrors<IRegistrationValues> = {};

    try {
        // В параметрах выбрано синхронное выполнение функции.
        /* eslint-disable-next-line */
        validateYupSchema(values, registrationValidationSchema, true, values);
    } catch (err) {
        errors = yupToFormErrors(err);
    }

    return errors;
};
