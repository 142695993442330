import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const barsSortObjectByName = { sort: [{ property: 'title', direction: 'ASC' }] } as IState['barsSortObject'];
const barsSortObjectByRating = { sort: [{ property: 'rating', direction: 'ASC' }] } as IState['barsSortObject'];

export const defaultState: IState = {
    points: null,
    isLoading: false,
    barsSortObject: barsSortObjectByRating,
    sortType: 'RATING',
    descending: true,
};

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_DEPUTY_OBJECTS:
            return {
                ...state,
                points: action.payload,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_SORT_BY_ALPHABET:
            return {
                ...state,
                barsSortObject: barsSortObjectByName,
                sortType: 'ALPHABET',
            };
        case actionTypes.SET_SORT_BY_RATING:
            return {
                ...state,
                barsSortObject: barsSortObjectByRating,
                sortType: 'RATING',
            };

        default:
            return state;
    }
};

export default reducer;
