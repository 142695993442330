import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import browserHistory from 'App/root/browserHistory';
import { RootState } from 'App/root/rootReducer';
import { handleApiError } from 'api/v1.0/common';
import { Pages } from 'constants/links';
import { createActivity, createEventPoll, editActivity } from 'api/v1.0/deputyActivities';
import {
    IActivityCreateRequest,
    IActivityEditRequest, IAnswer,
    IDeputyActivity,
    IEventPollCreateRequest,
    IInterview,
    IPoll,
    IPollOption,
} from 'api/types/v1.0/deputyActivities';
import { IObjectPoint } from 'api/types/v1.0/points';
import { IProjectListItem } from 'api/types/v1.0/projectList';
import { dateISODateString, dateISOTimeString } from 'utils/dateTime';
import { DATE_TIME_FORMAT } from 'constants/dateTime';
import { Role } from 'types/account';
import ActivityCardFormFields from '../ActivityCardFormFields/ActivityCardFormFields';
import { IActivityCardFormLocationState } from '../../../ActivitiesSchedulerPage';

export interface IProps {
    isEdit: boolean;
    activity?: IDeputyActivity | null;
    currentProject?: IProjectListItem;
    currentPoint?: IObjectPoint;
    onSubmit?: () => void;
    setIsModalOpen?: (isModalOpen: boolean) => void;
    refreshEvents?: () => void;
}

const ActivityCardForm: React.FC<IProps> = (cardProps) => {
    const history = useHistory<IDeputyActivity | undefined>();
    const historyFiltered = useHistory<IActivityCardFormLocationState | undefined>();
    const activityState = history.location.state ?? cardProps.activity;
    const [poll, setPoll] = useState<IInterview | null>(null);
    const [activityAnswers, setActivityAnswers] = useState<IAnswer[] | null>(null);
    const [loading, setLoading] = useState(false);
    const account = useSelector((state: RootState) => state.account);
    const pageState = historyFiltered.location.state;

    const accountId = Number(account.user?.sub);
    const accountRole = account.user?.role;

    if (activityAnswers && activityState && activityState.interview) {
        activityState.interview.answers = activityAnswers;
    }

    const onFormSubmit = (values: IDeputyActivity) => {
        const selectedDatetime = moment(`${values.date} ${values.time}`, DATE_TIME_FORMAT).toDate();
        const createPoll = {
            question: poll?.question,
            options: poll?.answers.map((x) => {
                return { optionTitle: x.answer } as IPollOption;
            }),
        } as IPoll;

        const editRequestBody = {
            interview: poll,
            date_time: selectedDatetime.toISOString(),
            deputy_oe: { Id: accountId },
            description: values.description,
            event_o: values.event_id ? { Id: values.event_id } : undefined,
            event_name: values.event_name ?? undefined,
            Id: values.object_event_id?.toString(),
            link: values.link ?? undefined,
            newEventName: values.event_name,
            object_e: values.object_id ? { Id: values.object_id } : undefined,
            Canceled: 0,
            project: { Id: values.project_id },
        } as IActivityEditRequest;

        const createPollBody = {
            ObjectEvent_id: values.object_event_id,
            poll: createPoll,
        } as IEventPollCreateRequest;

        const createRequestBody = {
            information: values.description,
            title: values.event_id ? null : values.event_name,
            date: dateISODateString(selectedDatetime),
            time: dateISOTimeString(selectedDatetime),
            objectId: values.object_id ?? undefined,
            projectId: accountRole !== Role.Municipal ? values.project_id : undefined,
            eventId: values.event_id ?? undefined,
            poll: createPollBody.poll.question ? createPoll : undefined,
            link: values.link,
        } as IActivityCreateRequest;

        if (cardProps.isEdit) {
            editActivity(editRequestBody)
                .then(() => {
                    if (values?.interview && createPollBody.poll?.options?.[0].optionTitle !== undefined) {
                        return createEventPoll(createPollBody);
                    }
                })
                .then(() => {
                    cardProps.onSubmit?.();
                    browserHistory.push(Pages.ActivitiesScheduler.url);
                })
                .catch((e) => handleApiError(e));
        } else {
            setLoading(true);
            createActivity(createRequestBody)
                .then(() => {
                    cardProps.refreshEvents?.();
                    cardProps.onSubmit?.();
                    setLoading(false);
                    browserHistory.push(Pages.ActivitiesScheduler.url);
                })
                .catch((e) => {
                    handleApiError(e);
                    setLoading(false);
                });
        }
    };

    return (
        <div className={'modal-div'}>
            <Formik<IDeputyActivity>
                initialValues={
                    {
                        ...activityState,
                        object_id: cardProps.isEdit
                            ? cardProps?.currentPoint ?? activityState?.object_id
                            : pageState?.pointId,
                        point_name: cardProps.currentPoint?.object_name,
                        project_id: cardProps.isEdit
                            ? cardProps?.currentProject ?? activityState?.project_id
                            : pageState?.projectId,
                        project_name: cardProps.currentProject?.project_name,
                    } as IDeputyActivity
                }
                onSubmit={() => {
                    browserHistory.push(Pages.ActivitiesScheduler.url);
                }}
            >
                {(props) => {
                    return (
                        <ActivityCardFormFields
                            setIsModalOpen={cardProps.setIsModalOpen}
                            isEdit={cardProps.isEdit}
                            setPoll={setPoll}
                            setActivityAnswer={setActivityAnswers}
                            activity={props.initialValues}
                            onSubmit={() => onFormSubmit(props.values)}
                            loading={loading}
                        />
                    );
                }}
            </Formik>
        </div>
    );
};

export default ActivityCardForm;
