import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    citizenEvents: null,
    isLoading: false,
    selectedProjectId: null,
    selectedPointId: null,
    selectedDate: null,
    totalCount: 0,
    selectedMonth: null,
    selectedYear: null,
    monthCitizenEvents: null,
    currentPage: 1,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CITIZEN_EVENTS:
            return {
                ...state,
                citizenEvents: action.payload,
            };

        case actionTypes.SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProjectId: action.payload,
            };

        case actionTypes.SET_SELECTED_POINT:
            return {
                ...state,
                selectedPointId: action.payload,
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.DELETE_CITIZEN_EVENTS:
            return {
                ...state,
                citizenEvents: [],
            };

        case actionTypes.SET_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload,
            };

        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.SET_SELECTED_MONTH:
            return {
                ...state,
                selectedMonth: action.payload,
                monthCitizenEvents: null,
            };

        case actionTypes.SET_SELECTED_YEAR:
            return {
                ...state,
                selectedYear: action.payload,
                monthCitizenEvents: null,
            };

        case actionTypes.SET_MONTH_CITIZEN_EVENTS:
            return {
                ...state,
                monthCitizenEvents: action.payload,
            };

        case actionTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
