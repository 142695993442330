import { IDeputyPointsResponse } from 'api/types/v1.0/deputyPoints';
import {
    SET_LOADING,
    SET_DEPUTY_OBJECTS,
    SET_SORT_BY_ALPHABET,
    SET_SORT_BY_RATING,
} from './actionTypes';

export const setDeputyObjects = (deputyObjects: IDeputyPointsResponse) =>
    ({ type: SET_DEPUTY_OBJECTS, payload: deputyObjects } as const);
export const setIsLoading = (isLoading: boolean) => ({ type: SET_LOADING, payload: isLoading } as const);

export const setSortByAlphabet = () => ({ type: SET_SORT_BY_ALPHABET } as const);
export const setSortByRating = () => ({ type: SET_SORT_BY_RATING } as const);
