import { Image, Input, Upload, UploadProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { IAccount, Role } from 'types/account';
import { hasRole } from 'utils/user';
import { RootState } from 'App/root/rootReducer';
import { IGroupsMessageItem } from 'api/types/v1.0/groupsList';
import { ReactComponent as UploadIcon } from 'components/Layout/components/LayoutHeader/components/Chat/assets/UploadIcon.svg';
import { ReactComponent as SendIcon } from 'components/Layout/components/LayoutHeader/components/Chat/assets/SendIcon.svg';
import { ReactComponent as DeleteIcon } from 'components/Layout/components/LayoutHeader/components/Chat/assets/DeleteIcon.svg';
import { getBeforeFileUploadHandler } from 'utils/getBeforeFileUploadHandler';
import { imageTypes } from 'constants/imageTypes';
import { videoTypes } from 'constants/videoTypes';
import { ISendMessage } from '../../../../hooks/useChatModal';
import { UploadedImage } from '../../types';
import './ChatInput.scss';

export interface IProps {
    group_id: number;
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    uploadedImages: UploadedImage[] | null;
    imageFiles: File[] | null;
    sendMessage: (data: ISendMessage, user: IAccount, isMundep: boolean, imgs?: File[]) => void;
    resetInputs: () => void;
    setImageFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
    setUploadedImages: React.Dispatch<React.SetStateAction<UploadedImage[] | null>>;
    answeringMessage: IGroupsMessageItem | null;
}

const ChatInput: React.FC<IProps> = ({
    group_id,
    inputValue,
    setInputValue,
    uploadedImages,
    imageFiles,
    sendMessage,
    resetInputs,
    setImageFiles,
    setUploadedImages,
    answeringMessage,
}) => {
    const { user } = useSelector((state: RootState) => state.account);
    const isMundep = hasRole([Role.Municipal], user?.role as Role);
    const typeQAC = isMundep ? 2 : 3;
    const availableFileTypes = [...imageTypes, ...videoTypes];
    const hasUploadedImages = (uploadedImages !== null && uploadedImages.length !== 0);

    const removeImage = (imgName: string) => {
        setImageFiles((files) => files?.filter((file) => file.name !== imgName) ?? null);
        setUploadedImages((files) => files?.filter((file) => file.name !== imgName) ?? null);
    };

    const uploadImagesProps: UploadProps = {
        name: 'file',
        multiple: true,
        maxCount: 2,
        showUploadList: false,
        accept: availableFileTypes.join(','),
        beforeUpload: getBeforeFileUploadHandler(
            imageFiles ?? [],
            2,
            (fileUrl, file) => {
                setUploadedImages((files) => [...files ?? [], {
                    url: fileUrl,
                    name: file.name,
                }]);
                setImageFiles((files) => [...files ?? [], file]);
            },
            true,
            availableFileTypes
        ),
    };

    const handleSendClick = () => {
        if (inputValue || hasUploadedImages) {
            const date = new Date();
            if (user) {
                const messageData = {
                    user_id: +user.sub,
                    date: date,
                    object_id: group_id,
                    text: inputValue,
                    TypeQAC: answeringMessage ? typeQAC : 1,
                    timezone: date.getTimezoneOffset() / 60,
                    QuestionAnswerCommentId: answeringMessage?.Id,
                    AnsweringMessageAuthorName: answeringMessage?.message_author_name,
                };
                sendMessage(
                    messageData,
                    user,
                    isMundep,
                    imageFiles ?? undefined
                );
            }

            resetInputs();
        }
    };

    return (
        <div className={'chat-conversation-card-footer'}>
            {uploadedImages && uploadedImages.map((img) => (
                <div key={img.name} className={'image-wrapper'}>
                    <Image
                        className={'chat-conversation-uploaded-image'}
                        preview={false}
                        src={img?.url}
                    />
                    <div className="remove-icon" onClick={() => removeImage(img.name)}>
                        <DeleteIcon />
                    </div>
                </div>
            ))}
            {answeringMessage &&
                <div className={'question-answering-info'}>
                    <div>
                        <div className={'question-answering-info-username'}>
                            {answeringMessage.message_author_name}
                        </div>
                        <div className={'question-answering-message'}>
                            {answeringMessage.message_text || 'изображение'}
                        </div>
                    </div>
                    <CloseOutlined
                        className={'replyCloseIcon'}
                        onClick={() => {
                            resetInputs();
                        }}
                    />
                </div>
            }
            <Input
                className={'chat-conversation-card-footer-input'}
                placeholder='Оставить сообщение'
                value={inputValue}
                disabled={isMundep && !answeringMessage}
                onChange={(e) => setInputValue(e.target.value)}
                addonBefore={
                    <Upload
                        disabled={isMundep && !answeringMessage}
                        {...uploadImagesProps}
                    >
                        <UploadIcon />
                    </Upload>
                }
                addonAfter={<SendIcon
                    className={'chat-conversation-send-button'}
                    onClick={handleSendClick}
                />
                }
            />
        </div>
    );
};

export default ChatInput;
