import React, { useCallback } from 'react';
import { useField } from 'formik';
import { Form, Input } from 'antd';
import './PowerPointField.scss';

export interface IPowerPointFieldProps {
    name: string;
    placeholder: string;
}

export const PowerPointField: React.FC<IPowerPointFieldProps> = (props) => {
    const { name } = props;

    const [field, meta, { setValue, setTouched }] = useField(name);

    const onChange = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setValue(value);
            setTouched(true, false);
        },
        [setValue, setTouched]
    );

    return (
        <Form.Item
            className={'point-power-field-container'}
            validateStatus={meta.touched && meta.error ? 'error' : undefined}
            help={meta.touched && meta.error ? meta.error : undefined}
        >
            <div className={'point-power-field-placeholder-asterisk'}>
                <Input
                    className={'point-power-field'}
                    type={'number'}
                    placeholder={props.placeholder}
                    value={field.value as string | number | undefined}
                    onChange={onChange}
                />
            </div>
        </Form.Item>
    );
};
