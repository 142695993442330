import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import { getNewsPageListFilter, IBarsFilter, IHasFilters } from '../bars-request-objects';
import { INewsListRequest, INewsListResponse } from '../types/v1.0/newsList';

export const getNewsList = async (request: INewsListRequest, ignoreFilters: boolean): Promise<INewsListResponse> => {
    try {
        const filterRequest = {
            dataFilter: ignoreFilters ? { Filters: [], Group: 2 } as IBarsFilter : getNewsPageListFilter(request),
            start: request.start,
            limit: request.limit,
        };

        const result = await axiosPostRequest<IHasFilters, INewsListResponse>(
            ApiPaths.NewsList,
            filterRequest
        );
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке новостей.');
    }
};
