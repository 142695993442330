import React, { useMemo } from 'react';
import './UserSettingsForm.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Spin } from 'antd';
import { UploadAvatar } from 'ui-kit/Form/FormFields/UploadAvatar';
import { formatDateRequest } from 'utils/dateTime';
import useUserSettingsPage from '../../hooks';
import { UserSettingsFormFields } from './UserSettingsFormFields';
import { validateUserSettingsValues } from './validateUserSettingsValues';
import { setSettingsData } from './SetSettingsData';

export interface PickUserProject {
    ProjectChoice: number;
    ProjectLink_project_name: string;
    ProjectLink_Ref_Id: number;
    value?: number;
}

export interface IUserSettingsFormValues {
    sub?: string;
    avatar?: string;
    preview?: string;
    name?: string;
    surname?: string;
    phantomic?: string;
    birthDate?: string;
    regionId?: number;
    phone?: string;
    address?: string;
    email?: string;
    projects?: PickUserProject[];
    isReceiveEventMessages?: boolean;
    isReceiveGroupMessages?: boolean;
    building?: string;
    street?: string;
    city?: string;
}

export const UserSettingsForm: React.FC = () => {
    const { isLoading, user, refresh } = useUserSettingsPage();

    const lazyLoadedValues = useMemo(() => {
        return {
            ...user,
        } as IUserSettingsFormValues;
    }, [user]);

    const handleOnSubmit = async (userSumbit: IUserSettingsFormValues) => {
        userSumbit.birthDate = formatDateRequest(userSumbit.birthDate);
        await setSettingsData(userSumbit, user?.projects);
        refresh();
    };

    return (
        <div>
            <div className={'user-settings-form-wrapper'}>
                <UploadAvatar imageUrl={user?.avatar} sub={lazyLoadedValues.sub} />
            </div>
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <Formik<IUserSettingsFormValues>
                    initialValues={{}}
                    validate={validateUserSettingsValues}
                    validateOnChange
                    validateOnBlur
                    validateOnMount
                    onSubmit={handleOnSubmit}
                >
                    {(props) => {
                        return <UserSettingsFormFields lazyValues={lazyLoadedValues} onSubmit={props.submitForm} />;
                    }}
                </Formik>
            </Spin>
        </div>
    );
};
