import { IRegionalCoordinatorDeputy } from 'api/types/v1.0/regionalCoordinatorDeputies';
import { IRegionalCoordinatorPoint } from 'api/types/v1.0/regionalCoordinatorPoints';
import {
    CLEAR_DEPUTIES,
    CLEAR_REGIONAL_COORDINATOR_POINTS,
    SET_DEPUTIES,
    SET_LOADING,
    SET_REGIONAL_COORDINATOR_POINTS,
    SET_SORT_BY_ALPHABET,
    SET_SORT_BY_RATING,
    SET_TOTALCOUNT,
} from './actionTypes';

export const setRegionalCoordinatorPoints = (deputyObjects: IRegionalCoordinatorPoint[] | null) =>
    ({ type: SET_REGIONAL_COORDINATOR_POINTS, payload: deputyObjects } as const);
export const setDeputies = (deputyObjects: IRegionalCoordinatorDeputy[]) =>
    ({ type: SET_DEPUTIES, payload: deputyObjects } as const);
export const setIsLoading = (isLoading: boolean) => ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: number) =>
    ({ type: SET_TOTALCOUNT, payload: totalCount });

export const clearDeputies = () => ({ type: CLEAR_DEPUTIES } as const);
export const clearRegionalCoordinatorPoints = () => ({ type: CLEAR_REGIONAL_COORDINATOR_POINTS } as const);

export const setSortByAlphabet = () => ({ type: SET_SORT_BY_ALPHABET } as const);
export const setSortByRating = () => ({ type: SET_SORT_BY_RATING } as const);
