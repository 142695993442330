import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IGroupsMessageItem, IPreviewListItem } from 'api/types/v1.0/groupsList';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    groupMessages: null,
    groupsList: null,
    groupsTotalCount: 0,
    messagesTotalCount: 0,
    groupMembersCount: 0,
    isChatMessagesLoading: false,
    isPreviewLoading: false,
    isSendMessageLoading: false,
    sortType: 'index_sort_date',
    sortDirection: 'ASC',
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_GROUPS_LIST:
            return {
                ...state,
                groupsList: [...state.groupsList ?? [], ...action.payload ?? []],
            };
        case actionTypes.SET_MESSAGES_SORT_TYPE:
            return {
                ...state,
                sortType: action.payload,
            };
        case actionTypes.SET_MESSAGES_SORT_DIRECTION:
            return {
                ...state,
                sortDirection: action.payload,
            };
        case actionTypes.SET_FILTERED_GROUPS_LIST:
            return {
                ...state,
                groupsList: action.payload,
            };
        case actionTypes.SET_GROUP_MESSAGES:
            return {
                ...state,
                groupMessages: action.payload,
            };
        case actionTypes.SEND_MESSAGE:
            return {
                ...state,
                groupMessages: [action.payload, ...state.groupMessages ?? []],
                messagesTotalCount: ++state.messagesTotalCount,
            };
        case actionTypes.SET_GROUPS_TOTALCOUNT:
            return {
                ...state,
                groupsTotalCount: action.payload,
            };
        case actionTypes.SET_GROUP_MEMBERS_COUNT:
            return {
                ...state,
                groupMembersCount: action.payload,
            };
        case actionTypes.DELETE_MESSAGE:
            return {
                ...state,
                groupMessages: state.groupMessages?.filter(
                    (msg) => msg.Id !== action.payload
                ) as IGroupsMessageItem[],
                messagesTotalCount: --state.messagesTotalCount,
            };
        case actionTypes.SET_MESSAGES_TOTALCOUNT:
            return {
                ...state,
                messagesTotalCount: action.payload,
            };
        case actionTypes.SET_IS_CHAT_MESSAGES_LOADING:
            return {
                ...state,
                isChatMessagesLoading: action.payload,
            };
        case actionTypes.SET_IS_PREVIEW_LOADING:
            return {
                ...state,
                isPreviewLoading: action.payload,
            };
        case actionTypes.SET_IS_SEND_MESSAGE_LOADING:
            return {
                ...state,
                isSendMessageLoading: action.payload,
            };
        case actionTypes.SET_MESSAGES_SEEN:
            return {
                ...state,
                groupsList: state.groupsList?.map((group) => {
                    if (action.payload[0]?.object_id === group?.object_id) {
                        action.payload.forEach((msg) => {
                            if (!msg.viewed) {
                                group.count_not_viewed = --group.count_not_viewed;
                            }
                        });
                    }

                    return group;
                }) as IPreviewListItem[],
                groupMessages: state.groupMessages?.map((msg) => {
                    msg.viewed = true;

                    return msg;
                }) as IGroupsMessageItem[],
            };
        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
