import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptOrDeclineInvitation, getIndicator, getNotifications } from 'api/v1.0/notifications';
import { handleApiError } from 'api/v1.0/common';
import { RootState } from 'App/root/rootReducer';
import * as indicatorActionCreators from 'ducks/notificationIndicator/actionCreators';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

export interface IUseDeputyPageProps {
    statesmanId: number;
}

const useNotificationCenterPage = () => {
    const user = useSelector((state: RootState) => state.account.user);
    const [pageState, dispatch] = useReducer(reducer, defaultState);
    const globalDispatch = useDispatch();

    const refresh = (start?: number) => {
        if (user) {
            void getNotifications(+user?.sub, start)
                .then((res) => {
                    if (res) {
                        dispatch(actionCreators.setNotifications({
                            totalCount: res.data.totalCount,
                            data: [
                                ...pageState.notifications?.data ?? [],
                                ...res.data.data,
                            ],
                        }));
                    }
                }).catch((exception) => handleApiError(exception));
            updateIndicator();
        }
    };

    const updateIndicator = () => {
        if (user) {
            void getIndicator(+user?.sub)
                .then((res) => {
                    if (res) {
                        globalDispatch(indicatorActionCreators.setNotificationIndicator(res.data.totalCount > 0 ?
                            { notificationIndicator: true, data: res.data.data } :
                            { notificationIndicator: false, data: null } ));
                    }
                }).catch((exception) => handleApiError(exception));
        }
    };

    const respondToInvitation = (invitation_id: number, invitationResponse: number) => {
        void acceptOrDeclineInvitation(invitation_id, invitationResponse);
    };

    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    return {
        ...pageState,
        refresh,
        respondToInvitation,
        user,
        updateIndicator,
    };
};

export default useNotificationCenterPage;
