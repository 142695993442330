import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    ICitizenGroupCreateRequest,
    ICitizenGroupCreateResponse,
    ICitizenGroupLeaveRequest,
    ICitizenGroupLeaveResponse,
    ICitizenGroupListResponse,
    ICitizenGroupRequest,
} from '../types/v1.0/citizenGroup';
import { getCitizenGroupFilter, IHasFilters } from '../bars-request-objects';

export const getCitizenGroup = async (request: ICitizenGroupRequest): Promise<ICitizenGroupListResponse> => {
    try {
        const filterRequest = {
            dataFilter: getCitizenGroupFilter(request),
            start: request.start,
            limit: request.limit,
        };

        const result = await axiosPostRequest<IHasFilters, ICitizenGroupListResponse>(
            ApiPaths.CitizenGroupList,
            filterRequest
        );
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при загрузке данных о группе пользователя.');
    }
};

export const addCitizenGroup = async (request: ICitizenGroupCreateRequest): Promise<ICitizenGroupCreateResponse> => {
    try {
        const result = await axiosPostRequest<ICitizenGroupCreateRequest,
            ICitizenGroupCreateResponse>(ApiPaths.CitizenGroupCreate, request);
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при добавлении пользователя в группу.');
    }
};

export const leaveCitizenGroup = async (request: ICitizenGroupLeaveRequest): Promise<ICitizenGroupLeaveResponse> => {
    try {
        const result = await axiosPostRequest<ICitizenGroupLeaveRequest,
            ICitizenGroupLeaveResponse>(ApiPaths.CitizenGroupLeave, request);
        return result.data;
    } catch (e) {
        throw new Error('Ошибка при выходе из группы.');
    }
};
