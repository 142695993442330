import React from 'react';
import { Card } from 'antd';
import Page from 'components/Page';
import styles from './ReasonToRegistrationPage.module.scss';

const ReasonToRegistrationPage: React.FC = () => {
    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <Card>
                        <h3 className={styles.listHead}>Регистрация в приложении открывает ряд возможностей
                            для пользователей:</h3>
                        <ul className={styles.listTabs}>
                            <li>Вступать в группы гражданских активистов объектов</li>
                            <li>Прямое общение с муниципальным ответственным за объект</li>
                            <li>Формировать предложения федеральным и региональным координаторам проектов</li>
                            <li>Принимать участие в мероприятиях в рамках проекта</li>
                            <li>Обмениваться своими текстовыми и медиа сообщениями с другими участниками</li>
                            <li>Оценивать результаты работы участников</li>
                            <li>Получать уведомления и напоминания по интересующим объектам и мероприятиям</li>
                        </ul>
                    </Card>
                </Page.Content>
            }
        />
    );
};

export default ReasonToRegistrationPage;
