export const ApiBaseRoot = 'https://barsup-bi.bars.group/ernp-dev/';

export const ApiPaths = {
    // АПИ для получения кода авторизации
    Authorize: `${ApiBaseRoot}oauth/authorize`,

    // АПИ для получения токена авторизации
    Token: `${ApiBaseRoot}oauth/token`,

    // АПИ для получения информации о пользователе
    UserInfo: `${ApiBaseRoot}oauth/userinfo`,

    // Список всех проектов/направлений
    Projects: `${ApiBaseRoot}Projects/list`,

    // Список всех проектов/направлений у депутата
    DeputyProjects: `${ApiBaseRoot}DeputiesProjects/list`,

    // Список всех депутатов
    DeputyList: `${ApiBaseRoot}DeputiesSurnameNP/list`,

    // Описание проекта
    ProjectInfo: `${ApiBaseRoot}Project/get`,

    // Показатели проекта
    ProjectIndicator: `${ApiBaseRoot}ProjectIndicator`,

    // Список всех объектов
    Points: `${ApiBaseRoot}Objects/list`,

    // Список всех регионов
    Regions: `${ApiBaseRoot}Region/list`,

    // Список фильтрованных регионов, регионы для страницы депутата
    RegionalDeputyPage: `${ApiBaseRoot}RegionalCoordinatorProject/list`,

    // Описание объекта
    PointInfo: `${ApiBaseRoot}Object/get`,

    // Список контрольных точек объекта
    PointControlPoints: `${ApiBaseRoot}ObjectCheckPoints/list`,

    // Список контрольных точек для редактирования
    ControlPointsForEditing: `${ApiBaseRoot}ObjectEditCheckPoints/list`,

    // Список файлов по объекту
    PointFiles: `${ApiBaseRoot}Files/list`,

    //Список медиа по объекту
    PointMedia: `${ApiBaseRoot}FilesMedia/get`,

    //Список уведомлений
    Notifications: `${ApiBaseRoot}Notifications/list`,

    //Просмотр уведомления
    NotificationSeen: `${ApiBaseRoot}NotificationEditViewed/update`,

    // Ответ на приглашение
    NoteInvitationResponse: `${ApiBaseRoot}Utils/GetSqlFunctionResult`,

    //Приглашение пользователей в группу объекта
    InviteToGroupObject: `${ApiBaseRoot}objectGroupInvite/create`,

    // Список документов по объекту
    PointDocuments: `${ApiBaseRoot}FileEnList/get`,

    // Информация по депутату (региональному, федеральному, муниципальному)
    StatesmanInfo: `${ApiBaseRoot}DeputyPage/get`,

    // Cписок пользователей на добавление в группу по объекту
    UsersToInvite: `${ApiBaseRoot}UsersToInvite/list`,

    // Данные, добавляемые через специальный api
    DataUsingSqlFunction: `${ApiBaseRoot}Utils/GetSqlFunctionResult`,

    // Добавление контрольной точки
    AddControlPoint: `${ApiBaseRoot}ObjectCheckPoint/create`,

    // Редактирование контрольной точки
    EditControlPoint: `${ApiBaseRoot}ObjectCheckPoint/update`,

    // Получение общей информации о депутате
    Municipal: `${ApiBaseRoot}Municipal/get`,

    // Статистика по объектам
    MunicipalStatistics: `${ApiBaseRoot}MunicipalStatistics/get`,

    // Список мероприятий депутата с фильтрацией по проекту и объекту
    ActivitiesList: `${ApiBaseRoot}DeputyActivities/list`,

    // Список мероприятий депутата для главной страницы
    DeputyActivitiesMain: `${ApiBaseRoot}DeputyActivitiesMain/list`,

    // Удалить документ/медиа
    RemoveFile: `${ApiBaseRoot}Files/delete`,

    // Добавить документ
    DocumentAdd: `${ApiBaseRoot}FileEnEditor4/create`,

    // Добавить медиа
    MediaAdd: `${ApiBaseRoot}FileEnEditor5/create`,

    // Редактирование мощности обьекта
    ObjectEditPower: `${ApiBaseRoot}ObjectEdit/update`,

    // Редактирование профиля
    CitizenUpdate: `${ApiBaseRoot}Citizen/update`,

    // Редактирование автара
    CitizenAvatarUpdate: `${ApiBaseRoot}FileEnEditor/create`,

    // Получение проектов пользователя
    CitizenProjectList: `${ApiBaseRoot}CitizenProjectEntityList/list`,

    // Получить данные пользователя
    CitizenInfo: `${ApiBaseRoot}UserCitizen/get`,

    // Получение информации о обьектах депутата
    DeputyPoints: `${ApiBaseRoot}DeputyObjects/list`,

    // Получение информации об объектах депутата в планировщике мероприятий
    DeputyObjectsPlanner: `${ApiBaseRoot}DeputiesObjects/list`,

    // Получения дефолтных значений для названий мероприятия
    EventsEntityList: `${ApiBaseRoot}EventsEntityList/list`,

    // Получение общей информации о федеральном координаторе
    RegionalCoordinator: `${ApiBaseRoot}RegionalCoordinator/get`,

    // Получение депутатов регионального координатора
    RegionalCoordinatorDeputies: `${ApiBaseRoot}RegionalCoordinatorDeputies/list`,

    // Получение объектов депутата
    RegionalCoordinatorDeputiesPoints: `${ApiBaseRoot}RegionalCoordinatorDeputiesPoints/list`,

    // Список объектов регионального координатора
    RegionalCoordinatorPoints: `${ApiBaseRoot}RegionalCoordinatorPoints/list`,

    // План реализации мероприятий, поступившие предложения(Regional)
    RegionalCoordinatorStatistics: `${ApiBaseRoot}RegionalCoordinatorStatistics/get`,

    // Получение общей информации о федеральном координаторе
    FederalCoordinator: `${ApiBaseRoot}FederalCoordinator/get`,

    // Список региональных координаторов федерального координатора
    FederalCoordinatorRegionalCoordinators: `${ApiBaseRoot}FederalCoordinatorRegionalCoordinators/list`,

    // Список объектов федерального координатора
    FederalCoordinatorPoints: `${ApiBaseRoot}FederalCoordinatorPoints/list`,

    // План реализации мероприятий, поступившие предложения(Federal)
    FederalCoordinatorStatistics: `${ApiBaseRoot}FederalCoordinatorStatistics/get`,

    //Выход пользователя из системы.
    Logout: `${ApiBaseRoot}oauth/logout`,

    // Редактирование мероприятия
    ActivityEdit: `${ApiBaseRoot}ObjectEvent/update`,

    // Добавление опроса для мероприятия
    ActivityPoll: `${ApiBaseRoot}ObjectEventPoll/create`,

    // Создание мероприятия
    ActivityCreate: `${ApiBaseRoot}ObjectEvent/create`,

    //Удаление опроса
    ActivityInterviewDelete: `${ApiBaseRoot}Interview/delete`,

    //Создание новости в генераторе
    NewsPageCreate: `${ApiBaseRoot}NewsPage/create`,

    // Список участников группы
    GroupMembersList: `${ApiBaseRoot}members/list`,

    // Удалить из списка участников группы
    GroupMembersListRemove: `${ApiBaseRoot}PointGroup/update`,

    // Восстановление участника в список группы
    GroupMembersListRecover: `${ApiBaseRoot}PointGroup/update`,

    //Добавление изображения для новости в генераторе
    FileEnEditor3: `${ApiBaseRoot}FileEnEditor3/create`,

    // Добавление объекта в избранное
    FavoritePointCreate: `${ApiBaseRoot}FavoritePoint/create`,

    // Удаление объекта из избранного
    FavoritePointDelete: `${ApiBaseRoot}FavoritePoints/delete`,

    // Список избранных объектов
    FavoritePointsList: `${ApiBaseRoot}FavoritePoints/list`,

    // Список групп пользователя
    CitizenGroupList: `${ApiBaseRoot}CitizenGroups/list`,

    // Список сообщений
    GroupMessages: `${ApiBaseRoot}ChatMessages/list`,

    // Добавление пользователя в группу
    CitizenGroupCreate: `${ApiBaseRoot}PointGroup/create`,

    // Покинуть группу пользователем
    CitizenGroupLeave: `${ApiBaseRoot}objectGroupExit/delete`,

    //Список новостей
    NewsList: `${ApiBaseRoot}NewsList/list`,

    //Делатальная информация о новости
    NewsData: `${ApiBaseRoot}NewsData/get`,

    // Список мероприятий гражданина с фильтрацией по проекту и объекту
    CitizenEventList: `${ApiBaseRoot}CitizenEventList/list`,

    // Добавить мероприятие гражданина
    CitizenEventCreate: `${ApiBaseRoot}CitizenEvent/create`,

    //Присоединится к мероприятию
    TakePartToActivity: `${ApiBaseRoot}CitizenEvent/create`,

    //Список хештегов
    OfferNPHashtagList: `${ApiBaseRoot}OfferNPHashtagList/list`,

    //Список отраслей
    BranchEconomyList: `${ApiBaseRoot}BranchEconomyList/list`,

    //Отправка предложения
    SendOfferCoordinator: `${ApiBaseRoot}OfferNP/create`,

    //Оценка мероприятия
    CitizenEventLike: `${ApiBaseRoot}CitizenEventLike`,

    //Оценка объекта
    ObjectLike: `${ApiBaseRoot}ObjectLike`,

    //Оценка новости
    NewsLike: `${ApiBaseRoot}NewsLike`,

    //Оценка сообщения
    MessageLike: `${ApiBaseRoot}MessageLike`,

    //Отправление сообщении
    SendMessage: `${ApiBaseRoot}QuestionAnswerCommentEntityEditor/create`,

    //Отправление файлов
    SendFile: `${ApiBaseRoot}FileEditor/create`,

    //Удаление сообщении из чата
    RemoveMessageFromChat: `${ApiBaseRoot}ChatMessageEditState/update`,

    // Получение вопросов и предложений
    QuestionsAndSuggestions: `${ApiBaseRoot}CoordinatorQuestions/list`,

    // Получение вопросов и предложений
    DeputyQuestions: `${ApiBaseRoot}DeputyQuestions/list`,

    // Получение вопросов и предложений
    CoordinatorQuestions: `${ApiBaseRoot}UnansweredQuestions/list`,

    // Прочитать вопрос и предложение
    ReadQuestionsAndSuggests: `${ApiBaseRoot}QuestionEditViewed`,

    // Часто задаваемые вопросы
    CommonQuestions: `${ApiBaseRoot}FAQ/list`,

    // Просмотр новых фото в обьекте
    ViewedNewsPhotos: `${ApiBaseRoot}is_viewed_deputy/update`,

    //Превю чатов
    ChatPreviewList: `${ApiBaseRoot}PreviewChatMessages/list`,

    //Просмотр сообщений
    MessageSeen: `${ApiBaseRoot}PreviewChatMessagesViewed/update`,

    //Переход на ответ
    ReplyMessage: `${ApiBaseRoot}ChatMessagesReply/list`,

    //Переход на ответ
    ReadMessage: `${ApiBaseRoot}IsViewedQuestion/update`,
};
