import React from 'react';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import Page from 'components/Page';
import FolkDivider from 'ui-kit/FolkDivider';
import { Pages } from 'constants/links';
import ActivityCardForm from '../ActivityCard/ActivityCardForm';

import styles from './ActivitiesConstructor.module.scss';

export const ActivitiesConstructorPage: React.FC = () => {
    const { location } = useHistory();
    const title = location.pathname === Pages.ActivitiesCreate.url ? 'Новое мероприятие' : 'Мероприятие';
    const isEdit = location.pathname === Pages.ActivitiesEdit.url;

    return (
        <Page
            content={
                <Card>
                    <Page.Content sidebar={'MainWithoutNews'}>
                        <div className={styles.activitiesGeneratorFieldsContainer}>
                            <div className={styles.activitiesGeneratorPageWrapperTitleBlock}>
                                <div className={styles.activitiesGeneratorPageWrapperTitle}>{title}</div>
                            </div>
                            <FolkDivider className={styles.activitiesDivider} />
                            <div className={styles.activitiesGeneratorFormFieldsWrapper}>
                                <ActivityCardForm isEdit={isEdit} />
                            </div>
                        </div>
                    </Page.Content>
                </Card>
            }
        />
    );
};
