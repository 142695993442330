import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YandexMaps from 'yandex-maps';
import { RootState } from 'App/root/rootReducer';
import { getPointsList } from 'api/v1.0/points';
import { postRegionsList } from 'api/v1.0/regionList';
import { handleApiError } from 'api/v1.0/common';
import { IPoint } from 'api/types/v1.0/points';
import { getBarsProjectAndRegionFilter } from 'api/bars-request-objects';
import store from 'App/root/store';
import * as projectActionCreators from 'ducks/selectedProjects/actionCreators';
import * as regionActionCreators from 'ducks/selectedRegion/actionCreators';
import * as pointActionCreators from 'ducks/selectedPoint/actionCreators';
import { getRegionalDeputyId } from 'api/v1.0/getRegionalDeputyId';
import { IState, TabType } from 'ducks/mainPage';
import * as actionCreators from 'ducks/mainPage/actionCreators';
import { getProjectList } from 'api/v1.0/projectList';
import { setListProjects } from 'ducks/projectsList';
import { hasCitizenRole } from 'helpers/account';
import { isStringWithValue } from 'utils/typeChecking';

const useMainPage = () => {
    const pageState = useSelector((state: RootState) => state.mainPage);
    const selectedPoint = useSelector((state: RootState) => state.selectedPoint.selectedPoint);
    const { user } = useSelector((state: RootState) => state.account);
    const selectedProjects = useSelector((state: RootState) => state.selectedProjects.selectedProjects);
    const selectedRegionId = useSelector((state: RootState) => state.selectedRegion.id);
    const projectsList = useSelector((state: RootState) => state.projectsList);
    const dispatch = useDispatch();
    const actualMapInfo = useRef(false);
    const actualListInfo = useRef(false);
    // eslint-disable-next-line
    const [searchControl, setSearchControl] = useState<YandexMaps.control.SearchControl>();

    //scroll to previous position when updating the redux state
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        window.scrollTo(0, scrollPosition);
    },[selectedProjects, selectedRegionId, projectsList, scrollPosition]);

    const getAllProjects = async () => {
        const response = await getProjectList({ sort:'project_name' });
        dispatch(setListProjects(response.data));
    };

    useEffect(() => {
        void getAllProjects();
    //eslint-disable-next-line
    }, []);

    const fetchRefreshList = async (isFirstFetch : boolean) => {
        actualListInfo.current = true;
        if ( isFirstFetch ){
            dispatch(actionCreators.setListIsLoading(true));
        }

        try {
            const response = await getPointsList({
                start: !isFirstFetch && pageState.listPoints ? pageState.listPoints?.length : 0,
                limit: 10,
                dataFilter: getBarsProjectAndRegionFilter(
                    selectedRegionId,
                    selectedProjects.length > 0 ? [selectedProjects[0].Id] : undefined
                ),
            });
            const points: IPoint[] = [];
            response.data.forEach((point) => {
                points.push({
                    projectId: point.project_Ref_Id,
                    id: point.Id.toString(),
                    title: point.object_name,
                    address: point.address,
                    regionId: point.region_Ref_Id,
                    object_media: point.object_media,
                    municipaCoordId: point.municipal_respons_id_Ref_Id,
                    regionalCoordId: point.regional_coord_id_Ref_Id,
                    coordinates: {
                        lat: point.latitude ?? 0,
                        lng: point.longitude ?? 0,
                    },
                } as IPoint);
            });
            dispatch(actionCreators.setListPoints(points));
            dispatch(actionCreators.setTotalItems(response.totalCount));
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setListIsLoading(false));
        }
    };

    const changeListPaging = (paging: IState['listPaging']) => dispatch(actionCreators.setListPaging(paging));

    const fetchRefreshMap = async () => {
        actualMapInfo.current = true;
        dispatch(actionCreators.setMapIsLoading(true));
        try {
            const response = await getPointsList({
                dataFilter: getBarsProjectAndRegionFilter(
                    selectedRegionId,
                    selectedProjects.length > 0 ? [selectedProjects[0].Id] : undefined
                ),
            });
            const mapPoints: IPoint[] = [];
            response.data.forEach((point) => {
                mapPoints.push({
                    projectId: point.project_Ref_Id,
                    id: point.Id.toString(),
                    title: point.object_name,
                    address: point.address,
                    regionId: point.region_Ref_Id,
                    object_media: point.object_media,
                    municipaCoordId: point.municipal_respons_id_Ref_Id,
                    regionalCoordId: point.regional_coord_id_Ref_Id,
                    coordinates: {
                        lat: point.latitude ?? 0,
                        lng: point.longitude ?? 0,
                    },
                } as IPoint);
            });

            dispatch(actionCreators.setMapPoints(mapPoints));
        } catch (exception) {
            handleApiError(exception);
        } finally {
            dispatch(actionCreators.setMapIsLoading(false));
        }
    };

    const changeMapCenter = (center: IState['mapCenter']) => dispatch(actionCreators.setMapCenter(center));
    const changeMapRegionName = (region_name: IState['mapRegionName']) => dispatch(actionCreators.setMapRegionName(region_name));
    const changePointsVisualisation = (type: IState['pointsVisualisation']) =>
        dispatch(actionCreators.setPointsVisualisation(type));

    const setRegionalDeputyAndMapRegion = (regionId: number, regionName: string) => {
        void getRegionalDeputyId(selectedProjects[0]?.Id, regionId)
            .then((result) => {
                setScrollPosition(window.scrollY);
                store.dispatch(regionActionCreators.setRegionCoordId(result));
            })
            .catch((exception) => handleApiError(exception));

        setScrollPosition(window.scrollY);
        changeMapRegionName(regionName);
    };

    const changeSelectedRegion = (regionId: number | null, regionName: string | null) => {
        store.dispatch(pointActionCreators.resetSelectedPoint());
        store.dispatch(regionActionCreators.handleSelectRegion(regionId));

        if (regionId && !regionName) {
            postRegionsList({ })
                .then((response) => {
                    regionName = response.data.find((region) => region.Id === regionId)?.region ?? null;
                    if (regionName) {
                        setRegionalDeputyAndMapRegion(regionId, regionName);
                    }
                })
                .catch((e) => handleApiError(e));
        } else if (regionId && regionName) {
            setRegionalDeputyAndMapRegion(regionId, regionName);
        } else {
            changeMapRegionName(null);
        }
    };

    const changeMapRegion = (regionId: number) => {
        postRegionsList({ })
            .then((response) => {
                const regionName = response.data.find((region) => region.Id === regionId)?.region ?? null;
                if (regionName) {
                    setScrollPosition(window.scrollY);
                    changeMapRegionName(regionName);
                }
            })
            .catch((e) => handleApiError(e));
    };

    // Выбираем по умолчанию регион пользователя, если он еще не выбрал никаких значений в селекторе регионов или в поиске на карте.
    useEffect(() => {
        //eslint-disable-next-line
        if (user && hasCitizenRole(user) && !selectedRegionId && searchControl && !selectedPoint) {
            const searchControlInputValue = searchControl.state.get('inputValue', {});
            if (isStringWithValue(searchControlInputValue)) {
                return;
            }

            changeMapRegion(user.regionId);
        }
        //eslint-disable-next-line
    }, [searchControl]);

    const changeSelectedProject = (projectId: number | null) => {
        setScrollPosition(window.scrollY);
        const project = projectsList.projects.find((p) => p.Id === projectId);
        store.dispatch(projectActionCreators.handleSelectProject(project));
        store.dispatch(pointActionCreators.resetSelectedPoint());
        store.dispatch(regionActionCreators.resetSelectRegion());
    };

    useEffect(() => {
        actualMapInfo.current = false;
        actualListInfo.current = false;
        dispatch(actionCreators.clearListPoints());
        if (pageState.pointsVisualisation === TabType.list) {
            void fetchRefreshList(true);
        } else if (pageState.pointsVisualisation === TabType.map) {
            void fetchRefreshMap();
        }
        // Снизу игнорируем deps, которые используются в useEffect ниже.
        // По причине отдельной обработки нажатий на переключатель между списком и картой, если не было изменений, то не должно быть нового fetch запроса
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjects, selectedRegionId]);

    useEffect(() => {
        if (pageState.pointsVisualisation === TabType.map && !actualMapInfo.current) {
            void fetchRefreshMap();
        } else if (pageState.pointsVisualisation === TabType.list && !actualListInfo.current) {
            void fetchRefreshList(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.pointsVisualisation]);

    return {
        ...pageState,
        selectedPoint,
        selectedProjects,
        selectedRegionId,
        searchControl,
        setSearchControl,
        changeListPaging,
        changeMapCenter,
        fetchRefreshList,
        changePointsVisualisation,
        changeSelectedRegion,
        changeSelectedProject,
    };
};

export default useMainPage;
