import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getPointDocumentsAsync } from 'api/v1.0/pointDocuments';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';

export interface IProps {
    pointId: number;
}

const usePointDocuments = ({ pointId }: IProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getPointDocumentsAsync({ id: pointId })
            .then((response) => {
                if (response.data.length > 0) {
                    dispatch(actionCreators.setPointDocuments(response));
                } else {
                    dispatch(actionCreators.setPointDocuments(null));
                }
            })
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
    }, [pointId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        ...state,
        refresh,
    };
};

export default usePointDocuments;
