import React from 'react';
import { useFormikContext } from 'formik';
import { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/dateTime';
import { DatePickerField } from 'ui-kit/Form/FormFields/DatePickerField';
import { CheckBoxField } from 'ui-kit/Form/FormFields/CheckBoxField';
import { IControlPointModalValues } from '../ControlPointModal';
import { ControlPointNameField } from './ControlPointNameField/ControlPointNameField';
import styles from './ControlPointModalFields.module.scss';

export interface IProps {
    pointType: number;
}

const ControlPointModalFields: React.FC<IProps> = (props: IProps) => {
    const { setFieldValue, values } = useFormikContext<IControlPointModalValues>();

    const onFactDateChange = (dateString: string) => {
        if (dateString) {
            setFieldValue('isCompleted', true);
        }
    };

    const getDisabledPlanDates = (momentDate: Moment) => {
        const date = momentDate.toDate();
        return values.controlPointDates?.some(
            (x) => x.getDate() === date.getDate()
            && x.getMonth() === date.getMonth()
            && x.getFullYear() === date.getFullYear()
        ) ?? false;
    };

    return <div className={styles.fieldsContainer}>
        <ControlPointNameField
            name={'name'}
            placeholder={'Название'}
            disabled={!props.pointType}
        />
        <DatePickerField
            placeholder={'Плановая дата'}
            name={'planDate'}
            format={DEFAULT_DATE_FORMAT}
            needToSetNameUndefined={true}
            useReadonlyInput={true}
            disabledDate={getDisabledPlanDates}
        />
        <DatePickerField
            placeholder={'Фактическая дата'}
            name={'factDate'}
            format={DEFAULT_DATE_FORMAT}
            needToSetNameUndefined={true}
            useReadonlyInput={true}
            onChange={onFactDateChange}
        />
        <div className={styles.checkboxFieldsContainer}>
            <CheckBoxField
                name={'isCompleted'}
                title={'Выполнено'}
                disabled={!!values.factDate}
            />
            <CheckBoxField
                name={'isDisplayed'}
                disabled={!!values.required}
                title={'Показывать на карточке объекта'}
            />
        </div>
    </div>;
};

export default ControlPointModalFields;
