import * as yup from 'yup';
import { alerts } from 'ui-kit/Form/FormFields/base/alerts';

export const userSettingsValidationSchema = yup.object().shape(
    {
        name: yup.string(),
        surname: yup.string(),
        patronymic: yup.string(),
        birthdate: yup
            .string(),
        phone: yup
            .string(),
        email: yup
            .string()
            .email(`${alerts.EMAIL}`)
            .nullable(),
        regionId: yup.string().required(`${alerts.REQUIRED}`),
        address: yup
            .string()
            .notRequired(),
        isReceiveEventMessages: yup
            .bool()
            .notRequired(),
    }
);
