import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IState } from './types';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

export const defaultState: IState = {
    regionalNews: null,
    federalNews : null,
    isLoading: false,
    totalCount: 0,
};

export type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const newsSidebarReducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_FEDERAL_NEWS:
            return {
                ...state,
                federalNews: [ ...action.payload ?? []],
            };
        case actionTypes.SET_REGIONAL_NEWS:
            return {
                ...state,
                regionalNews: [...action.payload ?? []],
            };

        default:
            return state;
    }
};

export default newsSidebarReducer;
