import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { VideoCameraOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { StatesmanTypes } from 'api/types/v1.0/statesman';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { sendLike } from 'api/v1.0/likesDislikes';
import { LikeType } from 'api/types/v1.0/likesDislikes';
import SurveyCard from 'ui-kit/SurveyCard/SurveyCard';
import { ReactComponent as UpIcon } from 'pages/StatesmanPage/assets/UpIcon.svg';
import { ReactComponent as DownIcon } from 'pages/StatesmanPage/assets/DownIcon.svg';
import LikeDislike from 'components/LikeDislike/LikeDislike';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { OnLoadingSpinner } from 'ui-kit/OnLoadingSpinner';
import { RootState } from 'App/root/rootReducer';
import * as jwt from 'App/root/jwt';
import { Role } from 'types/account';
import { hasRole } from 'utils/user';
import { utcDateToLocal } from 'utils/dateTime';
import useActivityCard from './hooks';
import TakePartButton from './components/TakePartButton/TakePartButton';
import './ActivityCard.scss';

export interface IActivityCardProps {
    activity: IDeputyActivity;
    statesmanType: StatesmanTypes;
    isUserInGroup?: boolean;
}

export const ActivityCard: React.FC<IActivityCardProps> = (props) => {
    const { sendVote, isUserBanned, interview, isLoading } = useActivityCard(props.activity.interview,
        props.activity.object_id, props.activity.Id, props.activity.object_event_id);
    const account = useSelector((state: RootState) => state.account);
    const isLoggedIn = !!jwt.get() && !!account;
    const isLiked = props.activity.mark_type === 1;
    const isDisliked = props.activity.mark_type === 2;
    const isDep = hasRole([Role.Municipal, Role.Regional, Role.Federal], account?.user?.role as Role);
    const showBroadcastIcon = !!props.activity.link;
    const [isSurveyCardHidden, setIsSurveyCardHidden] = useState(true);
    const [showYouParticipantButton, setShowYouParticipantButton] = useState(props.activity.is_took_part);
    const currentDate = new Date();
    const eventDate = new Date(props.activity.date_time ?
        props.activity.date_time.toString() : currentDate.toString());
    const isRoleCitizen = account.user?.role === Role.Citizen;
    const isEventEnded = currentDate.getTime() < utcDateToLocal(eventDate);
    const needToShowTakePartButton =
        !props.activity.is_took_part && isEventEnded;
    const isLikesDisabled = isEventEnded || !isRoleCitizen;

    const handleIconClick = () => {
        setIsSurveyCardHidden((prev) => !prev);
    };

    const handleResultsClick = () => {
        browserHistory.push(`${Pages.NewsDetails.url}/${props.activity.report_id}`);
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const onBroadcastIconClick = () => {
        let url = props.activity.link;

        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            url = `http://${url}`;
        }

        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <div className={'activity-card'}>
            <div className={'activity-card-header'}>
                <span className={'activity-card-date'}>{props.activity.date}</span>
                <span> {props.activity.event_name}</span>
            </div>
            <div className={'activity-card-buttons'}>
                <div className={'activity-card-buttons-group'}>
                    {<div className={'activity-card-expand-icon'} hidden={false}>
                        {isSurveyCardHidden ? (
                            <DownIcon onClick={handleIconClick} />
                        ) : (
                            <UpIcon onClick={handleIconClick} />
                        )}
                    </div>}

                    {(
                        <Button
                            className={!props?.activity?.event_report
                                ? 'disabled-activity-card-action-button'
                                : 'activity-card-action-button'}
                            disabled={!props?.activity?.event_report}
                            onClick={handleResultsClick}>
                            Отчет
                        </Button>
                    )}

                    {needToShowTakePartButton && isRoleCitizen &&
                        <TakePartButton
                            activity={props.activity}
                            setShowYouParticipantButton={setShowYouParticipantButton}
                            isUserBanned={isUserBanned}
                        />
                    }
                    {
                        showYouParticipantButton &&
                        <Button className={'you-participant-button'} disabled>Вы участник</Button>
                    }
                </div>
                {props.statesmanType === StatesmanTypes.MunicipalDeputy &&
                    <div className={'activity-likes'}>
                        <LikeDislike
                            isDisabled={isLikesDisabled}
                            dislikesCount={props.activity.dislikes}
                            likesCount={props.activity.likes}
                            isLiked={isLiked}
                            isDisliked={isDisliked}
                            onDislikeClick={() =>
                                sendLike(props.activity.object_event_id, LikeType.activityLike, false)}
                            onLikeClick={() =>
                                sendLike(props.activity.object_event_id, LikeType.activityLike, true)}
                        />
                    </div>
                }
            </div>
            <div hidden={isSurveyCardHidden} className={'activity-card-description'}>
                <span>{props.activity.description}</span>
            </div>
            <div hidden={isSurveyCardHidden}>
                {
                    interview &&
                    <OnLoadingSpinner
                        isLoading={isLoading}
                        content={<SurveyCard
                            onVote={sendVote}
                            interview={interview}
                            isTookPart={interview.answer_id > 0}
                            canVote={!isDep && isLoggedIn}
                        />}
                    />
                }
            </div>
            {(props.statesmanType === StatesmanTypes.RegionalCoordinator ||
                props.statesmanType === StatesmanTypes.FederalDeputy) && (
                <div className={'activity-icons'}>
                    {
                        showBroadcastIcon &&
                        <div onClick={onBroadcastIconClick} className={'activity-video-icon'}>
                            <VideoCameraOutlined />
                        </div>
                    }
                    <LikeDislike
                        isDisabled={isLikesDisabled}
                        dislikesCount={props.activity.dislikes}
                        likesCount={props.activity.likes}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onDislikeClick={() =>
                            sendLike(props.activity.object_event_id, LikeType.activityLike, false)}
                        onLikeClick={() =>
                            sendLike(props.activity.object_event_id, LikeType.activityLike, true)}
                    />
                </div>
            )}
        </div>
    );
};
