import React from 'react';
import { Menu } from 'antd';
import { SelectedIndicatorType } from '../../Indicators';
import styles from './IndicatorButtons.module.scss';

interface IProps {
    setTypeIndicator: React.Dispatch<React.SetStateAction<SelectedIndicatorType>>;
}

const IndicatorButtons: React.FC<IProps> = ({ setTypeIndicator }) => {
    return (
        <div className={styles['indicator-menu']}>
            <Menu
                mode="horizontal"
                defaultSelectedKeys={['all']}
                onClick={(item) => setTypeIndicator(item.key as SelectedIndicatorType)}
                items={[
                    {
                        className: styles['indicator-button'],
                        label: 'РФ',
                        key: 'all',
                    },
                    {
                        className: styles['indicator-button'],
                        label: 'Регион',
                        key: 'region',
                    },
                    {
                        className: styles['indicator-button'],
                        label: 'Период',
                        key: 'period',
                    },
                ]}
            ></Menu>
        </div>
    );
};

export default IndicatorButtons;

