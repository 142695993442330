import React, { useRef } from 'react';
import { Select, SelectProps } from 'antd';
import { IDeputyItem } from 'api/types/v1.0/deputyList';

const { Option } = Select;

interface IInfiniteScrollSelectProps extends SelectProps {
    hasMore: boolean;
    loadMore: () => void;
    data: IDeputyItem[];
}

// Переделанная реализация из https://github.com/mrinalraj/antd-select-infinte-scroll
export const InfiniteScrollSelect: React.FC<IInfiniteScrollSelectProps> = (props) => {
    const { hasMore, loadMore, data, loading, ...restProps } = props;
    const dropDown = useRef<HTMLDivElement>(null);

    const onScroll = () => {
        const lastElementId = (data || []).length - 1;
        const lastElement = document.getElementById(lastElementId.toString());
        if (lastElement && dropDown && dropDown.current) {
            const containerTop = dropDown.current.getBoundingClientRect().top;
            const lastElementTopPos = lastElement?.getBoundingClientRect()?.top - Number(containerTop);
            const containerHeight = dropDown.current.getBoundingClientRect().height;

            if (lastElementTopPos - 5 < containerHeight && !loading && hasMore) {
                loadMore();
            }
        }
    }

    return <Select
        onPopupScroll={onScroll}
        dropdownRender={menu => (
            <div ref={dropDown} id='dropdown'>{menu}</div>
        )}
        {...restProps}>
        {
            data && data.map((deputy, index) =>
                <Option
                    key={deputy.Id}
                    value={deputy.Id}
                    id={index}
                >
                    {deputy.surname_n_p}
                </Option>
            )
        }
        </Select>;
};

export default InfiniteScrollSelect;
