import { useCallback, useEffect, useReducer } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { getDeputyPoints } from 'api/v1.0/deputyPoints';
import { IDeputyPointsRequest } from 'api/types/v1.0/deputyPoints';
import { PointSortType } from 'pages/DeputyProfile/components/FederalCoordinatorProfile/components/DeputyPoints/hooks/types';
import reducer, { defaultState } from './reducer';
import * as actionCreators from './actionCreators';
import { setSortByAlphabet, setSortByRating } from './actionCreators';

const useDeputyPoints = (data: IDeputyPointsRequest) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refreshPoints = useCallback(() => {
        dispatch(actionCreators.setIsLoading(true));

        getDeputyPoints(data, state.descending, false)
            .then((response) =>
                dispatch(actionCreators.setDeputyObjects(response)))
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
        // eslint-disable-next-line
    }, [data.deputyId]);

    const refresh = (length: number) => {
        dispatch(actionCreators.setIsLoading(true));

        data.page.end = length;

        getDeputyPoints(data, state.descending, true)
            .then((response) =>
                dispatch(actionCreators.setDeputyObjects(response)))
            .catch((e) => handleApiError(e))
            .finally(() => dispatch(actionCreators.setIsLoading(false)));
    };

    useEffect(() => {
        refreshPoints();
    }, [refreshPoints]);

    const changeSort = async (sortType: PointSortType) => {
        switch (sortType) {
            case PointSortType.title: {
                data.sortType = PointSortType.title;
                state.descending = !state.descending;
                const pointsResponse = await getDeputyPoints(data, state.descending, false);
                dispatch(actionCreators.setDeputyObjects(pointsResponse));
                dispatch(setSortByAlphabet());
                break;
            }

            case PointSortType.rating: {
                data.sortType = PointSortType.rating;
                state.descending = !state.descending;
                const respRat = await getDeputyPoints(data, state.descending, false);
                dispatch(actionCreators.setDeputyObjects(respRat));
                dispatch(setSortByRating());
                break;
            }
        }
    };

    const changePage = async (page:{ start: number, end:number }) => {
        data.page = page;
        const respAlp = await getDeputyPoints(data, state.descending, false);
        dispatch(actionCreators.setDeputyObjects(respAlp));
    };

    return {
        state,
        refreshPoints,
        changeSort,
        changePage,
        refresh,
    };
};

export default useDeputyPoints;
