import { Pages } from 'constants/links';
import browserHistory from 'App/root/browserHistory';
import store from 'App/root/store';
import { resetSelectedPoint } from 'ducks/selectedPoint';
import { resetSelectProject } from 'ducks/selectedProjects';
import { resetSelectRegion } from 'ducks/selectedRegion';
import { logout } from 'ducks/account';
import { IMainTabKeyInfo, IProfileTabKeyInfo } from './interfaces/ITabKeyInfo';

const resetAndLogout = () => {
    browserHistory.push(Pages.Main.url);
    store.dispatch(resetSelectedPoint());
    store.dispatch(resetSelectProject());
    store.dispatch(resetSelectRegion());
    store.dispatch(logout());
};

export const mainTabsInfo: IMainTabKeyInfo[] = [
    {
        key: 0,
        url: Pages.ProjectInfo.url,
        useExactMapping: false,
        tabName: 'О проекте',
        onClick: (id) => {
            browserHistory.push(`${Pages.ProjectInfo.url}/${id ?? 0}`);
        },
    },
    {
        key: 1,
        url: `${Pages.DeputyLink.url}/${Pages.DeputyLink.flowCoordinator}/`,
        useExactMapping: false,
        tabName: 'Федеральный координатор',
        onClick: (id) => {
            browserHistory.push(`${Pages.DeputyLink.url}/${Pages.DeputyLink.flowCoordinator}/${id ?? 0}`);
        },
    },
    {
        key: 2,
        url: `${Pages.DeputyLink.url}/${Pages.DeputyLink.regionalCoordinator}/`,
        useExactMapping: false,
        tabName: 'Региональный координатор',
        onClick: (id) => {
            browserHistory.push(`${Pages.DeputyLink.url}/${Pages.DeputyLink.regionalCoordinator}/${id ?? 0}`);
        },
    },
    {
        key: 3,
        url: Pages.Main.url,
        useExactMapping: true,
        tabName: 'Карта объектов',
        onClick: () => {
            browserHistory.push(Pages.Main.url);
        },
        ignoreSelectedPointLogic: true,
    },
    {
        key: 4,
        url: `${Pages.PointInfo.url}/`,
        useExactMapping: false,
        tabName: 'Об объекте',
        onClick: (id) => {
            browserHistory.push(`${Pages.PointInfo.url}/${id ?? 0}`);
        },
    },
    {
        key: 5,
        url: `${Pages.DeputyLink.url}/${Pages.DeputyLink.municipalDeputy}/`,
        useExactMapping: false,
        tabName: 'Ответственный за объект',
        onClick: (id) => {
            browserHistory.push(`${Pages.DeputyLink.url}/${Pages.DeputyLink.municipalDeputy}/${id ?? 0}`);
        },
    },
];

export const municipalProfileTabsInfo: IProfileTabKeyInfo[] = [
    {
        key: 0,
        url: Pages.DeputyProfile.url,
        useExactMapping: true,
        tabName: 'Мои объекты',
        onClick: () => {
            browserHistory.push(Pages.DeputyProfile.url);
        },
    },
    {
        key: 1,
        url: Pages.ActivitiesScheduler.url,
        useExactMapping: true,
        tabName: 'Планировщик мероприятий',
        onClick: () => {
            browserHistory.push(Pages.ActivitiesScheduler.url);
        },
    },
    {
        key: 2,
        url: Pages.NewsGenerator.url,
        useExactMapping: true,
        tabName: 'Генератор новостей',
        onClick: () => {
            browserHistory.push(Pages.NewsGenerator.url);
        },
    },
    {
        key: 3,
        url: Pages.CommunityFormation.url,
        useExactMapping: true,
        tabName: 'Формирование сообществ',
        onClick: () => {
            browserHistory.push(Pages.CommunityFormation.url);
        },
    },
    {
        key: 5,
        url: Pages.UserProfile.url,
        useExactMapping: true,
        tabName: 'Часто задаваемые вопросы',
        onClick: () => {
            browserHistory.push(`${Pages.QuestionsAndAnswers.url}`);
        },
    },
    {
        key: 6,
        url: Pages.Chats.url,
        useExactMapping: true,
        tabName: 'Чаты',
        onClick: () => browserHistory.push(Pages.Chats.url),
    },
];

export const coordinatorProfileTabsInfo: IProfileTabKeyInfo[] = [
    {
        key: 0,
        url: Pages.DeputyProfile.url,
        useExactMapping: true,
        tabName: 'Мои объекты',
        onClick: () => {
            browserHistory.push(Pages.DeputyProfile.url);
        },
    },
    {
        key: 1,
        url: Pages.ActivitiesScheduler.url,
        useExactMapping: true,
        tabName: 'Планировщик мероприятий',
        onClick: () => {
            browserHistory.push(Pages.ActivitiesScheduler.url);
        },
    },
    {
        key: 2,
        url: Pages.NewsGenerator.url,
        useExactMapping: true,
        tabName: 'Генератор новостей',
        onClick: () => {
            browserHistory.push(Pages.NewsGenerator.url);
        },
    },
    {
        key: 3,
        url: Pages.UserProfile.url,
        useExactMapping: true,
        tabName: 'Библиотека вопросов и ответов',
        onClick: () => {
            browserHistory.push(Pages.QuestionsAndAnswers.url);
        },
    },
    {
        key: 3,
        url: Pages.UserProfile.url,
        useExactMapping: true,
        tabName: 'Поступившие вопросы',
        onClick: () => browserHistory.push(Pages.QuestionsAndSuggestions.url),
    },
    {
        key: 4,
        url: Pages.Chats.url,
        useExactMapping: true,
        tabName: 'Чаты',
        onClick: () => browserHistory.push(Pages.Chats.url),
    },
];

export const citizenProfileTabsInfo: IProfileTabKeyInfo[] = [
    {
        key: 0,
        url: Pages.Chats.url,
        useExactMapping: true,
        tabName: 'Чаты',
        onClick: () => browserHistory.push(Pages.Chats.url),
    },
    {
        key: 1,
        url: Pages.NotificationCenter.url,
        useExactMapping: true,
        tabName: 'Уведомления',
        onClick: () => {
            browserHistory.push(Pages.NotificationCenter.url);
        },
    },
    {
        key: 2,
        url: Pages.Favorite.url,
        useExactMapping: true,
        tabName: 'Избранное',
        onClick: () => {
            browserHistory.push(Pages.Favorite.url);
        },
    },
    {
        key: 3,
        url: Pages.CitizenGroups.url,
        useExactMapping: true,
        tabName: 'Группы',
        onClick: () => {
            browserHistory.push(Pages.CitizenGroups.url);
        },
    },
    {
        key: 4,
        url: Pages.CitizenEvents.url,
        useExactMapping: true,
        tabName: 'Мероприятия',
        onClick: () => {
            browserHistory.push(Pages.CitizenEvents.url);
        },
    },
    {
        key: 5,
        url: '',
        useExactMapping: true,
        tabName: 'Вопросы',
        disabled: true,
        onClick: () => null,
    },
    {
        key: 6,
        url: '',
        useExactMapping: true,
        tabName: 'Ответы',
        disabled: true,
        onClick: () => null,
    },
    {
        key: 7,
        url: Pages.ActivitiesScheduler.url,
        useExactMapping: true,
        tabName: 'Активность',
        onClick: () => null,
    },
    {
        key: 8,
        url: Pages.UserSettings.url,
        useExactMapping: true,
        tabName: 'Настройки',
        onClick: () => {
            browserHistory.push(Pages.UserSettings.url);
        },
    },
    {
        key: 9,
        url: Pages.UserSettings.url,
        useExactMapping: true,
        tabName: 'Выйти',
        onClick: resetAndLogout,
    },
];

export const logoutTabInfo: IProfileTabKeyInfo = {
    key: 9,
    url: Pages.UserSettings.url,
    useExactMapping: true,
    tabName: 'Выйти',
    onClick: resetAndLogout,
};

