import React from 'react';
import moment from 'moment';
import { Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StatesmanTypes } from 'api/types/v1.0/statesman';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { DATE_TIME_FORMAT_SECOND } from 'constants/dateTime';
import { ActivityCard } from './ActivityCard';
import './ActivitiesList.scss';

export interface IActivitiesListProps {
    activities: IDeputyActivity[];
    statesmanType: StatesmanTypes;
    loadMore: () => void;
    totalCount: number;
    isUserInGroup?: boolean;
}

export const ActivitiesList: React.FC<IActivitiesListProps> = (props) => {
    const { activities, statesmanType, loadMore, totalCount } = props;

    return (
        <div className='acitivities-list'>
            <div className={'activities-list-title'}>Мероприятия</div>
            <InfiniteScroll
                style={{ overflow: 'unset' }}
                dataLength={activities ? activities.length : 0}
                next={loadMore}
                hasMore={activities && activities.length < totalCount}
                loader={<div className={'loader'}>
                    <Skeleton title={false} active />
                </div>}
                scrollableTarget="scrollableDiv">
                <List
                    dataSource={activities ?? undefined}
                    renderItem={(activity) => (
                        <div>
                            <ActivityCard
                                key={activity.Id}
                                activity={{
                                    ...activity,
                                    date: moment.utc(activity.date_time).local().format(DATE_TIME_FORMAT_SECOND),
                                }}
                                statesmanType={statesmanType}
                                isUserInGroup={props.isUserInGroup}
                            />
                            {
                                (props.activities.indexOf(activity) !== props.activities.length - 1)
                                && <Divider className={'divider'} />
                            }
                        </div>
                    )}
                />
            </InfiniteScroll>
        </div>
    );
};
