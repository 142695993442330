import { IInterview } from 'api/types/v1.0/deputyActivities';
import {
    ADD_CITIZEN_MENU_EVENTS,
    CLEAR_CITIZEN_MENU_CITIZEN_EVENTS,
    DELETE_CITIZEN_MENU_CITIZEN_EVENTS,
    SET_CITIZEN_MENU_CITIZEN_EVENTS,
    SET_CITIZEN_MENU_CITIZEN_EVENTS_IS_EXPANDED,
    SET_CITIZEN_MENU_LOADING,
    SET_CITIZEN_MENU_TOTAL_COUNT,
    UPDATE_INTERVIEW,
} from './actionTypes';
import { IState } from './types';

export const setCitizenEvents = (citizenEvents: IState['citizenEvents']) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_EVENTS, payload: citizenEvents } as const);

export const addCitizenEvents = (citizenEvents: IState['citizenEvents']) =>
    ({ type: ADD_CITIZEN_MENU_EVENTS, payload: citizenEvents } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_CITIZEN_MENU_LOADING, payload: isLoading } as const);

export const deleteCitizenEvents = () =>
    ({ type: DELETE_CITIZEN_MENU_CITIZEN_EVENTS } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_CITIZEN_MENU_TOTAL_COUNT, payload: totalCount } as const);

export const setIsExpanded = (isExpanded: IState['isExpanded']) =>
    ({ type: SET_CITIZEN_MENU_CITIZEN_EVENTS_IS_EXPANDED, payload: isExpanded } as const);

export const clear = () =>
    ({ type: CLEAR_CITIZEN_MENU_CITIZEN_EVENTS } as const);

export const updateInterview = (eventId: number, interviewData: IInterview) =>
    ({ type: UPDATE_INTERVIEW, payload: { eventId, interviewData } } as const);
