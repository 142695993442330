import React from 'react';
import { useLocation } from 'react-router-dom';
import { Role } from 'types/account';
import { ITabKeyInfo } from '../interfaces/ITabKeyInfo';
import {
    coordinatorProfileTabsInfo,
    municipalProfileTabsInfo,
    mainTabsInfo,
} from '../LayoutHeaderTabsInfo';
import MainLayoutHeaderTabs from './MainLayoutHeaderTabs';
import ProfileLayoutHeaderTabs from './ProfileLayoutHeaderTabs';

interface IProps {
    userRole: Role | '';
}

const LayoutHeaderTabs: React.FC<IProps> = ({ userRole }) => {
    const location = useLocation();

    const getSelectedTabKey = (info: ITabKeyInfo[]): number | undefined => {
        let selectedTabInfo = info.find((x) => x.useExactMapping && x.url === location.pathname);

        if (!selectedTabInfo) {
            selectedTabInfo = info.find((x) => !x.useExactMapping && location.pathname.includes(x.url));
        }

        return selectedTabInfo?.key;
    };

    let tabsToRender = null;
    let selectedTabKey = undefined;

    switch (userRole) {
        case Role.Regional:
        case Role.Federal:
            selectedTabKey = getSelectedTabKey(coordinatorProfileTabsInfo);
            if (selectedTabKey !== undefined) {
                tabsToRender = (
                    <ProfileLayoutHeaderTabs selectedTabKey={selectedTabKey} tabKeyInfos={coordinatorProfileTabsInfo} />
                );
            }

            break;

        case Role.Municipal:
            selectedTabKey = getSelectedTabKey(municipalProfileTabsInfo);
            if (selectedTabKey !== undefined) {
                tabsToRender = (
                    <ProfileLayoutHeaderTabs selectedTabKey={selectedTabKey} tabKeyInfos={municipalProfileTabsInfo} />
                );
            }

            break;
    }

    if (tabsToRender === null) {
        selectedTabKey = getSelectedTabKey(mainTabsInfo);
        tabsToRender = <MainLayoutHeaderTabs selectedTabKey={selectedTabKey} />;
    }

    return <>{tabsToRender}</>;
};

export default LayoutHeaderTabs;
