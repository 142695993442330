import React from 'react';
import { useSelector } from 'react-redux';
import Page from 'components/Page';
import { Role } from 'types/account';
import { RootState } from 'App/root/rootReducer';
import { DeputyProfile } from './components/DeputyProfile';
import { FederalCoordinatorProfile } from './components/FederalCoordinatorProfile';
import { RegionCoordinatorProfile } from './components/RegionCoordinatorProfile';

export const DeputyProfilePage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.account);
    const role = !!user && user.role;
    const id = Number(user?.sub);

    return (
        <Page
            content={
                <Page.Content>
                    {(() => {
                        switch (role) {
                            case Role.Municipal:
                                return <DeputyProfile deputyId={id} />;
                            case Role.Regional:
                                return <RegionCoordinatorProfile deputyId={id} />;
                            case Role.Federal:
                                return <FederalCoordinatorProfile deputyId={id} />;
                            default:
                                return null;
                        }
                    })()}
                </Page.Content>
            }
        />
    );
};
