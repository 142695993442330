import React from 'react';
import { isDesktop } from 'react-device-detect';
import StatiscticsBlock from 'ui-kit/StatiscticsBlock/StatiscticsBlock';
import './DeputyStatistics.scss';
import { ICoordinatorStatistics } from 'api/types/v1.0/coordinatorStatistics';

export interface IDeputyStatisticsProps
{
    Id: number;
    data: ICoordinatorStatistics | null;
    rating: number | undefined;
}

const DeputyStatistics: React.FC<IDeputyStatisticsProps> = (props: IDeputyStatisticsProps) => {
    return (props.data &&
        <div>
            {
                isDesktop && <div className='deputy-statistics-container'>
                    <span className='deputy-statistics-header'>Общий рейтинг&nbsp;
                        <span className='statistics-value'>
                            {props.rating}%
                        </span>
                    </span>
                </div>
            }
            <div className='deputy-statistics-container'>
                <div className='deputy-statistics-header'>Статистика по мероприятиям</div>
                <div className='deputy-statistics-body'>
                    <div className='deputy-statistics-item1'>
                        <StatiscticsBlock key={1} value={props.data.planed_events?? 0} title={'Запланировано'} />
                    </div>
                    <div className='deputy-statistics-item2'>
                        <StatiscticsBlock key={2} value={props.data.passed_events ?? 0} title={'Прошло'} />
                    </div>
                    <div className='deputy-statistics-item3'>
                        <StatiscticsBlock key={3} value={props.data?.participants_events ?? 0} title={'Участника'} />
                    </div>
                    <div className='deputy-statistics-item4'>
                        <StatiscticsBlock key={4} value={props.data?.not_provided_reports ?? 0} title={'Без отчета'} />
                    </div>
                    <div className='deputy-statistics-item5'>
                        <StatiscticsBlock key={5} value={props.data?.satisfaction_events.toString().concat('%') ?? '0%'} title={'Одобрение граждан'} />
                    </div>
                </div>
            </div>
            <br />
            <div className='deputy-statistics-container'>
                <div className='deputy-statistics-header'>Статистика по вопросам и ответам</div>
                <div className='deputy-questions-body'>
                    <div className='deputy-statistics-item1'>
                        <StatiscticsBlock key={6} value={props.data?.all_questions ?? 0} title={'Всего вопросов'} />
                    </div>
                    <div className='deputy-statistics-item2'>
                        <StatiscticsBlock key={7} value={props.data?.not_viewed_questions ?? 0} title={'Без ответа'} />
                    </div>
                    <div className='deputy-questions-item'>
                        <StatiscticsBlock key={8} value={props.data?.users_count_questions ?? 0} title={'Человек задали вопросы'} />
                    </div>
                    <div className='deputy-percent-item'>
                        <StatiscticsBlock key={9} value={props.data?.satisfaction_questions.toString().concat('%') ?? '0%'} title={'Удовлетворенность граждан'} />
                    </div>
                </div>
            </div>
        </div>);
};

export default DeputyStatistics;
