import {
    SET_FAVORITES,
    SET_LOADING,
    SET_TOTAL_COUNT,
    DELETE_FAVORITES,
    DELETE_FAVORITE,
    SET_DELETED_FAVORITE,
} from './actionTypes';
import { IState } from './types';

export const setFavorites = (favorites: IState['favorites']) =>
    ({ type: SET_FAVORITES, payload: favorites } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);

export const setTotalCount = (totalCount: IState['totalCount']) =>
    ({ type: SET_TOTAL_COUNT, payload: totalCount } as const);

export const deleteFavorites = () =>
    ({ type: DELETE_FAVORITES } as const);

export const deleteFavorite = (favoriteId: number) =>
    ({ type: DELETE_FAVORITE, payload: favoriteId } as const);

export const setDeletedFavorite = (deletedFavorite: IState['deletedFavorite']) =>
    ({ type: SET_DELETED_FAVORITE, payload: deletedFavorite } as const);
