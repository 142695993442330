import { Button, Form, Tooltip } from 'antd';
import React from 'react';
import './SubmitButton.scss';

export interface ISubmitButtonProps {
    disabled?: boolean;
    buttonText: string;
    customValidationMessage?: string;
    isTouched?: boolean;
    loading?: boolean;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = (props) => {
    const isTouched = () => props.isTouched === undefined ? true : props.isTouched;

    const getValidationMessage = () => {
        if (!props.disabled || !isTouched) {
            return '';
        }

        if (!props.customValidationMessage) {
            return 'Заполнены не все обязательные поля';
        }

        return props.customValidationMessage;
    }
    return (
        <Form.Item wrapperCol={{}}>
            <Tooltip title={getValidationMessage()}>
                <Button
                    className={props.disabled || !isTouched() ? 'disabled-submit-button' : 'submit-button'}
                    htmlType={'submit'}
                    disabled={props.disabled || !isTouched()}
                    block
                    loading={props.loading}
                >
                    {props.buttonText}
                </Button>
            </Tooltip>
        </Form.Item>
    );
};
