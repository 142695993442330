import { Button } from 'antd';
import React, { useState } from 'react';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import moment from 'moment';
import { IDeputyActivity } from 'api/types/v1.0/deputyActivities';
import { DEFAULT_DATE_FORMAT, ONLY_TIME_FORMAT } from 'constants/dateTime';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { eventStatuses } from 'constants/eventStatuses';
import { INewsGeneratorPageLocationState } from '../../../NewsGenerator/NewsGeneratorPage';
import styles from './ActivityCard.module.scss';

export interface IActivityProps {
    activity: IDeputyActivity;
}

const ActivityCard: React.FC<IActivityProps> = (props) => {
    const [size] = useState<SizeType>('middle');
    const activityDate = moment(props.activity.date_time).format(DEFAULT_DATE_FORMAT);
    const activityTime = moment(props.activity.date_time).format(ONLY_TIME_FORMAT);
    const activityDateTime = `${activityDate} ${activityTime}`;

    props.activity['date'] = activityDate;
    props.activity['time'] = activityTime;

    const openNewsGenerator = () =>
    {
        browserHistory.push({
            pathname: Pages.NewsGenerator.url,
            state: {
                projectId: props.activity.project_id,
                pointId: props.activity.object_id,
                activityId: props.activity.object_event_id,
            } as INewsGeneratorPageLocationState,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    const openEditActivity = () => {
        browserHistory.push({
            pathname: Pages.ActivitiesEdit.url,
            state:  props.activity,
        });
        setTimeout(()=>{window.scrollTo(0, -3000);},100);
    };

    return (
        <div className={styles.activityCardWrapper}>
            <div className={styles.activityTextWrapper} onClick={openEditActivity}>
                <span className={styles.activityDate}>{activityDateTime.concat(' ')}</span>
                <span className={styles.activityName}>{props.activity.event_name}</span>
            </div>
            {props.activity.state_id === eventStatuses.completed && (
                <div className={styles.buttonsWrapper}>
                    {props.activity.event_report ? (
                        <Button
                            className={styles.resultButton}
                            type="link"
                            size={size}
                            onClick={() => {
                                browserHistory.push(
                                    `${Pages.NewsDetails.url}/${
                                        props.activity.report_id ? props.activity.report_id : ''
                                    }`
                                ); setTimeout(()=>{window.scrollTo(0, -3000);},100);
                            }}
                        >
                            Отчет
                        </Button>
                    ) : (
                        <Button
                            className={styles.generateResultButton}
                            type="link"
                            size={size}
                            onClick={() => {
                                openNewsGenerator();
                            }}
                        >
                            Сформировать отчет
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActivityCard;
