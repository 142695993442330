import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    interview: null,
    isExpanded: false,
    isLoading: false,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_INTERVIEW:
            return {
                ...state,
                interview: action.payload,
            };

        case actionTypes.SET_IS_INTERVIEW_EXPANDED:
            return {
                ...state,
                isExpanded: action.payload,
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
