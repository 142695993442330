import { IPointInfo } from 'api/types/v1.0/pointInfo';
import {
    DELETE_POINT_INFO,
    EDIT_POINT_INFO_ERROR,
    EDIT_POINT_INFO_FETCH,
    EDIT_POINT_INFO_LOAD,
    EDIT_POINT_INFO_SET,
} from './actionTypes';

export const deletePointInfo = () =>
    ({ type: DELETE_POINT_INFO } as const);

export const editPointInfoFetch = () =>
    ({ type: EDIT_POINT_INFO_FETCH } as const);

export const editPointInfoLoad = () =>
    ({ type: EDIT_POINT_INFO_LOAD } as const);

export const editPointInfoError = (error: string) =>
    ({ type: EDIT_POINT_INFO_ERROR, payload: error } as const);

export const editPointInfoSet = (pointInfo: IPointInfo) =>
    ({ type: EDIT_POINT_INFO_SET, payload: pointInfo } as const);
