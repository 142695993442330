import { useCallback, useEffect, useReducer } from 'react';
import { getProjectIndicators } from 'api/v1.0/projectIndicators';
import { IProjectIndicatorsRequestItem } from 'api/types/v1.0/projectIndicators';
import { handleApiError } from 'api/v1.0/common';
import { SelectedIndicatorType } from '../Indicators';
import * as actionCreators from './actionCreators';
import reducer, { defaultState } from './reducer';

interface IProjectIndicators {
    id: string;
    selectedIndicatorType: SelectedIndicatorType;
    selectedRegion?: number[];
    selectedPeriod?: { start?: string; end?: string };
}

export const useProjectIndicators = (
    {
        id,
        selectedRegion,
        selectedPeriod,
        selectedIndicatorType,
    }: IProjectIndicators
) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    const refresh = useCallback(() => {
        dispatch(actionCreators.setProjectIndicators(selectedIndicatorType, null));

        let filter: IProjectIndicatorsRequestItem = { projectId: id };

        if (selectedIndicatorType === 'region' && selectedRegion) {
            filter = { ...filter, regionId: selectedRegion };
        }

        if (selectedPeriod?.start) {
            filter = { ...filter, startDateTime: selectedPeriod.start };
        }

        if (selectedPeriod?.end) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            filter = { ...filter, endDateTime: selectedPeriod.end };
        }

        getProjectIndicators({ dataFilter: filter })
            .then((response) =>
                dispatch(actionCreators.setProjectIndicators(selectedIndicatorType, response.data)))
            .catch((e) => handleApiError(e));
    }, [id, selectedPeriod?.end, selectedPeriod?.start, selectedRegion, selectedIndicatorType]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        ...state,
    };
};
