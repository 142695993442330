import { mapZoom } from '../../constants/mapZoom';
import {
    SET_LIST_POINTS,
    SET_TOTAL_ITEMS,
    SET_LIST_LOADING,
    SET_LIST_PAGING,
    SET_MAP_POINTS,
    SET_MAP_LOADING,
    SET_MAP_CENTER,
    SET_POINTS_VISUALISATION,
    SET_ID_OF_FILTERED_REGIONS,
    SET_MAP_REGION_NAME,
    CLEAR_LIST_POINTS,
} from './actionTypes';
import { IState } from './types';

export const setListPoints = (points: IState['listPoints']) => ({ type: SET_LIST_POINTS, payload: points } as const);
export const clearListPoints = () => ({ type: CLEAR_LIST_POINTS } as const);
export const setTotalItems = (totalItems: IState['totalItems']) =>
    ({ type: SET_TOTAL_ITEMS, payload: totalItems } as const);
export const setListIsLoading = (isLoading: IState['isListLoading']) =>
    ({ type: SET_LIST_LOADING, payload: isLoading } as const);
export const setListPaging = (paging: IState['listPaging']) => ({ type: SET_LIST_PAGING, payload: paging } as const);

export const setMapPoints = (points: IState['mapPoints']) => ({ type: SET_MAP_POINTS, payload: points } as const);
export const setMapIsLoading = (isLoading: IState['isMapLoading']) =>
    ({ type: SET_MAP_LOADING, payload: isLoading } as const);
export const setMapCenter = (center: IState['mapCenter']) => ({ type: SET_MAP_CENTER, payload: center });
export const setMapOnPoint = (center: IState['mapCenter']) => ({
    type: SET_MAP_CENTER,
    payload: {
        lng: center.lng,
        lat: center.lat,
        zoom: center.zoom ?? mapZoom.defaultZoomOnPoint,
    },
});
export const setMapRegionName = (region_name: IState['mapRegionName']) => ({ type: SET_MAP_REGION_NAME, payload: region_name });
export const setIdOfFilteredRegions = (idOfFilteredRegions: IState['idOfFilteredRegions']) =>
    ({ type: SET_ID_OF_FILTERED_REGIONS, payload: idOfFilteredRegions } as const);

export const setPointsVisualisation = (type: IState['pointsVisualisation']) => ({
    type: SET_POINTS_VISUALISATION,
    payload: type,
});
