import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import { IState } from './types';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

export const defaultState: IState = {
    deputies: null,
    isLoading: false,
    totalCount: 0,
    searchInput: null,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_DEPUTIES:
            return {
                ...state,
                deputies: [...state.deputies ?? [], ...action.payload ?? []],
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.DELETE_DEPUTIES:
            return {
                ...state,
                deputies: [],
                totalCount: 0,
            };

        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.SET_SEARCH_INPUT:
            return {
                ...state,
                searchInput: action.payload,
            }

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
