import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { RootState } from 'App/root/rootReducer';
import { IPointMediaFile } from 'api/types/v1.0/pointMedia';
import { fileTypes } from 'constants/fileTypesDictionary';
import { MediaImage } from 'ui-kit/MediaImage/MediaImage';
import { MediaCamera } from 'ui-kit/MediaCamera/MediaCamera';
import { postViewedNewsPhotos } from 'api/v1.0/viewedNewsPhotos';
import { hasMunicipalRole } from 'helpers/account';
import { ReactComponent as RemoveIcon } from './assets/RemoveIcon.svg';
import './EditPointMediaItem.scss';
import './EditPointMedia.scss';

interface IProps {
    item: IPointMediaFile;
    deleteMedia?: (id: number) => void;
}

const EditPointMediaItem: React.FC<IProps> = (props) => {
    const { user } = useSelector((state: RootState) => state.account);

    const [isWatched, setIsWatched] = useState<boolean>(hasMunicipalRole(user)
        ? props.item.MunDepViewed
        : false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleOnPreview = async (id: number) => {
        if (hasMunicipalRole(user)) {
            await postViewedNewsPhotos({ modelData: { Id: id, MunDepViewed: true } });
        }

        setIsWatched(true);
    };

    const showModal = () => {
        setIsDeleteModalOpened(true);
    };

    const handleOk = (id: number) => {
        if (!props.deleteMedia) {
            return null;
        }

        props.deleteMedia(id);
        setIsDeleteModalOpened(false);
    };

    const handleCancel = () => {
        setIsDeleteModalOpened(false);
    };

    useEffect(() => {
        setIsWatched(props.item.MunDepViewed);
    }, [props.item.MunDepViewed]);

    const { item } = props;

    switch (item.type) {
        case fileTypes.Image:
            return (
                <div className={'edit-point-media-box'}>
                    <Modal
                        className={'edit-point-remove-media-modal'}
                        title="Вы уверены что хотите удалить данное изображение?"
                        open={isDeleteModalOpened}
                        onOk={() => handleOk(item.Id)}
                        onCancel={handleCancel}
                    />
                    <div className={'edit-point-media-file'}>
                        <MediaImage
                            onClick={() => handleOnPreview(item.Id)}
                            imageWidth={200}
                            url={item.media_url}
                            preview={{ src: item.preview_url ?? '' }}
                        />
                        {!isWatched && <span className={'edit-point-media-file-is-new'} />}
                        <div className="remove-icon-image" onClick={showModal}>
                            <RemoveIcon />
                        </div>
                    </div>
                </div>
            );
        case fileTypes.Video:
            return (
                <div className={'edit-point-media-box'}>
                    <Modal
                        className={'edit-point-remove-media-modal'}
                        title="Вы уверены что хотите удалить данное видео?"
                        open={isDeleteModalOpened}
                        onOk={() => handleOk(item.Id)}
                        onCancel={handleCancel}
                    />
                    <div className={'edit-point-media-file'}>
                        <MediaCamera
                            handleOnPreview={() => handleOnPreview(item.Id)}
                            isForEditPoint={true}
                            isMainVideo={false}
                            videoContentUrl={item.media_url}
                        />
                        {!isWatched && <span className={'edit-point-media-file-is-new'} />}
                        <div className="remove-icon-video" onClick={showModal}>
                            <RemoveIcon />
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default EditPointMediaItem;
