import React from 'react';
import { StarFilled } from '@ant-design/icons';
import browserHistory from 'App/root/browserHistory';
import { Pages } from 'constants/links';
import { IFavoritePointItem } from 'api/types/v1.0/favoritePoint';
import './FavoriteItem.scss';

interface IProps {
    handleStarClicked: (favorite: IFavoritePointItem) => void;
    favorite: IFavoritePointItem;
}

const FavoriteItem: React.FC<IProps> = ({
    handleStarClicked,
    favorite }) => {
    return (
        <>
            <div className={'favorite-item'} onClick={() =>
                browserHistory.push(`${Pages.PointInfo.url}/${favorite.ObjectId_Ref_Id.toString()}`)}>
                <div className={'favorite-item-text'}>
                    <div className={'favorite-item-title'}>
                        {favorite.ObjectId_object_name}
                    </div>
                    <div className={'favorite-item-description'}>
                        {favorite.ObjectId_address_o}
                    </div>
                </div>
                <div className={'favorite-item-star'}>
                    <StarFilled className={'favorite-item-star-icon'} onClick={(e) => {
                        e.stopPropagation();
                        handleStarClicked(favorite);
                    }} />
                </div>
            </div>
        </>);
};

export default FavoriteItem;
