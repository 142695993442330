import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';
import { IState } from './types';

export const defaultState: IState = {
    groups: null,
    isLoading: false,
    totalCount: 0,
    isConfirmModalOpen: false,
    selectedObjectId: 0,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CITIZEN_GROUPS:
            return {
                ...state,
                groups: [...state.groups ?? [], ...action.payload ?? []],
            };

        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case actionTypes.DELETE_GROUPS:
            return {
                ...state,
                groups: [],
            };

        case actionTypes.DELETE_GROUP:
            return {
                ...state,
                groups: state.groups
                    ? state.groups.filter((value) => value.object_id !== action.payload)
                    : null,
            };

        case actionTypes.SET_CONFIRM_MODAL_OPEN:
            return {
                ...state,
                isConfirmModalOpen: action.payload.isOpen,
                selectedObjectId: action.payload.objectId ?? 0,
            };

        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
