import React from 'react';
import { Card } from 'antd';
import Page from 'components/Page';
import styles from './HintsPage.module.scss';

const HintsPage: React.FC = () => {
    return (
        <Page
            content={
                <Page.Content sidebar={'Main'}>
                    <div className={styles['hint-body']}>
                        <Card>
                            <img className={styles['hint-image']} alt='' />
                        </Card>
                    </div>
                </Page.Content>
            }
        />
    );
};

export default HintsPage;
