import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'antd';
import { IInterview } from 'api/types/v1.0/deputyActivities';
import { ActivityCardPollFormFields } from './ActivityCardPollFormFields';
import { validateActivityCardPollValues } from './validateActivityCardPollValues';
import { ReactComponent as CloseIcon } from './assets/IconClose.svg';

export interface IActivityCardPollFormValues extends IInterview {}

export interface IActivityCardPollFormProps {
    open: boolean;
    onCancel: () => void;
    interview: IInterview | undefined;
    onFinish: (values: IInterview) => void;
    disabled?: boolean;
    setModalIsOpen: (open: boolean) => void;
}

export const ActivityCardPollForm: React.FC<IActivityCardPollFormProps> = (props) => {
    const interview = props.interview ?? ({} as IInterview);

    return (
        <Modal
            onCancel={props.onCancel}
            open={props.open}
            closable={true}
            title={'Добавление опроса'}
            closeIcon={<CloseIcon className={'iconStyle'} />}
            okButtonProps={{ hidden: true }}
            footer={
                <div className={'activity-card-poll-footer-close'}>
                    <Button className={'cancel-style'} onClick={() => props.setModalIsOpen(false)}>
                        Отмена
                    </Button>
                </div>
            }
            className={'activity-card-modal'}
        >
            <Formik<IActivityCardPollFormValues>
                initialValues={{ ...interview }}
                enableReinitialize={true}
                validate={validateActivityCardPollValues}
                validateOnChange
                validateOnBlur
                validateOnMount
                onSubmit={() => undefined}
            >
                {(formikProps) => (
                    <Form className={'activity-card-poll-form'} onSubmit={formikProps.handleSubmit}>
                        <ActivityCardPollFormFields
                            interview={interview}
                            open={props.open}
                            onFinish={props.onFinish}
                            onCancel={props.onCancel}
                            setModalIsOpen={props.setModalIsOpen}
                            disabled={props.disabled}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
