import { SET_INTERVIEW, SET_IS_INTERVIEW_EXPANDED, SET_LOADING } from './actionTypes';
import { IState } from './types';

export const setInterview = (interview: IState['interview']) =>
    ({ type: SET_INTERVIEW, payload: interview } as const);

export const setIsExpanded = (isExpanded: IState['isExpanded']) =>
    ({ type: SET_IS_INTERVIEW_EXPANDED, payload: isExpanded } as const);

export const setLoading = (isLoading: boolean) =>
    ({ type: SET_LOADING, payload: isLoading } as const);
