import { useEffect, useState } from 'react';
import { handleApiError } from 'api/v1.0/common';
import { IProjectListResponse } from 'api/types/v1.0/projectList';
import { mapToProjectList } from 'helpers/citizenFilterMapHelpers';
import { getCitizenEvents } from 'api/v1.0/citizenEvents';
import { MAX_INT } from 'constants/numbers';

const useCitizenProjectList = (userId: string) => {
    const [state, setState] = useState<IProjectListResponse>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);

                const request = {
                    start: 0,
                    limit: MAX_INT,
                    dataFilter: {
                        Group: 2,
                        Filters: [
                            {
                                Operand: 0,
                                Value: userId,
                                DataIndex: 'citizen_id',
                            },
                        ],
                    },
                };
                const response = await getCitizenEvents(request);
                const mappedResponse = mapToProjectList(response);
                setState(mappedResponse);
                setLoading(false);
            } catch (exception) {
                handleApiError(exception);
                setLoading(false);
            }
        };

        void fetch();
    }, [userId]);

    return {
        ...state,
        loading,
    };
};

export default useCitizenProjectList;
