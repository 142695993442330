import React, { useState } from 'react';

export type PointInfoContextType = {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    favoriteId: number;
    setFavoriteId: (favoriteId: number) => void;
    refreshFavoritesDueToDeletion: boolean;
    setRefreshFavoritesDueToDeletion: (refreshFavoritesDueToDeletion: boolean) => void;
};

export const PointInfoContext = React.createContext<PointInfoContextType | null>(null);

const PointInfoProvider: React.FC = ({ children }) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [favoriteId, setFavoriteId] = useState<number>(0);
    const [refreshFavoritesDueToDeletion, setRefreshFavoritesDueToDeletion] = useState<boolean>(false);

    return <PointInfoContext.Provider
        value={{
            isActive,
            setIsActive,
            favoriteId,
            setFavoriteId,
            refreshFavoritesDueToDeletion,
            setRefreshFavoritesDueToDeletion }}>
        {children}
    </PointInfoContext.Provider>;
};

export default PointInfoProvider;
