const Ok = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7260_7846)">
            <rect width="32" height="32" rx="6" fill="#FF8800"/>
            <path d="M16.0305 16.3155C18.6998 16.3155 20.8758 14.1395 20.8758 11.4702C20.8758 8.801 18.6998 6.625 16.0305 6.625C13.3323 6.625 11.1562 8.801 11.1562 11.4702C11.1562 14.1395 13.3323 16.3155 16.0305 16.3155ZM16.0305 9.46831C17.133 9.46831 18.0324 10.3677 18.0324 11.4702C18.0324 12.5728 17.133 13.4722 16.0305 13.4722C14.928 13.4722 14.0286 12.5728 14.0286 11.4702C13.9996 10.3677 14.899 9.46831 16.0305 9.46831Z" fill="white"/>
            <path d="M17.9749 20.2904C18.9614 20.0582 19.9188 19.681 20.7893 19.1298C21.4565 18.7236 21.6596 17.8242 21.2244 17.1569C20.8183 16.4896 19.9188 16.2865 19.2515 16.7217C17.2786 17.9693 14.6964 17.9693 12.7235 16.7217C12.0562 16.3155 11.1858 16.4896 10.7506 17.1569C10.3734 17.8242 10.5765 18.6946 11.2438 19.1008C12.1142 19.652 13.0717 20.0292 14.0581 20.2614L11.3599 22.9597C10.8086 23.5109 10.8086 24.4103 11.3599 24.9616C11.65 25.2516 11.9981 25.3677 12.3753 25.3677C12.7525 25.3677 13.1007 25.2226 13.3908 24.9616L16.06 22.2923L18.7293 24.9616C19.2805 25.5128 20.1799 25.5128 20.7312 24.9616C21.2824 24.4103 21.2824 23.5109 20.7312 22.9597L17.9749 20.2904Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_7260_7846">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default Ok;
