import { Reducer } from 'redux';
import { InferValueTypes } from 'utils/types';
import * as actionTypes from './actionTypes';
import * as actionCreators from './actionCreators';
import { IState } from './types';

export const defaultState: IState = {
    groupMembersList: null,
    isGroupMembersLoading: false,
};

type ActionsType = ReturnType<InferValueTypes<typeof actionCreators>>;

const reducer: Reducer<IState, ActionsType> = (state, action) => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_GROUP_MEMBERS_LIST:
            return {
                ...state,
                groupMembersList: action.payload,
            };
        case actionTypes.SET_IS_GROUP_MEMBERS_LOADING:
            return {
                ...state,
                isGroupMembersLoading: action.payload,
            };
        case actionTypes.REMOVE_GROUP_MEMBER:
            return {
                ...state,
                groupMembersList:
                    state.groupMembersList?.data && {
                        ...state?.groupMembersList,
                        data: state.groupMembersList?.data.map((member) => {
                            if (action.payload === member.citizen_id) {
                                member.remove_from_group = 1;
                            }

                            return member;
                        }),
                    },
            };
        case actionTypes.RECOVER_GROUP_MEMBER:
            return {
                ...state,
                groupMembersList:
                    state.groupMembersList?.data && {
                        ...state?.groupMembersList,
                        data: state.groupMembersList?.data.map((member) => {
                            if (action.payload === member.citizen_id) {
                                member.remove_from_group = 0;
                            }

                            return member;
                        }),
                    },
            };
        default:
            throw new Error('Unknown action type');
    }
};

export default reducer;
