import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

const defaultState: IState = {
    selectedPoint: null,
};

const reducer: Reducer<IState, ActionsType> = (state, action): IState => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SELECT_POINT:
        {
            return {
                ...state,
                selectedPoint: action.payload,
            };
        }

        case actionTypes.RESET_SELECTED_POINT:
        {
            return {
                ...state,
                selectedPoint: null,
            };
        }

        default:
            return state;
    }
};

export default reducer;
