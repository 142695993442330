import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { RootState } from 'App/root/rootReducer';
import { hasCitizenRole, hasDeputyRole } from 'helpers/account';
import { LikeDislikeType, sendLike } from 'api/v1.0/likesDislikes';
import { LikeType } from 'api/types/v1.0/likesDislikes';
import LikeDislike from 'components/LikeDislike/LikeDislike';
import Page from 'components/Page';
import FolkDivider from 'ui-kit/FolkDivider';
import CardTitle from 'ui-kit/Title';
import { IPoint } from 'api/types/v1.0/points';
import { setMapOnPoint } from 'ducks/mainPage';
import useSelectedPoint from 'hooks/useSelectedPoint';
import PointText from './components/PointText/PointText';
import PointMedia from './components/PointMedia/PointMedia';
import PointControlPoints from './components/PointControlPoints';
import usePointInfoText from './components/PointText/hooks/usePointInfoText';
import { PointFavorite } from './components/PointFavorite/PointFavorite';
import { PointJoinGroup } from './components/PointJoinGroup/PointJoinGroup';
import styles from './PointInfoPage.module.scss';
import PointDocuments from './components/PointDocuments/PointDocuments';
import PointInfoProvider from './context/PointInfoContext';

const PointInfoPage: React.FC = () => {
    const router = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const id = Number(router.id);
    const { user } = useSelector((state: RootState) => state.account);
    const { pointInfo, isLoading } = usePointInfoText({ id: Number(id) });
    const hasCitizenRoleVar = hasCitizenRole(user);
    const hasDeputyRoleVar = hasDeputyRole(user);
    const isLiked = pointInfo ? pointInfo.mark_type === LikeDislikeType.Like : false;
    const isDisliked = pointInfo ? pointInfo.mark_type === LikeDislikeType.Dislike : false;
    const positionLikeDislike = hasDeputyRoleVar ? 'end' : 'space-between';
    const oldPointId = useSelector((state: RootState) => state.selectedPoint.selectedPoint?.id);
    const { setSelectedPoint } = useSelectedPoint();

    if (pointInfo && oldPointId !== router.id)
    {
        const point = {
            id: router.id,
            projectId: pointInfo.project_id,
            title: pointInfo.object_name,
            region: pointInfo.region_name,
            address: pointInfo.address,
            regionId: pointInfo.region_id,
            regionalCoordId: pointInfo.regional_coord_id,
            municipaCoordId: pointInfo.municipal_respons_id,
        } as IPoint;
        setSelectedPoint(point);

        dispatch(setMapOnPoint({
            lng: pointInfo.longitude,
            lat: pointInfo.latitude,
        }));
    }

    return (
        <PointInfoProvider>
            <Page
                content={
                    <Page.Content sidebar={'Main'}>
                        <Card className={styles['info-card']}>
                            <Skeleton loading={isLoading} paragraph={{ rows: 6 }} className={styles['skeleton']}>
                                <div className={styles['title-wrapper']}>
                                    <CardTitle cardTitle={'Карточка объекта'} />
                                </div>
                                <FolkDivider />
                                <div className={styles['point-content-wrapper']}>
                                    <Row justify={'space-between'} wrap gutter={[24, 24]}>
                                        <Col className={styles['point-info-label']}>
                                            {pointInfo?.object_name}
                                        </Col>
                                        <Col className={styles['point-join-button']}>
                                            <div hidden={!!user && !hasCitizenRoleVar}>
                                                <PointJoinGroup
                                                    userId={Number(user?.sub)}
                                                    objectId={id}
                                                    hasCitizenRole={hasCitizenRoleVar}
                                                    isUserInSubscribed={pointInfo?.is_user_subscribed_to_group}
                                                    isUserDeleted={pointInfo?.is_user_deleted_from_group} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className={styles['wrapper']}>
                                        <Row justify={positionLikeDislike}>
                                            <Col hidden={hasDeputyRoleVar}>
                                                <PointFavorite userId={Number(user?.sub)} objectId={id} />
                                            </Col>
                                            <Col>
                                                <LikeDislike
                                                    isDisabled={hasDeputyRoleVar}
                                                    dislikesCount={pointInfo ? pointInfo.dislikes : 0}
                                                    likesCount={pointInfo ? pointInfo.likes : 0}
                                                    isLiked={isLiked}
                                                    isDisliked={isDisliked}
                                                    onDislikeClick={() =>
                                                        sendLike(pointInfo?.Id ?? 0, LikeType.pointLike, false)}
                                                    onLikeClick={() =>
                                                        sendLike(pointInfo?.Id ?? 0, LikeType.pointLike, true)}
                                                />
                                            </Col>
                                        </Row></div>
                                    <PointMedia pointId={id} />
                                    <PointText id={id} />
                                    <PointDocuments pointId={id} />
                                    <PointControlPoints id={id} />
                                </div>
                            </Skeleton>
                        </Card>
                    </Page.Content>
                }
            />
        </PointInfoProvider>
    );
};

export default PointInfoPage;

