import { axiosPostRequest } from 'utils/axiosClient';
import { ApiPaths } from 'constants/ApiPaths';
import {
    IGroupMessageResponseData,
    IGroupResponseData,
    IGroupsListRequest,
    IGroupsMessageRequest,
    ISendImageRequest,
    ISendMessageRequest,
    ISendMessageResponse,
    ISendImageResponse,
    IGetReplyMessageRequest,
} from 'api/types/v1.0/groupsList';
import { getGroupsListFilter, getGroupsMessagesFilter, getGroupsMessagesSort, IHasFilters } from '../bars-request-objects';
import { handleApiError } from './common';

export const getGroupsList = async (request: IGroupsListRequest) => {
    const filters = {
        dataFilter: getGroupsListFilter(request),
        limit: 10,
        start: request.start || 0,
    };

    const { data } = await axiosPostRequest<IHasFilters, IGroupResponseData>(
        ApiPaths.ChatPreviewList,
        filters
    );

    return data;
};

export const getGroupMessages = async (req: IGroupsMessageRequest) => {
    const request = {
        dataFilter: getGroupsMessagesFilter(req),
        sort: getGroupsMessagesSort(req.sortType, req.sortDirection),
        limit: req.limit || 10,
        start: req.start || 0,
    };

    const res = await axiosPostRequest(
        ApiPaths.GroupMessages,
        request
    );

    return res.data as IGroupMessageResponseData;
};

export const sendMessageRequest = async (msgReq: ISendMessageRequest, imgReq?: ISendImageRequest[]) => {
    try {
        const MsgRes = await axiosPostRequest<ISendMessageRequest, ISendMessageResponse>(ApiPaths.SendMessage, msgReq);

        let imgRes: ISendImageResponse[] = [];
        if (imgReq) {
            const requests: Promise<ISendImageResponse>[] = [];
            for (const req of imgReq) {
                const formData = new FormData();

                const imgReqBody = {
                    ContentType: 2,
                    question_answer_id: { Id: MsgRes.data.data.Id },
                    MediaAuthorCitizen: { Id: req?.MediaAuthorCitizen.Id },
                    Object: { Id: req?.Object.Id },
                };

                formData.append('modeldata', JSON.stringify(imgReqBody));
                formData.append('FileOriginal', req?.FIleOriginal as File);

                requests.push(axiosPostRequest(ApiPaths.SendFile, formData));
            }

            imgRes = await Promise.all(requests);
        }

        await messageSeen(MsgRes?.data.data.Id);

        return { msgRes: MsgRes.data, imgRes: imgRes };
    } catch (err) {
        handleApiError(err);
    }
};

export const removeMessageFromChat = async (message_id: number) => {
    try {
        const req = {
            modelData: {
                Id: message_id,
                StateQAC: 1,
            },
        };
        const res = await axiosPostRequest(
            ApiPaths.RemoveMessageFromChat,
            req
        );

        return res?.data as {success: boolean};
    } catch (err) {
        handleApiError(err);
    }
};

export const messageSeen = async (message_id: number) => {
    try {
        const request = {
            Id: message_id,
            viewed: true,
        };

        return await axiosPostRequest(
            ApiPaths.MessageSeen,
            request
        );
    } catch (exception) {
        handleApiError(exception);
    }
};

export const getSingleReplyMessage = async (req: {object_id: number, message_id: number}) => {
    try {
        const request = {
            modelData: {
                object_id: req.object_id,
                message_id: req.message_id,
                sort_type: 0,
                limit: 1,
            },
        };

        const res = await axiosPostRequest<IGetReplyMessageRequest, IGroupMessageResponseData>(
            ApiPaths.ReplyMessage,
            request
        );

        return res.data.data[0];
    } catch (exception) {
        handleApiError(exception);
    }
};

export const getReplyMessage = async (req: {object_id: number, message_id: number, start: number}) => {
    try {
        const request = {
            modelData: {
                object_id: req.object_id,
                message_id: req.message_id,
                sort_type: 0,
                limit: 1,
            },
        };

        const { data } = await axiosPostRequest<IGetReplyMessageRequest, IGroupMessageResponseData>(
            ApiPaths.ReplyMessage,
            request
        );

        request.modelData.limit = data.data[0].index_sort_date + 3;

        return await axiosPostRequest<IGetReplyMessageRequest, IGroupMessageResponseData>(
            ApiPaths.ReplyMessage,
            request
        );
    } catch (exception) {
        handleApiError(exception);
    }
};
