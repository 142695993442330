import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType } from './types';

export interface IAppState {
    isChatOpen: boolean;
    isGroupMembersOpen: boolean;
    isResponse: boolean;
    selectedMessageId: number | null;
    selectedProjectId: number | null;
    selectedChatId: number | null;
}

const defaultState: IAppState = {
    isChatOpen: false,
    isGroupMembersOpen: false,
    isResponse: false,
    selectedMessageId: null,
    selectedProjectId: null,
    selectedChatId: null,
};

const reducer: Reducer<IAppState, ActionsType> = (state, action): IAppState => {
    state = state || defaultState;

    switch (action.type) {
        case actionTypes.SET_CHAT_MODAL_STATE:
            return { ...state, isChatOpen: action.payload };

        case actionTypes.SET_GROUP_MEMBERS_STATE:
            return { ...state, isGroupMembersOpen: action.payload };

        case actionTypes.SET_SELECTED_CHAT_ID:
            return { ...state, selectedChatId: action.payload };

        case actionTypes.SET_IS_RESPONSE:
            return { ...state, isResponse: action.payload };

        case actionTypes.SET_SELECTED_MESSAGE_ID:
            return { ...state, selectedMessageId: action.palyload };

        case actionTypes.SET_SELECTED_PROJECT_ID:
            return { ...state, selectedProjectId: action.palyload };

        case actionTypes.RESET_CHAT:
            return defaultState;

        default:
            return state;
    }
};

export default reducer;
