import { Reducer } from 'redux';
import * as actionTypes from './actionTypes';
import { ActionsType, IState } from './types';

const defaultState: IState = {
    notificationIndicator: null,
};

const notificationIndicatorReducer: Reducer<IState, ActionsType> = (state, action): IState => {
    state = state || defaultState;
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION_INDICATOR:
        {
            return {
                ...state,
                notificationIndicator: action.payload,
            };
        }

        default:
            return state;
    }
};

export default notificationIndicatorReducer;
