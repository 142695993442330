import React from 'react';
import classnames from 'classnames';

interface IBaseProps {
    className?: string;
}

type ComponentType<P> = string | React.ComponentType<P>;

const withClass = <TProps extends IBaseProps>(
    Component: ComponentType<TProps>,
    originClassName: string
): React.FC<TProps> => {
    function WithClass({ className, ...tail }: TProps) {
        return <Component {...(tail as TProps)} className={classnames(className, originClassName)} />;
    }

    WithClass.displayName = `${originClassName}.withClass`;

    return WithClass;
};

export default withClass;
